/* eslint-disable functional/no-try-statements */
import { useQuery } from "@tanstack/react-query"
import { userSelectorClient } from "../api/clients/user-api-client"
import { QueryKeys } from "../api/query-keys"

type FullName = {
    firstName: string
    lastName: string
}

type Adress = {
    street: string
    zip: string
    city: string
    country: string
}

export const usePaymentsInfo = () => {
    const queryFn = async () => {
        const { data: userPaymentInfo } =
            await userSelectorClient.getUserPaymentInfo()
        let firstNameTmp = ""
        let lastNameTmp = ""
        try {
            const { firstName, lastName } = JSON.parse(
                userPaymentInfo.name,
            ) as FullName
            firstNameTmp = firstName
            lastNameTmp = lastName
        } catch (e) {
            console.error(e)
        }
        let streetTmp = ""
        let zipTmp = ""
        let cityTmp = ""
        let countryTmp = ""
        try {
            const { street, zip, city, country } = JSON.parse(
                userPaymentInfo.address,
            ) as Adress
            streetTmp = street
            zipTmp = zip
            cityTmp = city
            countryTmp = country
        } catch (e) {
            console.error(e)
        }

        return {
            id: userPaymentInfo.id,
            isSalesTaxLiable: userPaymentInfo.isSalesTaxLiable,
            taxId: userPaymentInfo.taxId,
            createdAt: userPaymentInfo.createdAt,
            firstName: firstNameTmp,
            lastName: lastNameTmp,
            street: streetTmp,
            zip: zipTmp,
            city: cityTmp,
            country: countryTmp,
        }
    }
    return useQuery({
        queryFn,
        queryKey: QueryKeys.paymentInfo(),
    })
}
