import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Url, UserProfileId, VideoId } from "api/branded-types"
import { uploadClient } from "api/clients/upload-client"
import { videoCreatorClient } from "api/clients/video-api-client"
import { QueryKeys, StaticQueryKeys } from "api/query-keys"
import { useCallback } from "react"
import { useEditVideoPostStore } from "../store/edit-video-post-store"

type UseVideoQueryHelpersModel = {
    videoId?: VideoId
    profileId?: UserProfileId
}

export const useVideoQueryHelpers = (model: UseVideoQueryHelpersModel) => {
    const queryClient = useQueryClient()

    const invalidateVideo = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: QueryKeys.video(model.videoId),
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.payableVideo(model.videoId),
        })
        queryClient.invalidateQueries({
            queryKey: [StaticQueryKeys.SEARCH, StaticQueryKeys.SEARCH_VIDEOS],
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.profileVideoContent(model.profileId),
        })
    }, [queryClient, model.videoId, model.profileId])

    return { invalidateVideo }
}

export type UploadMutationParams = {
    file: File
    url: Url
}

type CreateUploadMutationModel = {
    onSuccess: () => void
    onSettled: () => void
    onProgress: (v: number) => void
    onError?: () => void
}

export const createUploadMutation = ({
    onSuccess,
    onSettled,
    onProgress,
}: CreateUploadMutationModel) =>
    useMutation({
        mutationFn: (params: UploadMutationParams) =>
            uploadClient(params.url, {
                method: "PUT",
                data: params.file,
                onUploadProgress: event =>
                    onProgress((event.loaded / (event.total ?? 1)) * 100),
            }),
        onSettled,
        onSuccess,
    })

type HandleVideoUpdateSuccessModel = {
    result?: Awaited<
        ReturnType<typeof videoCreatorClient.updateVideoUploadById>
    >
    uploadVideo: (params: UploadMutationParams) => void
    uploadImage: (params: UploadMutationParams) => void
    uploadThumbnail: (params: UploadMutationParams) => void
}

export const handleVideoUpdateSuccess = (
    model: HandleVideoUpdateSuccessModel,
) => {
    const editVideoPostStore = useEditVideoPostStore.getState()
    if (!model.result) return
    if (
        !editVideoPostStore.file &&
        !editVideoPostStore.thumbnailFile &&
        !editVideoPostStore.thumbnail
    ) {
        editVideoPostStore.setUpdatedWithoutUploads(true)
        return
    }

    if (editVideoPostStore.file)
        model.uploadVideo({
            url: model.result.uploads.video,
            file: editVideoPostStore.file,
        })
    if (editVideoPostStore.thumbnailFile)
        model.uploadImage({
            url: model.result.uploads.previewImage,
            file: editVideoPostStore.thumbnailFile,
        })
    else if (editVideoPostStore.thumbnail) {
        const uploadFile = new File(
            [editVideoPostStore.thumbnail.blob],
            "thumbnail.jpg",
            {
                type: "image/jpeg",
            },
        )
        model.uploadThumbnail({
            url: model.result.uploads.previewImage,
            file: uploadFile,
        })
    }
}
