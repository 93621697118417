import { MonetizationOption } from "api/api-models"

export const monetizeEqual = (
    m1?: MonetizationOption,
    m2?: MonetizationOption,
) => {
    if (!m1 || !m2) return false

    if (m1.type === "None") {
        return m1.type === m2.type
    } else if (m2.type === "SubscriptionOnly") {
        return m1.amount === m2.amount && m1.currency === m2.currency
    } else return false
}
