import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, CommentedResourceRef, UserComment } from "../api-models"
import { UserCommentId, UserProfileId } from "../branded-types"

export const commentCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/comment",
        description: "Post a Comment",
        alias: "postComment",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    resourceOwnerId: UserProfileId,
                    text: z.string(),
                    resource: CommentedResourceRef,
                }),
            },
        ],
        response: z.object({
            data: UserComment,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/comment/:id",
        description: "Update a Comment",
        alias: "updateCommentById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserCommentId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    text: z.string(),
                }),
            },
        ],
        response: z.object({
            data: UserComment,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/comment/:id",
        description: "Delete a Comment",
        alias: "deleteCommentById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserCommentId,
            },
        ],
        response: z.object({
            data: UserComment,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
