import { useMutation, useQueryClient } from "@tanstack/react-query"

import { UpdateTextPostit } from "api/api-models"
import { PostitId } from "../../../api/branded-types"
import { postitCreatorClient } from "../../../api/clients/post-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../api/query-keys"

export type EditTextPostitMutationModel = {
    onSuccess: () => void
    onError: () => void
}

type MutationFnParams = {
    id: PostitId
    body: UpdateTextPostit
}

export const useEditTextPostitMutation = (
    props: EditTextPostitMutationModel,
) => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: (params: MutationFnParams) =>
            postitCreatorClient.updatePostitById(params.body, {
                params: {
                    id: params.id,
                },
            }),
        mutationKey: [StaticQueryKeys.EDITING_TEXT_POSTIT],

        onSuccess: props.onSuccess,

        onError: err => {
            console.error("use-edit-text-postit-mutation", err)
            props.onError()
        },

        onSettled: (data, _err, _) => {
            if (!data) return
            client.invalidateQueries({
                queryKey: QueryKeys.postit(data.data.id),
            })
            client.invalidateQueries({
                queryKey: QueryKeys.payablePostit(data.data.id),
            })
            client.invalidateQueries({
                queryKey: [
                    StaticQueryKeys.SEARCH,
                    StaticQueryKeys.SEARCH_POSTS,
                ],
            })
            client.invalidateQueries({
                queryKey: QueryKeys.profilePostContent(data.data.creatorId),
            })
        },
    })
}
