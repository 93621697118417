import { faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import classNames from "classnames"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "common/dialog"
import * as styles from "./delete-post-dialog.css"

type DeletePostDialogTextModel = {
    title: string
    description: string
    cancel: string
    submit: string
}

export type DeletePostDialogModel = {
    text: DeletePostDialogTextModel
    isOpen: boolean
    onSubmit: () => void
    onOpenChange: (open: boolean) => void
}
export const DeletePostDialog = (model: DeletePostDialogModel) => {
    return (
        <Dialog open={model.isOpen} onOpenChange={model.onOpenChange}>
            <DialogContent className={styles.content}>
                <FontAwesomeIcon
                    icon={faTrash}
                    className={styles.icon}
                    color="black"
                />
                <div className={styles.textContainer}>
                    <DialogTitle className={styles.title}>
                        {model.text.title}
                    </DialogTitle>
                    <DialogDescription className={styles.description}>
                        {model.text.description}
                    </DialogDescription>
                </div>
                <div className={styles.buttonWrapper}>
                    <IonButton
                        className={classNames(
                            styles.button,
                            styles.cancelButton,
                        )}
                        onClick={() => model.onOpenChange(false)}
                    >
                        {model.text.cancel}
                    </IonButton>
                    <IonButton
                        className={classNames(
                            styles.button,
                            styles.deleteButton,
                        )}
                        onClick={model.onSubmit}
                    >
                        {model.text.submit}
                    </IonButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
