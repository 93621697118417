import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, MessageRecipientRef, UserMessage } from "../api-models"
import { UserMessageId, UserProfileId } from "../branded-types"

export const messageCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/message",
        description: "Post a Message",
        alias: "postMessage",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    senderId: UserProfileId,
                    recipient: MessageRecipientRef,
                    text: z.string(),
                }),
            },
        ],
        response: z.object({
            data: UserMessage,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        description: "Send all message IDs that I have seen.",
        path: "/v1/messages/read",
        alias: "sendSeenMessageIds",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    messageIds: z.array(UserMessageId),
                }),
            },
        ],
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
