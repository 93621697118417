import { useInfiniteQuery } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { v4 as uuidv4 } from "uuid"

import { CommentedResourceRef } from "../../../api/api-models"
import { mapToMatchFilterDc } from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { userCommentSelectorClient } from "../../../api/clients/comment-api-client"
import { userProfileSelectorClient } from "../../../api/clients/user-profile-api-client"
import { QueryKeys } from "../../../api/query-keys"
import { getDefaultUserProfile } from "../../../data-flow/user"

const PAGINATION_AMOUNT = 20

export const useResourceComments = (resource?: CommentedResourceRef) => {
    const queryKey = QueryKeys.resourceComments(resource?.contentId)

    const queryFn = async ({ pageParam }: { pageParam: number }) => {
        if (!resource?.contentId)
            throw new Error("CommentContentId is required")

        const comments = await userCommentSelectorClient.listComments({
            filter: `{$match: {resource.contentType: "${resource.contentType}", resource.contentId: "${resource.contentId}"}}`,
            paging: {
                type: "Index",
                direction: "After",
                limit: PAGINATION_AMOUNT,
                index: pageParam,
            },
        })

        const creatorIds = pipe(
            comments.data,
            A.map(({ creatorId }) => creatorId),
        )

        const profiles = await userProfileSelectorClient.listProfiles({
            filter: `{${mapToMatchFilterDc("id", creatorIds)}}`,
        })

        return pipe(
            comments.data,
            A.map(comment => ({
                id: comment.id,
                userComment: comment,
                userProfile: pipe(
                    profiles.data,
                    A.findFirst(profile => profile.id === comment.creatorId),
                    O.getOrElse(() =>
                        getDefaultUserProfile(UserProfileId.parse(uuidv4())),
                    ),
                ),
            })),
            commentsWithUserData => ({
                total: comments.totalCount,
                commentsWithUserData,
                paging: comments.paging,
            }),
        )
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn,
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.commentsWithUserData.length !== PAGINATION_AMOUNT)
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!resource,
    })
}
