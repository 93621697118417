import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC } from "react"
import { match } from "ts-pattern"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useLiveEventsSearch } from "../../hooks/use-live-event-search"
import {
    LiveEventSearchResultList,
    LiveEventSearchSectionLoading,
} from "./live-events/live-event-search-result-list"
import { ResultNotFoundPlaceholder } from "./placeholder/result-not-found-placeholder"

import { useNavigate } from "react-router-dom"
import { vars } from "theme/variables.css"
import { Content } from "../../common/content"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { Toolbar } from "../../common/toolbar"
import { LocalStorage } from "../../local-storage"
import { liveVideoByIdRoute, userProfileByIdRoute } from "../routing/routing"
import * as styles from "./search-sub-pages.css"

export const SearchLiveEventsPage: FC = () => {
    const navigate = useNavigate()
    const liveEventQuerySearchQuery = useLiveEventsSearch("")
    const userId = LocalStorage.getOidcUserId()

    const liveEventState = match(liveEventQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const refreshSearchLiveEventsPage = async () => {
        await liveEventQuerySearchQuery.refetch()
    }

    return (
        <Page>
            <Toolbar className={styles.toolbar}>
                <NavigationBackButton />
            </Toolbar>
            <Content className={styles.pageContent}>
                <PullToRefresh onRefresh={refreshSearchLiveEventsPage}>
                    <div className={styles.header}>
                        <p
                            style={{
                                marginLeft: 4,
                                fontSize: vars.font.size.xxxl,
                                fontWeight: 700,
                                color: "white",
                            }}
                        >
                            Interesting live events.
                        </p>
                    </div>
                    <InfiniteScroll
                        disabled={!liveEventQuerySearchQuery.hasNextPage}
                        state={liveEventState}
                        threshold="100px"
                        onLoadRequested={
                            liveEventQuerySearchQuery.fetchNextPage
                        }
                    >
                        <LiveEventSearchResultList
                            isLoading={
                                liveEventQuerySearchQuery.isFetchingNextPage ||
                                liveEventQuerySearchQuery.isLoading
                            }
                            text={{ liveText: "Live" }}
                            events={pipe(
                                liveEventQuerySearchQuery?.data?.pages ?? [],
                                A.flatMap(page => page.liveEventAreas),
                                A.map(data => ({
                                    viewCount: data.statistic.viewCount.current,
                                    eventId: data.event.id,
                                    title: data.event.title,
                                    avatar: {
                                        image: data.profile.imageUrl,
                                        isLive: data.profile.isLive,
                                        name:
                                            data.profile.displayName ??
                                            data.profile.profileName,
                                    },
                                    onClicked: () =>
                                        pipe(
                                            liveVideoByIdRoute(
                                                data.event.creatorId,
                                                data.event.id,
                                            ),
                                            route => navigate(route),
                                        ),
                                    onUserClicked: () => {
                                        if (O.isNone(userId)) return

                                        navigate(
                                            userId.value ===
                                                data.profile.id.toString()
                                                ? "/app/my-profile"
                                                : userProfileByIdRoute(
                                                      data.profile.id,
                                                  ),
                                        )
                                    },
                                })),
                            )}
                        />
                    </InfiniteScroll>

                    {liveEventQuerySearchQuery.isSuccess &&
                        pipe(
                            liveEventQuerySearchQuery.data.pages,
                            A.flatMap(page => page.liveEventAreas),
                            events =>
                                events.length === 0 ? (
                                    <ResultNotFoundPlaceholder
                                        //TODO: localize this and style properly (only temporary solution)
                                        title={"Currently no live events"}
                                        description={"Try again later..."}
                                    />
                                ) : (
                                    <></>
                                ),
                        )}
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const SearchLiveEventsPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={styles.pageContent}>
            <LiveEventSearchSectionLoading />
        </Content>
    </Page>
)

export default SearchLiveEventsPage
