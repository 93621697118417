import { faXmark } from "@fortawesome/pro-light-svg-icons"
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useMemo, useRef } from "react"
import { match } from "ts-pattern"

import { VotedResourceRef } from "../../api/api-models"
import { UserProfileId } from "../../api/branded-types"
import {
    UserListItem,
    UserListLoadingItem,
} from "../../features/feed/areas/user/user-list-item"
import { useResourceVotes } from "../../features/video/hooks/use-resource-votes"
import { fill } from "../../utils/array"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InfiniteScroll } from "../../common/infinite-scroll"
import * as styles from "./voter-overview-modal.css"

export type VoterOverviewPageModel = {
    title: string
    isOpen: boolean
    voteContentRef: O.Option<VotedResourceRef>
    onClosed: () => void
    onUserClicked: (id: UserProfileId) => void
}
//TODO: implement using `Drawer`
export const VoterOverviewModal: FC<VoterOverviewPageModel> = ({
    title,
    isOpen,
    voteContentRef,

    onClosed,
    onUserClicked,
}) => {
    const modal = useRef<HTMLIonModalElement>(null)

    const resourceVotesQuery = useResourceVotes(
        O.isSome(voteContentRef) ? voteContentRef.value : undefined,
    )

    const votesState = match(resourceVotesQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const profiles = useMemo(
        () =>
            pipe(
                resourceVotesQuery.data?.pages ?? [],
                A.flatMap(page => page.profiles),
            ),
        [resourceVotesQuery.data?.pages],
    )

    return (
        <IonModal
            ref={modal}
            className={styles.modal}
            isOpen={modal.current !== undefined && isOpen}
            onDidDismiss={onClosed}
            initialBreakpoint={1}
            breakpoints={[0, 1]}
            animated
            backdropDismiss
            showBackdrop
        >
            <IonHeader className="ion-no-border">
                <IonToolbar className={styles.toolbar}>
                    <IonButtons>
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={onClosed}
                            className={styles.closeIcon}
                        />
                    </IonButtons>
                    <IonTitle>{title}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.content}>
                <IonHeader collapse="condense">
                    <IonToolbar className={styles.titleToolbar}>
                        <h1 className={styles.title}>{title}</h1>
                    </IonToolbar>
                </IonHeader>
                <InfiniteScroll
                    disabled={!resourceVotesQuery.hasNextPage}
                    state={votesState}
                    onLoadRequested={resourceVotesQuery.fetchNextPage}
                    threshold="100px"
                >
                    <div className={styles.contentBox}>
                        {resourceVotesQuery.isSuccess &&
                            pipe(
                                profiles,
                                A.map(profile => (
                                    <UserListItem
                                        className={styles.userListItem}
                                        key={profile.id}
                                        onClicked={onUserClicked}
                                        profile={profile}
                                        avatarSize={40}
                                    />
                                )),
                            )}
                        {votesState === "Loading" &&
                            fill(5, i => (
                                <UserListLoadingItem
                                    key={i}
                                    className={styles.userListItem}
                                />
                            ))}
                    </div>
                </InfiniteScroll>
            </IonContent>
        </IonModal>
    )
}
