import { useQuery } from "@tanstack/react-query"
import { UserProfileId } from "../../../api/branded-types"
import { notificationSelectorClient } from "../../../api/clients/notification-api-client"
import { QueryKeys } from "../../../api/query-keys"

export const useUnreadNotifications = (profileId?: UserProfileId) => {
    const readFilter = `notificationType: "NewNotification"`
    const tagFilter = `pushNotification.tag: in ["Subscribe", "Follow", "Like", "Comment", "Created"]`
    const profileFilter = `receiverId: "${profileId}"`

    const filter = `{$match:{${profileFilter}, ${tagFilter}, ${readFilter}}}`
    const queryFn = async () => {
        if (!profileId) throw new Error("profileId should be defined")

        const { totalCount } =
            await notificationSelectorClient.listNotifications({
                filter,
                paging: {
                    type: "Index",
                    direction: "After",
                    limit: 0,
                },
            })
        return { totalCount }
    }

    return useQuery({
        queryFn,
        queryKey: QueryKeys.unreadNotifications(profileId),
        enabled: !!profileId,
    })
}
