import { getIsAuthorizedAccount } from "api/api-utils"
import { UserProfileId } from "api/branded-types"
import { useNotificationMessagesHub } from "features/notifications/hooks/use-notifications-hub"
import { useProfileStatistics } from "features/user-profile/hooks/use-profile-statistic"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useMe } from "hooks/use-me"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useProfileChallengesOverview } from "hooks/use-profile-challenges-overview"
import { LocalStorage } from "local-storage"
import { FC, useEffect, useMemo } from "react"
import { useAppStore } from "store"
import { ChallgengeCompleteModal } from "./challenge-complete-modal"
import { CongratulationBronzeModal } from "./congratulation/congratulation-bronze-modal"
import { CongratulationGoldModal } from "./congratulation/congratulation-gold-modal"
import { CongratulationPlatinumModal } from "./congratulation/congratulation-platinum-modal"
import { CongratulationSilverModal } from "./congratulation/congratulation-silver-modal"
import { CreateContentCompleteModal } from "./create-content-complete-modal"
import { FirstStepsCompleteModal } from "./first-steps-complete-modal"
import { FirstStepsWelcomeModal } from "./first-steps-welcome-modal"
import { DailyLoginModal } from "./repeated-tasks/daily-login-modal"
import { useModalQueueStore as useModalQueueSystemStore } from "./store/modal-queue-system-store"
import { usePointSystemModalStore } from "./store/point-system-modal-store"

export const PointSystemModalProvider: FC = () => {
    const meQuery = useMe()

    const { handleCloseModal } = useModalQueueSystemStore()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const myProfileIdQuery = useMyUserProfileId(isAuthorizedAccount)

    const profileChallengesOverviewQuery = useProfileChallengesOverview(
        myProfileIdQuery.data,
    )

    const createContentCompleteModalOpen = usePointSystemModalStore(
        store => store.createContentCompleteModalOpen,
    )
    const setCreateContentCompleteModalOpen = usePointSystemModalStore(
        store => store.setCreateContentCompleteModalOpen,
    )

    const challengeCompleteModalOpen = usePointSystemModalStore(
        store => store.challengeCompleteModalOpen,
    )
    const setChallengeCompleteModalOpen = usePointSystemModalStore(
        store => store.setChallengeCompleteModalOpen,
    )

    const firstStepsModalOpen = usePointSystemModalStore(
        store => store.firstStepsModalOpen,
    )
    const setFirstStepsModalOpen = usePointSystemModalStore(
        store => store.setFirstStepsModalOpen,
    )

    const dailyStreakModalOpen = usePointSystemModalStore(
        store => store.dailyStreakModalOpen,
    )
    const setDailyStreakModalOpen = usePointSystemModalStore(
        store => store.setDailyStreakModalOpen,
    )

    const setLevelUpModalOpen = usePointSystemModalStore(
        store => store.setLevelUpModalOpen,
    )

    const firstWelcomeModalOpen = useAppStore(
        store => store.firstWelcomeModalOpen,
    )
    const setFirstWelcomeModalOpen = useAppStore(
        store => store.setFirstWelcomeModalOpen,
    )

    const firstStepsNotCompleted = useMemo(
        () =>
            profileChallengesOverviewQuery.isSuccess &&
            !profileChallengesOverviewQuery.data.firstSteps.every(
                task => task.progress.required === task.progress.done,
            ),
        [profileChallengesOverviewQuery.status],
    )

    useEffect(() => {
        const isFirstStepsModalOpened = sessionStorage.getItem(
            "firstStepsModalOpened",
        )

        if (firstStepsNotCompleted && !isFirstStepsModalOpened) {
            setFirstWelcomeModalOpen(true)
            sessionStorage.setItem("firstStepsModalOpened", "true")
        }
    }, [firstStepsNotCompleted, setFirstWelcomeModalOpen])

    useNotificationMessagesHub()

    return (
        <>
            <CreateContentCompleteModal
                isOpen={createContentCompleteModalOpen}
                onOpenChange={open => {
                    setCreateContentCompleteModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />

            <ChallgengeCompleteModal
                isOpen={challengeCompleteModalOpen}
                onOpenChange={open => {
                    setChallengeCompleteModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />

            <FirstStepsCompleteModal
                isOpen={firstStepsModalOpen}
                onOpenChange={open => {
                    setFirstStepsModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />
            <FirstStepsWelcomeModal
                isOpen={firstWelcomeModalOpen}
                onOpenChange={setFirstWelcomeModalOpen}
            />
            <DailyLoginModal
                isOpen={dailyStreakModalOpen}
                onOpenChange={open => {
                    setDailyStreakModalOpen(open)
                    if (!open) handleCloseModal()
                }}
                streak={4}
            />
            <CongratulationModalProvider
                profileId={myProfileIdQuery.data}
                onOpenChange={open => {
                    setLevelUpModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />
        </>
    )
}

type CongratulationModalProviderModel = {
    profileId?: UserProfileId
    onOpenChange: (v: boolean) => void
}

const CongratulationModalProvider: FC<
    CongratulationModalProviderModel
> = model => {
    const levelUpModalOpen = usePointSystemModalStore(
        store => store.levelUpModalOpen,
    )
    const profileStatisticsQuery = useProfileStatistics(model.profileId)

    const isBronzeOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Bronze"
    const isSilverOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Silver"
    const isGoldOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Gold"
    const isPlatinumOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Platinum"

    return (
        <>
            <CongratulationBronzeModal
                isOpen={isBronzeOpen}
                onOpenChange={model.onOpenChange}
            />
            <CongratulationSilverModal
                isOpen={isSilverOpen}
                onOpenChange={model.onOpenChange}
            />
            <CongratulationGoldModal
                isOpen={isGoldOpen}
                onOpenChange={model.onOpenChange}
            />
            <CongratulationPlatinumModal
                isOpen={isPlatinumOpen}
                onOpenChange={model.onOpenChange}
            />
        </>
    )
}
