import { create } from "zustand"

type State = {
    successPaymentModalOpen: boolean
    failedPaymentModalOpen: boolean
}

type Actions = {
    setSuccessPaymentModalOpen: (v: State["successPaymentModalOpen"]) => void
    setFailedPaymentModalOpen: (v: State["failedPaymentModalOpen"]) => void
}

export const paymentModalStore = create<State & Actions>(set => ({
    failedPaymentModalOpen: false,
    setFailedPaymentModalOpen: v => set({ failedPaymentModalOpen: v }),
    successPaymentModalOpen: false,
    setSuccessPaymentModalOpen: v => set({ successPaymentModalOpen: v }),
}))
