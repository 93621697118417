import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    tokenHandlerForGet,
    validationErrorHandler,
} from "../api-utils"
import { liveEventCreatorApi } from "../creators/live-event-creator-api"
import { liveEventSelectorApi } from "../selectors/live-event-selector-api"

// creator client
export const liveEventCreatorClient = new Zodios(
    `${API_URL}/live-event-creator`,
    liveEventCreatorApi,
)

liveEventCreatorClient.use(tokenHandlerForCreate)
liveEventCreatorClient.use(corsFetchHandler)
liveEventCreatorClient.use(validationErrorHandler)

// selector client
export const liveEventSelectorClient = new Zodios(
    `${API_URL}/live-event-selector`,
    liveEventSelectorApi,
)

liveEventSelectorClient.use(tokenHandlerForGet)
liveEventSelectorClient.use(corsFetchHandler)
liveEventSelectorClient.use(validationErrorHandler)

export const listLiveEventsTask = pipe(
    liveEventSelectorClient.listLiveEvents,
    endpointWithTaskEither,
)
export const getLiveEventByIdTask = pipe(
    liveEventSelectorClient.getLiveEventById,
    endpointWithTaskEither,
)
export const getLiveEventStatisticByIdTask = pipe(
    liveEventSelectorClient.getLiveEventStatisticById,
    endpointWithTaskEither,
)
export const getAudienceUrlByIdTask = pipe(
    liveEventSelectorClient.getAudienceUrlById,
    endpointWithTaskEither,
)
export const getStartedLiveEventByIdTask = pipe(
    liveEventSelectorClient.getStartedLiveEventById,
    endpointWithTaskEither,
)
export const listLiveEventsStatisticsTask = pipe(
    liveEventSelectorClient.listLiveEventsStatistics,
    endpointWithTaskEither,
)

export const listViewersByLiveEventIdTask = pipe(
    liveEventSelectorClient.listViewersByLiveEventId,
    endpointWithTaskEither,
)

export const listCurrentViewersByLiveEventIdTask = pipe(
    liveEventSelectorClient.listCurrentViewersByLiveEventId,
    endpointWithTaskEither,
)
