import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    validationErrorHandler,
} from "../api-utils"
import { commentCreatorApi } from "../creators/comment-creator-api"
import { commentSelectorApi } from "../selectors/comment-selector-api"

// creator client
export const userCommentCreatorClient = new Zodios(
    `${API_URL}/comment-creator`,
    commentCreatorApi,
)

userCommentCreatorClient.use(tokenHandlerForCreate)
userCommentCreatorClient.use(corsFetchHandler)
userCommentCreatorClient.use(validationErrorHandler)

export const postCommentTask = pipe(
    userCommentCreatorClient.postComment,
    endpointWithTaskEither,
)

// selector client
export const userCommentSelectorClient = new Zodios(
    `${API_URL}/comment-selector`,
    commentSelectorApi,
)

userCommentSelectorClient.use(tokenHandlerForCreate)
userCommentSelectorClient.use(corsFetchHandler)
userCommentSelectorClient.use(validationErrorHandler)

export const getCommentTask = pipe(
    userCommentSelectorClient.getComment,
    endpointWithTaskEither,
)

export const listCommentsTask = pipe(
    userCommentSelectorClient.listComments,
    endpointWithTaskEither,
)
