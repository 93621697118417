import { ImageType } from "api/api-models"
import { Url } from "api/branded-types"
import { FC, useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useAssetData } from "../hooks/use-asset"

/**
 * @param src - The url for assed data (blob)
 */
export const Image: FC<
    React.ComponentPropsWithoutRef<"img"> & {
        src?: Url
        imageType?: ImageType
        color?: string
    }
> = ({ src, imageType = "Jpeg", color, ...props }) => {
    const assetDataQuery = useAssetData(src)
    const [imageSrc, setImageSrc] = useState<string>("")

    useEffect(() => {
        if (!imageType) return
        let objectUrl: string | null = null

        if (assetDataQuery.isSuccess && assetDataQuery.data) {
            const blob = assetDataQuery.data // URL returned by useAssetData

            if (imageType === "Jpeg" || imageType === "Png") {
                const objectUrl = URL.createObjectURL(blob)
                setImageSrc(objectUrl)
            } else if (imageType === "Svg") {
                blob.text()
                    .then(svgContent => {
                        // Wrap with <svg> tags if necessary
                        const svgContentWithColor = color
                            ? replaceFillContent(svgContent, color)
                            : svgContent
                        let svgText = svgContentWithColor
                        if (!svgContent.includes("<svg")) {
                            svgText = `<svg width="${props.width}" height="${props.height}" xmlns="http://www.w3.org/2000/svg">${svgContentWithColor}</svg>`
                        }
                        // Create a new Blob from the modified SVG content
                        const svgBlob = new Blob([svgText], {
                            type: "image/svg+xml",
                        })
                        objectUrl = URL.createObjectURL(svgBlob)
                        setImageSrc(objectUrl)
                    })
                    .catch(error => {
                        console.error("Error reading SVG Blob:", error)
                        //todo: add fallback url
                        setImageSrc("")
                    })
            }
        } else {
            //todo: add fallback url
            setImageSrc("")
        }

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl)
            }
        }
    }, [
        assetDataQuery.isSuccess,
        assetDataQuery.data,
        imageType,
        props.width,
        props.height,
    ])

    return <LazyLoadImage src={imageSrc} {...props} />
}

const replaceFillContent = (inputString: string, newContent: string) =>
    inputString.replace(/fill="[^"]*"/g, `fill="${newContent}"`)
