import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    DatabaseNotification,
    NotificationSubscription,
    NotificationSubscriptionType,
    Pagination,
} from "../api-models"

export const notificationSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/notifications",
        alias: "listNotifications",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(DatabaseNotification),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/subscriptions",
        alias: "listNotificationSubscriptions",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(NotificationSubscription),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/subscriptions/:subscriptionType",
        alias: "listNotificationSubscriptionsByType",
        parameters: [
            {
                name: "subscriptionType",
                type: "Path",
                schema: NotificationSubscriptionType,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(NotificationSubscription),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
