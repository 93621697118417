import { useQuery } from "@tanstack/react-query"

import { UserId } from "api/branded-types"
import { userSelectorClient } from "../api/clients/user-api-client"
import { QueryKeys } from "../api/query-keys"

export const useUser = (userId?: UserId) => {
    return useQuery({
        queryFn: () => getUserById(userId),
        queryKey: QueryKeys.user(userId),
        enabled: !!userId,
    })
}

const getUserById = async (userId?: UserId) => {
    if (!userId) throw new Error("UserId is required")
    const meRes = await userSelectorClient.getUserById({
        params: { id: userId },
    })
    return meRes.data
}
