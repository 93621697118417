import { MonetizationOption } from "api/api-models"
import * as TE from "fp-ts/TaskEither"
import { pipe } from "fp-ts/function"
import { readFileAsBlob } from "utils/file"
import { create } from "zustand"

type State = {
    file?: File
    imageBlob?: Blob
    backgroundImageUrl?: string

    title?: string
    message?: string
    monetization?: MonetizationOption

    updatedWithoutUploads: boolean
}

type Actions = {
    setTitle: (v: State["title"]) => void
    setMessage: (v: State["message"]) => void
    setMonetization: (v: State["monetization"]) => void
    setFile: (v: State["file"]) => void
    setUpdatedWithoutUploads: (v: State["updatedWithoutUploads"]) => void

    reset: () => void
}

const initialState: State = {
    title: undefined,
    message: undefined,
    monetization: undefined,
    file: undefined,
    imageBlob: undefined,
    backgroundImageUrl: undefined,
    updatedWithoutUploads: false,
}

export const useEditImagePostStore = create<State & Actions>(set => ({
    ...initialState,

    setTitle: v => set({ title: v }),
    setMessage: v => set({ message: v }),
    setMonetization: v => set({ monetization: v }),
    setFile: file => {
        if (!file) {
            set({
                file,
            })
            return
        }
        pipe(
            readFileAsBlob(file),
            TE.map(blob => {
                const url = URL.createObjectURL(blob)
                set({
                    file,
                    imageBlob: blob,
                    backgroundImageUrl: url,
                })
            }),
        )()
    },
    setUpdatedWithoutUploads: v => set({ updatedWithoutUploads: v }),

    reset: () => set(initialState),
}))
