import { faHexagonExclamation } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import { vars } from "theme/variables.css"
import * as styles from "./error-state.css"
type ErrorStateModel = {
    onTryAgainClicked: () => void
}

export const ErrorState: FC<ErrorStateModel> = model => (
    <div className={styles.errorStateContainer}>
        <FontAwesomeIcon
            icon={faHexagonExclamation}
            size="2xl"
            className={styles.errorIcon}
        />
        <div className={styles.errorTextContainer}>
            <p
                style={{ fontSize: vars.font.size.m }}
                className={styles.errorStateTitle}
            >
                Ooops ...
            </p>
            <p
                style={{ fontSize: vars.font.size.regular }}
                className={styles.errorStateDescription}
            >
                An error has occured
            </p>
            <p
                style={{ fontSize: vars.font.size.regular }}
                className={styles.errorStateAction}
                onClick={model.onTryAgainClicked}
            >
                Please try again.
            </p>
        </div>
    </div>
)
