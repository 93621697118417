import { FC } from "react"

import { VisibilityAdt } from "../../data-flow/common"

import { pipe } from "fp-ts/function"
import { vars } from "theme/variables.css"
import { UserMessage } from "../../api/api-models"
import { FoldableStatefulText } from "../../components/texts/foldable-stateful-text"
import * as styles from "./my-message-item.css"
import { messageTimeInfo } from "./time-format"

type Props = {
    showTime: boolean
    message: UserMessage
}

export const MyMessageItem: FC<Props> = ({ showTime, message }) => (
    <div className={styles.message}>
        {showTime && (
            <p
                style={{
                    textAlign: "right",
                    margin: "0 16px",
                    color: vars.color.tertiary.hex,
                    fontSize: vars.font.size.xs,
                }}
            >
                {pipe(message.createdAt, messageTimeInfo)}
            </p>
        )}

        <FoldableStatefulText
            className={styles.text}
            visibility={VisibilityAdt.of.Invisible({})}
            maxLength={750}
            text={message.text.trim()}
            fontSize={vars.font.size.regular}
        />
    </div>
)
