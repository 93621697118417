import { FC } from "react"

import { UserComment, UserProfile } from "../../api/api-models"
import { UserProfileId } from "../../api/branded-types"
import { VisibilityAdt } from "../../data-flow/common"
import { Avatar } from "../avatars/avatar"
import { FoldableStatefulText } from "../texts/foldable-stateful-text"

import { vars } from "theme/variables.css"
import * as styles from "./user-comment-item.css"

type UserCommentItemProps = {
    profile: UserProfile
    comment: UserComment
    onUserClicked: (id: UserProfileId) => void
}

export const UserCommentItem: FC<UserCommentItemProps> = ({
    profile,
    comment,
    onUserClicked,
}) => (
    <div className={styles.comment}>
        <Avatar
            size={36}
            className={styles.commentAvatar}
            src={profile.imageUrl}
            onClicked={() => onUserClicked(profile.id)}
        />
        <div className={styles.commentTextWrapper}>
            <p
                className={styles.commentUsername}
                style={{ fontSize: vars.font.size.m }}
            >
                {profile.displayName}
            </p>

            <FoldableStatefulText
                className={styles.text}
                visibility={VisibilityAdt.of.Invisible({})}
                maxLength={250}
                text={comment.text.trim()}
                fontSize={vars.font.size.regular}
            />
        </div>
    </div>
)
