import { useMutation, useQueryClient } from "@tanstack/react-query"

import { QueryKeys } from "api/query-keys"
import { UserMessageId, UserProfileId } from "../../../api/branded-types"
import { userMessageCreatorClient } from "../../../api/clients/message-api-client"

type Params = {
    messageIds: UserMessageId[]
}

export const useSeenMessageMutation = (myProfileId?: UserProfileId) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ messageIds }: Params) =>
            userMessageCreatorClient.sendSeenMessageIds({
                messageIds,
            }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.myResourceMessages(myProfileId),
            })
        },
    })
}
