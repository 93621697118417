import { FC, PropsWithChildren } from "react"

import { vars } from "theme/variables.css"
import * as styles from "./section-with-title.css"

export type SectionWithTitleModel = {
    className?: string
    title: string
    showTitle?: boolean
    action?: {
        text: string
        onClick: () => void
    }
}

export const SectionWithTitle: FC<PropsWithChildren<SectionWithTitleModel>> = ({
    title,
    showTitle = false,
    className,
    action,
    children,
}) => (
    <div className={className}>
        {showTitle && (
            <div className={styles.header}>
                <p
                    style={{ fontSize: vars.font.size.l }}
                    className={styles.title}
                >
                    {title}
                </p>

                {action && (
                    <p
                        style={{ fontSize: vars.font.size.regular }}
                        className={styles.action}
                        onClick={action?.onClick}
                    >
                        {action.text}
                    </p>
                )}
            </div>
        )}

        {children}
    </div>
)
