import { PointSystemTaskCompleteMessage } from "api/api-models"
import { absurd } from "fp-ts/function"
import { create } from "zustand"
import { usePointSystemModalStore } from "./point-system-modal-store"

type ModalQueueState = {
    modalQueue: PointSystemTaskCompleteMessage[]
    isProcessing: boolean
    addToQueue: (message: PointSystemTaskCompleteMessage) => void
    processNextMessage: () => void
    handleCloseModal: () => void
    setProcessing: (isProcessing: boolean) => void
}

export const useModalQueueStore = create<ModalQueueState>((set, get) => ({
    modalQueue: [],
    isProcessing: false,

    addToQueue: (message: PointSystemTaskCompleteMessage) => {
        set(state => ({
            modalQueue: [...state.modalQueue, message],
        }))
        const { isProcessing, processNextMessage } = get()
        if (!isProcessing) {
            processNextMessage() // Process the next message if nothing is processing
        }
    },

    processNextMessage: () => {
        const { isProcessing, modalQueue, setProcessing } = get()
        if (isProcessing || modalQueue.length === 0) return

        const {
            setCreateContentCompleteModalOpen,
            setLevelUpModalOpen,
            setCreateContentCompleteModalState,
            setFirstStepState,
            setFirstStepsModalOpen,
            setChallengeCompleteModalState,
            setChallengeCompleteModalOpen,
        } = usePointSystemModalStore.getState()

        const message = modalQueue[0]
        setProcessing(true)

        switch (message.type) {
            case "tier": {
                if (message.eventType === "LevelUp") {
                    setLevelUpModalOpen(true)
                    break
                }
                //TODO: add level down handling if there will be any
                break
            }
            case "challenge": {
                setCreateContentCompleteModalState(message)
                setCreateContentCompleteModalOpen(true)
                break
            }
            case "challengeGroup": {
                if (message.groupType === "FirstSteps") {
                    setFirstStepState(message)
                    setFirstStepsModalOpen(true)
                } else {
                    setChallengeCompleteModalState(message)
                    setChallengeCompleteModalOpen(true)
                }
                break
            }
            default: {
                absurd(message)
            }
        }
    },

    handleCloseModal: () => {
        set(state => ({
            modalQueue: state.modalQueue.slice(1),
            isProcessing: false,
        }))
        const { modalQueue, processNextMessage } = get()
        if (modalQueue.length > 0) {
            processNextMessage()
        }
    },

    setProcessing: (isProcessing: boolean) => {
        set({ isProcessing })
    },
}))
