import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query"

import { Asset, ResourceRef, ResourceUrl } from "api/api-models"
import { getQueryClientMissingIds, mapToMatchFilterDc } from "api/api-utils"
import { Url } from "api/branded-types"
import { assetSelectorClient } from "api/clients/asset-api-client"
import { getAssetById, getAssetData } from "utils/asset"
import { QueryKeys } from "../api/query-keys"

type UseAssetsProps = {
    resources: ResourceRef[]
}

export const getAssetsQueryFn = async ({
    resourceRefs,
    queryClient,
}: {
    resourceRefs: ResourceRef[]
    queryClient: QueryClient
}) => {
    const missingAssetIds = getQueryClientMissingIds(
        queryClient,
        resourceRefs,
        QueryKeys.asset,
    ).map(id => id.resourceId)

    if (missingAssetIds.length === 0) return []

    const filter = `{${mapToMatchFilterDc("id", missingAssetIds)}}`
    const assetResponse = await assetSelectorClient.listAssets({ filter })

    assetResponse.data.forEach(asset =>
        queryClient.setQueryData<Asset>(QueryKeys.asset(asset.id), asset),
    )

    const assets = resourceRefs
        .map(id => {
            const cachedData = queryClient.getQueryData<Asset>(
                QueryKeys.asset(id),
            )
            return (
                cachedData ||
                assetResponse.data.find(
                    item => item.id.resourceId === id.resourceId,
                )
            )
        })
        .filter(Boolean) as Asset[]

    return assets
}

export const useAssets = (props: UseAssetsProps) => {
    const queryClient = useQueryClient()
    return useQuery({
        queryKey: QueryKeys.assets(props.resources),
        queryFn: () =>
            getAssetsQueryFn({ resourceRefs: props.resources, queryClient }),
        enabled: props.resources.length > 0,
    })
}

export const getAssetQueryFn = async (assetUrl?: ResourceUrl) => {
    if (!assetUrl) throw new Error("assetUrl must be defined")
    const { data: asset } = await getAssetById(assetUrl)
    return asset
}

type UseAssetProps = {
    resource?: ResourceRef
    enabled?: boolean
}

export const useAsset = (props: UseAssetProps) => {
    const assetUrl = props.resource
        ? (`${props.resource.resourceHost}${props.resource.resourceId}` as const)
        : undefined
    return useQuery({
        queryFn: () => getAssetQueryFn(assetUrl),
        queryKey: QueryKeys.asset(props.resource),
        enabled: !!props.resource && (props.enabled ?? true),
    })
}

export const useAssetData = (src?: Url) => {
    return useQuery({
        queryKey: QueryKeys.assetData(src),
        queryFn: async () => getAssetData(src),
        staleTime: 5 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
        retry: 2,
        refetchOnWindowFocus: false,
        enabled: !!src,
    })
}
