import { faEye } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonBadge, IonLabel } from "@ionic/react"
import { FC } from "react"

import { LiveEventId, UserProfileId } from "../../../../api/branded-types"
import { LiveAreaFeedModel } from "../../../../data-flow/feed/live-area-feed"
import { UserDetails } from "../user/user-details"

import { vars } from "theme/variables.css"
import * as styles from "./live-now-area-tile.css"

export type LiveNowAreaTileModel = {
    text: { liveState: string }
    liveArea: LiveAreaFeedModel
    onUserClicked: (id: UserProfileId) => void
    onClicked: (profileId: UserProfileId, id: LiveEventId) => void
}

export const LiveNowAreaTile: FC<LiveNowAreaTileModel> = ({
    text,
    liveArea,
    onUserClicked,
    onClicked,
}) => (
    <div
        className={styles.wrapper}
        onClick={() => onClicked(liveArea.event.creatorId, liveArea.event.id)}
    >
        <div className={styles.header}>
            <IonBadge className={styles.liveBadge}>{text.liveState}</IonBadge>
            <IonBadge className={styles.statBadge}>
                <FontAwesomeIcon
                    className={styles.viewerCountIcon}
                    icon={faEye}
                    color="white"
                />
                {liveArea.statistic.viewCount.current}
            </IonBadge>
        </div>
        <div className={styles.content}>
            <IonLabel>
                <p
                    style={{
                        fontSize: vars.font.size.m,
                        color: "white",
                    }}
                >
                    {liveArea.event.title}
                </p>
            </IonLabel>
        </div>
        <div className={styles.footer}>
            <UserDetails
                contentLastChangedAt={liveArea.event.lastChangedAt}
                stopPropagation
                paymentRequired={false}
                profile={liveArea.profile}
                avatarSize={36}
                onClicked={onUserClicked}
            />
        </div>
    </div>
)
