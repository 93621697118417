import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, ContentShareType } from "../api-models"
import { ShareToken } from "../branded-types"

export const authCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/sharing/token",
        description: "Creates a share token.",
        alias: "createShareToken",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    contents: z.array(ContentShareType),
                }),
            },
        ],
        response: z.object({
            data: ShareToken,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
