import classNames from "classnames"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { animate, motion, useMotionValue, useTransform } from "framer-motion"
import { FC, useEffect } from "react"

import { UserProfileId } from "../../../api/branded-types"
import { useProfileStatistics } from "../hooks/use-profile-statistic"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./profile-statistics.css"

type ProfileStatisticsTextModel = {
    posts: string
    following: string
    followers: string
}

export type ProfileStatisticsModel = {
    id?: UserProfileId
    text: ProfileStatisticsTextModel
    onFollowedViewClicked: (id: UserProfileId) => void
    onFollowingViewClicked: (id: UserProfileId) => void
}

export const ProfileStatistics: FC<ProfileStatisticsModel> = ({
    id,
    text,
    onFollowedViewClicked,
    onFollowingViewClicked,
}) => {
    const followerCount = useMotionValue(0)
    const roundedFollowerCount = useTransform(followerCount, Math.round)
    const profileStatistics = useProfileStatistics(id)

    useEffect(
        () => {
            if (!profileStatistics.isSuccess) return
            const animation = animate(
                followerCount,
                profileStatistics.data.count.followers,
                { duration: 0.25, ease: "easeIn", delay: 0.25 },
            )
            return animation.stop
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profileStatistics],
    )

    return (
        <>
            {profileStatistics.isSuccess && (
                <div className={styles.userStats}>
                    <div className={styles.column}>
                        <p
                            className={styles.statsText}
                            style={{ fontSize: vars.font.size.xs }}
                        >
                            {text.posts}
                        </p>
                        <p
                            className={classNames(
                                styles.statsText,
                                styles.statsTextValues,
                            )}
                            style={{ fontSize: vars.font.size.m }}
                        >
                            {profileStatistics.data.count.posts}
                        </p>
                    </div>

                    <div
                        className={styles.column}
                        onClick={() =>
                            pipe(
                                profileStatistics.data.count.followers,
                                O.fromPredicate(stat => stat !== 0),
                                O.map(() =>
                                    pipe(
                                        id,
                                        O.fromNullable,
                                        O.fold(
                                            () => undefined,
                                            onFollowedViewClicked,
                                        ),
                                    ),
                                ),
                            )
                        }
                    >
                        <p
                            className={styles.statsText}
                            style={{ fontSize: vars.font.size.xs }}
                        >
                            {text.followers}
                        </p>
                        <motion.p
                            key="follower-count"
                            className={classNames(
                                styles.statsText,
                                styles.statsTextValues,
                            )}
                            style={{ fontSize: vars.font.size.m }}
                        >
                            {roundedFollowerCount}
                        </motion.p>
                    </div>

                    <div
                        className={styles.column}
                        onClick={() =>
                            pipe(
                                profileStatistics.data.count.following,
                                O.fromPredicate(stat => stat !== 0),
                                O.map(() =>
                                    pipe(
                                        id,
                                        O.fromNullable,
                                        O.fold(
                                            () => undefined,
                                            onFollowingViewClicked,
                                        ),
                                    ),
                                ),
                            )
                        }
                    >
                        <p
                            className={styles.statsText}
                            style={{ fontSize: vars.font.size.xs }}
                        >
                            {text.following}
                        </p>
                        <p
                            className={classNames(
                                styles.statsText,
                                styles.statsTextValues,
                            )}
                            style={{ fontSize: vars.font.size.m }}
                        >
                            {profileStatistics.data.count.following}
                        </p>
                    </div>
                </div>
            )}
            {profileStatistics.isLoading && <ProfileStatisticsLoading />}
        </>
    )
}

export const ProfileStatisticsLoading: FC = () => (
    <div className={styles.userStatsLoading}>
        <Skeleton width="100%" height={54} borderRadius={12} />
    </div>
)
