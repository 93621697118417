import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { isImageAsset, isResourceRef } from "utils/asset"
import { Postit, UserProfile } from "../api/api-models"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mapToNotMatchFilterDc,
    mergeWithQueryCache,
} from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { postitSelectorClient } from "../api/clients/post-api-client"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"
import { PostitArea } from "../features/feed/areas/post/post-area-tile"
import { getAssetsQueryFn } from "./use-asset"
import { UserFollowingsInfiniteData } from "./use-user-followings"

const PAGINATION_AMOUNT = 25

export const useRecommendedPostits = (profileId?: UserProfileId) => {
    const queryClient = useQueryClient()

    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            listRecommendedPostits({ pageParam, profileId, queryClient }),
        queryKey: QueryKeys.recommendedPostits(profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.items.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
    })
}

const listRecommendedPostits = async ({
    pageParam,
    profileId,
    queryClient,
}: {
    pageParam: number
    profileId?: UserProfileId
    queryClient: QueryClient
}) => {
    const userFollowingsCache =
        queryClient.getQueryData<UserFollowingsInfiniteData>(
            QueryKeys.profileFollowings(profileId),
        )
    let profilesWhichUserFollows: UserProfile[] = []

    if (userFollowingsCache) {
        profilesWhichUserFollows = userFollowingsCache.pages.flatMap(
            page => page.items,
        )
    } else if (!userFollowingsCache && profileId) {
        //TODO: when proper query merging is possible - use caching call here
        profilesWhichUserFollows = await userProfileSelectorClient
            .listProfilesWhichUserProfileIdFollows(
                {},
                { params: { id: profileId } },
            )
            .then(res => res.data)
    }

    let profileIds = pipe(
        profilesWhichUserFollows,
        A.map(profile => profile.id),
    )

    profileIds = profileId ? [...profileIds, profileId] : profileIds

    const posts = await postitSelectorClient.listPostits({
        filter: `{${mapToNotMatchFilterDc("creatorId", profileIds)}}`,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })
    posts.data.forEach(post =>
        queryClient.setQueryData<Postit>(QueryKeys.postit(post.id), post),
    )

    const postResourceAssetRefs = pipe(
        posts.data,
        A.filter(post => post.type === "Image"),
        A.map(post => post.imageUrl),
        A.filter(isResourceRef),
    )

    const creatorIds = pipe(
        posts.data,
        A.map(post => post.creatorId),
    )

    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        creatorIds,
        QueryKeys.profile,
    )
    const profilesPromise =
        missingProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("userId", missingProfileIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const [profilesRes, assetsRes] = await Promise.all([
        profilesPromise,
        getAssetsQueryFn({ resourceRefs: postResourceAssetRefs, queryClient }),
    ])

    profilesRes.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )

    const creatorProfiles = mergeWithQueryCache(
        queryClient,
        creatorIds,
        QueryKeys.profile,
        profilesRes.data,
    )

    return pipe(
        posts.data,
        A.map(post => {
            const profile = creatorProfiles.find(p => p.id === post.creatorId)
            const assetInfo = post.type === "Image" ? post.imageUrl : undefined
            const assetResource =
                assetInfo && isResourceRef(assetInfo) ? assetInfo : undefined
            const asset = assetResource
                ? assetsRes.find(
                      asset => asset.id.resourceId === assetResource.resourceId,
                  )
                : undefined

            const imageAsset =
                !!asset && isImageAsset(asset) ? asset : undefined

            return {
                id: post.id,
                profile,
                post,
                createdAt: post.createdAt,
                asset: imageAsset,
            } as PostitArea
        }),
        A.filter(
            ({ profile, post }) => post !== undefined && profile !== undefined,
        ),
        items => ({
            items,
            paging: posts.paging,
        }),
    )
}
