import { useMutation } from "@tanstack/react-query"
import { UpdateVideo } from "api/api-models"
import { UserProfileId, VideoId } from "api/branded-types"
import { videoCreatorClient } from "api/clients/video-api-client"
import { useEditVideoPostStore } from "../store/edit-video-post-store"
import { useDerivedEditVideoState } from "./use-derived-edit-video-state"
import {
    createUploadMutation,
    handleVideoUpdateSuccess,
    useVideoQueryHelpers,
} from "./use-video-query-helpers"

type UseEditVideoModel = {
    videoId?: VideoId
    profileId?: UserProfileId
}

export const useEditVideo = (model: UseEditVideoModel) => {
    const { invalidateVideo } = useVideoQueryHelpers(model)

    const editVideoPostStore = useEditVideoPostStore()

    const {
        mutate: uploadVideo,
        isError: isUploadVideoError,
        isPending: isVideoUploadPending,
    } = createUploadMutation({
        onSuccess: () => editVideoPostStore.setVideoUploadFinished(true),
        onSettled: invalidateVideo,
        onProgress: editVideoPostStore.setVideoUploadProgress,
    })
    const {
        mutate: uploadThumbnail,
        isError: isUploadThumbnailError,
        isPending: isUploadThumbnailPending,
    } = createUploadMutation({
        onSuccess: () => editVideoPostStore.setImageUploadFinished(true),
        onSettled: invalidateVideo,
        onProgress: editVideoPostStore.setImageUploadProgress,
    })
    const {
        mutate: uploadImage,
        isError: isUploadImageError,
        isPending: isUploadImagePending,
    } = createUploadMutation({
        onSuccess: () => editVideoPostStore.setImageUploadFinished(true),
        onSettled: invalidateVideo,
        onProgress: editVideoPostStore.setImageUploadProgress,
    })

    const {
        mutate: updateVideoUploadById,
        isError: isUpdateVideoUploadError,
        isPending: isUpdateVideoUploadPending,
    } = useMutation({
        mutationFn: async (values: UpdateVideo) => {
            if (!model.videoId) return
            return videoCreatorClient.updateVideoUploadById(values, {
                params: { id: model.videoId },
            })
        },
        onSettled: invalidateVideo,
        onSuccess: result =>
            handleVideoUpdateSuccess({
                result,
                uploadImage,
                uploadThumbnail,
                uploadVideo,
            }),
    })

    const isError =
        isUploadVideoError ||
        isUploadThumbnailError ||
        isUploadImageError ||
        isUpdateVideoUploadError

    const derivedState = useDerivedEditVideoState({
        videoId: model.videoId,
        isError,
    })

    const editDisabled =
        derivedState.isUploadButtonDisabled ||
        isUpdateVideoUploadPending ||
        isVideoUploadPending ||
        isUploadThumbnailPending ||
        isUploadImagePending

    return {
        ...derivedState,
        isError,
        editDisabled,
        updateVideoUploadById,
        thumbnailImage:
            derivedState.thumbnailImage ??
            editVideoPostStore.thumbnail?.base64Data,
    }
}
