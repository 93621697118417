/* eslint-disable no-useless-escape */

import {
    GetNextPageParamFunction,
    InfiniteData,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { isImageAsset, isResourceRef } from "utils/asset"
import { mapToMatchFilterDc, mapToSortFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { postitSelectorClient } from "../api/clients/post-api-client"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"
import { PostitArea } from "../features/feed/areas/post/post-area-tile"
import { InfiniteProfilesData } from "../features/search/search-profiles-page"
import { getAssetsQueryFn } from "./use-asset"

const PAGINATION_AMOUNT = 25

export type UseImagePostSearchModel = {
    searchKey: string
    profileId?: UserProfileId
    enabled?: boolean
}

const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
const typeFilter = `type: "Image"`

export const searchImagePostsDefaultFilter = `{$match:{${typeFilter}}, ${sortFilter}}`

export const getSearchImagePosts = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: unknown
    filter: string
    queryClient: QueryClient
}) => {
    const posts = await postitSelectorClient.listPostits({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam as number,
        },
    })

    const postResourceAssetRefs = pipe(
        posts.data,
        A.filter(post => post.type === "Image"),
        A.map(post => post.imageUrl),
        A.filter(isResourceRef),
    )

    const profileIds = pipe(
        posts.data,
        A.map(({ creatorId }) => creatorId),
    )

    const profiles = () =>
        userProfileSelectorClient.listProfiles({
            filter: `{${mapToMatchFilterDc("id", profileIds)}}`,
        })

    const [profilesResult, assetsRes] = await Promise.all([
        profiles().then(({ data }) => data),
        getAssetsQueryFn({ resourceRefs: postResourceAssetRefs, queryClient }),
    ])

    return pipe(
        posts.data,
        A.map(post => {
            const profile = profilesResult.find(p => p.id === post.creatorId)
            const assetInfo = post.type === "Image" ? post.imageUrl : undefined
            const assetResource =
                assetInfo && isResourceRef(assetInfo) ? assetInfo : undefined
            const asset = assetResource
                ? assetsRes.find(
                      asset => asset.id.resourceId === assetResource.resourceId,
                  )
                : undefined

            const imageAsset =
                !!asset && isImageAsset(asset) ? asset : undefined
            return {
                id: post.id,
                post,
                profile,
                createdAt: post.createdAt,
                asset: imageAsset,
            } as PostitArea
        }),
        A.filter(
            ({ profile, post }) => post !== undefined && profile !== undefined,
        ),
        items => ({
            items,
            paging: posts.paging,
        }),
    )
}

type Data = Awaited<ReturnType<typeof getSearchImagePosts>>

export type SearchImagePostsData = Data
export type InfiniteSearchImagePostsData = InfiniteData<Data>

export const useImagePostsSearch = (model: UseImagePostSearchModel) => {
    const queryKey = QueryKeys.searchImagePosts(model)

    const searchProfilesQueryKey = QueryKeys.searchUserProfiles(model)

    const queryClient = useQueryClient()

    const userProfilesData = queryClient.getQueryData<InfiniteProfilesData>(
        searchProfilesQueryKey,
    )

    const creatorIds =
        userProfilesData?.pages
            .flatMap(page => page.data)
            .map(item => item.id) ?? []

    const titleFilter = `title: *"${model.searchKey}"*:ci`
    const messageFilter = `message: *"${model.searchKey}"*:ci`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
    const typeFilter = `type: "Image"`
    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, creatorId: !"${model.profileId}"`
    //TODO: this is not 100% precise, but would work out most of the time
    //TODO: otherwise we need specific API endpoint for this
    const profilesFilter =
        model.searchKey.length === 0
            ? ""
            : `, {creatorId: in ${JSON.stringify(creatorIds)}}`

    const filter = `{$match:{$or: [{${titleFilter}}, {${messageFilter}}${profilesFilter}]${removeMyProfileFilter}, ${typeFilter}}, ${sortFilter}}`

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) =>
            getSearchImagePosts({ pageParam, filter, queryClient }),
        getNextPageParam: getSearchImagePostsNextPageParam,
        enabled: model.enabled,
    })
}

export const getSearchImagePostsNextPageParam: GetNextPageParamFunction<
    number,
    Data
> = lastPage => {
    if (lastPage.items.length !== PAGINATION_AMOUNT) {
        return undefined
    }

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}
