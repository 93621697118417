import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    CreatorRef,
    Vote,
    VoteType,
    VotedResourceRef,
} from "../api-models"
import { UserProfileId, VoteId } from "../branded-types"

export const votingCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/vote",
        alias: "vote",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    resource: VotedResourceRef,
                    resourceOwnerId: UserProfileId,
                    voter: CreatorRef,
                    type: VoteType,
                }),
            },
        ],
        response: z.object({
            data: Vote,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/vote/:id",
        alias: "revokeVote",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: VoteId,
            },
        ],
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
