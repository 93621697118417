import {
    faHeart,
    faMessage,
    faShare,
    faCircleStar as lightStar,
} from "@fortawesome/pro-light-svg-icons"
import {
    faHeart as faHeartSolid,
    faCircleStar as solidStar,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonLabel } from "@ionic/react"
import classNames from "classnames"
import { flow } from "fp-ts/function"
import { motion } from "framer-motion"
import { FC } from "react"

import { MonetizationOption, UserProfile } from "../../../../api/api-models"
import { UserProfileId } from "../../../../api/branded-types"
import { Avatar, AvatarModel } from "../../../../components/avatars/avatar"
import { AvatarLoading } from "../../../../components/avatars/avatar-loading"
import { stopPropagation as stopClickPropagation } from "../../../../utils/fp"

import { messageDateTimeInfo } from "features/messaging/time-format"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./user-details.css"

export type UserDetailsModel = {
    className?: string
    avatarSize?: AvatarModel["size"]
    contentLastChangedAt: string
    profile: UserProfile
    votes?: number
    comments?: number
    sharedCount?: number
    liked?: boolean
    stopPropagation?: boolean
    monetization?: MonetizationOption
    paymentRequired: boolean

    onClicked?: (id: UserProfileId) => void
    onShareClicked?: () => void
    onPurchaseClicked?: () => void

    onCommentDetailsClicked?: () => void
    onLikeChangedClicked?: () => void
    onLikeDetailsClicked?: () => void
}
export const UserDetails: FC<UserDetailsModel> = ({
    className = "",
    avatarSize,
    contentLastChangedAt,
    profile,
    liked = false,
    votes = 0,
    comments = 0,
    sharedCount = 0,
    stopPropagation,
    monetization = { type: "None" },
    paymentRequired,

    onClicked,
    onPurchaseClicked,
    onLikeChangedClicked,
    onCommentDetailsClicked,
    onShareClicked,
    onLikeDetailsClicked,
}) => (
    <div className={classNames(styles.wrapper, className)}>
        <div
            className={styles.content}
            onClick={flow(
                e => (stopPropagation ? stopClickPropagation(e) : {}),
                () => (onClicked ? onClicked(profile.id) : {}),
            )}
        >
            <Avatar
                className={styles.avatar}
                src={profile.imageUrl}
                size={avatarSize}
                styleState={profile.isLive ? "isLive" : "None"}
            />

            <div>
                <IonLabel>
                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: "white",
                        }}
                    >
                        {profile.displayName}
                    </p>
                </IonLabel>

                <IonLabel>
                    <p
                        style={{
                            marginTop: "2px",
                            fontSize: vars.font.size.xs,
                            color: "white",
                            opacity: 0.75,
                        }}
                    >
                        {messageDateTimeInfo(contentLastChangedAt)}
                    </p>
                </IonLabel>
            </div>
        </div>

        <div className={styles.icons}>
            {onPurchaseClicked && monetization.type === "SubscriptionOnly" && (
                <div
                    className={styles.lines}
                    onClick={() => (paymentRequired ? onPurchaseClicked() : {})}
                >
                    {paymentRequired ? (
                        <>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={lightStar}
                                color="white"
                            />

                            <p
                                style={{
                                    fontSize: vars.font.size.xs,
                                    color: "white",
                                }}
                            >
                                {`${monetization.amount} ${
                                    monetization.currency === "EUR" ? "€" : "$"
                                }`}
                            </p>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={solidStar}
                                color="white"
                            />

                            <p
                                style={{
                                    fontSize: vars.font.size.xs,
                                    color: "white",
                                }}
                            >
                                payed
                            </p>
                        </>
                    )}
                </div>
            )}

            {onLikeChangedClicked &&
                (liked ? (
                    <div className={styles.lines}>
                        <motion.div
                            key="like-heart"
                            className={styles.lines}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.14,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                    type: "spring",
                                    damping: 5,
                                    stiffness: 100,
                                    restDelta: 0.025,
                                },
                            }}
                        >
                            <FontAwesomeIcon
                                className={styles.icon}
                                style={{ color: vars.color.liveColor.hex }}
                                icon={faHeartSolid}
                                onClick={onLikeChangedClicked}
                            />
                        </motion.div>
                        <p
                            style={{
                                fontSize: vars.font.size.xs,
                                color: "white",
                                pointerEvents: votes <= 0 ? "none" : "inherit",
                            }}
                            onClick={onLikeDetailsClicked}
                        >
                            {votes ?? 0}
                        </p>
                    </div>
                ) : (
                    <div className={styles.lines}>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={faHeart}
                            onClick={onLikeChangedClicked}
                        />
                        <p
                            style={{
                                fontSize: vars.font.size.xs,
                                color: "white",
                                pointerEvents: votes <= 0 ? "none" : "inherit",
                            }}
                            onClick={onLikeDetailsClicked}
                        >
                            {votes ?? 0}
                        </p>
                    </div>
                ))}

            {onCommentDetailsClicked && (
                <div onClick={onCommentDetailsClicked} className={styles.lines}>
                    <FontAwesomeIcon
                        className={styles.iconMessage}
                        icon={faMessage}
                    />
                    <p
                        style={{
                            fontSize: vars.font.size.xs,
                            color: "white",
                        }}
                    >
                        {comments}
                    </p>
                </div>
            )}

            {onShareClicked && (
                <div className={styles.lines}>
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={faShare}
                        onClick={onShareClicked}
                    />
                    <p
                        style={{
                            fontSize: vars.font.size.xs,
                            color: "white",
                        }}
                    >
                        {sharedCount ?? 0}
                    </p>
                </div>
            )}
        </div>
    </div>
)

export const UserDetailsLoading = () => (
    <div className={styles.wrapper}>
        <div className={styles.content}>
            <AvatarLoading size={36} />
            <Skeleton width={75} height={18} borderRadius={24} />
        </div>
    </div>
)
