import { faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { flow } from "fp-ts/function"
import { FC } from "react"

import { Url } from "../../../../api/branded-types"
import { Avatar } from "../../../../components/avatars/avatar"
import { AvatarLoading } from "../../../../components/avatars/avatar-loading"
import { stopPropagation } from "../../../../utils/fp"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./user-search-result-list-item.css"

export type UserSearchResultListItemModel = {
    className?: string
    locationNotAvailable: string
    avatarSize?: number
    src: Url
    name: string
    location?: string
    isLive: boolean
    onClicked: () => void
}
export const UserSearchResultListItem: FC<UserSearchResultListItemModel> = ({
    className = "",
    avatarSize,
    src,
    isLive,
    name,
    location,
    locationNotAvailable,
    onClicked,
}) => (
    <div
        className={classNames(styles.wrapper, className)}
        onClick={flow(stopPropagation, () => onClicked())}
    >
        <Avatar
            src={src}
            size={avatarSize}
            styleState={isLive ? "isLive" : "None"}
        />

        <div className={styles.maxWidth}>
            <p
                style={{
                    fontSize: vars.font.size.regular,
                    color: "var(--text-color, white)",
                    textAlign: "left",
                }}
            >
                {name}
            </p>

            <p
                style={{
                    fontSize: vars.font.size.regular,
                    color: vars.color.tertiary.hex,
                    textAlign: "left",
                }}
            >
                {location && location.length > 0
                    ? location
                    : locationNotAvailable}
            </p>
        </div>

        <FontAwesomeIcon
            className={styles.navButton}
            icon={faAngleRight}
            color="var(--nav-icon-color, white)"
        />
    </div>
)

export type UserResultListLoadingItemModel = {
    className?: string
    avatarSize?: number
}

export const UserResultListLoadingItem: FC<UserResultListLoadingItemModel> = ({
    className,
    avatarSize,
}) => (
    <div className={classNames(styles.wrapper, className)}>
        <AvatarLoading baseColor="#3C375C" size={avatarSize} />

        <div className={styles.maxWidth}>
            <Skeleton width={224} height={15} borderRadius={24} />
            <Skeleton width={164} height={15} borderRadius={24} />
        </div>
    </div>
)
