import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"

import { vars } from "theme/variables.css"
import * as styles from "./result-not-found-placeholder.css"

export type ResultNotFoundPlaceholderModel = {
    title: string
    description: string
    className?: string
}

export const ResultNotFoundPlaceholder: FC<ResultNotFoundPlaceholderModel> = ({
    title,
    description,
    className,
}) => (
    <div className={classNames(styles.wrapper, className)}>
        <div className={styles.content}>
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
            <p
                className={styles.title}
                style={{ fontSize: vars.font.size.regular }}
            >
                {title}
            </p>
            <p
                className={styles.description}
                style={{ fontSize: vars.font.size.regular }}
            >
                {description}
            </p>
        </div>
    </div>
)
