import { faLocationPin } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import { FC } from "react"

import { UserProfile } from "../../../../api/api-models"
import { UserProfileId } from "../../../../api/branded-types"
import { Avatar } from "../../../../components/avatars/avatar"
import { AvatarLoading } from "../../../../components/avatars/avatar-loading"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./user-search-result-tile.css"

export type UserSearchResultTileTextModel = {
    action: string
}

export type UserSearchResultTileModel = {
    profile: UserProfile
    text: UserSearchResultTileTextModel
    onUserClicked: (id: UserProfileId) => void
    onActionClicked: (profile: UserProfileId) => void
}

export const UserSearchResultTile: FC<UserSearchResultTileModel> = model => (
    <div className={styles.tile}>
        <div className={styles.info}>
            <Avatar
                onClicked={() => model.onUserClicked(model.profile.id)}
                size={72}
                src={model.profile.imageUrl}
            />

            <div className={styles.description}>
                <p
                    className={styles.text}
                    style={{
                        fontSize: vars.font.size.m,
                        color: "white",
                    }}
                >
                    {model.profile.displayName ?? model.profile.profileName}
                </p>
                <p
                    className={styles.text}
                    style={{
                        fontSize: vars.font.size.regular,
                        color: vars.color.tertiary.hex,
                    }}
                >
                    <FontAwesomeIcon icon={faLocationPin} />{" "}
                    {model.profile.location ?? "not specified"}
                </p>
            </div>
        </div>

        <IonButton
            className={styles.actionButton}
            onClick={() => model.onActionClicked(model.profile.id)}
        >
            {model.text.action}
        </IonButton>
    </div>
)

export const UserSearchResultLoadingTile: FC = () => (
    <div className={styles.tile}>
        <div className={styles.info}>
            <AvatarLoading baseColor="#3C375C" size={72} />

            <div className={styles.description}>
                <Skeleton width={75} height={18} borderRadius={24} />
                <Skeleton width={50} height={12} borderRadius={24} />
            </div>

            <div className={styles.loadingButton} />
        </div>
    </div>
)
