import { makeApi, Zodios } from "@zodios/core"
import { ApiError } from "api/api-models"
import {
    corsFetchHandler,
    tokenHandlerForCreate,
    validationErrorHandler,
} from "api/api-utils"
import { SHARE_LINK_API } from "envs"
import { z } from "zod"

export const CreateLink = z.object({
    link: z.string(),
})
export type CreateLink = z.infer<typeof CreateLink>

export const LinkCreated = z.object({
    shortURL: z.string(),
    createdAt: z.string(),
})
export type LinkCreated = z.infer<typeof LinkCreated>

export const shareLinkApi = makeApi([
    {
        method: "post",
        path: "/v1/link",
        description: "Create shortened link",
        alias: "createShortenedLink",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: CreateLink,
            },
        ],
        response: LinkCreated,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])

// creator client
export const shareLinkClient = new Zodios(`${SHARE_LINK_API}/api`, shareLinkApi)

shareLinkClient.use(tokenHandlerForCreate)
shareLinkClient.use(corsFetchHandler)
shareLinkClient.use(validationErrorHandler)
