/* eslint-disable no-useless-escape */

import {
    GetNextPageParamFunction,
    InfiniteData,
    useInfiniteQuery,
} from "@tanstack/react-query"

import { mapToSortFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 10

export type UseUserProfilesSearchModel = {
    searchKey: string
    profileId?: UserProfileId
}

const sortFilter = mapToSortFilterDc("engagementScore: desc, profileName: asc")
export const searchProfilesDefaultFilter = `{${sortFilter}}`

export const getSearchProfiles = async ({
    pageParam,
    filter,
}: {
    pageParam: unknown
    filter: string
}) => {
    const res = await userProfileSelectorClient.listProfiles({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam as number,
        },
    })
    return res
}

type Data = Awaited<ReturnType<typeof getSearchProfiles>>

export type SearchProfilesData = Data
export type InfiniteSearchProfilesData = InfiniteData<Data>

export const useUserProfilesSearch = (model: UseUserProfilesSearchModel) => {
    const queryKey = QueryKeys.searchUserProfiles(model)
    const profileNameFilter = `profileName: *"${model.searchKey}"*:ci`
    const displayNameFilter = `displayName: *"${model.searchKey}"*:ci`
    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, id: !"${model.profileId}"`

    const filter = `{$match:{$or: [{${profileNameFilter}}, {${displayNameFilter}}]${removeMyProfileFilter}}, ${sortFilter}}`

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) => getSearchProfiles({ pageParam, filter }),
        getNextPageParam: getSearchProfilesNextPageParam,
    })
}

export const getSearchProfilesNextPageParam: GetNextPageParamFunction<
    number,
    Data
> = lastPage => {
    // Determine if there's a next page
    if (lastPage.data.length !== PAGINATION_AMOUNT) {
        return undefined // No more pages
    }

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}
