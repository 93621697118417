import { toDate } from "date-fns"
import { pipe } from "fp-ts/function"
import {
    hmDateFormat,
    isALastWeekOld,
    isAWeekOld,
    isOlderThanAWeek,
    isOneDayOld,
    mdDateFormat,
} from "../../utils/time"

export const messageTimeInfo = (date: string) =>
    pipe(date, toDate, d =>
        isOlderThanAWeek(d)
            ? mdDateFormat(d)
            : isAWeekOld(d)
              ? "a week before"
              : isALastWeekOld(d)
                ? "last week"
                : isOneDayOld(d)
                  ? `yesterday, ${hmDateFormat(d)}`
                  : hmDateFormat(d),
    )

export const messageDateTimeInfo = (date: string) =>
    pipe(date, toDate, d =>
        isOlderThanAWeek(d)
            ? mdDateFormat(d)
            : isAWeekOld(d)
              ? "a week before"
              : isALastWeekOld(d)
                ? "last week"
                : isOneDayOld(d)
                  ? `yesterday, ${hmDateFormat(d)}`
                  : "today",
    )
