import { useQueryClient } from "@tanstack/react-query"
import { PostitId, UserProfileId } from "api/branded-types"
import { QueryKeys, StaticQueryKeys } from "api/query-keys"
import { useCallback, useState } from "react"
import { isNotDefined } from "utils/object"
import { useEditImagePostStore } from "../store/edit-image-post-store"
import { useDerivedEditImagePostState } from "./use-derived-edit-image-post-state"
import { useEditImagePostitMutation } from "./use-edit-image-postit-mutation"
import { createUploadMutation } from "./use-video-query-helpers"

type UseEditImageModel = {
    postitId?: PostitId
    profileId?: UserProfileId
}

export const useEditImage = (model: UseEditImageModel) => {
    const queryClient = useQueryClient()

    const imageBlob = useEditImagePostStore(store => store.imageBlob)
    const updatedWithoutUploads = useEditImagePostStore(
        store => store.updatedWithoutUploads,
    )
    const setUpdatedWithoutUploads = useEditImagePostStore(
        store => store.setUpdatedWithoutUploads,
    )

    // TODO use different states to avoid intersection state
    const [uploadFinished, setUploadFinished] = useState(false)
    const [uploadFailed, setUploadFailed] = useState(false)

    const invalidateQueries = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: QueryKeys.postit(model.postitId),
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.payablePostit(model.postitId),
        })
        queryClient.invalidateQueries({
            queryKey: [StaticQueryKeys.SEARCH, StaticQueryKeys.SEARCH_POSTS],
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.profilePostContent(model.profileId),
        })
    }, [queryClient, model.postitId, model.profileId])

    const { mutate: uploadImage, isPending: isUploadImagePending } =
        createUploadMutation({
            onProgress: () => {},
            onSettled: invalidateQueries,
            onSuccess: () => {
                setUploadFinished(true)
                setUploadFailed(false)
            },
            onError: () => {
                setUploadFinished(false)
                setUploadFailed(true)
            },
        })

    const { mutate: editPost, isPending: isEditPostPending } =
        useEditImagePostitMutation({
            onSuccess: url => {
                if (isNotDefined(imageBlob)) {
                    setUpdatedWithoutUploads(true)
                    return
                }
                const uploadFile = new File([imageBlob], "thumbnail.jpg", {
                    type: "image/jpeg",
                })
                uploadImage({
                    file: uploadFile,
                    url,
                })
            },
            onError: () => {
                setUploadFinished(false)
                setUploadFailed(true)
            },
            onSettled: invalidateQueries,
        })

    const {
        backgroundImageUrlValue,
        isFileInvalid,
        isPostitLoaded,
        isUploadButtonDisabled,
        message,
        monetization,
        monetizationDisabled,
        title,
    } = useDerivedEditImagePostState({
        postitId: model.postitId,
        isEditPostPending,
        isUploadImagePending,
    })

    const updateComplete = uploadFinished || updatedWithoutUploads

    return {
        isPostitLoaded,
        title,
        message,
        monetization,
        isFileInvalid,
        isUploadButtonDisabled,
        updateComplete,
        uploadFailed,
        monetizationDisabled,
        backgroundImageUrlValue,
        editPost,
    }
}
