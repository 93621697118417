import { ADTType, makeADT, ofType } from "@morphic-ts/adt"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { ReactNode, useEffect, useState } from "react"

import { ToastProps } from "./toast"

//? if more needed - we would need to add stacked styles
const TOAST_LIMIT = 1
const TOAST_REMOVE_DELAY = 1000

type ToasterToast = ToastProps & {
    id: string
    toastContent?: ReactNode
}

type AddToast = {
    type: "AddToast"
    toast: ToasterToast
}

type UpdateToast = {
    type: "UpdateToast"
    toast: Partial<ToasterToast>
}

type DismissToast = {
    type: "DismissToast"
    toastId?: ToasterToast["id"]
}

type RemoveToast = {
    type: "RemoveToast"
    toastId?: ToasterToast["id"]
}

const ToastActionAdt = makeADT("type")({
    AddToast: ofType<AddToast>(),
    UpdateToast: ofType<UpdateToast>(),
    DismissToast: ofType<DismissToast>(),
    RemoveToast: ofType<RemoveToast>(),
})

type ToastAction = ADTType<typeof ToastActionAdt>

let count = 0

const genId = () => {
    count = (count + 1) % Number.MAX_SAFE_INTEGER
    return count.toString()
}

type State = {
    toasts: ToasterToast[]
}

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>()

const addToRemoveQueue = (toastId: string) => {
    if (toastTimeouts.has(toastId)) {
        return
    }

    const timeout = setTimeout(() => {
        toastTimeouts.delete(toastId)
        dispatch(
            ToastActionAdt.as.RemoveToast({
                toastId,
            }),
        )
    }, TOAST_REMOVE_DELAY)

    toastTimeouts.set(toastId, timeout)
}

//? rewrite with matcher?
export const reducer = (state: State, action: ToastAction): State =>
    pipe(
        action,
        ToastActionAdt.matchStrict({
            AddToast: ({ toast }) => {
                return {
                    ...state,
                    toasts: [toast, ...state.toasts].slice(0, TOAST_LIMIT),
                }
            },
            UpdateToast: ({ toast }) => {
                return {
                    ...state,
                    toasts: state.toasts.map(t =>
                        t.id === toast.id ? { ...t, ...toast } : t,
                    ),
                }
            },
            DismissToast: ({ toastId }) => {
                // ! Side effects ! - This could be extracted into a dismissToast() action,
                // but I'll keep it here for simplicity
                if (toastId) {
                    addToRemoveQueue(toastId)
                } else {
                    state.toasts.forEach(toast => {
                        addToRemoveQueue(toast.id)
                    })
                }

                return {
                    ...state,
                    toasts: state.toasts.map(t =>
                        t.id === toastId || toastId === undefined
                            ? {
                                  ...t,
                                  open: false,
                              }
                            : t,
                    ),
                }
            },
            RemoveToast: ({ toastId }) => {
                if (toastId === undefined) {
                    return {
                        ...state,
                        toasts: [],
                    }
                }
                return {
                    ...state,
                    toasts: state.toasts.filter(t => t.id !== toastId),
                }
            },
        }),
    )

const listeners: Array<(state: State) => void> = []

let memoryState: State = {
    toasts: [],
}

const dispatch = (action: ToastAction) => {
    memoryState = reducer(memoryState, action)
    pipe(
        listeners,
        A.map(listener => listener(memoryState)),
    )
}

type ToastModel = Omit<ToasterToast, "id">

export const toast = ({ ...props }: ToastModel) => {
    const id = genId()

    const update = (props: ToasterToast) =>
        dispatch(
            ToastActionAdt.as.UpdateToast({
                toast: { ...props, id },
            }),
        )
    const dismiss = () =>
        dispatch(
            ToastActionAdt.as.DismissToast({
                toastId: id,
            }),
        )

    dispatch(
        ToastActionAdt.as.AddToast({
            toast: {
                ...props,
                id,
                open: true,
                onOpenChange: open => {
                    if (!open) dismiss()
                },
            },
        }),
    )

    return {
        id,
        dismiss,
        update,
    }
}

export const useToast = () => {
    const [state, setState] = useState<State>(memoryState)

    useEffect(() => {
        listeners.push(setState)

        return () => {
            const index = listeners.indexOf(setState)
            if (index > -1) listeners.splice(index, 1)
        }
    }, [state])

    return {
        ...state,
        toast,
        dismiss: (toastId?: string) =>
            dispatch(
                ToastActionAdt.as.DismissToast({
                    toastId,
                }),
            ),
    }
}
