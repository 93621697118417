import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    DatabaseNotification,
    NotificationSubscription,
    NotificationSubscriptionType,
} from "../api-models"
import {
    DatabaseNotificationId,
    NotificationSubscriptionId,
    UserProfileId,
} from "../branded-types"

export const notificationCreatorApi = makeApi([
    {
        method: "patch",
        path: "/v1/notification/:id",
        description: "Marks notification as read",
        alias: "readNotification",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: DatabaseNotificationId,
            },
        ],
        response: z.object({
            data: DatabaseNotification,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/notifications",
        description: "Marks notifications as read",
        alias: "readNotifications",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    notificationIds: z.array(DatabaseNotificationId),
                }),
            },
        ],
        response: z.object({
            data: z.array(DatabaseNotificationId),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/subscription",
        description: "Create a notification subscription",
        alias: "createNotificationSubscription",
        parameters: [
            {
                name: "body",
                type: "Body",
                //TODO: when other notification subscriptions are available - update this
                schema: z.object({
                    type: NotificationSubscriptionType,
                    profileId: UserProfileId,
                    auth: z.string(),
                    p256dh: z.string(),
                    endpoint: z.string().url(),
                }),
            },
        ],
        response: z.object({
            data: NotificationSubscription,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/subscription/:id",
        description: "Stop an initiated live event.",
        alias: "deleteNotificationSubscription",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: NotificationSubscriptionId,
            },
        ],
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
