import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CreatorRef, VotedResourceRef } from "../../../api/api-models"
import { VoteId } from "../../../api/branded-types"
import { votingCreatorClient } from "../../../api/clients/voting-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../api/query-keys"
import { MyResourceVoteData } from "./use-my-resource-vote"
import { InfiniteVideoVotesData } from "./use-resource-votes"

type MutationFunctionParams = {
    voter: CreatorRef
    voteResourceRef: VotedResourceRef
    voteId: VoteId
}

export const useRevokeVoteMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ voteId }: MutationFunctionParams) =>
            votingCreatorClient.revokeVote(undefined, {
                params: {
                    id: voteId,
                },
            }),
        mutationKey: [StaticQueryKeys.RESOURCE, StaticQueryKeys.RESOURCE_VOTES],
        onMutate: async ({ voteResourceRef, voter }) => {
            const previousVideoVoteKey = QueryKeys.myResourceVote(
                voteResourceRef,
                voter,
            )
            const previousVideoVotesKey = QueryKeys.resourceVotes(
                voteResourceRef.contentId,
            )

            queryClient.cancelQueries({
                queryKey: previousVideoVoteKey,
            })

            queryClient.cancelQueries({
                queryKey: previousVideoVotesKey,
            })

            const previousVideoVote =
                queryClient.getQueryData<MyResourceVoteData>(
                    previousVideoVoteKey,
                )
            const previousVideoVotes =
                queryClient.getQueryData<InfiniteVideoVotesData>(
                    previousVideoVotesKey,
                )

            if (previousVideoVote) {
                queryClient.setQueryData<MyResourceVoteData>(
                    previousVideoVoteKey,
                    {
                        ...previousVideoVote,
                        data: {
                            ...previousVideoVote.data,
                            isLiked: false,
                        },
                    },
                )
            }

            if (previousVideoVotes) {
                queryClient.setQueryData<InfiniteVideoVotesData>(
                    previousVideoVotesKey,
                    {
                        ...previousVideoVotes,
                        pages: previousVideoVotes.pages.map(page => ({
                            ...page,
                            total: Math.max(page.total - 1, 0),
                        })),
                    },
                )
            }

            return { previousVideoVote, previousVideoVotes }
        },
        onError: (err, { voter, voteResourceRef }, context) => {
            console.error(err)
            if (context?.previousVideoVote) {
                queryClient.setQueryData(
                    QueryKeys.myResourceVote(voteResourceRef, voter),
                    context.previousVideoVote,
                )
            }
            if (context?.previousVideoVote) {
                queryClient.setQueryData(
                    QueryKeys.resourceVotes(voteResourceRef.contentId),
                    context.previousVideoVotes,
                )
            }
        },
        onSettled: (_data, _err, { voter, voteResourceRef }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.myResourceVote(voteResourceRef, voter),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.resourceVotes(voteResourceRef.contentId),
            })
        },
    })
}
