import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    tokenHandlerForGet,
    validationErrorHandler,
} from "../api-utils"
import { votingCreatorApi } from "../creators/voting-creator-api"
import { votingSelectorApi } from "../selectors/voting-selector-api"

// creator  client
export const votingCreatorClient = new Zodios(
    `${API_URL}/voting-creator`,
    votingCreatorApi,
)

votingCreatorClient.use(tokenHandlerForCreate)
votingCreatorClient.use(corsFetchHandler)
votingCreatorClient.use(validationErrorHandler)

export const voteTask = pipe(votingCreatorClient.vote, endpointWithTaskEither)
export const revokeVoteTask = pipe(
    votingCreatorClient.revokeVote,
    endpointWithTaskEither,
)

// selector client
export const votingSelectorClient = new Zodios(
    `${API_URL}/voting-selector`,
    votingSelectorApi,
)

votingSelectorClient.use(tokenHandlerForGet)
votingSelectorClient.use(corsFetchHandler)
votingSelectorClient.use(validationErrorHandler)

export const getVotingByIdTask = pipe(
    votingSelectorClient.get,
    endpointWithTaskEither,
)

export const listVotingTask = pipe(
    votingSelectorClient.listVotes,
    endpointWithTaskEither,
)

export const listVotesByVotedResourceTask = pipe(
    votingSelectorClient.listVotesByVotedResource,
    endpointWithTaskEither,
)

export const listVotesOfTypeByVotedResourceTask = pipe(
    votingSelectorClient.listVotesOfTypeByVotedResource,
    endpointWithTaskEither,
)
