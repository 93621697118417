import { CookieConsent } from "features/registration/cookie-consent"
import { AnimatePresence } from "framer-motion"
import { SkeletonTheme } from "react-loading-skeleton"
import { Outlet, ScrollRestoration, useNavigationType } from "react-router-dom"
import { getNavigationDirection } from "../common/page"
import { Toaster } from "../components/controls/toaster"
import { NotificationProvider } from "./notification-provider"
import { ProtectedProviders } from "./protected-provider-outlet"

export const ProviderOutlet = () => {
    const navigationType = useNavigationType()
    const direction = getNavigationDirection(navigationType)

    return (
        <>
            <SkeletonTheme baseColor="#3C375C" highlightColor="#FFFFFF0F">
                <CookieConsent />
                <NotificationProvider>
                    <Toaster />
                    <AnimatePresence
                        initial={false}
                        custom={direction}
                        key="page-animate"
                    >
                        <Outlet />
                    </AnimatePresence>
                </NotificationProvider>
                <ProtectedProviders />
            </SkeletonTheme>
            <ScrollRestoration
                getKey={location => {
                    return location.pathname
                }}
            />
        </>
    )
}
