import { differenceInDays, parseISO } from "date-fns"

export const hmDateFormat = (date: Date) =>
    new Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
    }).format(date)

export const mdDateFormat = (date: Date) =>
    new Intl.DateTimeFormat(navigator.language, {
        month: "long",
        day: "numeric",
    }).format(date)

export const isOneDayOld = (date: Date | string | number): boolean =>
    differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    ) === 1

export const isAWeekOld = (date: Date | string | number): boolean =>
    differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    ) === 7

export const isALastWeekOld = (date: Date | string | number): boolean => {
    const diff = differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    )

    return diff < 7 && diff > 1
}

export const isOlderThanAWeek = (date: Date | string | number): boolean =>
    differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    ) > 7

export const delay = (fn: () => unknown, wait?: number) =>
    setTimeout(() => {
        fn()
    }, wait ?? 3000)
