import { IonApp, setupIonicReact } from "@ionic/react"
import {
    keepPreviousData,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query"
import classNames from "classnames"
import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import { I18nextProvider } from "react-i18next"
import { AuthProvider } from "react-oidc-context"
import { RouterProvider } from "react-router-dom"
import * as styles from "./app.css"

import i18n from "./features/locales/localization"

import "react-loading-skeleton/dist/skeleton.css"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/padding.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import "@ionic/react/css/palettes/dark.always.css"; */
/* import "@ionic/react/css/palettes/dark.class.css"; */
/* import "@ionic/react/css/palettes/dark.system.css"; */

import "swiper/css"
import "swiper/css/pagination"
import "theme/global.css"
import "theme/variables.css"

import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
} from "react-router-dom"

import { oidcConfig } from "api/api-utils"
import { TabBar } from "components/tabs/tab-bar"
import EditImagePostPage from "features/edit/edit-image-post-page"
import EditTextPostPage from "features/edit/edit-text-post-page"
import EditVideoPostPage from "features/edit/edit-video-post-page"
import { UserOverviewPageLoading } from "features/feed/areas/user/user-loading-overview-page"
import PaymentPage from "features/payment/payment-page"
import { ProtectedRoute } from "features/routing/protected-route"
import { RootBoundary } from "features/routing/root-boundary"
import { SearchPostSubPagesLoading } from "features/search/search-post-sub-pages-loading"
import { ProviderOutlet } from "providers/provider-outlet"
import { lazy, Suspense } from "react"
import { stepTheme } from "theme/step.css"
import { FeedOverviewPageLoading } from "./features/feed/feed-overview-page"
import { MessageToUserProfilePageLoading } from "./features/messaging/messages-to-user-profile-page"
import { MyMessagesLoadingPage } from "./features/messaging/my-messages-page"
import { NotificationsLoadingPage } from "./features/notifications/notifications-page"
import { PostitConsumerPageLoading } from "./features/postit/pages/postit-consumer-page"
import { SearchLiveEventsPageLoading } from "./features/search/search-live-events-page"
import { SearchPageLoading } from "./features/search/search-page"
import { SearchProfilesPageLoading } from "./features/search/search-profiles-page"
import { MyUserProfilePageLoading } from "./features/user-profile/my-user-profile-page"
import { UserProfilePageLoading } from "./features/user-profile/user-profile-page"
import { VideoConsumerPageLoading } from "./features/video/video-consumer-page"

const FollowerOverviewPage = lazy(
    () => import("./features/feed/areas/follower/follower-overview-page"),
)
const FollowingOverviewPage = lazy(
    () => import("./features/feed/areas/following/following-overview-page"),
)
const LiveEventConsumerPage = lazy(
    () => import("./features/live-event/consumer/live-event-consumer-page"),
)
const LiveEventProducerPage = lazy(
    () => import("./features/live-event/producer/live-event-producer-page"),
)
const LiveEventProducerSummaryPage = lazy(
    () =>
        import(
            "./features/live-event/producer/live-event-producer-summary-page"
        ),
)
const MessageToUserProfilePage = lazy(
    () => import("./features/messaging/messages-to-user-profile-page"),
)
const NotificationSettingsPage = lazy(
    () => import("./features/notifications/notification-settings-page"),
)
const NotificationsPage = lazy(
    () => import("./features/notifications/notifications-page"),
)
const PostitConsumerPage = lazy(
    () => import("./features/postit/pages/postit-consumer-page"),
)
const ConfirmationPage = lazy(
    () => import("./features/registration/confirmation-page"),
)
const TermsAndConditionPage = lazy(
    () => import("./features/registration/terms-and-condition-page"),
)
const CancellationPolicyPage = lazy(
    () => import("./features/registration/cancellation-policy-page"),
)
const SearchLiveEventsPage = lazy(
    () => import("./features/search/search-live-events-page"),
)
const SearchImagePostsPage = lazy(
    () => import("./features/search/search-image-posts-page"),
)
const SearchTextPostsPage = lazy(
    () => import("./features/search/search-text-posts-page"),
)
const SearchVideosPage = lazy(
    () => import("./features/search/search-videos-page"),
)
const SearchProfilesPage = lazy(
    () => import("./features/search/search-profiles-page"),
)
const ImageUploadPage = lazy(
    () => import("./features/upload/image-upload-page"),
)
const TextUploadPage = lazy(() => import("./features/upload/text-upload-page"))
const VideoUploadPage = lazy(
    () => import("./features/upload/video-upload-page"),
)
const CookieConsentSettingsPage = lazy(
    () =>
        import(
            "./features/user-profile/components/cookie-consent-settings-page"
        ),
)
const SettingsPage = lazy(
    () => import("./features/user-profile/components/settings-page"),
)
const EditMyUserProfilePage = lazy(
    () => import("./features/user-profile/modal/edit-my-user-profile-page"),
)
const EditMyUserProfileImagePage = lazy(
    () =>
        import("./features/user-profile/modal/edit-my-user-profile-image-page"),
)
const MyUserProfilePage = lazy(
    () => import("./features/user-profile/my-user-profile-page"),
)
const UserProfilePage = lazy(
    () => import("./features/user-profile/user-profile-page"),
)
const VideoConsumerPage = lazy(
    () => import("./features/video/video-consumer-page"),
)

const IntroPage = lazy(() => import("./features/registration/intro-page"))
const FeedOverviewPage = lazy(
    () => import("./features/feed/feed-overview-page"),
)
const SearchPage = lazy(() => import("./features/search/search-page"))
const MyMessagesPage = lazy(
    () => import("./features/messaging/my-messages-page"),
)

const PrivacyPolicyPage = lazy(
    () => import("./features/registration/privacy-policy-page"),
)

const PlayMessagingPage = lazy(
    () => import("./features/playground/play-messaging-page"),
)
const RewardsPage = lazy(() => import("./features/point-system/rewards-page"))
const RewardsExplainedPage = lazy(
    () =>
        import(
            "./features/point-system/rewards-explained/rewards-explained-page"
        ),
)
const BenefitsOverviewPage = lazy(
    () =>
        import(
            "./features/point-system/benefits-overview/benefits-overview-page"
        ),
)

const ChallengesPage = lazy(
    () => import("./features/point-system/challenge-page"),
)

const InviteFriendsForPointsPage = lazy(
    () => import("./features/point-system/invite-friends-for-points-page"),
)

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

// Sentry.init({
//     dsn: "https://4580ea8a4aec03de12af34c2f7a09b12@o4508297423945728.ingest.de.sentry.io/4508297885646928",
//     integrations: [],
// })

setupIonicReact({
    swipeBackEnabled: false,
    backButtonText: "",
    spinner: "circular",
    loadingSpinner: "circular",
    infiniteLoadingSpinner: "circular",
    refreshingSpinner: "circular",
    scrollAssist: false,
    mode: "ios",
})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            placeholderData: keepPreviousData,
            staleTime: 5 * MINUTE,
            gcTime: 5 * MINUTE,
        },
    },
})

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={<ProviderOutlet />}
            errorElement={<RootBoundary />}
        >
            <Route index element={<Navigate to="/app/feed" replace />} />

            <Route
                path="/intro"
                element={
                    <Suspense fallback={<div></div>}>
                        <IntroPage />
                    </Suspense>
                }
            />

            <Route
                path="/registration/privacy-policy"
                element={
                    <Suspense fallback={<div></div>}>
                        <PrivacyPolicyPage />
                    </Suspense>
                }
            />

            <Route
                path="/registration/cookie-consent"
                element={
                    <Suspense fallback={<div></div>}>
                        <CookieConsentSettingsPage />
                    </Suspense>
                }
            />

            <Route
                path="/registration/confirmation"
                element={
                    <Suspense fallback={<div></div>}>
                        <ConfirmationPage />
                    </Suspense>
                }
            />

            <Route
                path="/registration/terms-and-condition"
                element={
                    <Suspense fallback={<div></div>}>
                        <TermsAndConditionPage />
                    </Suspense>
                }
            />

            <Route
                path="/registration/cancellation-policy"
                element={
                    <Suspense fallback={<div></div>}>
                        <CancellationPolicyPage />
                    </Suspense>
                }
            />
            <Route element={<ProtectedRoute />}>
                <Route
                    path="/app/my-profile/edit"
                    element={
                        <Suspense fallback={<div></div>}>
                            <EditMyUserProfilePage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/my-profile/image/edit"
                    element={
                        <Suspense fallback={<div></div>}>
                            <EditMyUserProfileImagePage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/video-upload"
                    element={
                        <Suspense fallback={<div></div>}>
                            <VideoUploadPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/text-upload"
                    element={
                        <Suspense fallback={<div></div>}>
                            <TextUploadPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/text-edit/:id"
                    element={
                        <Suspense fallback={<div></div>}>
                            <EditTextPostPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/image-edit/:id"
                    element={
                        <Suspense fallback={<div></div>}>
                            <EditImagePostPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/video-edit/:id"
                    element={
                        <Suspense fallback={<div></div>}>
                            <EditVideoPostPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/image-upload"
                    element={
                        <Suspense fallback={<div></div>}>
                            <ImageUploadPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/video/viewer/:id"
                    element={
                        <Suspense fallback={<VideoConsumerPageLoading />}>
                            <VideoConsumerPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/postit/viewer/:id"
                    element={
                        <Suspense fallback={<PostitConsumerPageLoading />}>
                            <PostitConsumerPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/live-event/:profileId/viewer/:id"
                    element={
                        <Suspense fallback={<div></div>}>
                            <LiveEventConsumerPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/live-event/creator"
                    element={
                        <Suspense fallback={<div></div>}>
                            <LiveEventProducerPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/live-event/summary/:id"
                    element={
                        <Suspense fallback={<div></div>}>
                            <LiveEventProducerSummaryPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/followers/overview/:id"
                    element={
                        <Suspense fallback={<UserOverviewPageLoading />}>
                            <FollowerOverviewPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/followings/overview/:id"
                    element={
                        <Suspense fallback={<UserOverviewPageLoading />}>
                            <FollowingOverviewPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/search/videos"
                    element={
                        <Suspense fallback={<SearchPostSubPagesLoading />}>
                            <SearchVideosPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/search/image-posts"
                    // loader={imagePostsSearchPageLoader(queryClient)}
                    element={
                        <Suspense fallback={<SearchPostSubPagesLoading />}>
                            <SearchImagePostsPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/search/text-posts"
                    element={
                        <Suspense fallback={<SearchPostSubPagesLoading />}>
                            <SearchTextPostsPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/search/profiles"
                    element={
                        <Suspense fallback={<SearchProfilesPageLoading />}>
                            <SearchProfilesPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/search/live-events"
                    element={
                        <Suspense fallback={<SearchLiveEventsPageLoading />}>
                            <SearchLiveEventsPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/settings"
                    element={
                        <Suspense fallback={<div></div>}>
                            <SettingsPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/settings/notifications"
                    element={
                        <Suspense fallback={<div></div>}>
                            <NotificationSettingsPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/settings/payments"
                    element={
                        <Suspense fallback={<></>}>
                            <PaymentPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/message/profile/:id"
                    element={
                        <Suspense
                            fallback={<MessageToUserProfilePageLoading />}
                        >
                            <MessageToUserProfilePage />
                        </Suspense>
                    }
                />
                <Route
                    path="/app/notifications"
                    element={
                        <Suspense fallback={<NotificationsLoadingPage />}>
                            <NotificationsPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/point-system/invite"
                    element={
                        <Suspense fallback={<></>}>
                            <InviteFriendsForPointsPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/settings/rewards"
                    element={
                        <Suspense fallback={<></>}>
                            <RewardsPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/rewards-explained"
                    element={
                        <Suspense fallback={<></>}>
                            <RewardsExplainedPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/settings/rewards/benefits/benefits-overview"
                    element={
                        <Suspense fallback={<></>}>
                            <BenefitsOverviewPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/app/settings/rewards/challenge-details/:id"
                    element={
                        <Suspense fallback={<></>}>
                            <ChallengesPage />
                        </Suspense>
                    }
                />

                <Route element={<TabBar />}>
                    <Route
                        path="/app/my-profile"
                        element={
                            <Suspense fallback={<MyUserProfilePageLoading />}>
                                <MyUserProfilePage />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/app/feed"
                        element={
                            <Suspense fallback={<FeedOverviewPageLoading />}>
                                <FeedOverviewPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/app/search"
                        element={
                            <Suspense fallback={<SearchPageLoading />}>
                                <SearchPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/app/messages"
                        element={
                            <Suspense fallback={<MyMessagesLoadingPage />}>
                                <MyMessagesPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/app/user-profile/:id"
                        element={
                            <Suspense fallback={<UserProfilePageLoading />}>
                                <UserProfilePage />
                            </Suspense>
                        }
                    />

                    {import.meta.env.DEV && import.meta.env.MODE !== "test" && (
                        <Route
                            path="/app/playground/play-messaging"
                            element={
                                <Suspense fallback={<></>}>
                                    <PlayMessagingPage />
                                </Suspense>
                            }
                        />
                    )}
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/app/feed" replace />} />
        </Route>,
    ),
)

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <StrictMode>
        <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider {...oidcConfig}>
                    <IonApp
                        className={classNames(
                            stepTheme,
                            styles.typography,
                            styles.normalizeIonApp,
                        )}
                    >
                        <RouterProvider router={router} />
                    </IonApp>
                </AuthProvider>
            </QueryClientProvider>
        </I18nextProvider>
    </StrictMode>,
)
