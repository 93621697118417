import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useUserProfilesSearch } from "../../hooks/use-user-profiles-search"
import {
    UserSearchResultSectionLoading,
    UserSearchResultTileList,
} from "./users/tile/user-search-result-tile-list"

import { InfiniteData } from "@tanstack/react-query"
import { getIsAuthorizedAccount } from "api/api-utils"
import { useMe } from "hooks/use-me"
import { useNavigate } from "react-router-dom"
import { vars } from "theme/variables.css"
import { Pagination, UserProfile } from "../../api/api-models"
import { UserProfileId } from "../../api/branded-types"
import { Content } from "../../common/content"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { Toolbar } from "../../common/toolbar"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { LocalStorage } from "../../local-storage"
import { userProfileByIdRoute } from "../routing/routing"
import * as styles from "./search-sub-pages.css"

export type InfiniteProfilesData = InfiniteData<{
    data: UserProfile[]
    paging: Pagination
    totalCount: number
}>

export const SearchProfilesPage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { t } = useTranslation(["search"])
    const navigate = useNavigate()

    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const userProfilesSearchQuery = useUserProfilesSearch({
        searchKey: "",
        profileId,
    })
    const userId = LocalStorage.getOidcUserId()
    const userProfileState = match(userProfilesSearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const refreshSearchProfilesPage = async () => {
        await userProfilesSearchQuery.refetch()
    }

    const onUserClicked = (id: UserProfileId) => {
        if (O.isNone(userId)) return

        navigate(
            userId.value === id.toString()
                ? "/app/my-profile"
                : userProfileByIdRoute(id),
        )
    }

    return (
        <Page>
            <NavigationBackButton />
            <Toolbar className={styles.toolbar} />
            <Content className={styles.pageContent}>
                <PullToRefresh onRefresh={refreshSearchProfilesPage}>
                    <div className={styles.header}>
                        <h1
                            style={{
                                marginLeft: 4,
                                fontSize: vars.font.size.l,
                                color: "white",
                            }}
                        >
                            Interesting user.
                        </h1>
                    </div>
                    <InfiniteScroll
                        disabled={!userProfilesSearchQuery.hasNextPage}
                        state={userProfileState}
                        threshold="100px"
                        onLoadRequested={userProfilesSearchQuery.fetchNextPage}
                    >
                        <UserSearchResultTileList
                            isLoading={
                                userProfilesSearchQuery.isFetchingNextPage ||
                                userProfilesSearchQuery.isLoading
                            }
                            onUserClicked={onUserClicked}
                            onActionClicked={onUserClicked}
                            profiles={pipe(
                                userProfilesSearchQuery?.data?.pages ?? [],
                                A.flatMap(page => page.data),
                            )}
                            text={{
                                action: t("search.tabs.profile.action"),
                            }}
                        />
                    </InfiniteScroll>
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const SearchProfilesPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={styles.pageContent} fullscreen>
            <UserSearchResultSectionLoading />
        </Content>
    </Page>
)
//TODO: unused, but contains useful logic for future impl.
// refactor when we have time to make this function properly

// export const profilesSearchPageLoader =
//     (queryClient: QueryClient) => async () => {
//         const queryKey = QueryKeys.searchUserProfiles({ searchKey: "" })
//         const builder = searchPagePreloadDataBuilder.reset()
//         // eslint-disable-next-line functional/no-try-statements
//         try {
//             const cachedData =
//                 queryClient.getQueryData<InfiniteSearchProfilesData>(queryKey)
//             if (cachedData) {
//                 builder.appendData("profiles", cachedData)
//                 return defer({ data: builder.getPageData() })
//             }

//             const fetchedData = await queryClient.fetchInfiniteQuery({
//                 initialPageParam: -1,
//                 queryFn: ({ pageParam }) =>
//                     getSearchProfiles({
//                         pageParam,
//                         filter: searchProfilesDefaultFilter,
//                     }),
//                 queryKey,
//                 getNextPageParam: getSearchProfilesNextPageParam,
//             })

//             builder.appendData("profiles", fetchedData)
//             return defer({ data: builder.getPageData() })
//         } catch (error) {
//             console.error("Failed to load profiles:", error)
//             builder.appendEmptyData("profiles", { pages: [], pageParams: [] })
//             return defer({ data: builder.getPageData() })
//         }
//     }

export default SearchProfilesPage
