import { useQueryClient } from "@tanstack/react-query"
import {
    ContentChangedMessage,
    PointSystemTaskCompleteMessage,
} from "api/api-models"
import { getIsAuthorizedAccount } from "api/api-utils"
import { API_URL } from "envs"
import { useSignalRMessaging } from "features/messaging/hooks/use-signalr-messaging"
import { useModalQueueStore } from "features/point-system/store/modal-queue-system-store"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useMe } from "hooks/use-me"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { LocalStorage } from "local-storage"
import { useCallback, useEffect, useMemo } from "react"
import { handleContentChangedMessage } from "../handle-server-messages"

export const useNotificationMessagesHub = () => {
    const {
        connection,
        error: connectionError,
        connectionStatus,
    } = useSignalRMessaging(`${API_URL}/notification-creator/v1/connect`)

    const meQuery = useMe()

    const accountType = useMemo(() => {
        if (meQuery.isSuccess) {
            return meQuery.data.accountType
        }
        return pipe(
            LocalStorage.getAccountType(),
            O.getOrElseW(() => "Guest" as const),
        )
    }, [meQuery])

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const myProfileIdQuery = useMyUserProfileId(isAuthorizedAccount)

    const queryClient = useQueryClient()

    const { addToQueue } = useModalQueueStore()

    const handleOnEventTriggered = useCallback(
        (message: string) => {
            let parsedMessage
            try {
                parsedMessage = JSON.parse(message)
            } catch (e) {
                console.error(e)
                return
            }

            const contentChangedResult =
                ContentChangedMessage.safeParse(parsedMessage)
            if (contentChangedResult?.success) {
                handleContentChangedMessage({
                    queryClient,
                    myUserId: meQuery.data?.user.id,
                    myProfileId: myProfileIdQuery.data,
                    data: contentChangedResult.data,
                })
                return
            }

            const pointSystemResult =
                PointSystemTaskCompleteMessage.safeParse(parsedMessage)
            if (pointSystemResult?.success) {
                addToQueue(pointSystemResult.data)
            }
        },
        [myProfileIdQuery.data, queryClient, addToQueue],
    )

    useEffect(() => {
        if (!connection || connectionStatus !== "Connected") return

        connection.on("OnEventTriggered", handleOnEventTriggered)

        return () => {
            connection.off("OnEventTriggered", handleOnEventTriggered)
        }
    }, [connection, connectionStatus, handleOnEventTriggered])

    return {
        connectionStatus,
        connectionError,
    }
}
