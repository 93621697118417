import { IonButton } from "@ionic/react"
import { ADTType, makeADT, ofType } from "@morphic-ts/adt"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { Loading } from "../../../data-flow/common"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./subscribe-button.css"

type ActiveSubscription = { type: "ActiveSubscription" }
type NoSubscription = { type: "NoSubscription" }
type NotPossibleSubscription = { type: "NotPossibleSubscription" }
export const SubscriptionStateAdt = makeADT("type")({
    Loading: ofType<Loading>(),
    NoSubscription: ofType<NoSubscription>(),
    ActiveSubscription: ofType<ActiveSubscription>(),
    NotPossibleSubscription: ofType<NotPossibleSubscription>(),
})

export type SubscriptionState = ADTType<typeof SubscriptionStateAdt>

export type SubscribeButtonModel = {
    text: {
        subscribe: string
        unSubscribe: string
    }
    subscriptionState: SubscriptionState
    onSubscribeClicked: () => void
    onNotPossibleSubscribeClicked: () => void
    onUnSubscribeClicked: () => void
}

export const SubscribeButton: FC<SubscribeButtonModel> = ({
    text,
    subscriptionState,
    onUnSubscribeClicked,
    onNotPossibleSubscribeClicked,
    onSubscribeClicked,
}) => (
    <div className={styles.followButtonContainer}>
        {pipe(
            subscriptionState,
            SubscriptionStateAdt.match({
                ActiveSubscription: () => (
                    <IonButton
                        expand="block"
                        className={styles.isFollowing}
                        style={{ color: vars.color.background.hex }}
                        onClick={onUnSubscribeClicked}
                    >
                        <p
                            style={{ fontSize: vars.font.size.m }}
                            className={styles.isFollowingText}
                        >
                            {text.unSubscribe}
                        </p>
                    </IonButton>
                ),
                NoSubscription: () => (
                    <IonButton
                        expand="block"
                        className={styles.isNotFollowing}
                        style={{ color: vars.color.background.hex }}
                        onClick={onSubscribeClicked}
                    >
                        <p
                            style={{ fontSize: vars.font.size.m }}
                            className={styles.isNotFollowingText}
                        >
                            {text.subscribe}
                        </p>
                    </IonButton>
                ),
                Loading: () => (
                    <Skeleton height={50} width="100%" borderRadius={16} />
                ),
                NotPossibleSubscription: () => (
                    <IonButton
                        onClick={onNotPossibleSubscribeClicked}
                        expand="block"
                        className={styles.isNotFollowing}
                        style={{ color: vars.color.background.hex }}
                    >
                        <p
                            style={{ fontSize: vars.font.size.m }}
                            className={styles.isNotFollowingText}
                        >
                            {text.subscribe}
                        </p>
                    </IonButton>
                ),
            }),
        )}
    </div>
)
