import { useMutation } from "@tanstack/react-query"

import { UpdateImagePostit, UpdateImagePostitResponse } from "api/api-models"
import { PostitId, Url } from "../../../api/branded-types"
import { postitCreatorClient } from "../../../api/clients/post-api-client"
import { StaticQueryKeys } from "../../../api/query-keys"

export type EditImagePostitMutationModel = {
    onSuccess: (imageUploadUrl: Url) => void
    onError: () => void
    onSettled: () => void
}

type MutationFnParams = {
    id: PostitId
    body: UpdateImagePostit
}

export const useEditImagePostitMutation = (
    props: EditImagePostitMutationModel,
) => {
    return useMutation({
        mutationFn: (params: MutationFnParams) =>
            postitCreatorClient.updatePostitById(params.body, {
                params: { id: params.id },
            }),
        mutationKey: [StaticQueryKeys.EDITING_IMAGE_POSTIT],

        onError: err => {
            console.error("use-edit-image-postit-mutation", err)
            props.onError()
        },

        onSuccess: data => {
            if (data.data.type === "Image") {
                const response = data as UpdateImagePostitResponse
                props.onSuccess(response.uploads.image)
            }
        },

        onSettled: props.onSettled,
    })
}
