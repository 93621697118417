import { useMutation, useQueryClient } from "@tanstack/react-query"

import { MessageRecipientRef } from "../../../api/api-models"
import { UserProfileId } from "../../../api/branded-types"
import { userMessageCreatorClient } from "../../../api/clients/message-api-client"
import { QueryKeys } from "../../../api/query-keys"

type Params = {
    senderId: UserProfileId
    recipient: MessageRecipientRef
    message: string
}

export const useMessageMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ senderId, recipient, message }: Params) =>
            userMessageCreatorClient.postMessage({
                senderId,
                recipient,
                text: message,
            }),
        onSettled: (_data, _err, { recipient, senderId }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.resourceMessages(
                    recipient.contentId,
                    senderId,
                ),
            })

            queryClient.invalidateQueries({
                queryKey: QueryKeys.myResourceMessages(senderId),
            })
        },
    })
}
