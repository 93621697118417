import {
    GetNextPageParamFunction,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { UserProfile } from "api/api-models"
import { mapToNotMatchFilterDc, mapToSortFilterDc } from "../api/api-utils"
import { UserId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

export const PAGINATION_AMOUNT = 50

export const useTopUsers = (id?: UserId) => {
    const queryClient = useQueryClient()
    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            listTopUsers({ pageParam, id, queryClient }),
        queryKey: QueryKeys.feedTopUsers(id),
        getNextPageParam: getTopUsersNextPageParam,
    })
}

type TopUsersData = Awaited<ReturnType<typeof listTopUsers>>

const listTopUsers = async ({
    id,
    pageParam,
    queryClient,
}: {
    id?: UserId
    pageParam: number
    queryClient: QueryClient
}) => {
    const filter = id
        ? `{${mapToNotMatchFilterDc("userId", [id])} ${mapToSortFilterDc("engagementScore: desc, profileName: asc")}}`
        : `{${mapToSortFilterDc("engagementScore: desc, profileName: asc")}}`
    const topUsersResponse = await userProfileSelectorClient.listProfiles({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam as number,
        },
    })

    topUsersResponse.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )

    return {
        items: topUsersResponse.data,
        paging: topUsersResponse.paging,
        totalCount: topUsersResponse.totalCount,
    }
}

export const getTopUsersNextPageParam: GetNextPageParamFunction<
    number,
    TopUsersData
> = lastPage => {
    if (lastPage.items.length !== PAGINATION_AMOUNT) {
        return undefined
    }

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}
