import { AccountType } from "../../../api/api-models"
import { UserId, UserProfileId } from "../../../api/branded-types"

type UserContentReferenceModel = {
    accountType?: AccountType
    userId?: UserId
    userProfileId?: UserProfileId
}

export const creatorReferenceStrategy = (model: UserContentReferenceModel) =>
    model.accountType === "User" && !!model.userProfileId
        ? ({
              type: "UserProfile",
              id: model.userProfileId,
          } as const)
        : model.userId
          ? ({
                type: "User",
                id: model.userId,
            } as const)
          : undefined
