import classNames from "classnames"
import { forwardRef } from "react"

import * as styles from "./toolbar.css"

export const Toolbar = forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        {...props}
        className={classNames(styles.toolbar, className)}
    />
))
