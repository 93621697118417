import { FC } from "react"

import { UserProfile } from "../../../../api/api-models"
import { UserProfileId } from "../../../../api/branded-types"

import { LazyLoadImage } from "react-lazy-load-image-component"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./top-user-area-tile.css"

export type TopUserAreaTileModel = {
    profile: UserProfile
    onUserClicked: (id: UserProfileId) => void
}

export const TopUserAreaTile: FC<TopUserAreaTileModel> = ({
    profile,
    onUserClicked,
}) => (
    <div className={styles.wrapper}>
        <LazyLoadImage
            width={116}
            height={116}
            src={`${profile.imageUrl}?height=${232}`}
            className={styles.image}
            onClick={() => onUserClicked(profile.id)}
        />

        <p
            style={{
                margin: "0 4px 0 4px",
                fontSize: vars.font.size.regular,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                msTextOverflow: "ellipsis",
            }}
        >
            {profile.displayName ?? profile.profileName}
        </p>
    </div>
)

export const TopUserAreaLoadingTile = () => (
    <div className={styles.wrapper}>
        <Skeleton
            baseColor="#3C375C"
            width={116}
            height={116}
            borderRadius={16}
        />
        <Skeleton
            baseColor="#3C375C"
            width={75}
            height={18}
            borderRadius={24}
        />
    </div>
)
