import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { UserProfile, Video } from "../api/api-models"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mapToNotMatchFilterDc,
    mergeWithQueryCache,
} from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { videoSelectorClient } from "../api/clients/video-api-client"
import { QueryKeys } from "../api/query-keys"
import { VideoAreaFeedModel } from "../features/feed/areas/videos/video-area-tile"
import { UserFollowingsInfiniteData } from "./use-user-followings"

const PAGINATION_AMOUNT = 25

export const useRecommendedVideos = (profileId?: UserProfileId) => {
    const queryClient = useQueryClient()

    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            listRecommendedVideos({ pageParam, profileId, queryClient }),
        queryKey: QueryKeys.recommendedVideos(profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.items.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
    })
}
const listRecommendedVideos = async ({
    pageParam,
    profileId,
    queryClient,
}: {
    pageParam: number
    profileId?: UserProfileId
    queryClient: QueryClient
}) => {
    const userFollowingsCache =
        queryClient.getQueryData<UserFollowingsInfiniteData>(
            QueryKeys.profileFollowings(profileId),
        )

    let profilesWhichUserFollows: UserProfile[] = []

    if (userFollowingsCache) {
        profilesWhichUserFollows = userFollowingsCache.pages.flatMap(
            page => page.items,
        )
    } else if (!userFollowingsCache && profileId) {
        //TODO: when proper query merging is possible - use caching call here
        profilesWhichUserFollows = await userProfileSelectorClient
            .listProfilesWhichUserProfileIdFollows(
                {},
                { params: { id: profileId } },
            )
            .then(res => res.data)
    }

    let profileIds = pipe(
        profilesWhichUserFollows,
        A.map(profile => profile.id),
    )

    profileIds = profileId ? [...profileIds, profileId] : profileIds

    const videos = await videoSelectorClient.listVideos({
        filter: `{${mapToNotMatchFilterDc("creatorId", profileIds, 'mimeType: !"" and !"unknown/unknown"')}, $sort:{createdAt: desc}}`,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    videos.data.forEach(video =>
        queryClient.setQueryData<Video>(QueryKeys.video(video.id), video),
    )

    const creatorIds = pipe(
        videos.data,
        A.map(video => video.creatorId),
    )
    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        creatorIds,
        QueryKeys.profile,
    )
    const profilesPromise =
        missingProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("userId", missingProfileIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const profilesRes = await profilesPromise

    profilesRes.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )

    const creatorProfiles = mergeWithQueryCache(
        queryClient,
        creatorIds,
        QueryKeys.profile,
        profilesRes.data,
    )

    return pipe(
        videos.data,
        A.map(video => {
            const profile = creatorProfiles.find(p => p.id === video.creatorId)

            return {
                id: video.id,
                profile,
                video,
                createdAt: video.createdAt,
            } as VideoAreaFeedModel
        }),
        A.filter(
            ({ profile, video }) =>
                video !== undefined && profile !== undefined,
        ),
        items => ({
            items,
            paging: videos.paging,
        }),
    )
}
