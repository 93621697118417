import {
    IconDefinition,
    faImage,
    faPlay,
    faSignalStream,
    faText,
    faWaveformLines,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./create-content-overview-modal.css"

export type CreateContentOverviewModalModel = {
    title: string
    isOpen: boolean

    showFeatureNotAvailable: () => void
    showGoLive: () => void
    showVideoUpload: () => void
    showImageUpload: () => void
    showTextUpload: () => void
    onClosed: () => void
}

export const CreateContentOverviewModal: FC<
    CreateContentOverviewModalModel
> = ({
    title,
    isOpen,
    showFeatureNotAvailable,
    showGoLive,
    showVideoUpload,
    showImageUpload,
    showTextUpload,
    onClosed,
}) => (
    <Drawer open={isOpen} onClose={onClosed}>
        <DrawerContent onClose={onClosed} className={styles.modal}>
            <DrawerTitle>{title}</DrawerTitle>
            <DrawerDescription />

            <div className={styles.wrapper}>
                <ActionTile
                    title="go live"
                    dataTestKey="action-go-live"
                    icon={faSignalStream}
                    onClicked={() => {
                        onClosed()
                        showGoLive()
                    }}
                />
                <ActionTile
                    title="video"
                    dataTestKey="action-post-video"
                    icon={faPlay}
                    onClicked={() => {
                        onClosed()
                        showVideoUpload()
                    }}
                />
                <ActionTile
                    title="text"
                    dataTestKey="action-post-text"
                    icon={faText}
                    onClicked={() => {
                        onClosed()
                        showTextUpload()
                    }}
                />
                <ActionTile
                    title="image"
                    dataTestKey="action-post-image"
                    icon={faImage}
                    onClicked={() => {
                        onClosed()
                        showImageUpload()
                    }}
                />
                <ActionTile
                    title="audio"
                    dataTestKey="action-post-audio"
                    icon={faWaveformLines}
                    onClicked={() => {
                        onClosed()
                        setTimeout(() => showFeatureNotAvailable(), 150)
                    }}
                />
            </div>
        </DrawerContent>
    </Drawer>
)

type ActionTileModel = {
    title: string
    icon: IconDefinition
    dataTestKey?: string
    onClicked?: () => void
}

const ActionTile: FC<ActionTileModel> = ({
    title,
    icon,
    dataTestKey,
    onClicked,
}) => (
    <div
        className={styles.actionWrapper}
        data-test={dataTestKey}
        onClick={onClicked}
    >
        <div className={styles.actionTileImage}>
            <FontAwesomeIcon
                className={styles.icon}
                icon={icon}
                color="black"
            />
        </div>
        <div className={styles.actionTileTitle}>
            <p style={{ fontSize: vars.font.size.m, margin: 0 }}>{title}</p>
        </div>
    </div>
)
