import {
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
} from "@microsoft/signalr"
import { LocalStorage } from "local-storage"
import { useEffect, useState } from "react"

export type ConnectionStatus =
    | "Connecting"
    | "Connected"
    | "Disconnected"
    | "Reconnecting"

export const useSignalRMessaging = (serviceHubUrl: string) => {
    const [connection, setConnection] = useState<HubConnection | undefined>(
        undefined,
    )
    const [error, setError] = useState<string | undefined>(undefined)
    const [connectionStatus, setConnectionStatus] =
        useState<ConnectionStatus>("Disconnected")

    useEffect(() => {
        const con = new HubConnectionBuilder()
            .withUrl(serviceHubUrl, {
                accessTokenFactory: () => {
                    const token = LocalStorage.getAccessToken()
                    if (!token)
                        throw new Error("Trying to connect while unauthorized")
                    return token
                },
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build()

        setConnection(con)

        return () => {
            con.stop()
        }
    }, [serviceHubUrl])

    useEffect(() => {
        if (!connection) return

        setConnectionStatus("Connecting")
        connection
            .start()
            .then(() => {
                setConnectionStatus("Connected")
                setError(undefined)
            })
            .catch(error => {
                setError(error.message)
                setConnectionStatus("Disconnected")
            })

        connection.onclose(() => setConnectionStatus("Disconnected"))
        connection.onreconnecting(() => setConnectionStatus("Reconnecting"))
        connection.onreconnected(() => setConnectionStatus("Connected"))

        return () => {
            connection
                .stop()
                .catch(error =>
                    console.error("Error stopping SignalR connection", error),
                )
        }
    }, [connection])

    return { connection, connectionStatus, error }
}
