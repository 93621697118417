import classNames from "classnames"
import { forwardRef } from "react"
import * as styles from "./content.css"

type ContentModel = {
    fullscreen?: boolean
    wrapperClassName?: string
}

export const Content = forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div"> & ContentModel
>(({ className, fullscreen, children, wrapperClassName, ...props }, ref) => (
    <div
        ref={ref}
        {...props}
        className={classNames(
            styles.content,
            fullscreen ? styles.fullscreenWrapper : "",
            wrapperClassName,
        )}
    >
        <div
            className={classNames(
                styles.contentInner,
                fullscreen ? styles.fullscreen : "",
                className,
            )}
        >
            {children}
        </div>
    </div>
))
