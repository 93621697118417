import { FC } from "react"

import Skeleton, { SkeletonStyleProps } from "react-loading-skeleton"
import { vars } from "theme/variables.css"

export type AvatarLoadingModel = {
    className?: string
    baseColor?: string
    size?: number
    style?: SkeletonStyleProps
}

export const AvatarLoading: FC<AvatarLoadingModel> = ({
    className = "",
    baseColor,
    size = 64,
    style,
}) => (
    <Skeleton
        style={style}
        containerClassName={className}
        baseColor={baseColor ?? vars.color.dark.hex}
        width={size}
        height={size}
        circle
    />
)
