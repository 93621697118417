import { faPause, faPlay, faStop } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { pipe } from "fp-ts/function"
import { FC, PropsWithChildren } from "react"

import {
    PlayState,
    PlayStateAdt,
} from "../../../components/controls/play-state-control"

import * as styles from "./video-consumer-control.css"

export type VideoConsumerControlModel = {
    className?: string
    disabled?: boolean
    playState: PlayState
    onChanged: (playing: PlayState) => void
}

export const VideoConsumerControl: FC<
    PropsWithChildren<VideoConsumerControlModel>
> = ({ className = "", disabled, playState, children, onChanged }) => (
    <div
        style={{ pointerEvents: disabled ? "none" : "inherit" }}
        className={classNames(styles.control, className)}
        onClick={() => {
            // whole screen for user's selection because
            // control disappears after playing
            if (playState.type !== "Playing") {
                return
            }
            onChanged(playState)
        }}
    >
        {pipe(
            playState,
            PlayStateAdt.matchStrict({
                None: () => (
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faPlay}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
                Pausing: () => (
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faPlay}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
                Playing: () => (
                    <FontAwesomeIcon
                        className={styles.fadeOutButton}
                        icon={faPause}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
                Ended: () => (
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faStop}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
            }),
        )}
        {children}
    </div>
)
