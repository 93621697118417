import { QueryClient } from "@tanstack/react-query"
import {
    ImageAsset,
    ImagePost,
    LiveEvent,
    LiveEventPayment,
    Payment,
    Postit,
    PostitPayment,
    TextPost,
    UserProfile,
    Video,
    VideoPayment,
} from "api/api-models"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mergeWithQueryCache,
} from "api/api-utils"
import { liveEventSelectorClient } from "api/clients/live-event-api-client"
import { paymentSelectorClient } from "api/clients/payment-api-client"
import { postitSelectorClient } from "api/clients/post-api-client"
import { userProfileSelectorClient } from "api/clients/user-profile-api-client"
import { videoSelectorClient } from "api/clients/video-api-client"
import { QueryKeys } from "api/query-keys"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { getAssetsQueryFn } from "hooks/use-asset"
import { MY_PAYMENTS_PAGINATION_AMOUNT } from "hooks/use-my-payments"
import { isImageAsset, isResourceRef } from "utils/asset"

type MyPaymentArea =
    | MyVideoPaymentArea
    | MyLiveEventPaymentArea
    | MyPostitPaymentArea

type MyVideoPaymentArea = {
    payment: VideoPayment
    item: Video
    profile: UserProfile
    createdAt: string
}

type MyLiveEventPaymentArea = {
    payment: LiveEventPayment
    item: LiveEvent
    profile: UserProfile
    createdAt: string
}

type MyPostitBasePaymentArea = {
    payment: PostitPayment
    profile: UserProfile
    createdAt: string
}

type MyPostitTextPaymentArea = MyPostitBasePaymentArea & {
    item: TextPost
}

type MyPostitImagePaymentArea = MyPostitBasePaymentArea & {
    item: ImagePost
    asset: ImageAsset
}

type MyPostitPaymentArea = MyPostitTextPaymentArea | MyPostitImagePaymentArea

export const videoPaymentPredicate = (
    payment: Payment,
): payment is VideoPayment => payment.contentRef.contentType === "Video"

export const liveEventPaymentPredicate = (
    payment: Payment,
): payment is LiveEventPayment => payment.contentRef.contentType === "LiveEvent"

export const postitPaymentPredicate = (
    payment: Payment,
): payment is PostitPayment => payment.contentRef.contentType === "Postit"

export const videoPaymentAreaPredicate = (
    paymentArea: MyPaymentArea,
): paymentArea is MyVideoPaymentArea =>
    paymentArea.payment.contentRef.contentType === "Video"

export const liveEventPaymentAreaPredicate = (
    paymentArea: MyPaymentArea,
): paymentArea is MyLiveEventPaymentArea =>
    paymentArea.payment.contentRef.contentType === "LiveEvent"

export const postitPaymentAreaPredicate = (
    paymentArea: MyPaymentArea,
): paymentArea is MyPostitPaymentArea =>
    paymentArea.payment.contentRef.contentType === "Postit"

export const imagePostitPaymentAreaPredicate = (
    paymentArea: MyPostitPaymentArea,
): paymentArea is MyPostitImagePaymentArea => paymentArea.item.type === "Image"

export const getMyPayments = async ({
    pageParam,
    queryClient,
}: {
    pageParam: number
    queryClient: QueryClient
}) => {
    const paymentsRes = await paymentSelectorClient.listPayments({
        filter: `{$match: {state: "Succeeded"} ,$sort:{createdAt: desc}}`,
        paging: {
            type: "Index",
            direction: "After",
            limit: MY_PAYMENTS_PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    const payments = paymentsRes.data

    const videoIds = pipe(
        payments,
        A.filter(videoPaymentPredicate),
        A.map(videoPayment => videoPayment.contentRef.contentId),
    )

    const liveEventIds = pipe(
        payments,
        A.filter(liveEventPaymentPredicate),
        A.map(liveEventPayment => liveEventPayment.contentRef.contentId),
    )

    const postitIds = pipe(
        payments,
        A.filter(postitPaymentPredicate),
        A.map(postitPayment => postitPayment.contentRef.contentId),
    )

    const missingVideoIds = getQueryClientMissingIds(
        queryClient,
        videoIds,
        QueryKeys.video,
    )
    const missingPostIds = getQueryClientMissingIds(
        queryClient,
        postitIds,
        QueryKeys.postit,
    )
    const missingLiveEventIds = getQueryClientMissingIds(
        queryClient,
        liveEventIds,
        QueryKeys.liveEvent,
    )

    const videosFilter = `{${mapToMatchFilterDc("id", missingVideoIds)}}`
    const liveEventsFilter = `{${mapToMatchFilterDc("id", missingLiveEventIds)}}`
    const postitsFilter = `{${mapToMatchFilterDc("id", missingPostIds)}}`

    const getVideos =
        missingVideoIds.length > 0
            ? videoSelectorClient.listVideos({
                  filter: videosFilter,
              })
            : Promise.resolve({ data: [] as Video[] })

    const getLiveEvents =
        missingLiveEventIds.length > 0
            ? liveEventSelectorClient.listLiveEvents({
                  filter: liveEventsFilter,
              })
            : Promise.resolve({ data: [] as LiveEvent[] })

    const getPostits =
        missingPostIds.length > 0
            ? postitSelectorClient.listPostits({
                  filter: postitsFilter,
              })
            : Promise.resolve({ data: [] as Postit[] })

    const [videosRes, liveEventsRes, postitsRes] = await Promise.all([
        getVideos,
        getLiveEvents,
        getPostits,
    ])

    const postResourceAssetRefs = pipe(
        postitsRes.data,
        A.filter(post => post.type === "Image"),
        A.map(post => post.imageUrl),
        A.filter(isResourceRef),
    )

    videosRes.data.forEach(video =>
        queryClient.setQueryData<Video>(QueryKeys.video(video.id), video),
    )
    postitsRes.data.forEach(post =>
        queryClient.setQueryData<Postit>(QueryKeys.postit(post.id), post),
    )
    liveEventsRes.data.forEach(event =>
        queryClient.setQueryData<LiveEvent>(
            QueryKeys.liveEvent(event.id),
            event,
        ),
    )

    const videos = mergeWithQueryCache(
        queryClient,
        videoIds,
        QueryKeys.video,
        videosRes.data,
    )
    const posts = mergeWithQueryCache(
        queryClient,
        postitIds,
        QueryKeys.postit,
        postitsRes.data,
    )
    const liveEvents = mergeWithQueryCache(
        queryClient,
        liveEventIds,
        QueryKeys.liveEvent,
        liveEventsRes.data,
    )

    const profileIds = pipe(
        videos,
        A.concatW(liveEvents),
        A.concatW(posts),
        A.map(item => item.creatorId),
    )

    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        profileIds,
        QueryKeys.profile,
    )

    const uniqueProfileIds = Array.from(new Set(missingProfileIds))

    const getProfiles =
        uniqueProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("id", uniqueProfileIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const [profilesRes, assetsRes] = await Promise.all([
        getProfiles,
        getAssetsQueryFn({ queryClient, resourceRefs: postResourceAssetRefs }),
    ])

    profilesRes.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )

    const profiles = mergeWithQueryCache(
        queryClient,
        profileIds,
        QueryKeys.profile,
        profilesRes.data,
    )

    const result = pipe(
        payments,
        A.map(payment => {
            const video = videos.find(
                v => v.id === payment.contentRef.contentId,
            )
            const liveEvent = liveEvents.find(
                p => p.id === payment.contentRef.contentId,
            )
            const postit = posts.find(
                l => l.id === payment.contentRef.contentId,
            )

            const paymentCreatorContentInfo = pipe(
                video,
                O.fromNullable,
                O.foldW(
                    () =>
                        pipe(
                            postit,
                            O.fromNullable,
                            O.foldW(
                                () =>
                                    pipe(
                                        liveEvent,
                                        O.fromNullable,
                                        O.foldW(
                                            () => undefined,
                                            l =>
                                                ({
                                                    type: "LiveEvent",
                                                    creatorId: l.creatorId,
                                                    content: l,
                                                }) as const,
                                        ),
                                    ),
                                p => {
                                    const assetInfo =
                                        p.type === "Image"
                                            ? p.imageUrl
                                            : undefined
                                    const assetResource =
                                        assetInfo && isResourceRef(assetInfo)
                                            ? assetInfo
                                            : undefined
                                    const asset = assetResource
                                        ? assetsRes.find(
                                              asset =>
                                                  asset.id.resourceId ===
                                                  assetResource.resourceId,
                                          )
                                        : undefined

                                    const imageAsset =
                                        !!asset && isImageAsset(asset)
                                            ? asset
                                            : undefined
                                    return {
                                        type: "Postit",
                                        creatorId: p.creatorId,
                                        content: p,
                                        asset: imageAsset,
                                    } as const
                                },
                            ),
                        ),
                    v =>
                        ({
                            type: "Video",
                            creatorId: v.creatorId,
                            content: v,
                        }) as const,
                ),
            )

            const profile = profiles.find(
                p => p.id === paymentCreatorContentInfo?.creatorId,
            )

            return {
                id: payment.id,
                payment,
                profile,
                item: paymentCreatorContentInfo?.content,
                asset:
                    paymentCreatorContentInfo?.type === "Postit"
                        ? paymentCreatorContentInfo.asset
                        : undefined,
                createdAt: payment.createdAt,
            } as MyPaymentArea
        }),
        A.filter(
            ({ item, profile }) => item !== undefined && profile !== undefined,
        ),
        items => ({
            items,
            paging: paymentsRes.paging,
        }),
    )

    return result
}
