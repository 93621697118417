import { TitleMessageModal } from "features/user-profile/modal/title-message-modal"
import { useNavigate } from "react-router-dom"
import { paymentModalStore } from "../payment-modal-store"
import { SuccessMessageModal } from "./success-message-modal"

export const PaymentModalProvider = () => {
    const navigate = useNavigate()

    const failedOpen = paymentModalStore(store => store.failedPaymentModalOpen)
    const setFailedOpen = paymentModalStore(
        store => store.setFailedPaymentModalOpen,
    )

    const successOpen = paymentModalStore(
        store => store.successPaymentModalOpen,
    )
    const setSuccessOpen = paymentModalStore(
        store => store.setSuccessPaymentModalOpen,
    )
    return (
        <>
            <TitleMessageModal
                isOpen={failedOpen}
                title="Something went wrong"
                message="Please make sure you are logged in and check your network connection. Then try again."
                action="Okay"
                onClosed={() => {
                    setFailedOpen(false)
                    navigate("/app/my-profile?tab=Purchased")
                }}
            />

            <SuccessMessageModal
                isOpen={successOpen}
                title="Congratulations! Your purchase has been successfully completed."
                message="You now have full access to the purchased content and can enjoy it at any time."
                action="Close"
                onClosed={() => {
                    setSuccessOpen(false)
                    navigate("/app/my-profile?tab=Purchased")
                }}
            />
        </>
    )
}
