import { FC, useEffect } from "react"
import { useIntersectionObserver } from "../hooks/use-intersection-observer"
import { RemoteAdt } from "../utils/remote-adt"

type InfiniteScrollModel = {
    threshold?: `${number}${"px" | "%"}`
    disabled?: boolean
    state: RemoteAdt["type"]
    onLoadRequested: () => void
    children?: React.ReactNode
}

export const InfiniteScroll: FC<InfiniteScrollModel> = ({
    children,
    threshold = "100px",
    disabled = false,
    state,
    onLoadRequested,
}) => {
    const { isIntersecting, ref } = useIntersectionObserver()

    useEffect(() => {
        if (disabled) return
        if (isIntersecting && state !== "Loading") onLoadRequested()
    }, [disabled, isIntersecting, onLoadRequested, state])

    return (
        <>
            {children}
            <div ref={ref} style={{ width: "100%", height: threshold }} />
        </>
    )
}
