import * as TE from "fp-ts/TaskEither"

import { COMMON_MIME_TYPES } from "./mime-types"

export const toFileWithPath = (
    file: FileWithPath,
    path?: string,
): FileWithPath => {
    const f = withMimeType(file)
    if (typeof f.path !== "string") {
        // on electron, path is already set to the absolute path
        const { webkitRelativePath } = file
        Object.defineProperty(f, "path", {
            value:
                typeof path === "string"
                    ? path
                    : // If <input webkitdirectory> is set,
                      // the File will have a {webkitRelativePath} property
                      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/webkitdirectory
                      typeof webkitRelativePath === "string" &&
                        webkitRelativePath.length > 0
                      ? webkitRelativePath
                      : file.name,
            writable: false,
            configurable: false,
            enumerable: true,
        })
    }

    return f
}

export type FileWithPath = File & {
    readonly path?: string
}

export const withMimeType = (file: FileWithPath) => {
    const { name } = file
    const hasExtension = name && name.lastIndexOf(".") !== -1

    if (hasExtension && !file.type) {
        const ext = name.split(".").pop()!.toLowerCase()
        const type = COMMON_MIME_TYPES.get(ext)
        if (type) {
            Object.defineProperty(file, "type", {
                value: type,
                writable: false,
                configurable: false,
                enumerable: true,
            })
        }
    }

    return file
}

export const readFileAsBlob: (
    file: File,
) => TE.TaskEither<Error, Blob> = file =>
    TE.tryCatch(
        () =>
            new Promise<Blob>((resolve, reject) => {
                const reader = new FileReader()

                reader.onload = () => {
                    const arrayBuffer = reader.result
                    if (arrayBuffer) {
                        const blob = new Blob([arrayBuffer], {
                            type: file.type,
                        })
                        resolve(blob)
                    } else {
                        reject(new Error("No data received from file read"))
                    }
                }

                reader.onerror = () => reject(reader.error)
                reader.readAsArrayBuffer(file)
            }),
        reason => new Error(`${reason}`),
    )
