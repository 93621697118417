import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { LiveEventId, Url } from "../../../api/branded-types"
import { fill } from "../../../utils/array"

import * as styles from "./live-event-search-result-list.css"
import {
    LiveEventSearchResultLoadingTile,
    LiveEventSearchResultTile,
    LiveEventSearchResultTileModel,
} from "./live-event-search-result-tile"

export type LiveEventSearchResultListTextModel = {
    liveText: string
}

export type LiveEventSearchResultListModel = {
    text: LiveEventSearchResultListTextModel
    events: {
        eventId: LiveEventId
        title: string
        viewCount: number
        avatar: {
            image: Url
            isLive: boolean
            name: string
        }
        onUserClicked: LiveEventSearchResultTileModel["avatar"]["onClicked"]
        onClicked: LiveEventSearchResultTileModel["onClicked"]
    }[]
    isLoading?: boolean
    loadingItemsAmount?: number
}

export const LiveEventSearchResultList: FC<LiveEventSearchResultListModel> = ({
    text,
    events,
    isLoading,
    loadingItemsAmount,
}) => (
    <div className={styles.list}>
        {pipe(
            events,
            A.map(event => (
                <LiveEventSearchResultTile
                    key={event.eventId}
                    title={event.title}
                    stateText={text.liveText}
                    avatar={{
                        image: event.avatar.image,
                        isLive: event.avatar.isLive,
                        name: event.avatar.name,
                        viewCount: event.viewCount,
                        onClicked: event.onUserClicked,
                    }}
                    onClicked={event.onClicked}
                />
            )),
        )}
        {isLoading &&
            fill(loadingItemsAmount ?? 4, idx => (
                <LiveEventSearchResultLoadingTile key={idx} />
            ))}
    </div>
)

export const LiveEventSearchSectionLoading: FC = () => (
    <div className={styles.loadingSectionContainer}>
        <div className={styles.loadingSectionTitle} />
        <div className={styles.list}>
            <LiveEventSearchResultLoadingTile />
            <LiveEventSearchResultLoadingTile />
            <LiveEventSearchResultLoadingTile />
            <LiveEventSearchResultLoadingTile />
        </div>
    </div>
)
