import { IonLabel } from "@ionic/react"
import classNames from "classnames"
import { flow, pipe } from "fp-ts/function"
import { FC } from "react"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { UserProfile } from "../../api/api-models"
import { UserMessageId, UserProfileId } from "../../api/branded-types"
import { Avatar } from "../../components/avatars/avatar"
import { stopPropagation } from "../../utils/fp"
import { messageTimeInfo } from "./time-format"
import * as styles from "./user-message-list-item.css"

export type UserMessageListItemModel = {
    className?: string
    avatarSize?: number
    message?: string
    time?: string
    profile: UserProfile
    newMessageIds: UserMessageId[]
    onClicked: (id: UserProfileId) => void
}
export const UserMessageListItem: FC<UserMessageListItemModel> = ({
    className = "",
    avatarSize,
    profile,
    message,
    newMessageIds,
    time,
    onClicked,
}) => (
    <div
        className={classNames(styles.wrapper, className)}
        onClick={flow(stopPropagation, () => onClicked(profile.id))}
    >
        <Avatar
            src={profile.imageUrl}
            size={avatarSize}
            styleState={profile.isLive ? "isLive" : "None"}
        />

        <div
            style={{
                width: "100%",
                textAlign: "left",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            }}
        >
            <IonLabel>
                <p
                    style={{
                        fontSize: vars.font.size.regular,
                        color: "var(--text-color, white)",
                        textAlign: "left",
                    }}
                >
                    {profile.displayName}
                </p>
            </IonLabel>

            <IonLabel>
                <p
                    style={{
                        fontSize: vars.font.size.regular,
                        color: "var(--text-color, #B4B2C0)",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}
                >
                    {message}
                </p>
            </IonLabel>
        </div>

        <div
            className={styles.wrapperRight}
            onClick={() => onClicked(profile.id)}
        >
            {newMessageIds.length > 0 && (
                <p
                    className={styles.messageCountInfo}
                    style={{ fontSize: vars.font.size.xs }}
                >
                    {newMessageIds.length}
                </p>
            )}

            {time && (
                <IonLabel>
                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: "var(--text-color, #B4B2C0)",
                            textAlign: "left",
                        }}
                    >
                        {pipe(time, messageTimeInfo)}
                    </p>
                </IonLabel>
            )}
        </div>
    </div>
)

export const UserMessageListLoadingItem: FC = () => (
    <div className={classNames(styles.wrapper)}>
        <div className={styles.loadingContent}>
            <Skeleton width={48} height={48} circle />

            <div className={styles.loadingWrapperContent}>
                <Skeleton height={20} width={"45%"} borderRadius={24} />
                <Skeleton height={20} width={"80%"} borderRadius={24} />
            </div>
        </div>
    </div>
)
