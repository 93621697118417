import { faAward } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { vars } from "theme/variables.css"

export const RewardPointSystemTeaser: FC = () => {
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: "flex",
                padding: "16px 20px",
                backgroundColor: vars.color.secondary.hex,
                borderRadius: 12,
                gap: 18,
                color: vars.color.medium.hex,
                fontWeight: 600,
            }}
        >
            <FontAwesomeIcon icon={faAward} style={{ height: 32 }} />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    textAlign: "left",
                    alignItems: "flex-start",
                    gap: 4,
                }}
            >
                <p style={{ fontSize: vars.font.size.m, fontWeight: 600 }}>
                    Collect points and get rewarded!
                </p>

                <div
                    style={{
                        fontSize: vars.font.size.regular,
                        cursor: "pointer",
                        color: "white",
                        lineHeight: "18px",
                    }}
                    onClick={() => navigate("/app/rewards-explained")}
                >
                    Learn more about how you can earn points through activities
                    in the Seemee app.
                </div>
            </div>
        </div>
    )
}
