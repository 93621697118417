import * as A from "fp-ts/Array"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"

import { faBell } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InfiniteData } from "@tanstack/react-query"
import { getIsAuthorizedAccount } from "api/api-utils"
import classNames from "classnames"
import { ErrorState } from "common/error-state"
import { InfiniteScroll } from "common/infinite-scroll"
import { PullToRefresh } from "common/pull-to-refresh"
import { Toolbar } from "common/toolbar"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/tabs/tabs"
import {
    DatabaseNotificationWithDetails,
    NotificationDetailType,
} from "data-flow/notification"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { match } from "ts-pattern"
import { fill } from "utils/array"
import { DatabaseNotification, Pagination } from "../../api/api-models"
import { DatabaseNotificationId, UserProfileId } from "../../api/branded-types"
import { QueryKeys, QueryKeyValue } from "../../api/query-keys"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { useCommentNotification } from "./hooks/use-comment-notification"
import { useFollowNotification } from "./hooks/use-follow-notifications"
import { useLikeNotification } from "./hooks/use-like-notifications"
import { useNotificationCenter } from "./hooks/use-notification-center"
import { usePostNotification } from "./hooks/use-post-notification"
import { useReadMultipleNotificationsMutation } from "./hooks/use-read-multiple-notifications-mutation"
import { useSubscriptionNotification } from "./hooks/use-subscription-notifications"
import { useUnreadNotifications } from "./hooks/use-unread-notifications"
import {
    NotificationCenterItem,
    NotificationCenterLoadingItem,
} from "./notification-center-item"
import * as styles from "./notifications-page.css"

export type InfiniteNotificationsData = InfiniteData<{
    items: DatabaseNotificationWithDetails<NotificationDetailType>[]
    paging: Pagination
}>

export type UnreadNotificationsData = {
    totalCount: number
}

export type NotificationsPageTab =
    | "All"
    | "Followers"
    | "Subscribers"
    | "Likes"
    | "Comments"
    | "Posts"

type Tab = NotificationsPageTab

export const NotificationsPage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    //TODO: add localization
    const myProfileIdQuery = useMyUserProfileId(isAuthorizedAccount)
    const profileId = useRef<UserProfileId | undefined>(undefined)
    profileId.current = myProfileIdQuery.isSuccess
        ? myProfileIdQuery.data
        : undefined

    const [notificationsTab, setNotificaitonsTab] = useState<Tab>("All")
    const [userSelectedTabs, setUserSelectedTabs] = useState<
        Set<NotificationsPageTab>
    >(new Set(["All"]))

    const selectedPageQueryKey = useRef<QueryKeyValue | undefined>(undefined)

    selectedPageQueryKey.current = useMemo(
        () =>
            pipe(
                notificationsTab,
                getNotificationCenterQueryKeyByTab,
                O.fromNullable,
                O.fold(
                    () => undefined,
                    f => f(profileId.current),
                ),
            ),
        [notificationsTab],
    )

    const { mutate: readNotifications } = useReadMultipleNotificationsMutation()

    const newNotificationsQuery = useUnreadNotifications(profileId.current)
    const isHaveNewNotifications =
        (newNotificationsQuery.data?.totalCount ?? 0) > 0

    const allNotificationsQuery = useNotificationCenter(profileId.current)

    const subscriptionNotificationsQuery = useSubscriptionNotification(
        profileId.current,
    )
    //TODO: make these disabled until the specific filter selected?
    const followNotificationsQuery = useFollowNotification(profileId.current)
    const likeNotificationsQuery = useLikeNotification(profileId.current)
    const commentNotificationsQuery = useCommentNotification(profileId.current)
    const postNotificationsQuery = usePostNotification(profileId.current)

    const allNotifications = pipe(
        allNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.items),
    )
    const allNotificationsWithDetails = pipe(
        allNotifications,
        A.filter(notification => notification.type !== "NoDetail"),
    )

    const subscriptionNotifications = pipe(
        subscriptionNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.items),
    )
    const subscriptionNotificationsWithDetails = pipe(
        subscriptionNotifications,
        A.filter(n => n.type !== "NoDetail"),
    )

    const followNotifications = pipe(
        followNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.items),
    )
    const followNotificationsWithDetails = pipe(
        followNotifications,
        A.filter(n => n.type !== "NoDetail"),
    )

    const likeNotifications = pipe(
        likeNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.items),
    )
    const likeNotificationsWithDetails = pipe(
        likeNotifications,
        A.filter(n => n.type !== "NoDetail"),
    )

    const commentNotifications = pipe(
        commentNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.items),
    )
    const commentNotificationsWithDetails = pipe(
        commentNotifications,
        A.filter(n => n.type !== "NoDetail"),
    )

    const postNotifications = pipe(
        postNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.items),
    )
    const postNotificationsWithDetails = pipe(
        postNotifications,
        A.filter(n => n.type !== "NoDetail"),
    )

    const allUnreadNotificationIds = useMemo(
        () =>
            allNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [allNotifications],
    )
    const subscriptionUnreadNotificationIds = useMemo(
        () =>
            subscriptionNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [subscriptionNotifications],
    )
    const followUnreadNotificationIds = useMemo(
        () =>
            followNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [followNotifications],
    )
    const likeUnreadNotificationIds = useMemo(
        () =>
            likeNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [likeNotifications],
    )
    const commentUnreadNotificationIds = useMemo(
        () =>
            commentNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [commentNotifications],
    )
    const postUnreadNotificationIds = useMemo(
        () =>
            postNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [postNotifications],
    )

    const seenNotificationIds = useRef(new Set<DatabaseNotificationId>())

    seenNotificationIds.current = useMemo(() => {
        const notificationsSet = new Set(allUnreadNotificationIds)
        if (userSelectedTabs.has("Subscribers"))
            subscriptionUnreadNotificationIds.forEach(id =>
                notificationsSet.add(id),
            )
        if (userSelectedTabs.has("Followers"))
            followUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        if (userSelectedTabs.has("Likes"))
            likeUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        if (userSelectedTabs.has("Comments"))
            commentUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        if (userSelectedTabs.has("Posts"))
            postUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        return notificationsSet
    }, [
        allUnreadNotificationIds,
        commentUnreadNotificationIds,
        followUnreadNotificationIds,
        likeUnreadNotificationIds,
        postUnreadNotificationIds,
        subscriptionUnreadNotificationIds,
        userSelectedTabs,
    ])

    const allNotificationsState = match(allNotificationsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const subscriptionNotificationsState = match(subscriptionNotificationsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)
    const followNotificationsState = match(followNotificationsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)
    const likeNotificationsState = match(likeNotificationsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)
    const commentNotificationsState = match(commentNotificationsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)
    const postNotificationsState = match(postNotificationsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showAllNotificationsLoadingState =
        (allNotificationsQuery.isLoading ||
            allNotificationsQuery.isFetchingNextPage) &&
        !allNotificationsQuery.isPlaceholderData

    const showSubscriptionNotificationsLoadingState =
        (subscriptionNotificationsQuery.isLoading ||
            subscriptionNotificationsQuery.isFetchingNextPage) &&
        !subscriptionNotificationsQuery.isPlaceholderData

    const showFollowNotificationsLoadingState =
        (followNotificationsQuery.isLoading ||
            followNotificationsQuery.isFetchingNextPage) &&
        !followNotificationsQuery.isPlaceholderData

    const showLikeNotificationsLoadingState =
        (likeNotificationsQuery.isLoading ||
            likeNotificationsQuery.isFetchingNextPage) &&
        !likeNotificationsQuery.isPlaceholderData

    const showCommentNotificationsLoadingState =
        (commentNotificationsQuery.isLoading ||
            commentNotificationsQuery.isFetchingNextPage) &&
        !commentNotificationsQuery.isPlaceholderData

    const showPostNotificationsLoadingState =
        (postNotificationsQuery.isLoading ||
            postNotificationsQuery.isFetchingNextPage) &&
        !postNotificationsQuery.isPlaceholderData

    const showAllNotificationsEmptyState =
        (allNotificationsState === "Loaded" ||
            allNotificationsQuery.isRefetching) &&
        allNotificationsWithDetails.length === 0

    const showSubscriptionNotificationsEmptyState =
        (subscriptionNotificationsState === "Loaded" ||
            subscriptionNotificationsQuery.isRefetching) &&
        subscriptionNotificationsWithDetails.length === 0

    const showFollowNotificationsEmptyState =
        (followNotificationsState === "Loaded" ||
            followNotificationsQuery.isRefetching) &&
        followNotificationsWithDetails.length === 0

    const showLikeNotificationsEmptyState =
        (likeNotificationsState === "Loaded" ||
            likeNotificationsQuery.isRefetching) &&
        likeNotificationsWithDetails.length === 0

    const showCommentNotificationsEmptyState =
        (commentNotificationsState === "Loaded" ||
            commentNotificationsQuery.isRefetching) &&
        commentNotificationsWithDetails.length === 0

    const showPostNotificationsEmptyState =
        (postNotificationsState === "Loaded" ||
            postNotificationsQuery.isRefetching) &&
        postNotificationsWithDetails.length === 0

    const markNotificationsAsRead = useCallback(() => {
        if (seenNotificationIds.current.size === 0) return
        readNotifications({
            notificationIdSet: seenNotificationIds.current,
            profileId: profileId.current,
            selectedPageQueryKey: selectedPageQueryKey.current,
        })
    }, [readNotifications])

    const refreshNotificationsState = async () => {
        markNotificationsAsRead()
        await Promise.allSettled([
            allNotificationsQuery.refetch(),
            followNotificationsQuery.refetch(),
            subscriptionNotificationsQuery.refetch(),
            likeNotificationsQuery.refetch(),
            commentNotificationsQuery.refetch(),
            postNotificationsQuery.refetch(),
            newNotificationsQuery.refetch(),
        ])
    }
    //TODO: has an issue, when continuously navigating to notifications page
    // this function runs both when going into the page and going out of the page
    // (ISSUE ONLY EXISTS IN STRICT MODE, NOT DEPLOYED VERSION)
    useEffect(() => {
        return () => markNotificationsAsRead()
    }, [markNotificationsAsRead])

    return (
        <Page>
            <NavigationBackButton />
            <Toolbar />
            <Content className={styles.content}>
                <PullToRefresh onRefresh={refreshNotificationsState}>
                    <h1>
                        Activities{" "}
                        {isHaveNewNotifications ? (
                            <span
                                style={{ fontSize: vars.font.size.regular }}
                                className={styles.newActivities}
                            >
                                {newNotificationsQuery.data?.totalCount} new
                            </span>
                        ) : null}
                    </h1>

                    <Tabs
                        value={notificationsTab}
                        onValueChange={tab => {
                            setNotificaitonsTab(tab)
                            setUserSelectedTabs(prev => {
                                const updatedSelection = new Set(prev)
                                updatedSelection.add(tab)
                                return updatedSelection
                            })
                        }}
                    >
                        <TabsList
                            className={classNames(
                                styles.tabsList,
                                allNotificationsWithDetails.length === 0
                                    ? styles.tabsListInvisible
                                    : "",
                            )}
                        >
                            <TabsTrigger<Tab> value="All">All</TabsTrigger>
                            <TabsTrigger<Tab> value="Followers">
                                New Follower
                            </TabsTrigger>
                            <TabsTrigger<Tab> value="Subscribers">
                                New Subscriber
                            </TabsTrigger>
                            <TabsTrigger<Tab> value="Likes">Likes</TabsTrigger>
                            <TabsTrigger<Tab> value="Comments">
                                Comments
                            </TabsTrigger>
                            <TabsTrigger<Tab> value="Posts">
                                New Post
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="All"
                        >
                            <InfiniteScroll
                                disabled={!allNotificationsQuery.hasNextPage}
                                state={allNotificationsState}
                                threshold="100px"
                                onLoadRequested={
                                    allNotificationsQuery.fetchNextPage
                                }
                            >
                                <div className={styles.notificationsList}>
                                    {allNotificationsWithDetails.map(
                                        notification => (
                                            <NotificationCenterItem
                                                key={notification.id}
                                                className={
                                                    styles.notificationItem
                                                }
                                                notification={notification}
                                            />
                                        ),
                                    )}
                                    {showAllNotificationsLoadingState &&
                                        fill(5, key => (
                                            <NotificationCenterLoadingItem
                                                key={key}
                                                hasImage={key < 2}
                                                className={
                                                    styles.notificationItem
                                                }
                                            />
                                        ))}
                                </div>
                                {showAllNotificationsEmptyState ? (
                                    <EmptyActivities
                                        text={{
                                            title: "No activities yet",
                                            description: `There is no news for you (yet). We'll let you
    know when there's something new.`,
                                        }}
                                    />
                                ) : null}
                                {allNotificationsState === "Failed" && (
                                    <ErrorState
                                        onTryAgainClicked={
                                            allNotificationsQuery.refetch
                                        }
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Followers"
                        >
                            <InfiniteScroll
                                disabled={!followNotificationsQuery.hasNextPage}
                                state={followNotificationsState}
                                threshold="100px"
                                onLoadRequested={
                                    followNotificationsQuery.fetchNextPage
                                }
                            >
                                <div className={styles.notificationsList}>
                                    {followNotificationsWithDetails.map(
                                        notification => (
                                            <NotificationCenterItem
                                                key={notification.id}
                                                className={
                                                    styles.notificationItem
                                                }
                                                notification={notification}
                                            />
                                        ),
                                    )}
                                    {showFollowNotificationsLoadingState &&
                                        fill(5, key => (
                                            <NotificationCenterLoadingItem
                                                key={key}
                                                hasImage={key < 2}
                                                className={
                                                    styles.notificationItem
                                                }
                                            />
                                        ))}
                                </div>
                                {showFollowNotificationsEmptyState ? (
                                    <EmptyActivities
                                        text={{
                                            title: "No activities yet",
                                            description: `There is no 'new followers' for you. We'll let you
    know when there's something new.`,
                                        }}
                                    />
                                ) : null}
                                {followNotificationsState === "Failed" && (
                                    <ErrorState
                                        onTryAgainClicked={
                                            followNotificationsQuery.refetch
                                        }
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Subscribers"
                        >
                            <InfiniteScroll
                                disabled={
                                    !subscriptionNotificationsQuery.hasNextPage
                                }
                                state={subscriptionNotificationsState}
                                threshold="100px"
                                onLoadRequested={
                                    subscriptionNotificationsQuery.fetchNextPage
                                }
                            >
                                <div className={styles.notificationsList}>
                                    {subscriptionNotificationsWithDetails.map(
                                        notification => (
                                            <NotificationCenterItem
                                                key={notification.id}
                                                className={
                                                    styles.notificationItem
                                                }
                                                notification={notification}
                                            />
                                        ),
                                    )}
                                    {showSubscriptionNotificationsLoadingState &&
                                        fill(5, key => (
                                            <NotificationCenterLoadingItem
                                                key={key}
                                                hasImage={key < 2}
                                                className={
                                                    styles.notificationItem
                                                }
                                            />
                                        ))}
                                </div>
                                {showSubscriptionNotificationsEmptyState ? (
                                    <EmptyActivities
                                        text={{
                                            title: "No activities yet",
                                            description: `There is no 'new subscribers' for you. We'll let you
    know when there's something new.`,
                                        }}
                                    />
                                ) : null}
                                {subscriptionNotificationsState ===
                                    "Failed" && (
                                    <ErrorState
                                        onTryAgainClicked={
                                            subscriptionNotificationsQuery.refetch
                                        }
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Likes"
                        >
                            <InfiniteScroll
                                disabled={!likeNotificationsQuery.hasNextPage}
                                state={likeNotificationsState}
                                threshold="100px"
                                onLoadRequested={
                                    likeNotificationsQuery.fetchNextPage
                                }
                            >
                                <div className={styles.notificationsList}>
                                    {likeNotificationsWithDetails.map(
                                        notification => (
                                            <NotificationCenterItem
                                                key={notification.id}
                                                className={
                                                    styles.notificationItem
                                                }
                                                notification={notification}
                                            />
                                        ),
                                    )}
                                    {showLikeNotificationsLoadingState &&
                                        fill(5, key => (
                                            <NotificationCenterLoadingItem
                                                key={key}
                                                hasImage={key < 2}
                                                className={
                                                    styles.notificationItem
                                                }
                                            />
                                        ))}
                                </div>
                                {showLikeNotificationsEmptyState ? (
                                    <EmptyActivities
                                        text={{
                                            title: "No activities yet",
                                            description: `There is no 'likes' for you. We'll let you
    know when there's something new.`,
                                        }}
                                    />
                                ) : null}
                                {likeNotificationsState === "Failed" && (
                                    <ErrorState
                                        onTryAgainClicked={
                                            likeNotificationsQuery.refetch
                                        }
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Comments"
                        >
                            <InfiniteScroll
                                disabled={
                                    !commentNotificationsQuery.hasNextPage
                                }
                                state={commentNotificationsState}
                                threshold="100px"
                                onLoadRequested={
                                    commentNotificationsQuery.fetchNextPage
                                }
                            >
                                <div className={styles.notificationsList}>
                                    {commentNotificationsWithDetails.map(
                                        notification => (
                                            <NotificationCenterItem
                                                key={notification.id}
                                                className={
                                                    styles.notificationItem
                                                }
                                                notification={notification}
                                            />
                                        ),
                                    )}
                                    {showCommentNotificationsLoadingState &&
                                        fill(5, key => (
                                            <NotificationCenterLoadingItem
                                                key={key}
                                                hasImage={key < 2}
                                                className={
                                                    styles.notificationItem
                                                }
                                            />
                                        ))}
                                </div>
                                {showCommentNotificationsEmptyState ? (
                                    <EmptyActivities
                                        text={{
                                            title: "No activities yet",
                                            description: `There is no 'comments' for you. We'll let you
    know when there's something new.`,
                                        }}
                                    />
                                ) : null}
                                {commentNotificationsState === "Failed" && (
                                    <ErrorState
                                        onTryAgainClicked={
                                            commentNotificationsQuery.refetch
                                        }
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Posts"
                        >
                            <InfiniteScroll
                                disabled={!postNotificationsQuery.hasNextPage}
                                state={postNotificationsState}
                                threshold="100px"
                                onLoadRequested={
                                    postNotificationsQuery.fetchNextPage
                                }
                            >
                                <div className={styles.notificationsList}>
                                    {postNotificationsWithDetails.map(
                                        notification => (
                                            <NotificationCenterItem
                                                key={notification.id}
                                                className={
                                                    styles.notificationItem
                                                }
                                                notification={notification}
                                            />
                                        ),
                                    )}
                                    {showPostNotificationsLoadingState &&
                                        fill(5, key => (
                                            <NotificationCenterLoadingItem
                                                key={key}
                                                hasImage={key < 2}
                                                className={
                                                    styles.notificationItem
                                                }
                                            />
                                        ))}
                                </div>
                                {showPostNotificationsEmptyState ? (
                                    <EmptyActivities
                                        text={{
                                            title: "No activities yet",
                                            description: `There is no 'posts' for you. We'll let you
    know when there's something new.`,
                                        }}
                                    />
                                ) : null}
                                {postNotificationsState === "Failed" && (
                                    <ErrorState
                                        onTryAgainClicked={
                                            postNotificationsQuery.refetch
                                        }
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                    </Tabs>
                </PullToRefresh>
            </Content>
        </Page>
    )
}

type EmptyActivitiesModel = {
    text: {
        title: string
        description: string
    }
}

const EmptyActivities: FC<EmptyActivitiesModel> = model => (
    <div className={styles.emptyContainer}>
        <FontAwesomeIcon icon={faBell} className={styles.emptyIcon} />
        <p
            style={{
                fontSize: vars.font.size.m,
            }}
            className={styles.emptyTitle}
        >
            {model.text.title}
        </p>
        <p
            style={{
                fontSize: vars.font.size.regular,
            }}
            className={styles.emptyDescription}
        >
            {model.text.description}
        </p>
    </div>
)

const getNotificationCenterQueryKeyByTab = (tab: Tab) => {
    switch (tab) {
        case "Posts":
            return QueryKeys.postNotifications
        case "Followers":
            return QueryKeys.followNotifications
        case "Likes":
            return QueryKeys.likeNotifications
        case "Comments":
            return QueryKeys.commentNotifications
        case "Subscribers":
            return QueryKeys.subscriptionNotifications
        default: {
            return null
        }
    }
}

const isNotificationUnreadPredicate = (n: DatabaseNotification) =>
    n.notificationType === "NewNotification"

export const NotificationsLoadingPage: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content>
            <div style={{ paddingInline: 20 }}>
                <Skeleton width={196} height={31} borderRadius={16} />
                <div style={{ marginTop: 16 }}>
                    <div className={styles.notificationsList}>
                        <NotificationCenterLoadingItem
                            hasImage
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            hasImage
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            className={styles.notificationItem}
                        />
                    </div>
                </div>
            </div>
        </Content>
    </Page>
)

export default NotificationsPage
