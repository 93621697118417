import { AnimatePresence, motion } from "framer-motion"
import React, { FC } from "react"
import { vars } from "theme/variables.css"
type CondensedHeaderProps = {
    isInView: boolean
    children: React.ReactNode
    className?: string
}

export const CondensedHeader: FC<CondensedHeaderProps> = ({
    isInView,
    children,
    className,
}) => (
    <AnimatePresence>
        {!isInView && (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{
                    opacity: isInView ? 0 : 1,
                }}
                transition={{ ease: [0, 0.71, 0.2, 1.01], duration: 0.4 }}
                style={{
                    fontSize: vars.font.size.xm,
                    fontWeight: 600,
                    width: "100%",
                }}
                className={className}
            >
                {children}
            </motion.div>
        )}
    </AnimatePresence>
)
