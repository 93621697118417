import { ADTType, makeADT, ofType } from "@morphic-ts/adt"

type LoadingFollow = {
    type: "LoadingFollow"
}

type Following = {
    type: "Following"
}

type IgnoringFollow = {
    type: "IgnoringFollow"
}

type NotPossibleFollow = {
    type: "NotPossibleFollow"
}

export const FollowStateAdt = makeADT("type")({
    LoadingFollow: ofType<LoadingFollow>(),
    Following: ofType<Following>(),
    IgnoringFollow: ofType<IgnoringFollow>(),
    NotPossibleFollow: ofType<NotPossibleFollow>(),
})

export type FollowState = ADTType<typeof FollowStateAdt>
