import { parseDate } from "fp-ts-std/Date"
import { sequenceS } from "fp-ts/Apply"
import { flow } from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"

import { isDefined, noAny } from "./object"

// ============================================================================
// #region Number
// ============================================================================
const floorWith = (decimals: number) => (num: number) => {
    const dec = 10 ** decimals
    return Math.floor(num * dec) / dec
}

// according to https://en.wikipedia.org/wiki/Metric_prefix
const shortNumberAndSiPrefix = (decimals: number) => (value: number) => {
    const floor = floorWith(decimals),
        absValue = Math.abs(value)

    return absValue >= 1e9
        ? ([floor(value / 1e9), "G"] as const)
        : absValue >= 1e6
          ? ([floor(value / 1e6), "M"] as const)
          : absValue >= 1e3
            ? ([floor(value / 1e3), "k"] as const)
            : ([value, ""] as const)
}

export const stdShortNumber = shortNumberAndSiPrefix(1)

// ============================================================================
// #region date time
// ============================================================================
export const dateFromUtcIsoString = parseDate
export const dateToUtcIsoString = (d: Date) => d.toISOString()

// ============================================================================
// #region Event
// ============================================================================
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const getEventDetailLax = <K extends unknown>(ev: { detail: K }) => ev.detail
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const getEventDetail = flow(getEventDetailLax, noAny)

export const getEventDetailValueLax = (ev: {
    detail: { value?: unknown }
}): string | null => {
    const value = ev.detail.value
    return typeof value === "string" ? value : null
}

export const getEventDetailValue = flow(
    getEventDetailValueLax,
    O.fromNullable,
    O.chain(
        O.fromPredicate((value): value is string => typeof value === "string"),
    ),
)

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const getEventTargetLax = <K extends unknown>(ev: { target: K }) => ev.target
export const getEventTarget = flow(getEventTargetLax, noAny)

export const stopPropagation = <EV extends { stopPropagation(): void }>(
    e: EV,
) => {
    e.stopPropagation()
    return e
}

export const preventDefault = <EV extends { preventDefault(): void }>(
    e: EV,
) => {
    e.preventDefault()
    return e
}

export const makeOptional =
    <T, U>(f: (x: T) => U) =>
    (x: T | undefined | null) =>
        isDefined(x) ? f(x) : false

export const sequenceSTEPar = sequenceS(TE.ApplyPar)

export const fromPromise = <E, A>(
    promise: Promise<A>,
    onRejected: (reason: unknown) => E,
): TE.TaskEither<E, A> => TE.tryCatch(() => promise, onRejected)
