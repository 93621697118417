import { PostitId, UserProfileId } from "api/branded-types"
import { EDIT_POSTIT_MONETIZE_ENABLED } from "envs"
import { monetizeEqual } from "features/payment/utils"
import { usePostitById } from "features/postit/hooks/use-postit-by-id"
import { isStringInvalid } from "utils/object"
import { useEditTextPostStore } from "../store/edit-text-post-store"
import { useEditTextPostitMutation } from "./use-edit-text-postit-mutation"

type UseEditTextModel = {
    postitId?: PostitId
    profileId?: UserProfileId
}

export const useEditText = (model: UseEditTextModel) => {
    const postitQuery = usePostitById(model.postitId)

    const title = useEditTextPostStore(store => store.title)
    const message = useEditTextPostStore(store => store.message)
    const uploadFinished = useEditTextPostStore(store => store.uploadFinished)
    const uploadFailed = useEditTextPostStore(store => store.uploadFailed)
    const setUploadFinished = useEditTextPostStore(
        store => store.setUploadFinished,
    )
    const setUploadFailed = useEditTextPostStore(store => store.setUploadFailed)

    const monetization = useEditTextPostStore(store => store.monetization)

    const { mutate: editPost, isPending } = useEditTextPostitMutation({
        onSuccess: () => {
            setUploadFinished(true)
            setUploadFailed(false)
        },
        onError: () => {
            setUploadFinished(false)
            setUploadFailed(true)
        },
    })

    const titleValue = postitQuery.isSuccess
        ? (title ?? postitQuery.data.title)
        : undefined
    const messageValue = postitQuery.isSuccess
        ? (message ?? postitQuery.data.message)
        : undefined
    const monetizationValue = postitQuery.isSuccess
        ? (monetization ?? postitQuery.data.monetization)
        : undefined

    const isTitleInvalid = isStringInvalid(titleValue)
    const isMessageInvalid = isStringInvalid(messageValue)
    const valuesAreSame =
        titleValue === postitQuery.data?.title &&
        messageValue === postitQuery.data?.message &&
        monetizeEqual(monetizationValue, postitQuery.data?.monetization)

    const isUploadButtonDisabled =
        isTitleInvalid || isMessageInvalid || valuesAreSame || isPending

    const monetizationDisabled = !EDIT_POSTIT_MONETIZE_ENABLED

    return {
        isPostitLoaded: postitQuery.isSuccess,
        isUploadButtonDisabled,
        title: titleValue,
        message: messageValue,
        monetization: monetizationValue,
        editPost,
        uploadFinished,
        uploadFailed,
        monetizationDisabled,
    }
}
