import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    CreateVideo,
    CreateVideoResponse,
    UpdateVideo,
    UpdateVideoResponse,
} from "../api-models"
import { VideoId } from "../branded-types"

export const videoCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/video",
        alias: "createVideoUpload",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: CreateVideo,
            },
        ],
        response: CreateVideoResponse,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/video/:id",
        alias: "updateVideoUploadById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: VideoId,
            },
            {
                name: "body",
                type: "Body",
                schema: UpdateVideo,
            },
        ],
        response: UpdateVideoResponse,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/video/:id",
        alias: "deleteVideoById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: VideoId,
            },
        ],
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
