import { useMutation, useQueryClient } from "@tanstack/react-query"

import { PostitId, UserProfileId } from "../../../../../api/branded-types"
import { postitCreatorClient } from "../../../../../api/clients/post-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../../../api/query-keys"

type MutationFunctionParams = {
    id: PostitId
    profileId: UserProfileId
}

const searchPagePostsQueryKey = [
    StaticQueryKeys.SEARCH,
    StaticQueryKeys.SEARCH_POSTS,
]

export const useDeletePostMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ id }: MutationFunctionParams) =>
            postitCreatorClient.deletePostitById(undefined, {
                params: {
                    id,
                },
            }),
        onSettled: (_data, _err, { profileId }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profilePostContent(profileId),
            })
            queryClient.invalidateQueries({
                queryKey: searchPagePostsQueryKey,
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileStatistics(profileId),
            })
        },
    })
}
