import { useMutation, useQueryClient } from "@tanstack/react-query"

import { UserProfileId } from "../../../api/branded-types"
import { StaticQueryKeys } from "../../../api/query-keys"
import { createPushSubscription } from "../notification"

type MutationFunctionParams = {
    profileId: UserProfileId
    pushSubscription: PushSubscription
}

export const useCreateNotificationSubscriptionMutation = () => {
    const queryClient = useQueryClient()
    const mutationFn = async ({
        profileId,
        pushSubscription,
    }: MutationFunctionParams) => {
        await createPushSubscription({
            profileId: profileId,
            subscription: pushSubscription,
        })
    }
    return useMutation({
        mutationFn,
        mutationKey: [StaticQueryKeys.CREATE_NOTIFICATION_SUBSCRIPTION],
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [StaticQueryKeys.NOTIFICATION_SUBSCRIPTION_AVAILABLE],
            })
        },
    })
}
