import { FC } from "react"
import { Content } from "../../../../common/content"
import { Page } from "../../../../common/page"
import { NavigationBackButton } from "../../../../components/buttons/navigation-back-button"
import { UserResultLoadingSection } from "../../../search/users/list/user-search-result-list"

export const UserOverviewPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    padding: 20,
                    paddingTop: 0,
                }}
            >
                <UserResultLoadingSection />
            </div>
        </Content>
    </Page>
)
