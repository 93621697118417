import { useMutation, useQueryClient } from "@tanstack/react-query"
import { userCreatorClient } from "../api/clients/user-api-client"
import { QueryKeys } from "../api/query-keys"

export const useUpdatePaymentInfoMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: userCreatorClient.updateUserPayment,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: QueryKeys.paymentInfo() })
        },
    })
}
