import { AppActions, createAppStore } from "./actions"

import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { AppState } from "./state"

const useAppStore = create<AppState & AppActions>()(
    devtools(createAppStore, { name: "AppStore" }),
)

export default useAppStore
