import React from "react"

type Dimensions = {
    width: number | undefined
    height: number | undefined
}

export function useMeasure<T extends Element>(): [
    (node: T | null) => void,
    Dimensions,
] {
    const [dimensions, setDimensions] = React.useState<Dimensions>({
        width: undefined,
        height: undefined,
    })

    const previousObserver = React.useRef<ResizeObserver | null>(null)

    const customRef = React.useCallback((node: T | null) => {
        if (previousObserver.current) {
            previousObserver.current.disconnect()
            previousObserver.current = null
        }

        if (node) {
            const observer = new ResizeObserver(([entry]) => {
                if (entry && entry.borderBoxSize) {
                    const { inlineSize: width, blockSize: height } =
                        entry.borderBoxSize[0]

                    setDimensions({ width, height })
                }
            })

            observer.observe(node)
            previousObserver.current = observer
        }
    }, [])

    return [customRef, dimensions]
}
