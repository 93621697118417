import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    validationErrorHandler,
} from "../api-utils"
import { notificationCreatorApi } from "../creators/notification-creator-api"
import { notificationSelectorApi } from "../selectors/notification-selector-api"

export const notificationSelectorClient = new Zodios(
    `${API_URL}/notification-selector`,
    notificationSelectorApi,
)

notificationSelectorClient.use(tokenHandlerForCreate)
notificationSelectorClient.use(corsFetchHandler)
notificationSelectorClient.use(validationErrorHandler)

export const listNotificationSubscriptions = pipe(
    notificationSelectorClient.listNotificationSubscriptions,
    endpointWithTaskEither,
)

export const listNotificationSubscriptionsByTypeTask = pipe(
    notificationSelectorClient.listNotificationSubscriptionsByType,
    endpointWithTaskEither,
)

export const notificationCreatorClient = new Zodios(
    `${API_URL}/notification-creator`,
    notificationCreatorApi,
)

notificationCreatorClient.use(tokenHandlerForCreate)
notificationCreatorClient.use(corsFetchHandler)
notificationCreatorClient.use(validationErrorHandler)

export const createNotificationSubscriptionTask = pipe(
    notificationCreatorClient.createNotificationSubscription,
    endpointWithTaskEither,
)

export const deleteNotificationSubscriptionTask = pipe(
    notificationCreatorClient.deleteNotificationSubscription,
    endpointWithTaskEither,
)
