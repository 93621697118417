/* eslint-disable no-useless-escape */

import { useInfiniteQuery } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { mapToMatchFilterDc, mapToSortFilterDc } from "../api/api-utils"
import { liveEventSelectorClient } from "../api/clients/live-event-api-client"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"
import { LiveAreaFeedModel } from "../data-flow/feed/live-area-feed"

const PAGINATION_AMOUNT = 25

export const useLiveEventsSearch = (searchKey: string) => {
    const queryKey = QueryKeys.searchLiveEvents(searchKey)
    const titleFilter = `title: *"${searchKey}"*:ci`
    const descriptionFilter = `description: *"${searchKey}"*:ci`
    const stateFilter = `state: "Running"`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")

    const filter = `{$match:{$or: [{${titleFilter}}, {${descriptionFilter}}], ${stateFilter}}, ${sortFilter}}`

    const queryFn = async ({ pageParam }: { pageParam: number }) => {
        const events = await liveEventSelectorClient.listLiveEvents({
            filter,
            paging: {
                type: "Index",
                direction: "After",
                limit: PAGINATION_AMOUNT,
                index: pageParam,
            },
        })

        const profileIds = pipe(
            events.data,
            A.map(({ creatorId }) => creatorId),
        )

        const profiles = () =>
            userProfileSelectorClient.listProfiles({
                filter: `{${mapToMatchFilterDc("id", profileIds)}}`,
            })

        const statistics = () =>
            liveEventSelectorClient.listLiveEventsStatistics({
                // FIXME - currently not supported from BE
                // filter: `{${mapToMatchFilterDc("id", profileIds)}}`
            })

        const [profilesResult, statisticsResult] = await Promise.all([
            profiles().then(({ data }) => data),
            statistics().then(({ data }) => data),
        ])

        return pipe(
            events.data,
            A.map(event => {
                const profile = profilesResult.find(
                    p => p.id === event.creatorId,
                )
                const statistic = statisticsResult.find(
                    s => s.creatorId === event.creatorId,
                )

                return {
                    id: event.id,
                    event,
                    profile,
                    statistic,
                }
            }),
            A.filter(
                ({ profile, statistic }) =>
                    statistic !== undefined && profile !== undefined,
            ),
            liveEventAreas => ({
                liveEventAreas: liveEventAreas as LiveAreaFeedModel[],
                paging: events.paging,
            }),
        )
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn,
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.liveEventAreas.length !== PAGINATION_AMOUNT)
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
    })
}
