import { faStars } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import { FC } from "react"

import { vars } from "theme/variables.css"
import * as styles from "./live-now-area-placeholder.css"

type LiveNowAreaPlaceholderModel = {
    className?: string
    description: string
    action: string
    onClicked: () => void
}

export const LiveNowAreaPlaceholder: FC<LiveNowAreaPlaceholderModel> = ({
    className = "",
    description,
    action,
    onClicked,
}) => (
    <div className={className}>
        <div className={styles.wrapper}>
            <FontAwesomeIcon
                className={styles.icon}
                icon={faStars}
                color="white"
            />
            <p style={{ fontSize: vars.font.size.regular }}>{description}</p>

            <IonButton style={{ width: "100%" }} onClick={onClicked}>
                {action}
            </IonButton>
        </div>
    </div>
)
