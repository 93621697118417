import { faLocationPin } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonChip } from "@ionic/react"
import classNames from "classnames"

import { UserProfileId } from "api/branded-types"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useAsset } from "hooks/use-asset"
import { useUser } from "hooks/use-user"
import { useUserProfile } from "hooks/use-user-profile"
import { isImageAsset } from "utils/asset"
import { useProfileStatistics } from "../hooks/use-profile-statistic"
import { ProfileDataLoading } from "./my-user-profile-data"

import { calc } from "@vanilla-extract/css-utils"
import { Image } from "common/image"
import { vars } from "theme/variables.css"
import * as styles from "../user-profile-page.css"

export type UserProfilePageStatusModel = {
    profileId?: UserProfileId
    text: {
        live: string
        online: string
        offline: string
        locationPlaceholder: string
    }
}

export const UserProfilePageStatus = (model: UserProfilePageStatusModel) => {
    const userProfileQuery = useUserProfile(model.profileId)
    const userQuery = useUser(userProfileQuery.data?.userId)
    const myProfileStatistics = useProfileStatistics(model.profileId)

    const currentTierLogoAssetQuery = useAsset({
        resource: myProfileStatistics.data?.currentTier.logoRef,
    })

    const currentTierLogoImageAsset = pipe(
        currentTierLogoAssetQuery.data,
        O.fromNullable,
        O.chain(O.fromPredicate(isImageAsset)),
    )
    return (
        <>
            {userProfileQuery.isSuccess ? (
                <div className={styles.userStatus}>
                    <div className={styles.rows}>
                        <div className={styles.profileNameContainer}>
                            <h1
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textWrap: "nowrap",
                                    maxWidth: calc.subtract(
                                        "100vw",
                                        calc.add("30px", "40px"),
                                    ),
                                }}
                            >
                                {userProfileQuery.data?.displayName ??
                                    userProfileQuery.data?.profileName}
                            </h1>
                            {O.isSome(currentTierLogoImageAsset) ? (
                                <Image
                                    width={
                                        currentTierLogoImageAsset.value
                                            .resolution.width
                                    }
                                    height={
                                        currentTierLogoImageAsset.value
                                            .resolution.height
                                    }
                                    imageType={
                                        currentTierLogoImageAsset.value
                                            .imageType
                                    }
                                    src={currentTierLogoImageAsset.value.source}
                                    className={styles.tierIcon}
                                />
                            ) : (
                                <div style={{ width: 16, height: 16 }} />
                            )}
                        </div>
                        {userProfileQuery.data?.isLive && (
                            <IonChip
                                className={classNames(
                                    styles.playState,
                                    styles.noMargin,
                                )}
                            >
                                {model.text.live}
                            </IonChip>
                        )}
                    </div>
                    <div className={styles.rows}>
                        <p className={styles.textLight}>
                            <small>
                                {userProfileQuery.data?.profileName}
                                &nbsp;&#x2022;&nbsp;
                                {userQuery.data?.isOnline
                                    ? model.text.online
                                    : model.text.offline}
                            </small>
                        </p>
                    </div>
                    <div
                        className={classNames(
                            styles.rows,
                            styles.locationContainer,
                        )}
                    >
                        <FontAwesomeIcon
                            icon={faLocationPin}
                            color={vars.color.tertiary.hex}
                        />
                        <small className={styles.textLight}>
                            {userProfileQuery.data?.location ??
                                model.text.locationPlaceholder}
                        </small>
                    </div>
                </div>
            ) : (
                <ProfileDataLoading />
            )}
        </>
    )
}
