import { faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonLabel } from "@ionic/react"
import classNames from "classnames"
import { flow } from "fp-ts/function"
import { FC } from "react"

import { UserProfile } from "../../../../api/api-models"
import { UserProfileId } from "../../../../api/branded-types"
import { Avatar } from "../../../../components/avatars/avatar"
import { stopPropagation } from "../../../../utils/fp"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { AvatarLoading } from "../../../../components/avatars/avatar-loading"
import * as styles from "./user-list-item.css"

export type UserListItemModel = {
    className?: string
    avatarSize?: number
    profile: UserProfile
    onClicked: (id: UserProfileId) => void
}
export const UserListItem: FC<UserListItemModel> = ({
    className = "",
    avatarSize,
    profile,
    onClicked,
}) => (
    <div
        className={classNames(styles.wrapper, className)}
        onClick={flow(stopPropagation, () => onClicked(profile.id))}
    >
        <Avatar
            src={profile.imageUrl}
            size={avatarSize}
            styleState={profile.isLive ? "isLive" : "None"}
        />
        <IonLabel style={{ width: "100%" }}>
            <p
                style={{
                    fontSize: vars.font.size.m,
                    color: "var(--text-color, white)",
                    textAlign: "left",
                }}
            >
                {profile.displayName}
            </p>
        </IonLabel>

        <div onClick={() => onClicked(profile.id)}>
            <FontAwesomeIcon
                className={styles.navButton}
                icon={faAngleRight}
                color="var(--nav-icon-color, white)"
            />
        </div>
    </div>
)

export type UserListLoadingItemModel = {
    className?: string
    avatarSize?: string
}
//TODO: baseColor here is improvised, ask design to add proper color
export const UserListLoadingItem = (model: UserListLoadingItemModel) => (
    <div className={classNames(styles.wrapper, model.className)}>
        <div className={styles.loadingContent}>
            <AvatarLoading baseColor="#D5D6D8" size={40} />
            <div>
                <Skeleton
                    containerClassName="flex-fit"
                    baseColor="#D5D6D8"
                    width={224}
                    height={15}
                    borderRadius={24}
                />
                <Skeleton
                    containerClassName="flex-fit"
                    baseColor="#D5D6D8"
                    width={164}
                    height={15}
                    borderRadius={24}
                />
            </div>
        </div>
    </div>
)
