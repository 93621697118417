import { faAward, faLocationPin } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"

import { Image } from "common/image"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useAsset } from "hooks/use-asset"
import { useMe } from "hooks/use-me"
import { useMyUserProfile } from "hooks/use-my-user-profiles"
import Skeleton from "react-loading-skeleton"
import { useProfileStatistics } from "../hooks/use-profile-statistic"

import { calc } from "@vanilla-extract/css-utils"
import { vars } from "theme/variables.css"
import { isImageAsset } from "utils/asset"
import * as styles from "../my-user-profile-page.css"

export type MyUserProfileDataTextModel = {
    online: string
    offline: string
    locationPlaceholder: string
}

type MyUserProfileDataModel = {
    text: MyUserProfileDataTextModel
    setEditProfileModalVisible: () => void
    isAuthorizedAccount: boolean
    onRewardsClicked: () => void
}

export const MyUserProfileData: FC<MyUserProfileDataModel> = model => {
    const meQuery = useMe()
    const myProfileQuery = useMyUserProfile(model.isAuthorizedAccount)
    const myProfileStatistics = useProfileStatistics(myProfileQuery.data?.id)

    const currentTierLogoAssetQuery = useAsset({
        resource: myProfileStatistics.data?.currentTier.logoRef,
    })

    const currentTierLogoImageAsset = pipe(
        currentTierLogoAssetQuery.data,
        O.fromNullable,
        O.chain(O.fromPredicate(isImageAsset)),
    )

    return (
        <>
            {myProfileQuery.isSuccess && (
                <div className={styles.userStatus}>
                    <div className={styles.rows}>
                        <div className={styles.profileNameContainer}>
                            <h1
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textWrap: "nowrap",
                                    maxWidth: calc.subtract(
                                        "100vw",
                                        calc.add("130px", "40px"),
                                    ),
                                }}
                            >
                                {myProfileQuery.data?.displayName ??
                                    myProfileQuery.data?.profileName}
                            </h1>
                            {O.isSome(currentTierLogoImageAsset) ? (
                                <Image
                                    width={
                                        currentTierLogoImageAsset.value
                                            .resolution.width
                                    }
                                    height={
                                        currentTierLogoImageAsset.value
                                            .resolution.height
                                    }
                                    imageType={
                                        currentTierLogoImageAsset.value
                                            .imageType
                                    }
                                    src={currentTierLogoImageAsset.value.source}
                                    className={styles.tierIcon}
                                />
                            ) : (
                                <div style={{ width: 16, height: 16 }} />
                            )}
                        </div>
                        <div
                            className={styles.editButton}
                            onClick={model.setEditProfileModalVisible}
                        >
                            edit
                        </div>

                        <div
                            className={styles.editButton}
                            onClick={model.onRewardsClicked}
                        >
                            <FontAwesomeIcon
                                icon={faAward}
                                style={{ height: 20 }}
                            />
                        </div>
                    </div>

                    <div className={styles.rows}>
                        <small className={styles.textLight}>
                            {myProfileQuery.data?.profileName}
                            &nbsp;&#x2022;&nbsp;
                            {meQuery.data?.user.isOnline
                                ? model.text.online
                                : model.text.offline}
                        </small>
                    </div>

                    <div
                        className={classNames(
                            styles.rows,
                            styles.locationContainer,
                        )}
                    >
                        <FontAwesomeIcon
                            icon={faLocationPin}
                            color={vars.color.tertiary.hex}
                        />
                        <small className={styles.textLight}>
                            {myProfileQuery.data?.location ??
                                model.text.locationPlaceholder}
                        </small>
                    </div>
                </div>
            )}
            {!myProfileQuery.isSuccess && <ProfileDataLoading />}
        </>
    )
}

export const ProfileDataLoading: FC = () => (
    <div className={styles.userStatus}>
        <Skeleton width={230} height={30} borderRadius={16} />
        <Skeleton width={144} height={18} borderRadius={24} />
        <Skeleton width={144} height={18} borderRadius={24} />
    </div>
)
