import { FC } from "react"

import Skeleton from "react-loading-skeleton"
import * as styles from "./post-loading-tile.css"

export const PostLoadingSection: FC = () => (
    <div className={styles.loadingSectionContainer}>
        <div className={styles.loadingSectionTitle} />
        <div className={styles.loadingSectionContent}>
            <PostLoadingTile />
            <PostLoadingTile />
        </div>
    </div>
)

export const PostLoadingTile: FC = () => (
    <div className={styles.loadingTile}>
        <div className={styles.loadingInfo}>
            <Skeleton
                containerClassName="flex-fit"
                width={95}
                height={24}
                borderRadius={24}
            />
            <Skeleton
                containerClassName="flex-fit"
                width={230}
                height={18}
                borderRadius={24}
            />
        </div>
        <div className={styles.loadingAvatarWrapper}>
            <Skeleton width={36} height={36} circle />
            <Skeleton width={75} height={18} borderRadius={24} />
        </div>
    </div>
)
