import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { v4 as uuidv4 } from "uuid"

import { UserMessage, UserProfile } from "../../../api/api-models"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mergeWithQueryCache,
} from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { userMessageSelectorClient } from "../../../api/clients/message-api-client"
import { userProfileSelectorClient } from "../../../api/clients/user-profile-api-client"
import { QueryKeys } from "../../../api/query-keys"
import { getDefaultUserProfile } from "../../../data-flow/user"

const PAGINATION_AMOUNT = 25

type Params = {
    recipientId?: UserProfileId
    senderId?: UserProfileId
    refetchInterval?: number | false
}

export type UserMessageWithProfile = {
    id: string
    message: UserMessage
    profile: UserProfile
}

export const useMessages = (params: Params) => {
    const queryKey = QueryKeys.resourceMessages(
        params.recipientId,
        params.senderId,
    )
    const queryClient = useQueryClient()

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) =>
            listMessages({ ...params, pageParam, queryClient }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.messagesWithUserData.length !== PAGINATION_AMOUNT)
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!params.recipientId,
        refetchInterval: params.refetchInterval,
        notifyOnChangeProps: ["data", "error"],
    })
}

const listMessages = async ({
    pageParam,
    recipientId,
    senderId,
    queryClient,
}: { pageParam: number; queryClient: QueryClient } & Params) => {
    if (!recipientId) throw new Error("Recipient Id is required")
    if (!senderId) throw new Error("Sender Id is required")

    const filter = `{$match: {$or: [
              {recipient.contentType: "UserProfile", recipient.contentId: "${recipientId}", senderId: "${senderId}"},
              {recipient.contentType: "UserProfile", recipient.contentId: "${senderId}", senderId: "${recipientId}"},
            ]}}`

    const messages = await userMessageSelectorClient.listMessages({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    const profileIds = pipe(
        messages.data,
        A.map(({ senderId }) => senderId),
    )
    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        profileIds,
        QueryKeys.profile,
    )

    const profilesPromise =
        missingProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("id", missingProfileIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const profilesRes = await profilesPromise

    profilesRes.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )

    const profiles = mergeWithQueryCache(
        queryClient,
        profileIds,
        QueryKeys.profile,
        profilesRes.data,
    )

    return pipe(
        messages.data,
        A.map(message => ({
            id: message.id,
            message,
            profile: pipe(
                profiles,
                A.findFirst(profile => profile.id === message.senderId),
                O.getOrElse(() =>
                    getDefaultUserProfile(UserProfileId.parse(uuidv4())),
                ),
            ),
        })),
        A.map(data => ({
            ...data,
            id: `${data.message.id}-${data.profile.id}`,
        })),
        messagesWithUserData => ({
            total: messages.totalCount,
            paging: messages.paging,
            messagesWithUserData,
        }),
    )
}
