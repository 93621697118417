import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "common/dialog"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PrimaryButton, SecondaryButton } from "common/buttons"
import { FC } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useNavigate } from "react-router-dom"
import { vars } from "theme/variables.css"
import * as styles from "./first-steps-welcome-modal.css"

type FirstStepsWelcomeModalModel = {
    isOpen: boolean
    onOpenChange: (v: boolean) => void
}

export const FirstStepsWelcomeModal: FC<
    FirstStepsWelcomeModalModel
> = model => {
    const navigate = useNavigate()

    return (
        <Dialog open={model.isOpen} onOpenChange={model.onOpenChange}>
            <DialogContent className={styles.content}>
                <DialogHeader className={styles.header}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={styles.closeIcon}
                        onClick={() => model.onOpenChange(false)}
                    />
                </DialogHeader>
                <div className={styles.mainContentWrapper}>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xxxl,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            Welcome to Seemee Rewards
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            You're starting at{" "}
                            <span style={{ fontWeight: 700, margin: 0 }}>
                                Basic level.
                            </span>
                        </DialogDescription>
                    </div>
                    <LazyLoadImage
                        width={window.innerWidth - 36 * 2}
                        height={(window.innerWidth - 36 * 2) * 0.44}
                        className={styles.image}
                        alt="rewards"
                        loading="eager"
                        src="/images/gfx_points_welcome.svg"
                        style={{ marginBottom: 40 }}
                    />
                    <div className={styles.textContent}>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                fontWeight: 400,
                                marginBottom: 26,
                            }}
                        >
                            No worries – this is just the beginning! Start
                            exploring the app, complete tasks, and earn points
                            to level up. Unlock exclusive rewards and amazing
                            benefits as you progress.
                        </p>
                        <div style={{ margin: "12px 4px" }}>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 700,
                                    margin: 0,
                                }}
                            >
                                Here’s how to start:
                            </p>
                            <ul className={styles.tasksList}>
                                <li>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                            margin: 0,
                                        }}
                                    >
                                        Complete your profile and earn 15 points
                                    </p>
                                </li>
                                <li>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                            margin: 0,
                                        }}
                                    >
                                        Invite friends and earn 20 points
                                    </p>
                                </li>
                                <li>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                            margin: 0,
                                        }}
                                    >
                                        Post your first image and get 10 points
                                    </p>
                                </li>
                                <li>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                            margin: 0,
                                        }}
                                    >
                                        And more...
                                    </p>
                                </li>
                            </ul>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 400,
                                    margin: "12px 4px 32px",
                                }}
                            >
                                The more points you earn, the faster you'll
                                unlock Bronze, Silver, and beyond!
                            </p>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 700,
                                    margin: "0px 4px 12px",
                                }}
                            >
                                Keep it going and claim your rewards.
                            </p>
                        </div>
                    </div>
                </div>

                <DialogFooter
                    style={{
                        display: "flex",
                        flexDirection: "column",

                        width: "100%",

                        position: "fixed",
                        padding: "24px 36px 52px",
                        bottom: 0,
                    }}
                >
                    <PrimaryButton
                        onClick={() => {
                            model.onOpenChange(false)
                            navigate(
                                "/app/settings/rewards?currentTab=get-started",
                            )
                        }}
                    >
                        Start earning now!
                    </PrimaryButton>

                    {/* //TODO: add do not show again functionality, preferably
                    without local storage or cookies */}

                    <SecondaryButton
                        onClick={() => {
                            model.onOpenChange(false)
                        }}
                    >
                        Do not show again
                    </SecondaryButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
