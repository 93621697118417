import { IonTextarea } from "@ionic/react"
import { Button } from "common/button"
import { DialogDescription, DialogTitle } from "common/dialog"
import {
    multiStepModalVariants,
    MultiStepModalWrapper,
} from "common/multi-step-modal"
import { CheckItem } from "components/controls/check-item"
import { AnimatePresence, motion, MotionConfig } from "framer-motion"
import { FC, useMemo } from "react"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { DrawerClose } from "common/drawer"
import { useMeasure } from "hooks/use-measure"

import { ContentReportIssue } from "api/api-models"
import { useReportMutation } from "hooks/use-report-mutation"
import * as styles from "./report-content-modal.css"
import { useReportStore } from "./report-store"

type ContentReportItem = {
    id: ContentReportIssue
    title: string
    description: string
}

const contentReportItems: Array<ContentReportItem> = [
    {
        id: "Spam",
        title: "Spam",
        description:
            "Content that contains unwanted or repetitive information will be reported.",
    },
    {
        id: "Harassment",
        title: "Harassment",
        description:
            "Content that is perceived as offensive, threatening or harassing.",
    },
    {
        id: "MissInformation",
        title: "False information",
        description: "Content that contains false or misleading information.",
    },
    {
        id: "Offensive",
        title: "Offensive content",
        description: "Content that is considered offensive or inappropriate.",
    },
    {
        id: "Other",
        title: "Other",
        description:
            "Content that does not fit into any of the above categories.",
    },
]

type ReportContentModalModel = {
    open: boolean
    onClose: () => void
    onReset: () => void
}

export const ReportContentModal: FC<ReportContentModalModel> = ({
    open,
    onClose,
    onReset,
}) => {
    const currentStep = useReportStore(store => store.reportContentStep)
    const setCurrentStep = useReportStore(store => store.setReportContentStep)
    const customDetails = useReportStore(store => store.reportContentMessage)
    const reportContent = useReportStore(store => store.reportContent)
    const reportContentOwner = useReportStore(store => store.reportContentOwner)
    const issue = useReportStore(store => store.selectedIssue)

    const { mutate: createReport } = useReportMutation()

    const [ref, bounds] = useMeasure()

    const content = useMemo(() => {
        switch (currentStep) {
            case "initial": {
                return <InitialScreen />
            }
            case "detail": {
                return <DetailScreen />
            }
            case "thanks": {
                return <ThanksScreen />
            }
            default: {
                return <></>
            }
        }
    }, [currentStep])

    return (
        <MultiStepModalWrapper open={open} onClose={onClose} onReset={onReset}>
            <DrawerClose className={styles.closeIconContainer}>
                <FontAwesomeIcon
                    className={styles.closeIcon}
                    icon={faXmark}
                    onClick={onClose}
                />
            </DrawerClose>
            <MotionConfig
                transition={{
                    bounce: 0,
                    duration: 0.27,
                    ease: [0.26, 0.08, 0.25, 1],
                }}
            >
                <AnimatePresence
                    key={currentStep}
                    mode="popLayout"
                    initial={false}
                >
                    <motion.div
                        key={`step-content-${currentStep}`}
                        transition={{
                            duration: 0.27,
                            ease: [0.26, 0.08, 0.25, 1],
                        }}
                        style={{ paddingBottom: bounds.height }}
                        className={styles.content}
                        variants={multiStepModalVariants}
                        initial="initial"
                        animate="active"
                        exit="exit"
                    >
                        {content}
                    </motion.div>
                    <motion.div key={`step-footer-${currentStep}`}>
                        <div className={styles.footer} ref={ref}>
                            <Button
                                disabled={currentStep === "initial" && !issue}
                                onClick={() => {
                                    if (currentStep === "initial") {
                                        setCurrentStep("detail")
                                    }
                                    if (currentStep === "detail") {
                                        if (
                                            !reportContentOwner ||
                                            !reportContent ||
                                            !issue
                                        )
                                            return
                                        createReport({
                                            type: "Content",
                                            customDetails,
                                            issue,
                                            resourceOwnerId: reportContentOwner,
                                            resource: reportContent,
                                        })
                                        setCurrentStep("thanks")
                                    }
                                    if (currentStep === "thanks") {
                                        onClose()
                                    }
                                }}
                            >
                                {currentStep === "initial" && "Continue"}
                                {currentStep === "detail" && "Submit"}
                                {currentStep === "thanks" && "Close"}
                            </Button>
                            {currentStep === "detail" && (
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setCurrentStep("initial")
                                    }}
                                    asChild
                                >
                                    <motion.button
                                        key="back-button"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        Back
                                    </motion.button>
                                </Button>
                            )}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </MotionConfig>
        </MultiStepModalWrapper>
    )
}

const InitialScreen = () => {
    const selectedIssue = useReportStore(store => store.selectedIssue)
    const setReportContentIssue = useReportStore(
        store => store.setReportContentIssue,
    )

    return (
        <>
            <DialogTitle className={styles.title}>Content report</DialogTitle>
            <DialogDescription className={styles.description}>
                Please select a reason for your report. Your report will be
                treated anonymously and confidentially. We take all reports
                seriously and take legal action against violations.
            </DialogDescription>
            <div className={styles.checkItems}>
                {contentReportItems.map(item => (
                    <CheckItem
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        message={item.description}
                        checked={item.id === selectedIssue}
                        onChange={value =>
                            setReportContentIssue(value ? item.id : undefined)
                        }
                    />
                ))}
            </div>
        </>
    )
}

const DetailScreen = () => {
    const reportMessage = useReportStore(store => store.reportContentMessage)
    const setReportMessage = useReportStore(
        store => store.setReportContentMessage,
    )
    return (
        <>
            <DialogTitle className={styles.title}>Content report</DialogTitle>
            <DialogDescription className={styles.description}>
                Be specific about why you are reporting this content. The more
                specific the information, the better the issue can be addressed.
            </DialogDescription>
            <IonTextarea
                value={reportMessage}
                onIonChange={e => setReportMessage(e.detail.value ?? "")}
                autoGrow
                className={styles.textarea}
            />
        </>
    )
}

export const ThanksScreen = () => {
    return (
        <>
            <DialogTitle className={styles.title}>
                Thank you for your report
            </DialogTitle>
            <DialogDescription
                className={classNames(
                    styles.description,
                    styles.thanksDescription,
                )}
            >
                Your report will help us to continue to improve Seemee. We will
                take care of it.
            </DialogDescription>
        </>
    )
}
