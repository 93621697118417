import { faPlay } from "@fortawesome/pro-light-svg-icons"
import { faCircleStar as solidStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { UserProfileId, VideoId } from "../../../../api/branded-types"
import { UserDetails } from "../user/user-details"

import { LinkItUrl } from "features/linkify"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"
import { PayableVideo, UserProfile } from "../../../../api/api-models"
import * as styles from "./video-area-tile.css"

export type VideoAreaFeedModel = {
    id: VideoId
    video: PayableVideo
    profile: UserProfile
    createdAt: string
}

export type VideoAreaTileModel = {
    videoArea: VideoAreaFeedModel
    onUserClicked: (id: UserProfileId) => void
    onClicked: (id: VideoId) => void
}

export const VideoAreaTile: FC<VideoAreaTileModel> = ({
    videoArea,
    onUserClicked,
    onClicked,
}) => (
    <div
        className={styles.wrapper}
        onClick={() => onClicked(videoArea.video.id)}
    >
        <FontAwesomeIcon
            icon={faPlay}
            color="white"
            className={styles.videoIcon}
        />
        <div className={styles.imageWrapper}>
            {videoArea.video.previewImageUrl && (
                <LazyLoadImage
                    className={styles.image}
                    width={window.innerWidth - 40}
                    src={`${videoArea.video.previewImageUrl}?height=${window.innerWidth * 2}`}
                    alt="video preview image"
                />
            )}
        </div>

        <div className={styles.overlay} />

        <div className={styles.content}>
            {videoArea.video.monetization.type !== "None" && (
                <div className={styles.payableMark}>
                    <FontAwesomeIcon
                        style={{
                            width: 16,
                            height: 16,
                        }}
                        icon={solidStar}
                        color="white"
                    />
                    <p
                        className={styles.message}
                        style={{
                            fontSize: vars.font.size.regular,
                            fontWeight: 600,
                        }}
                    >
                        Exclusive Post
                    </p>
                </div>
            )}

            <div className={styles.textContent}>
                <p
                    style={{
                        fontSize: vars.font.size.m,
                    }}
                    className={styles.label}
                >
                    {videoArea.video.title}
                </p>
                {videoArea.video.description ? (
                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                        }}
                        className={styles.message}
                    >
                        <LinkItUrl
                            className={styles.link}
                            onLinkClicked={e => e.stopPropagation()}
                        >
                            {videoArea.video.description}
                        </LinkItUrl>
                    </p>
                ) : (
                    <></>
                )}
            </div>
        </div>

        <div className={styles.footer}>
            <UserDetails
                contentLastChangedAt={videoArea.video.lastChangedAt}
                stopPropagation
                paymentRequired={videoArea.video.paymentRequired}
                avatarSize={36}
                profile={videoArea.profile}
                onClicked={onUserClicked}
            />
        </div>
    </div>
)
