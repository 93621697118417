import classNames from "classnames"
import { FC } from "react"

import * as styles from "./toggle.css"

//todo: refactor, so onchange is not required when disabled
export type ToggleModel = {
    id: string
    className?: string
    containerClassName?: string
    thumbClassName?: string
    checked: boolean
    disabled?: boolean
    onChange: (v: boolean) => void
    label: React.ReactNode
}

export const Toggle: FC<ToggleModel> = ({
    id,
    checked,
    label,
    disabled,
    className,
    containerClassName,
    thumbClassName,
    onChange,
}) => {
    const dataState = checked ? "checked" : "unchecked"
    return (
        <label
            aria-disabled={disabled}
            className={classNames(styles.control, className)}
            htmlFor={id}
        >
            <div>{label}</div>
            <div className={styles.toggle}>
                <div
                    className={classNames(styles.container, containerClassName)}
                    aria-disabled={disabled}
                    data-state={dataState}
                    role="switch"
                    aria-checked={checked}
                    tabIndex={0}
                >
                    <input
                        id={id}
                        type="checkbox"
                        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                        checked={checked}
                        onChange={() => onChange(!checked)}
                    />
                    <div
                        data-state={dataState}
                        className={classNames(styles.thumb, thumbClassName)}
                    />
                </div>
            </div>
        </label>
    )
}
