import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useEffect } from "react"
import { hasAuthParams, useAuth } from "react-oidc-context"

import { Loading } from "common/loading"
import {
    matchRoutes,
    Navigate,
    Outlet,
    RouteProps,
    useLocation,
    useSearchParams,
} from "react-router-dom"
import { base64WithUrlDecode, safeUrlEncode } from "utils/url"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { useMe } from "../../hooks/use-me"
import { LocalStorage } from "../../local-storage"
import { privacyPolicyUrl, termsAndConditionsUrl } from "../../utils/constant"
import { AUTHORIZED_ROUTES } from "./routing"

type Props = RouteProps

export const ProtectedRoute: FC<Props> = ({ children }) => {
    const auth = useAuth()
    const meQuery = useMe(auth.isAuthenticated)
    const location = useLocation()
    const match = matchRoutes(AUTHORIZED_ROUTES, location)

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = getIsAuthorizedAccount(accountType)

    const [searchParams] = useSearchParams()
    const share_token = searchParams.get("share_token")
    const x_redirect = searchParams.get("x_redirect")

    useEffect(() => {
        if (hasAuthParams()) return
        if (auth.isAuthenticated) return
        if (auth.activeNavigator) return
        if (auth.isLoading) return

        const getShareToken = () => O.fromNullable(share_token)

        const redirectToShareSignIn = (shareToken: string) =>
            auth.signinRedirect({
                extraQueryParams: {
                    auth_policy: "shareSignIn",
                    share_token: pipe(
                        shareToken,
                        base64WithUrlDecode,
                        safeUrlEncode,
                    ),
                    x_redirect: x_redirect ?? "",
                    privacyPolicyUrl: `${window.location.origin}/registration/privacy-policy`,
                    termsAndConditionsUrl: `${window.location.origin}/registration/terms-and-condition`,
                },
            })

        const redirectOrSilentSignIn = () =>
            pipe(
                getShareToken(),
                O.fold(
                    () =>
                        auth.signinSilent().catch(() => {
                            const loginHint = meQuery.isSuccess
                                ? meQuery.data.user.username
                                : (LocalStorage.getUsernameOrUndefined() ?? "")

                            auth.signinRedirect({
                                extraQueryParams: {
                                    login_hint: loginHint,
                                    privacyPolicyUrl,
                                    termsAndConditionsUrl,
                                },
                            })
                        }),
                    redirectToShareSignIn,
                ),
            )

        redirectOrSilentSignIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        share_token,
        x_redirect,
        auth.isAuthenticated,
        auth.activeNavigator,
        auth.isLoading,
        auth.signinRedirect,
        meQuery.data?.user.username,
        meQuery.isSuccess,
    ])

    if (auth.activeNavigator) {
        return (
            <>
                {auth.activeNavigator === "signoutSilent" && (
                    <Loading open message="Signing you out..." />
                )}
                {auth.activeNavigator !== "signoutSilent" && (
                    <Loading open message="Loading..." />
                )}
            </>
        )
    }
    if (auth.isLoading) return <></>
    if (match && !isAuthorizedAccount)
        return <Navigate to="/app/feed" replace />
    if (auth.isAuthenticated) return <>{children ? children : <Outlet />}</>
    if (auth.error) return <Navigate to="/intro" replace />

    return <></>
}
