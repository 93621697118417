import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    tokenHandlerForGet,
    validationErrorHandler,
} from "../api-utils"
import { userCreatorApi } from "../creators/user-creator-api"
import { userSelectorApi } from "../selectors/user-selector-api"

// creator client
export const userCreatorClient = new Zodios(
    `${API_URL}/user-creator`,
    userCreatorApi,
)

userCreatorClient.use(tokenHandlerForCreate)
userCreatorClient.use(corsFetchHandler)
userCreatorClient.use(validationErrorHandler)

export const createUserTask = pipe(
    userCreatorClient.createUser,
    endpointWithTaskEither,
)

// selector client
export const userSelectorClient = new Zodios(
    `${API_URL}/user-selector`,
    userSelectorApi,
)

userSelectorClient.use(tokenHandlerForGet)
userSelectorClient.use(corsFetchHandler)
userSelectorClient.use(validationErrorHandler)

export const listUsersTask = pipe(
    userSelectorClient.listUsers,
    endpointWithTaskEither,
)
export const getMeTask = pipe(userSelectorClient.getMe, endpointWithTaskEither)
export const getUserByIdTask = pipe(
    userSelectorClient.getUserById,
    endpointWithTaskEither,
)
export const isUsernameTakenTask = pipe(
    userSelectorClient.isUsernameTaken,
    endpointWithTaskEither,
)
