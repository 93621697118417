import z from "zod"

export const Url = z.string().brand("Url")
export type Url = z.infer<typeof Url>

export const AgoraUserId = z.number().brand("AgoraUserId")
export type AgoraUserId = z.infer<typeof AgoraUserId>

export const UserId = z.string().brand("UserId")
export type UserId = z.infer<typeof UserId>

export const UserProfileId = z.string().brand("UserProfileId")
export type UserProfileId = z.infer<typeof UserProfileId>

export const Email = z.string().brand("Email")
export type Email = z.infer<typeof Email>

export const UserName = z.string().brand("UserName")
export type UserName = z.infer<typeof UserName>

export const LiveEventId = z.string().brand("LiveEventId")
export type LiveEventId = z.infer<typeof LiveEventId>

export const AgoraVideoId = z.string().brand("AgoraId")
export type AgoraVideoId = z.infer<typeof AgoraVideoId>

export const LiveEventUrl = z.string().brand("LiveEventUrl")
export type LiveEventUrl = z.infer<typeof LiveEventUrl>

export const AudienceUrl = z.string().brand("AudienceUrl")
export type AudienceUrl = z.infer<typeof AudienceUrl>

export const VideoId = z.string().brand("VideoId")
export type VideoId = z.infer<typeof VideoId>

export const ImageId = z.string().brand("ImageId")
export type ImageId = z.infer<typeof ImageId>

export const AgoraAppId = z.string().brand("AgoraAppId")
export type AgoraAppId = z.infer<typeof AgoraAppId>

export const AgoraToken = z.string().brand("AgoraToken")
export type AgoraToken = z.infer<typeof AgoraToken>

export const AgoraChannelName = z.string().brand("AgoraChannelName")
export type AgoraChannelName = z.infer<typeof AgoraChannelName>

export const ShareToken = z.string().brand("ShareToken")
export type ShareToken = z.infer<typeof ShareToken>

export const ShareContentId = z.string().brand("ShareContentId")
export type ShareContentId = z.infer<typeof ShareContentId>

export const VoteId = z.string().brand("VoteId")
export type VoteId = z.infer<typeof VoteId>

export const UserCommentId = z.string().brand("UserCommentId")
export type UserCommentId = z.infer<typeof UserCommentId>

export const UserMessageId = z.string().brand("UserMessageId")
export type UserMessageId = z.infer<typeof UserMessageId>

export const PaymentId = z.string().brand("PaymentId")
export type PaymentId = z.infer<typeof PaymentId>

export const Webhook = z.string().brand("Webhook")
export type Webhook = z.infer<typeof Webhook>

export const NotificationSubscriptionId = z
    .string()
    .brand("NotificationSubscriptionId")
export type NotificationSubscriptionId = z.infer<
    typeof NotificationSubscriptionId
>

export const PostitId = z.string().brand("PostitId")
export type PostitId = z.infer<typeof PostitId>

export const NotificationId = z.string().brand("NotificationId")
export type NotificationId = z.infer<typeof NotificationId>

export const DatabaseNotificationId = z.string().brand("DatabaseNotificationId")
export type DatabaseNotificationId = z.infer<typeof DatabaseNotificationId>

export const MessageRoomId = z.string().brand("MessageRoomId")
export type MessageRoomId = z.infer<typeof MessageRoomId>

export const ResourceHost = z.string().brand("ResourceHost")
export type ResourceHost = z.infer<typeof ResourceHost>

export const ResourceId = z.string().brand("ResourceId")
export type ResourceId = z.infer<typeof ResourceId>

export const PointSystemTransactionId = z
    .string()
    .brand("PointSystemTransactionId")
export type PointSystemTransactionId = z.infer<typeof PointSystemTransactionId>

export const ProfileChallengeTaskId = z.string().brand("ProfileChallengeTaskId")
export type ProfileChallengeTaskId = z.infer<typeof ProfileChallengeTaskId>

export const ProfileChallengeId = z.string().brand("ProfileChallengeId")
export type ProfileChallengeId = z.infer<typeof ProfileChallengeId>

export const ProfileChallengeOverviewId = z
    .string()
    .brand("ProfileChallengeOverviewId")
export type ProfileChallengeOverviewId = z.infer<
    typeof ProfileChallengeOverviewId
>

export const TierId = z.string().brand("TierId")
export type TierId = z.infer<typeof TierId>

export const ProfileRewardId = z.string().brand("ProfileRewardId")
export type ProfileRewardId = z.infer<typeof ProfileRewardId>

export const ReportId = z.string().brand("ReportId")
export type ReportId = z.infer<typeof ReportId>
