import classNames from "classnames"
import { FC, useEffect, useRef } from "react"
import { createPortal } from "react-dom"

import * as styles from "./particles-animation.css"

type Particle = {
    x: number
    y: number
    speed: number
    radius: number
    color: string
}

const colors = [
    "#FDB9C6",
    "#FF3E54",
    "#302A51",
    "#FF944B",
    "#ECEBEF",
    "#B4B2C0",
]

type ParticleAnimationModel = {
    className?: string
    duration?: number
}

export const ParticleAnimation: FC<ParticleAnimationModel> = model => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const particlesRef = useRef<Particle[]>([])
    const tickRef = useRef<number>(0)
    const stopAfterMs = model.duration ?? 2000

    useEffect(() => {
        if (!canvasRef.current) return
        const canvas = canvasRef.current
        const context = canvas.getContext("2d")
        let animationFrameId: number | undefined
        let stopGenerating = false

        const w = window.innerWidth
        const h = window.innerHeight
        canvas.width = w
        canvas.height = h

        const createParticles = () => {
            if (!stopGenerating && tickRef.current % 2 === 0) {
                if (particlesRef.current.length < 100) {
                    particlesRef.current.push({
                        x: Math.random() * canvas.width,
                        y: 0,
                        speed: 2.5 + Math.random() * 3,
                        radius: 3 + Math.random() * 5,
                        color: colors[
                            Math.floor(Math.random() * colors.length)
                        ],
                    })
                }
            }
        }

        const updateParticles = () => {
            particlesRef.current.forEach(part => {
                part.y += part.speed
            })
        }

        const killParticles = () => {
            particlesRef.current = particlesRef.current.filter(
                part => part.y <= canvas.height,
            )
        }

        const drawParticles = () => {
            if (!context) return
            context.clearRect(0, 0, canvas.width, canvas.height)
            particlesRef.current.forEach(part => {
                context.beginPath()
                context.arc(part.x, part.y, part.radius, 0, Math.PI * 2)
                context.closePath()
                context.fillStyle = part.color
                context.fill()
            })
        }

        const loop = () => {
            createParticles()
            updateParticles()
            killParticles()
            drawParticles()
            tickRef.current++

            if (particlesRef.current.length > 0) {
                animationFrameId = window.requestAnimationFrame(loop)
            }
        }

        // Start the loop
        loop()

        // Stop generating particles after the specified time
        const stopGeneratingTimeout = setTimeout(() => {
            stopGenerating = true
        }, stopAfterMs)

        // Cleanup function
        return () => {
            if (animationFrameId) window.cancelAnimationFrame(animationFrameId)
            clearTimeout(stopGeneratingTimeout)
        }
    }, [])

    return createPortal(
        <canvas
            ref={canvasRef}
            className={classNames(styles.canvas, model.className)}
        />,
        document.body,
    )
}
