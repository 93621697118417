import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    tokenHandlerForGet,
    validationErrorHandler,
} from "../api-utils"
import { videoCreatorApi } from "../creators/video-creator-api"
import { videoSelectorApi } from "../selectors/video-selector-api"

// creator client
export const videoCreatorClient = new Zodios(
    `${API_URL}/video-creator`,
    videoCreatorApi,
)

videoCreatorClient.use(tokenHandlerForCreate)
videoCreatorClient.use(corsFetchHandler)
videoCreatorClient.use(validationErrorHandler)

export const createVideoUploadTask = pipe(
    videoCreatorClient.createVideoUpload,
    endpointWithTaskEither,
)

// selector client
export const videoSelectorClient = new Zodios(
    `${API_URL}/video-selector`,
    videoSelectorApi,
)

videoSelectorClient.use(tokenHandlerForGet)
videoSelectorClient.use(corsFetchHandler)
videoSelectorClient.use(validationErrorHandler)

export const listVideosTask = pipe(
    videoSelectorClient.listVideos,
    endpointWithTaskEither,
)
export const getVideoByIdTask = pipe(
    videoSelectorClient.getVideoById,
    endpointWithTaskEither,
)
