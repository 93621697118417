import { useMutation } from "@tanstack/react-query"
import { reportCreatorClient } from "api/clients/report-api-client"
import { StaticQueryKeys } from "api/query-keys"

export const useReportMutation = () => {
    return useMutation({
        mutationFn: reportCreatorClient.report,
        mutationKey: [StaticQueryKeys.REPORTING],

        onError: err => {
            console.error("use-report-mutation", err)
        },
    })
}
