import { faBars, faShare } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import { FC } from "react"

import * as styles from "../my-user-profile-page.css"

type MyUserProfileActionsModel = {
    onNavigateToSettings: () => void
    shareMyProfile: () => void
}

export const MyUserProfileActions: FC<MyUserProfileActionsModel> = model => (
    <div className={styles.actionGroup}>
        <motion.div
            key="actions-share-icon"
            className="box"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.025,
                },
            }}
        >
            <FontAwesomeIcon
                icon={faShare}
                color="white"
                className={styles.actionIcon}
                onClick={model.shareMyProfile}
                size="xl"
            />
        </motion.div>
        <FontAwesomeIcon
            icon={faBars}
            color="white"
            className={styles.actionIcon}
            onClick={model.onNavigateToSettings}
            size="xl"
        />
    </div>
)
