import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties, FC } from "react"

import { calc } from "@vanilla-extract/css-utils"
import { useLocation, useNavigate } from "react-router-dom"

export type NavigationBackButtonModel = {
    style?: CSSProperties
    icon?: IconProp
    iconColor?: string
    fallbackRoute?: string
    onClicked?: () => void
    onActionBeforeNav?: () => void
}

export const NavigationBackButton: FC<NavigationBackButtonModel> = ({
    style,
    icon = faAngleLeft,
    iconColor = "white",
    onClicked,
    onActionBeforeNav,
}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const handleClick = () => {
        onActionBeforeNav ? onActionBeforeNav() : {}

        if (onClicked) onClicked()
        else if (location.key) navigate(-1)
        else navigate("/app/feed")
    }

    return (
        <div
            style={{
                position: "fixed",
                zIndex: 1000,
                top: calc.add("env(safe-area-inset-top, 0)", "16px"),
                left: calc.add("env(safe-area-inset-left, 0)", "10px"),
                paddingRight: "8px",
                ...style,
            }}
            onClick={handleClick}
        >
            <FontAwesomeIcon
                style={{
                    color: `var(--nav-icon-color, ${iconColor})`,
                    zIndex: 1001,
                    width: 32,
                    height: 32,
                }}
                icon={icon}
            />
        </div>
    )
}
