import classNames from "classnames"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { Visibility, VisibilityAdt } from "../../data-flow/common"

import { vars } from "theme/variables.css"
import * as styles from "./foldable-text.css"

export type FoldableTextModel = {
    className?: string
    fontSize?: string
    text: string
    offsetText?: string
    maxLength?: number
    visibility: Visibility
    onVisibilityChanged: (visibility: Visibility) => void
}

export const FoldableText: FC<FoldableTextModel> = ({
    className = "",
    text,
    offsetText = "",
    maxLength = 100,
    visibility,
    fontSize = vars.font.size.m,

    onVisibilityChanged,
}) => (
    <div className={classNames(styles.wrapper, className)}>
        <p
            className={classNames(styles.scrollable, styles.compact)}
            style={{
                fontSize,
                whiteSpace: "pre-wrap",
            }}
        >
            {pipe(
                text,
                isFoldNeeded(maxLength),
                O.fromPredicate(
                    x => x && VisibilityAdt.is.Invisible(visibility),
                ),
                O.map(() =>
                    pipe(text, truncateText(maxLength), t => t + offsetText),
                ),
                O.getOrElse(() => text),
            )}
            {pipe(text, isFoldNeeded(maxLength)) && (
                <span
                    onClick={x => {
                        onVisibilityChanged(visibility)
                        x.stopPropagation()
                    }}
                    className={styles.compact}
                >
                    <small>
                        <span
                            style={{
                                color: vars.color.secondary.hex,
                                fontSize,
                            }}
                        >
                            {VisibilityAdt.is.Invisible(visibility)
                                ? " see more"
                                : " see less"}
                        </span>
                    </small>
                </span>
            )}
        </p>
    </div>
)

const isFoldNeeded = (maxLength: number) => (text: string) =>
    text.length > maxLength

const truncateText = (maxLength: number) => (text: string) =>
    text.length > maxLength ? text.substring(0, maxLength) : text
