import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { IonButton } from "@ionic/react"
import { FC } from "react"

import { Except } from "type-fest"

import { Toggle, ToggleModel } from "../../components/controls/toggle"
import {
    CookiePreference,
    CookiePreferences,
    UpdateCookiePreference,
} from "./cookie-consent"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./cookie-consent-options-modal.css"

type CookieConsentOptionsModalModel = {
    isOpen: boolean
    preferences: CookiePreferences
    updatePreferences: <TPreference extends CookiePreference>(
        v: UpdateCookiePreference<TPreference>,
    ) => void
    onDidDismiss: () => void
    onNavigateToPrivacyPolicy: () => void
    onAcceptAllClicked: () => void
    onSaveClicked: () => void
}

export const CookieConsentOptionsModal: FC<
    CookieConsentOptionsModalModel
> = model => {
    return (
        <Drawer open={model.isOpen} onClose={model.onDidDismiss}>
            <DrawerContent
                onClose={model.onDidDismiss}
                className={styles.modal}
            >
                <DrawerTitle />
                <DrawerDescription />
                <DrawerClose className={styles.modalCloseContainer}>
                    <FontAwesomeIcon
                        className={styles.closeIcon}
                        icon={faXmark}
                        onClick={model.onDidDismiss}
                    />
                </DrawerClose>
                <div className={styles.content}>
                    <p
                        style={{
                            margin: 0,
                            fontSize: vars.font.size.xl,
                        }}
                        className={styles.title}
                    >
                        Protection of your data
                    </p>
                    <p
                        style={{
                            margin: "8px 0 0 0",
                            fontSize: vars.font.size.regular,
                        }}
                        className={styles.subtitle}
                    >
                        Choose which cookies you want to accept. More
                        information is available in our{" "}
                        <span
                            style={{
                                fontSize: vars.font.size.regular,
                            }}
                            onClick={model.onNavigateToPrivacyPolicy}
                            className={styles.link}
                        >
                            Privacy Policy
                        </span>
                        .
                    </p>
                    <div className={styles.togglesContainer}>
                        <CookieConsentToggle
                            checked={model.preferences.essential}
                            id="essential"
                            disabled
                            onChange={() => {}}
                            text={{
                                title: "Essential Cookies",
                                subtitle:
                                    "These technologies are required to enable the core functionality of the app.",
                            }}
                        />
                        <CookieConsentToggle
                            checked={model.preferences.functional}
                            id="functional"
                            text={{
                                title: "Functional Cookies",
                                subtitle:
                                    "These technologies allow us to analyze the use of the app in order to measure and improve performance.",
                            }}
                            onChange={value =>
                                model.updatePreferences({
                                    key: "functional",
                                    value,
                                })
                            }
                        />
                        <CookieConsentToggle
                            checked={model.preferences.marketing}
                            id="marketing"
                            text={{
                                title: "Cookies for marketing purposes",
                                subtitle:
                                    "These technologies are used by advertisers to show ads that are relevant to your interests.",
                            }}
                            onChange={value =>
                                model.updatePreferences({
                                    key: "marketing",
                                    value,
                                })
                            }
                        />
                    </div>
                </div>
                <DrawerFooter>
                    <div>
                        <IonButton
                            data-test="accept-all"
                            className={styles.acceptAllButton}
                            onClick={model.onAcceptAllClicked}
                        >
                            Accept all
                        </IonButton>
                        <IonButton
                            className={styles.saveAndContinueButton}
                            onClick={model.onSaveClicked}
                        >
                            Save and continue
                        </IonButton>
                    </div>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

type CookieConsentToggleModel = Except<
    ToggleModel,
    "containerClassName" | "thumbClassName" | "className" | "label"
> & {
    text: {
        title: string
        subtitle: string
    }
}
//todo: refactor into light mode toggle
const CookieConsentToggle: FC<CookieConsentToggleModel> = model => (
    <Toggle
        checked={model.checked}
        id={model.id}
        containerClassName={styles.toggleContainer}
        thumbClassName={styles.toggleThumb}
        className={styles.toggle}
        label={
            <>
                <p
                    className={styles.toggleTitle}
                    style={{
                        fontSize: vars.font.size.regular,
                        margin: 0,
                    }}
                >
                    {model.text.title}
                </p>
                <p
                    className={styles.toggleSubtitle}
                    style={{
                        margin: 0,
                        fontSize: vars.font.size.regular,
                    }}
                >
                    {model.text.subtitle}
                </p>
            </>
        }
        onChange={model.onChange}
    />
)
