import classNames from "classnames"
import { FC } from "react"

import { CircleCheckbox } from "common/cicle-checkbox"
import { vars } from "theme/variables.css"
import * as styles from "./check-item.css"

export type CheckItemModel = {
    id: string
    className?: string
    containerClassName?: string
    thumbClassName?: string
    checked: boolean
    disabled?: boolean
    onChange: (v: boolean) => void
    title: string
    message?: string
}

export const CheckItem: FC<CheckItemModel> = ({
    id,
    checked,
    title,
    message,
    disabled,
    className,
    onChange,
}) => {
    return (
        <label
            aria-disabled={disabled}
            className={classNames(styles.control, className)}
            htmlFor={id}
        >
            <div className={styles.label}>
                <p
                    style={{
                        color: vars.color.medium.hex,
                        fontSize: vars.font.size.regular,
                        fontWeight: 500,
                        margin: 0,
                    }}
                >
                    {title}
                </p>
                <p
                    style={{
                        color: vars.color.tertiary.hex,
                        fontSize: vars.font.size.regular,
                        margin: 0,
                    }}
                >
                    {message}
                </p>
            </div>
            <CircleCheckbox
                id={id}
                checked={checked}
                onCheckedChange={onChange}
            />
        </label>
    )
}
