import { IonButton } from "@ionic/react"
import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "react-oidc-context"

import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../../common/drawer"
import { LocalStorage } from "../../../local-storage"
import {
    privacyPolicyUrl,
    termsAndConditionsUrl,
} from "../../../utils/constant"

import logo from "/app-logo.svg"

import { useMe } from "hooks/use-me"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import * as styles from "./create-account-modal.css"

export const CreateAccountModal: FC = () => {
    const close = useAppStore(store => store.close)
    const isModalVisible = useAppStore(store => store.isModalVisible)

    const { t } = useTranslation(["feed"])
    const auth = useAuth()
    const meQuery = useMe(auth.isAuthenticated)
    const userId = auth.user?.profile.sub

    const onCreateAccount = useCallback(
        () =>
            auth.signinRedirect({
                extraQueryParams:
                    auth.user && userId
                        ? {
                              auth_policy: "emailVerification",
                              userId,
                              login_hint: meQuery.isSuccess
                                  ? meQuery.data.user.username
                                  : (LocalStorage.getUsernameOrUndefined() ??
                                    ""),
                              privacyPolicyUrl,
                              termsAndConditionsUrl,
                          }
                        : {
                              auth_policy: "trialSignUp",
                              login_hint: meQuery.isSuccess
                                  ? meQuery.data.user.username
                                  : (LocalStorage.getUsernameOrUndefined() ??
                                    ""),
                              privacyPolicyUrl,
                              termsAndConditionsUrl,
                          },
            }),
        [auth, meQuery],
    )

    return (
        <Drawer
            open={isModalVisible.CreateAccount}
            onClose={() => close("CreateAccount")}
        >
            <DrawerContent
                onClose={() => close("CreateAccount")}
                className={styles.modal}
            >
                <DrawerTitle />
                <DrawerDescription />
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <svg className={styles.logo}>
                            <use href={`${logo}#app_logo`} />
                        </svg>
                        <h1
                            style={{ margin: 0, fontSize: vars.font.size.xxxl }}
                        >
                            <strong>
                                {t(
                                    "feedOverView.liveAreaView.verifyAccount.title",
                                )}
                            </strong>
                        </h1>
                        <p
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {t(
                                "feedOverView.liveAreaView.verifyAccount.message",
                            )}
                        </p>
                    </div>

                    <IonButton
                        className={styles.createButton}
                        expand="block"
                        onClick={onCreateAccount}
                    >
                        {t("feedOverView.liveAreaView.verifyAccount.action")}
                    </IonButton>

                    <IonButton
                        className={styles.cancelButton}
                        expand="block"
                        onClick={() => close("CreateAccount")}
                    >
                        {t(
                            "feedOverView.liveAreaView.verifyAccount.cancel.text",
                        )}
                    </IonButton>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
