import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    tokenHandlerForGet,
    validationErrorHandler,
} from "../api-utils"
import { postitCreatorApi } from "../creators/post-creator-api"
import { postSelectorApi } from "../selectors/post-selector-api"

// creator client
export const postitCreatorClient = new Zodios(
    `${API_URL}/postit-creator`,
    postitCreatorApi,
)

postitCreatorClient.use(tokenHandlerForCreate)
postitCreatorClient.use(corsFetchHandler)
postitCreatorClient.use(validationErrorHandler)

export const createPostTask = pipe(
    postitCreatorClient.createPostit,
    endpointWithTaskEither,
)

// selector client
export const postitSelectorClient = new Zodios(
    `${API_URL}/postit-selector`,
    postSelectorApi,
)

postitSelectorClient.use(tokenHandlerForGet)
postitSelectorClient.use(corsFetchHandler)
postitSelectorClient.use(validationErrorHandler)

export const listPostsTask = pipe(
    postitSelectorClient.listPostits,
    endpointWithTaskEither,
)
export const getPostByIdTask = pipe(
    postitSelectorClient.getPostitById,
    endpointWithTaskEither,
)
