import { MonetizationOption } from "api/api-models"
import { FileWithPath } from "utils/file"
import { getVideoThumbnail, Thumbnail } from "utils/thumbnail"
import { create } from "zustand"
type State = {
    file?: FileWithPath
    thumbnailFile?: FileWithPath
    thumbnail?: Thumbnail
    thumbnailImage?: string

    videoUploadProgress?: number
    imageUploadProgress?: number
    videoUploadFinished: boolean
    imageUploadFinished: boolean

    updatedWithoutUploads: boolean

    title?: string
    message?: string
    monetization?: MonetizationOption
}

type Actions = {
    setTitle: (v: State["title"]) => void
    setMessage: (v: State["message"]) => void
    setMonetization: (v: State["monetization"]) => void
    setFile: (v: State["file"]) => void
    setThumbnailFile: (v: State["thumbnailFile"]) => void

    setUpdatedWithoutUploads: (v: State["updatedWithoutUploads"]) => void

    setVideoUploadProgress: (v: State["videoUploadProgress"]) => void
    setImageUploadProgress: (v: State["imageUploadProgress"]) => void

    setImageUploadFinished: (v: State["imageUploadFinished"]) => void
    setVideoUploadFinished: (v: State["videoUploadFinished"]) => void

    reset: () => void
}

const initialState: State = {
    title: undefined,
    message: undefined,
    monetization: undefined,
    file: undefined,
    thumbnailFile: undefined,
    thumbnail: undefined,
    thumbnailImage: undefined,
    imageUploadProgress: undefined,
    videoUploadProgress: undefined,
    imageUploadFinished: false,
    videoUploadFinished: false,
    updatedWithoutUploads: false,
}

export const useEditVideoPostStore = create<State & Actions>(set => ({
    ...initialState,

    setTitle: v => set({ title: v }),
    setMessage: v => set({ message: v }),
    setMonetization: v => set({ monetization: v }),
    setFile: file => {
        if (!file) {
            set({
                file,
            })
            return
        }
        getVideoThumbnail(file, 0)
            .then(thumbnail => {
                set({
                    file,
                    thumbnail,
                })
            })
            .catch(console.error)
    },
    setThumbnailFile: file => {
        if (!file) {
            set({
                thumbnailFile: file,
            })
            return
        }
        const reader = new FileReader()
        reader.onload = e => {
            if (!e.target) return

            set({ thumbnailImage: `${e.target.result}`, thumbnailFile: file })
        }
        reader.readAsDataURL(file)
    },

    setVideoUploadProgress: v => set({ videoUploadProgress: v }),
    setImageUploadProgress: v => set({ imageUploadProgress: v }),

    setImageUploadFinished: v => set({ imageUploadFinished: v }),
    setVideoUploadFinished: v => set({ videoUploadFinished: v }),

    setUpdatedWithoutUploads: v => set({ updatedWithoutUploads: v }),

    reset: () => set(initialState),
}))
