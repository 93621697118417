import { IonButton } from "@ionic/react"
import { Content } from "common/content"
import { Page } from "common/page"
import { FC } from "react"
import {
    isRouteErrorResponse,
    useLocation,
    useRouteError,
} from "react-router-dom"
import * as styles from "./root-boundary.css"

export const RootBoundary: FC = () => {
    const error = useRouteError()
    const location = useLocation()

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>This page doesn't exist!</div>
        }

        if (error.status === 401) {
            return <div>You aren't authorized to see this</div>
        }

        if (error.status === 503) {
            return <div>Looks like our API is down</div>
        }
    }
    if (
        error instanceof Error &&
        (error.message.includes(
            "Failed to fetch dynamically imported module",
        ) ||
            error.message.includes("Importing a module script failed"))
    ) {
        return (
            <Page>
                <Content className={styles.content}>
                    <div>
                        <h2>New Version of the App is available!</h2>
                        <p style={{ marginTop: 16 }}>
                            To access it refresh the page.
                        </p>
                    </div>
                    <IonButton
                        className={styles.button}
                        onClick={() => refreshPage(location.pathname)}
                    >
                        Refresh
                    </IonButton>
                </Content>
            </Page>
        )
    }
    return <div>Something went wrong</div>
}

const refreshPage = (path?: string) => {
    if (path) {
        window.location.href = path
    } else {
        window.location.reload()
    }
}
