import {
    ContentRef,
    ContentReportIssue,
    UserReportIssue,
    UserReportSubIssue,
} from "api/api-models"
import { UserProfileId } from "api/branded-types"
import { create } from "zustand"

export type ReportContentModalStep = "initial" | "detail" | "thanks"
export type ReportUserModalStep =
    | "initial"
    | "instruction"
    | "detail"
    | "thanks"

type State = {
    //Content
    reportContentStep: ReportContentModalStep
    reportContentOpen: boolean
    reportContentMessage: string
    reportContent?: ContentRef
    reportContentOwner?: UserProfileId
    selectedIssue?: ContentReportIssue

    // User
    reportUserStep: ReportUserModalStep
    reportUserOpen: boolean
    reportUserMessage: string
    reportUserProfileId?: UserProfileId
    selectedUserReportItem?: UserReportIssue
    selectedUserReportInstructionItem?: UserReportSubIssue
}

type Actions = {
    // Content
    setReportContentOpen: (
        v:
            | {
                  content: NonNullable<State["reportContent"]>
                  contentOwner: NonNullable<State["reportContentOwner"]>
                  open: true
              }
            | { open: false },
    ) => void
    setReportContentStep: (v: State["reportContentStep"]) => void
    setReportContentIssue: (v: State["selectedIssue"]) => void
    setReportContentMessage: (v: State["reportContentMessage"]) => void
    resetReportContent: () => void

    //User
    setReportUserOpen: (
        v:
            | {
                  profileId: NonNullable<State["reportUserProfileId"]>
                  open: true
              }
            | { open: false },
    ) => void
    setReportUserStep: (v: State["reportUserStep"]) => void
    setUserReportItem: (v: State["selectedUserReportItem"]) => void
    setUserReportInstructionItem: (
        v: State["selectedUserReportInstructionItem"],
    ) => void
    setReportUserMessage: (v: State["reportContentMessage"]) => void
    resetReportUser: () => void
}

export const useReportStore = create<State & Actions>(set => ({
    //Content,
    reportContentStep: "initial",
    reportContentOpen: false,
    reportContent: undefined,
    reportContentOwner: undefined,
    selectedReportContentItemIds: [],
    reportContentMessage: "",

    setReportContentStep: value => set({ reportContentStep: value }),
    setReportContentOpen: value =>
        set({
            reportContentOpen: value.open,
            reportContent: value.open ? value.content : undefined,
            reportContentOwner: value.open ? value.contentOwner : undefined,
        }),
    setReportContentIssue: v =>
        set({
            selectedIssue: v,
        }),
    setReportContentMessage: value => set({ reportContentMessage: value }),
    resetReportContent: () =>
        set({
            selectedIssue: undefined,
            reportContentMessage: "",
            reportContentStep: "initial",
        }),
    //User

    reportUserStep: "initial",
    reportUserOpen: false,
    reportUserProfileId: undefined,
    reportUserMessage: "",
    selectedUserReportItem: undefined,
    selectedUserReportInstructionItem: undefined,

    setReportUserStep: value => set({ reportUserStep: value }),
    setReportUserOpen: value =>
        set({
            reportUserOpen: value.open,
            reportUserProfileId: value.open ? value.profileId : undefined,
        }),
    setReportUserMessage: v =>
        set({
            reportUserMessage: v,
        }),
    setUserReportItem: v =>
        set({
            selectedUserReportItem: v,
        }),
    setUserReportInstructionItem: value =>
        set({ selectedUserReportInstructionItem: value }),
    resetReportUser: () =>
        set({
            selectedUserReportItem: undefined,
            selectedUserReportInstructionItem: undefined,
            reportUserMessage: "",
            reportUserStep: "initial",
        }),
}))
