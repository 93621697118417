import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonContent, IonModal } from "@ionic/react"
import classNames from "classnames"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useRef, useState } from "react"

import { LinkItUrl } from "features/linkify"
import { vars } from "theme/variables.css"
import * as styles from "./see-more-text-view-modal.css"

export type Visibility = "Closed" | "Opened"

export type SeeMoreTextViewModalModel = {
    className?: string
    text: {
        title?: string
        description: string
        seeMore: string
    }
    maxLength?: number
    defaultVisibility?: Visibility
}
//TODO: implement using `Drawer`
export const SeeMoreTextViewModal: FC<SeeMoreTextViewModalModel> = ({
    className = "",
    text,
    maxLength = 90,
    defaultVisibility = "Closed",
}) => {
    const modal = useRef<HTMLIonModalElement>(null)
    const [modalVisibility, setModalVisibility] =
        useState<Visibility>(defaultVisibility)

    return (
        <>
            <IonModal
                ref={modal}
                className={styles.modal}
                isOpen={modalVisibility === "Opened"}
                canDismiss
                onDidDismiss={() => {
                    setModalVisibility("Closed")
                }}
                initialBreakpoint={0.45}
                breakpoints={[0, 0.45, 1]}
                animated
                backdropDismiss
                showBackdrop
            >
                <IonContent className={styles.modalContent}>
                    <div className={styles.closeModal}>
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setModalVisibility("Closed")}
                            size="xl"
                        />
                    </div>
                    <div className={styles.textContent}>
                        <p
                            style={{
                                fontSize: vars.font.size.l,
                            }}
                            className={styles.title}
                        >
                            {text.title}
                        </p>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            <LinkItUrl className={styles.link}>
                                {text.description}
                            </LinkItUrl>
                        </p>
                    </div>
                </IonContent>
            </IonModal>

            <div className={classNames(styles.wrapper, className)}>
                {text.title && text.title.length > 0 && (
                    <p
                        className={styles.title}
                        style={{ fontSize: vars.font.size.l }}
                    >
                        {text.title}
                    </p>
                )}

                <p style={{ fontSize: vars.font.size.regular }}>
                    <LinkItUrl className={styles.link}>
                        {pipe(
                            text.description,
                            O.fromPredicate(isFoldNeeded(maxLength)),
                            O.map(truncateText(maxLength)),
                            O.getOrElse(() => text.description),
                        )}
                    </LinkItUrl>
                    {isFoldNeeded(maxLength)(text.description) && (
                        <>
                            {"... "}
                            <span
                                className={styles.seeMoreText}
                                style={{
                                    fontSize: vars.font.size.regular,
                                }}
                                onClick={() => setModalVisibility("Opened")}
                            >
                                {text.seeMore}
                            </span>
                        </>
                    )}
                </p>
            </div>
        </>
    )
}

const isFoldNeeded = (maxLength: number) => (text: string) =>
    text.length > maxLength

const truncateText = (maxLength: number) => (text: string) =>
    text.length > maxLength ? text.substring(0, maxLength) : text
