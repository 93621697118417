import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import { FC } from "react"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "common/dialog"
import { Image } from "common/image"
import { useAsset } from "hooks/use-asset"
import { useNavigate } from "react-router-dom"
import { usePointSystemModalStore } from "./store/point-system-modal-store"

import { vars } from "theme/variables.css"
import * as styles from "./create-content-complete-modal.css"
import { ParticleAnimation } from "./particles-animation/particles-animation"

type CreateContentCompleteModal = {
    isOpen: boolean
    onOpenChange: (v: boolean) => void
}

export const CreateContentCompleteModal: FC<
    CreateContentCompleteModal
> = model => {
    const createContentCompleteModalState = usePointSystemModalStore(
        store => store.createContentCompleteModalState,
    )

    const navigate = useNavigate()

    const assetQuery = useAsset({
        resource: createContentCompleteModalState?.icon,
    })

    return (
        <Dialog open={model.isOpen} onOpenChange={model.onOpenChange}>
            {model.isOpen && <ParticleAnimation />}
            <DialogContent className={styles.content}>
                <div>
                    <div className={styles.iconWrapper}>
                        <div className={styles.iconContainer}>
                            <Image
                                color={vars.color.medium.hex}
                                imageType={
                                    assetQuery.isSuccess &&
                                    assetQuery.data.type === "Image"
                                        ? assetQuery.data.imageType
                                        : "Png"
                                }
                                width={
                                    assetQuery.isSuccess &&
                                    assetQuery.data.type === "Image"
                                        ? assetQuery.data.resolution.width
                                        : 28
                                }
                                height={
                                    assetQuery.isSuccess &&
                                    assetQuery.data.type === "Image"
                                        ? assetQuery.data.resolution.height
                                        : 28
                                }
                                className={styles.icon}
                                src={
                                    assetQuery.isSuccess
                                        ? assetQuery.data.source
                                        : undefined
                                }
                            />
                            <FontAwesomeIcon
                                className={styles.hornIcon}
                                icon={faPartyHorn}
                            />
                        </div>
                    </div>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xl,
                                margin: 0,
                                fontWeight: 500,
                            }}
                        >
                            {createContentCompleteModalState?.title}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {createContentCompleteModalState?.description}
                        </DialogDescription>
                    </div>
                    <div className={styles.rewardContainer}>
                        <p className={styles.rewardText}>
                            +{createContentCompleteModalState?.points} Points
                        </p>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <IonButton
                            className={styles.button}
                            color="medium"
                            onClick={() => {
                                model.onOpenChange(false)
                                //todo: define what path this button should navigate to
                            }}
                        >
                            Explore further
                        </IonButton>
                        <IonButton
                            onClick={() => {
                                model.onOpenChange(false)
                                navigate(
                                    "/app/settings/rewards?currentTab=get-started",
                                )
                            }}
                            className={styles.ghostButton}
                        >
                            To my rewards
                        </IonButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
