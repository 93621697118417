import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    tokenHandlerForGet,
    validationErrorHandler,
} from "../api-utils"
import { userProfileCreatorApi } from "../creators/user-profile-creator-api"
import { userProfileSelectorApi } from "../selectors/user-profile-selector-api"

// creator client
export const userProfileCreatorClient = new Zodios(
    `${API_URL}/user-profile-creator`,
    userProfileCreatorApi,
)

userProfileCreatorClient.use(tokenHandlerForCreate)
userProfileCreatorClient.use(corsFetchHandler)
userProfileCreatorClient.use(validationErrorHandler)

export const createUserProfileTask = pipe(
    userProfileCreatorClient.createProfile,
    endpointWithTaskEither,
)
export const updateProfileByIdTask = pipe(
    userProfileCreatorClient.changeProfileById,
    endpointWithTaskEither,
)

export const followUserProfileTask = pipe(
    userProfileCreatorClient.followUserProfile,
    endpointWithTaskEither,
)
export const unfollowUserProfileTask = pipe(
    userProfileCreatorClient.unfollowUserProfile,
    endpointWithTaskEither,
)

export const subscribeUserProfileTask = pipe(
    userProfileCreatorClient.subscribeUserProfile,
    endpointWithTaskEither,
)

export const unsubscribeUserProfileTask = pipe(
    userProfileCreatorClient.unsubscribeUserProfile,
    endpointWithTaskEither,
)

// selector client
export const userProfileSelectorClient = new Zodios(
    `${API_URL}/user-profile-selector`,
    userProfileSelectorApi,
)

userProfileSelectorClient.use(tokenHandlerForGet)
userProfileSelectorClient.use(corsFetchHandler)
userProfileSelectorClient.use(validationErrorHandler)

export const listUserProfilesTask = pipe(
    userProfileSelectorClient.listProfiles,
    endpointWithTaskEither,
)
export const getMyProfilesTask = pipe(
    userProfileSelectorClient.getMyProfiles,
    endpointWithTaskEither,
)
export const getUserProfileByIdTask = pipe(
    userProfileSelectorClient.getProfileById,
    endpointWithTaskEither,
)
export const getUserProfileStatisticByIdTask = pipe(
    userProfileSelectorClient.getUserProfileStatisticById,
    endpointWithTaskEither,
)

export const listFollowersOfUserProfileIdTask = pipe(
    userProfileSelectorClient.listFollowersOfUserProfileId,
    endpointWithTaskEither,
)

export const listProfilesWhichUserProfileIdFollowsTask = pipe(
    userProfileSelectorClient.listProfilesWhichUserProfileIdFollows,
    endpointWithTaskEither,
)

export const listSubscriberOfUserProfileIdTask = pipe(
    userProfileSelectorClient.listSubscriberOfUserProfileId,
    endpointWithTaskEither,
)

export const listSubscriptionsOfProfileIdTask = pipe(
    userProfileSelectorClient.listSubscriptionsOfProfileId,
    endpointWithTaskEither,
)
