import * as ProgressPrimitive from "@radix-ui/react-progress"
import classNames from "classnames"
import * as React from "react"
import * as styles from "./progress.css"

const Progress = React.forwardRef<
    React.ElementRef<typeof ProgressPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
        indicatorClassName?: string
    }
>(({ className, value, indicatorClassName, ...props }, ref) => (
    <ProgressPrimitive.Root
        ref={ref}
        className={classNames(styles.container, className)}
        {...props}
    >
        <ProgressPrimitive.Indicator
            className={classNames(styles.indicator, indicatorClassName)}
            style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
        />
    </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
