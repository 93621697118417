import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    Pagination,
    Vote,
    VoteContentIddResourceRefs,
    VoteType,
    VotedResourceContentTypes,
} from "../api-models"
import { VoteId } from "../branded-types"

export const votingSelectorApi = makeApi([
    {
        method: "get",
        path: "/v1/vote/:id",
        alias: "getVote",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: VoteId,
            },
        ],
        response: z.object({
            data: Vote,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/vote/:type/:id",
        alias: "getVoteOfType",
        parameters: [
            {
                name: "type",
                type: "Path",
                schema: VoteType,
            },
            {
                name: "id",
                type: "Path",
                schema: VoteId,
            },
        ],
        response: z.object({
            data: Vote,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/votes",
        alias: "listVotes",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Vote),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/votes/:type",
        alias: "listVotesOfType",
        parameters: [
            {
                name: "type",
                type: "Path",
                schema: VoteType,
            },
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Vote),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/votes/:ref/:id",
        alias: "listVotesByVotedResource",
        parameters: [
            {
                name: "ref",
                type: "Path",
                schema: VotedResourceContentTypes,
            },
            {
                name: "id",
                type: "Path",
                schema: VoteContentIddResourceRefs,
            },
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Vote),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },

    {
        method: "post",
        path: "/v1/votes/:type/:ref/:id",
        alias: "listVotesOfTypeByVotedResource",
        parameters: [
            {
                name: "type",
                type: "Path",
                schema: VoteType,
            },
            {
                name: "ref",
                type: "Path",
                schema: VotedResourceContentTypes,
            },
            {
                name: "id",
                type: "Path",
                schema: VoteContentIddResourceRefs,
            },
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Vote),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
