import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, Pagination, PayablePostit, Postit } from "../api-models"
import { PostitId } from "../branded-types"

export const postSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/postits",
        alias: "listPostits",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Postit),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/postit/:id",
        alias: "getPostitById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: PostitId,
            },
        ],
        response: z.object({
            data: PayablePostit,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
