import { faPaperPlaneTop } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButtons, IonItem, IonTextarea, IonToolbar } from "@ionic/react"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { match } from "ts-pattern"
import { UserProfileId } from "../../api/branded-types"
import { Avatar } from "../../components/avatars/avatar"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { useUserProfile } from "../../hooks/use-user-profile"
import { getEventDetailValue } from "../../utils/fp"
import { useMessageMutation } from "./hooks/use-message-mutation"
import { UserMessageWithProfile, useMessages } from "./hooks/use-messages"
import * as styles from "./messages-to-user-profile-page.css"
import { UserMessageItem } from "./user-message-item"

import { getIsAuthorizedAccount } from "api/api-utils"
import classNames from "classnames"
import { contramap } from "fp-ts/Ord"
import * as S from "fp-ts/string"
import { motion } from "framer-motion"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { UserProfile } from "../../api/api-models"
import { Content } from "../../common/content"
import { Footer } from "../../common/footer"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { Toolbar } from "../../common/toolbar"
import { useIOSKeyboardAdaptations } from "../../hooks/use-ios-keyboard-adaptations"
import { userProfileByIdRoute } from "../routing/routing"
import { useSeenMessageMutation } from "./hooks/use-seen-message-mutation"
import { MyMessageItem } from "./my-message-item"

const byDate = pipe(
    S.Ord,
    contramap((data: UserMessageWithProfile) => data.message.createdAt),
)

export const MessageToUserProfilePage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const navigate = useNavigate()
    const bottomRef = useRef<HTMLDivElement>(null)

    const { id: recipientId } = useParams<{ id: string }>()
    const { data: recipientProfile } = useUserProfile(
        UserProfileId.parse(recipientId),
    )
    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)
    const [lastMessageId, setLastMessageId] = useState("")
    const messagesQuery = useMessages({
        senderId: myProfileId,
        recipientId: recipientProfile?.id,
        refetchInterval: 3000,
    })

    const { mutate: postSeenMessages } = useSeenMessageMutation(myProfileId)

    const messagesWithUserData = useMemo(
        () =>
            pipe(
                messagesQuery.data?.pages ?? [],
                A.flatMap(page => page.messagesWithUserData),
                A.sortBy([byDate]),
            ),
        [messagesQuery.data?.pages],
    )

    const scrollToBottom = () => {
        if (!bottomRef.current) return

        bottomRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        setTimeout(scrollToBottom, 250)
    }, [])

    useEffect(() => {
        if (messagesQuery.isLoading) return
        if (!messagesQuery.data) return
        if (!messagesQuery.isSuccess) return

        const noUpdatesExists = pipe(
            messagesWithUserData,
            A.last,
            O.fold(
                () => true,
                ({ message }) => {
                    const isOld = message.id === lastMessageId
                    setLastMessageId(message.id)

                    return isOld
                },
            ),
        )

        if (noUpdatesExists) return

        postSeenMessages({
            messageIds: pipe(
                messagesWithUserData,
                A.map(e => e.message.id),
            ),
        })

        setTimeout(scrollToBottom, 250)
    }, [
        messagesQuery.isLoading,
        messagesQuery.data,
        messagesQuery.isSuccess,
        bottomRef,
        lastMessageId,
        messagesWithUserData,
        postSeenMessages,
    ])

    const messageState = match(messagesQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    return (
        <Page className={styles.page}>
            <NavigationBackButton />
            <Toolbar>
                {recipientProfile && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 8,
                            width: "100%",
                        }}
                    >
                        <Avatar
                            src={recipientProfile.imageUrl}
                            styleState={
                                recipientProfile.isLive ? "isLive" : "None"
                            }
                            size={40}
                            onClicked={() => {
                                navigate(
                                    userProfileByIdRoute(
                                        pipe(recipientId, UserProfileId.parse),
                                    ),
                                )
                            }}
                        />

                        <p
                            style={{
                                marginTop: "6px",
                                fontSize: vars.font.size.regular,
                                color: "white",
                                fontWeight: 700,
                                textAlign: "center",
                            }}
                        >
                            {recipientProfile.displayName}
                        </p>
                    </div>
                )}
            </Toolbar>
            <Content>
                <PullToRefresh onRefresh={messagesQuery.refetch}>
                    <InfiniteScroll
                        disabled={!messagesQuery.hasNextPage}
                        state={messageState}
                        onLoadRequested={messagesQuery.fetchNextPage}
                        threshold="60px"
                    >
                        <div className={styles.wrapper}>
                            {messagesQuery.isSuccess &&
                                !messagesQuery.isLoading &&
                                pipe(
                                    messagesWithUserData,
                                    A.mapWithIndex(
                                        (key, { message, profile }) =>
                                            profile.id === myProfileId ? (
                                                <MyMessageItem
                                                    key={`my-message-item-${key}`}
                                                    showTime={true}
                                                    message={message}
                                                />
                                            ) : (
                                                <UserMessageItem
                                                    key={`user-message-item-${key}`}
                                                    showTime={true}
                                                    message={message}
                                                    imageUrl={profile.imageUrl}
                                                />
                                            ),
                                    ),
                                )}

                            <motion.div
                                key="message-to-user-profile-bottom"
                                ref={bottomRef}
                            />
                        </div>
                    </InfiniteScroll>
                </PullToRefresh>
            </Content>
            <MessageToUserProfilePageToolbar
                messageState={messageState}
                myProfileId={myProfileId}
                recipientProfile={recipientProfile}
            />
        </Page>
    )
}

type MessageToUserProfilePageToolbarModel = {
    messageState: "Loading" | "Loaded" | "Failed" | "Unloaded"
    recipientProfile?: UserProfile
    myProfileId?: UserProfileId
}

const MessageToUserProfilePageToolbar: FC<
    MessageToUserProfilePageToolbarModel
> = ({ messageState, myProfileId, recipientProfile }) => {
    const fakeInputRef = useRef<HTMLDivElement>(null)
    const realInputRef = useRef<HTMLIonTextareaElement>(null)
    const resizeElementRef = useRef<HTMLIonToolbarElement>(null)

    useIOSKeyboardAdaptations({
        realInputRef,
        fakeInputRef,
        resizeElementRef,
    })

    const [message, setMessage] = useState("")
    const { mutate: postMessage, isPending: isPostPending } =
        useMessageMutation()

    return (
        <IonToolbar
            ref={resizeElementRef}
            className={classNames(styles.bottomToolbar)}
        >
            <div className={styles.inputWrapper}>
                <IonItem className={styles.inputBox}>
                    <div ref={fakeInputRef} className={styles.fakeInput}>
                        {message ? (
                            <p
                                style={{
                                    fontSize: vars.font.size.m,
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {message}
                            </p>
                        ) : (
                            <p
                                style={{
                                    fontSize: vars.font.size.m,
                                }}
                                className={styles.fakeInputPlaceholder}
                            >
                                Your message
                            </p>
                        )}
                    </div>
                    <IonTextarea
                        ref={realInputRef}
                        className={styles.input}
                        autoGrow
                        disabled={isPostPending}
                        placeholder={"Your message"}
                        value={message}
                        maxlength={100000}
                        rows={1}
                        onIonInput={flow(
                            getEventDetailValue,
                            O.map(setMessage),
                        )}
                    />
                </IonItem>
                <IonButtons>
                    {!isPostPending &&
                        message.length > 0 &&
                        pipe(
                            messageState,
                            O.fromPredicate(state => state === "Loaded"),
                            O.chain(O.fromPredicate(() => message.length > 0)),
                            O.fold(
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        color={vars.color.tertiary.hex}
                                    />
                                ),
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        onClick={() => {
                                            if (!myProfileId) return
                                            if (!recipientProfile) return

                                            postMessage(
                                                {
                                                    senderId: myProfileId,
                                                    message,
                                                    recipient: {
                                                        contentType:
                                                            "UserProfile",
                                                        contentId:
                                                            recipientProfile?.id,
                                                    },
                                                },
                                                {
                                                    onSettled: () =>
                                                        setMessage(""),
                                                },
                                            )
                                        }}
                                    />
                                ),
                            ),
                        )}
                </IonButtons>
            </div>
        </IonToolbar>
    )
}

export const MessageToUserProfilePageLoading: FC = () => (
    <Page className={styles.pageLoading}>
        <NavigationBackButton />
        <Toolbar className={styles.newToolbar}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignSelf: "center",
                    marginTop: 8,
                }}
            >
                <Skeleton width={48} height={48} circle />
                <Skeleton
                    style={{
                        marginTop: "6px",
                    }}
                    height={14}
                    width={60}
                    borderRadius={24}
                />
            </div>
        </Toolbar>
        <Footer className={styles.loadingFooter}>
            <Skeleton width="100%" height={50} borderRadius={8} />
        </Footer>
    </Page>
)

export default MessageToUserProfilePage
