import { IonButton } from "@ionic/react"
import { FC } from "react"
import { useAuth } from "react-oidc-context"

import { Avatar } from "../../components/avatars/avatar"
import { LocalStorage } from "../../local-storage"

import Skeleton from "react-loading-skeleton"
import { UserProfileId } from "../../api/branded-types"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../common/drawer"
import { AvatarLoading } from "../../components/avatars/avatar-loading"
import { useUserProfile } from "../../hooks/use-user-profile"
import { privacyPolicyUrl, termsAndConditionsUrl } from "../../utils/constant"
import * as styles from "./share-welcome-modal.css"

type ShareWelcomeModalTextModel = {
    title: (name: string | undefined) => string
    message: string
    onSignUp: string
    onSignIn: string
}

export type ShareWelcomeModalModel = {
    text: ShareWelcomeModalTextModel
    isOpen: boolean
    profileId?: UserProfileId
    onDismissed: () => void
}

export const ShareWelcomeModal: FC<ShareWelcomeModalModel> = ({
    text,
    isOpen,
    profileId,
    onDismissed,
}) => {
    const auth = useAuth()
    const userProfileQuery = useUserProfile(profileId)

    return (
        <Drawer open={isOpen} onClose={onDismissed}>
            <DrawerContent onClose={onDismissed} className={styles.modal}>
                <DrawerTitle />
                <DrawerDescription />
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        {userProfileQuery.isSuccess && (
                            <Avatar
                                className={styles.avatar}
                                src={userProfileQuery.data.imageUrl}
                                styleState={
                                    userProfileQuery.data.isLive
                                        ? "isLive"
                                        : "None"
                                }
                            />
                        )}
                        {userProfileQuery.isSuccess && (
                            <h1 style={{ margin: 0 }}>
                                <strong>
                                    {text.title(
                                        userProfileQuery.data.displayName,
                                    )}
                                </strong>
                            </h1>
                        )}
                        {userProfileQuery.isLoading && (
                            <AvatarLoading
                                baseColor="#D5D6D8"
                                className={"flex-fit"}
                            />
                        )}
                        {userProfileQuery.isLoading && (
                            <Skeleton
                                baseColor="#D5D6D8"
                                width={220}
                                height={35}
                                borderRadius={24}
                            />
                        )}
                        <p style={{ margin: 0 }}>{text.message}</p>
                    </div>

                    <IonButton
                        className={styles.signUp}
                        expand="block"
                        onClick={() =>
                            auth.signinRedirect({
                                extraQueryParams: {
                                    auth_policy: "trialSignUp",
                                    privacyPolicyUrl,
                                    termsAndConditionsUrl,
                                },
                            })
                        }
                    >
                        {text.onSignUp}
                    </IonButton>

                    <IonButton
                        className={styles.signIn}
                        expand="block"
                        onClick={() =>
                            auth.signinRedirect({
                                extraQueryParams: {
                                    login_hint:
                                        LocalStorage.getUsernameOrUndefined() ??
                                        "",
                                    auth_policy: "signIn",
                                    privacyPolicyUrl,
                                    termsAndConditionsUrl,
                                },
                            })
                        }
                    >
                        {text.onSignIn}
                    </IonButton>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
