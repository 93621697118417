import { faMessageExclamation } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonItem, IonList } from "@ionic/react"
import { FC } from "react"

import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { vars } from "theme/variables.css"
import * as styles from "./profile-actions-modal.css"

export type ProfileActionsModalTextModel = {
    report: string
}

type ProfileActionsModalModel = {
    text: ProfileActionsModalTextModel
    isOpen: boolean
    onReportClicked: () => void
    dismiss: () => void
}
export const ProfileActionsModal: FC<ProfileActionsModalModel> = model => {
    return (
        <Drawer open={model.isOpen} onClose={model.dismiss}>
            <DrawerContent className={styles.actionModalContent}>
                <DrawerTitle />
                <DrawerDescription />
                <IonList className={styles.actionList}>
                    <IonItem
                        className={styles.action}
                        onClick={model.onReportClicked}
                    >
                        <FontAwesomeIcon
                            icon={faMessageExclamation}
                            className={styles.icon}
                        />
                        <span
                            style={{
                                fontSize: vars.font.size.m,
                            }}
                        >
                            {model.text.report}
                        </span>
                    </IonItem>
                </IonList>
            </DrawerContent>
        </Drawer>
    )
}
