import { VideoId } from "api/branded-types"
import { EDIT_VIDEO_MONETIZE_ENABLED } from "envs"
import { monetizeEqual } from "features/payment/utils"
import { useVideoById } from "features/video/hooks/use-video-by-id"
import { isDefined, isNotDefined, isStringInvalid } from "utils/object"
import { useEditVideoPostStore } from "../store/edit-video-post-store"

type UseDerivedEditVideoStateParams = {
    videoId?: VideoId
    isError: boolean
}

export const useDerivedEditVideoState = ({
    videoId,
    isError,
}: UseDerivedEditVideoStateParams) => {
    const editVideoStore = useEditVideoPostStore()
    const videoQuery = useVideoById(videoId)
    const uploadNotStarted =
        isNotDefined(editVideoStore.videoUploadProgress) &&
        isNotDefined(editVideoStore.imageUploadProgress)

    const videoUploadFinishedIfStarted = isDefined(
        editVideoStore.videoUploadProgress,
    )
        ? editVideoStore.videoUploadFinished
        : true
    const imageUploadFinishedIfStarted = isDefined(
        editVideoStore.imageUploadProgress,
    )
        ? editVideoStore.imageUploadFinished
        : true
    const uploadFinished =
        editVideoStore.updatedWithoutUploads ||
        (!uploadNotStarted &&
            videoUploadFinishedIfStarted &&
            imageUploadFinishedIfStarted)

    const combinedUploadProgress = uploadNotStarted
        ? 0
        : ((editVideoStore.videoUploadProgress ?? 1) +
              (editVideoStore.imageUploadProgress ?? 1)) /
          2

    const titleValue = videoQuery.isSuccess
        ? (editVideoStore.title ?? videoQuery.data.title)
        : undefined
    const messageValue = videoQuery.isSuccess
        ? (editVideoStore.message ?? videoQuery.data.description)
        : undefined
    const monetizationValue = videoQuery.isSuccess
        ? (editVideoStore.monetization ?? videoQuery.data.monetization)
        : undefined
    const thumbnailImageValue = videoQuery.isSuccess
        ? (editVideoStore.thumbnailImage ??
          editVideoStore.thumbnail?.base64Data ??
          videoQuery.data.previewImageUrl)
        : undefined

    const imageIsTheSame =
        editVideoStore.thumbnail === undefined &&
        editVideoStore.thumbnailImage === undefined
    const titleIsTheSame = titleValue === videoQuery.data?.title
    const messageIsTheSame =
        messageValue === videoQuery.data?.description ||
        (messageValue === "" && videoQuery.data?.description === undefined)
    const monetizeIsTheSame = monetizeEqual(
        monetizationValue,
        videoQuery.data?.monetization,
    )

    const uploadInProgress =
        !!combinedUploadProgress && !uploadFinished && !isError

    const valuesAreSame =
        imageIsTheSame &&
        titleIsTheSame &&
        messageIsTheSame &&
        monetizeIsTheSame

    const isFileInvalid =
        editVideoStore.file === undefined
            ? false
            : editVideoStore.file.size <= 0

    const isThumbnailInvalid =
        editVideoStore.thumbnail === undefined
            ? false
            : editVideoStore.thumbnail.blob.size <= 0
    const isTitleInvalid = isStringInvalid(titleValue)

    const isUploadButtonDisabled =
        valuesAreSame ||
        isFileInvalid ||
        isTitleInvalid ||
        isThumbnailInvalid ||
        uploadInProgress

    const monetizationDisabled =
        uploadInProgress || !EDIT_VIDEO_MONETIZE_ENABLED

    const thumbnailExists = editVideoStore.thumbnail || thumbnailImageValue

    return {
        isFileInvalid,
        uploadInProgress,
        title: titleValue,
        message: messageValue,
        monetization: monetizationValue,
        thumbnailImage: thumbnailImageValue,
        uploadFinished,
        combinedUploadProgress,
        isUploadButtonDisabled,
        monetizationDisabled,
        thumbnailExists,
    }
}
