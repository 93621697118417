import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import { flow, pipe } from "fp-ts/function"
import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { AccountType } from "../../api/api-models"
import { ShareContentId, UserProfileId } from "../../api/branded-types"
import { createShareTokenTask } from "../../api/clients/auth-api-client"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { ShareOverviewAlertModal } from "../../components/modals/share-overview-alert-modal"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsListLoading,
    TabsTrigger,
} from "../../components/tabs/tabs"
import { VisibilityAdt } from "../../data-flow/common"
import { createShareUrlWithLocation } from "../../data-flow/sharing"
import { useMe } from "../../hooks/use-me"
import { useMyPayments } from "../../hooks/use-my-payments"
import { LocalStorage } from "../../local-storage"
import { fill } from "../../utils/array"
import { PostAreaTile } from "../feed/areas/post/post-area-tile"
import { VideoAreaTile } from "../feed/areas/videos/video-area-tile"
import { PostLoadingTile } from "../search/videos/post-loading-tile"
import { MyUserProfileActions } from "./components/my-user-profile-actions"
import {
    MyUserProfileData,
    ProfileDataLoading,
} from "./components/my-user-profile-data"
import { MyUserProfilePostContent } from "./components/my-user-profile-post-content"
import { MyUserProfileToolbar } from "./components/my-user-profile-toolbar"
import { ProfileDescription } from "./components/profile-description"
import {
    ProfileStatistics,
    ProfileStatisticsLoading,
} from "./components/profile-statistics"

import { shareLinkClient } from "api/clients/share-link-api-client"
import { Loading } from "common/loading"
import {
    imagePostitPaymentAreaPredicate,
    postitPaymentAreaPredicate,
    videoPaymentAreaPredicate,
} from "data-flow/payment"
import { useInView } from "framer-motion"
import { useMyUserProfile } from "hooks/use-my-user-profiles"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Skeleton from "react-loading-skeleton"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppStore } from "store"
import {
    getIsAuthorizedAccount,
    withAuthorizedAccess,
} from "../../api/api-utils"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { getSafeArea } from "../../utils/constant"
import {
    postByIdRoute,
    userProfileByIdRoute,
    videoByIdRoute,
} from "../routing/routing"
import { useProfilePostContent } from "./hooks/use-profile-post-content"
import { useProfileStatistics } from "./hooks/use-profile-statistic"
import { useProfileVideoContent } from "./hooks/use-profile-video-content"
import * as styles from "./my-user-profile-page.css"

export const MyUserProfilePage: FC = () => {
    const open = useAppStore(store => store.open)
    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation(["user"])
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const myProfileQuery = useMyUserProfile(isAuthorizedAccount)

    const [shareModalVisibility, setShareModalVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [shareUrl, setShareUrl] = useState<undefined | string>()

    const profileId = myProfileQuery.data?.id

    const shareMyProfile = useCallback(
        () => {
            setIsShareLinkInCreation(true)

            pipe(
                profileId,
                O.fromNullable,
                O.foldW(
                    () => () => setIsShareLinkInCreation(false),
                    id =>
                        pipe(
                            createShareTokenTask(
                                {
                                    contents: [
                                        {
                                            contentType: "UserProfile",
                                            contentFilter:
                                                ShareContentId.parse(id),
                                        },
                                    ],
                                },
                                {},
                            ),
                            TE.bimap(
                                error => {
                                    setIsShareLinkInCreation(false)
                                    console.error("Error share", error)
                                },
                                ({ data }) =>
                                    pipe(
                                        data,
                                        createShareUrlWithLocation(
                                            "app/user-profile/" + id,
                                        ),
                                        link => {
                                            shareLinkClient
                                                .createShortenedLink({
                                                    link,
                                                })
                                                .then(({ shortURL }) => {
                                                    setShareUrl(shortURL)
                                                    setShareModalVisibility(
                                                        VisibilityAdt.as.Visible(
                                                            {},
                                                        ),
                                                    )
                                                    setIsShareLinkInCreation(
                                                        false,
                                                    )
                                                })
                                        },
                                    ),
                            ),
                        ),
                ),
            )()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profileId, isAuthorizedAccount],
    )

    const imageRef = useRef(null)
    const isInView = useInView(imageRef, {
        margin: `${window.innerWidth * -0.2 - getSafeArea("top")}px`,
    })

    const myProfileVideoContentQuery = useProfileVideoContent(profileId)
    const myProfilePostContentQuery = useProfilePostContent(profileId)
    const myProfileStatisticsQuery = useProfileStatistics(profileId)

    const myPaymentsQuery = useMyPayments()

    const refreshPage = async () =>
        await Promise.allSettled([
            myProfileQuery.refetch(),
            myPaymentsQuery.refetch(),
            myProfileVideoContentQuery.refetch(),
            myProfilePostContentQuery.refetch(),
            meQuery.refetch(),
            myProfileStatisticsQuery.refetch(),
        ])

    return (
        <Page className={styles.page}>
            <Loading open={isShareLinkInCreation} message="Loading..." />

            <MyUserProfileToolbar
                isVisible={isInView}
                isAuthorizedAccount={isAuthorizedAccount}
            />

            {isInView && <div className={styles.overlay} />}

            <MyUserProfileActions
                shareMyProfile={withAuthorizedAccess({
                    onAuthorized: shareMyProfile,
                    onUnauthorized: () => open("CreateAccount"),
                    accountType,
                })}
                onNavigateToSettings={withAuthorizedAccess({
                    onAuthorized: () => navigate("/app/settings"),
                    onUnauthorized: () => open("CreateAccount"),
                    accountType,
                })}
            />

            <Content fullscreen>
                <PullToRefresh onRefresh={refreshPage}>
                    <div ref={imageRef}>
                        <LazyLoadImage
                            width={window.innerWidth}
                            height={window.innerWidth}
                            className={styles.profileImage}
                            src={
                                myProfileQuery.isSuccess && myProfileQuery.data
                                    ? `${myProfileQuery.data.imageUrl}?width=${window.innerWidth * 2}`
                                    : ""
                            }
                        />
                    </div>

                    <div className={styles.content}>
                        <ShareOverviewAlertModal
                            isOpen={VisibilityAdt.is.Visible(
                                shareModalVisibility,
                            )}
                            title="Share my profile"
                            onClosed={() =>
                                setShareModalVisibility(
                                    VisibilityAdt.as.Invisible({}),
                                )
                            }
                            shareUrl={O.fromNullable(shareUrl)}
                        />

                        <div className={styles.wrapper}>
                            <MyUserProfileData
                                isAuthorizedAccount={isAuthorizedAccount}
                                setEditProfileModalVisible={withAuthorizedAccess(
                                    {
                                        onAuthorized: () =>
                                            navigate("/app/my-profile/edit"),
                                        onUnauthorized: () =>
                                            open("CreateAccount"),
                                        accountType,
                                    },
                                )}
                                text={{
                                    locationPlaceholder: t(
                                        "myUserProfile.location.text",
                                    ),
                                    online: t("myUserProfile.online.text"),
                                    offline: t("myUserProfile.offline.text"),
                                }}
                                onRewardsClicked={() =>
                                    navigate(
                                        "/app/settings/rewards?currentTab=get-started",
                                    )
                                }
                            />

                            <ProfileStatistics
                                id={profileId}
                                onFollowedViewClicked={id =>
                                    navigate(`/app/followers/overview/${id}`)
                                }
                                onFollowingViewClicked={id =>
                                    navigate(`/app/followings/overview/${id}`)
                                }
                                text={{
                                    posts: t("myUserProfile.posts.text"),
                                    followers: t(
                                        "myUserProfile.followers.text",
                                    ),
                                    following: t(
                                        "myUserProfile.following.text",
                                    ),
                                }}
                            />

                            <ProfileDescription
                                profile={myProfileQuery.data}
                                text={{
                                    more: count => ` and ${count} more`,
                                }}
                            />

                            <div className={styles.divider} />

                            <ProfileContentTabs
                                accountType={accountType}
                                profileId={profileId}
                            />
                        </div>
                    </div>
                </PullToRefresh>
            </Content>
        </Page>
    )
}
export default MyUserProfilePage

type Tab = "All" | "Purchased"

type TabProps = {
    profileId?: UserProfileId
    accountType: AccountType
}

export const ProfileContentTabs: FC<TabProps> = ({
    accountType,
    profileId,
}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const profileContentTab = searchParams.get("tab") ?? ("All" as Tab)

    const userId = LocalStorage.getOidcUserId()
    const { t } = useTranslation(["user"])
    const navigate = useNavigate()

    const myPaymentsQuery = useMyPayments()

    const myPaymentsData = pipe(
        myPaymentsQuery.data?.pages ?? [],
        A.flatMap(page => page.items),
    )

    return (
        <>
            <Tabs
                value={profileContentTab}
                onValueChange={tab => setSearchParams({ tab })}
            >
                <TabsList>
                    <TabsTrigger<Tab> value="All">All</TabsTrigger>

                    <TabsTrigger<Tab> value="Purchased">Purchased</TabsTrigger>
                </TabsList>

                <TabsContent<Tab> value="All">
                    <MyUserProfilePostContent
                        profileId={profileId}
                        text={{
                            title: t(
                                "myUserProfile.createContentPlaceholder.title",
                            ),
                            subtitle: t(
                                "myUserProfile.createContentPlaceholder.subtitle",
                            ),
                            action: t(
                                "myUserProfile.createContentPlaceholder.action",
                            ),
                        }}
                        onVideoSelected={flow(videoByIdRoute, route =>
                            navigate(route),
                        )}
                        onPostSelected={flow(postByIdRoute, route =>
                            navigate(route),
                        )}
                        accountType={accountType}
                    />
                </TabsContent>

                <TabsContent<Tab> value="Purchased">
                    <div className={styles.purchasedContentContainer}>
                        {myPaymentsQuery.isSuccess &&
                            pipe(
                                myPaymentsData,
                                A.map(payment =>
                                    pipe(
                                        payment,
                                        O.fromPredicate(
                                            videoPaymentAreaPredicate,
                                        ),
                                        O.fold(
                                            () =>
                                                pipe(
                                                    payment,
                                                    O.fromPredicate(
                                                        postitPaymentAreaPredicate,
                                                    ),
                                                    //todo: when needed - handle bought live events
                                                    O.fold(
                                                        () => <div></div>,
                                                        postitPayment => (
                                                            <PostAreaTile
                                                                key={
                                                                    postitPayment
                                                                        .payment
                                                                        .id
                                                                }
                                                                area={
                                                                    imagePostitPaymentAreaPredicate(
                                                                        postitPayment,
                                                                    )
                                                                        ? {
                                                                              id: postitPayment
                                                                                  .item
                                                                                  .id,
                                                                              createdAt:
                                                                                  postitPayment.createdAt,
                                                                              profile:
                                                                                  postitPayment.profile,
                                                                              post: postitPayment.item,
                                                                              asset: postitPayment.asset,
                                                                          }
                                                                        : {
                                                                              id: postitPayment
                                                                                  .item
                                                                                  .id,
                                                                              createdAt:
                                                                                  postitPayment.createdAt,
                                                                              profile:
                                                                                  postitPayment.profile,
                                                                              post: postitPayment.item,
                                                                          }
                                                                }
                                                                onClicked={() =>
                                                                    navigate(
                                                                        postByIdRoute(
                                                                            postitPayment
                                                                                .item
                                                                                .id,
                                                                        ),
                                                                    )
                                                                }
                                                                onUserClicked={profileId => {
                                                                    if (
                                                                        O.isNone(
                                                                            userId,
                                                                        )
                                                                    )
                                                                        return

                                                                    navigate(
                                                                        userId.value ===
                                                                            profileId.toString()
                                                                            ? "/app/my-profile"
                                                                            : userProfileByIdRoute(
                                                                                  profileId,
                                                                              ),
                                                                    )
                                                                }}
                                                            />
                                                        ),
                                                    ),
                                                ),
                                            videoPayment => (
                                                <VideoAreaTile
                                                    key={
                                                        videoPayment.payment.id
                                                    }
                                                    videoArea={{
                                                        id: videoPayment.item
                                                            .id,
                                                        video: {
                                                            ...videoPayment.item,
                                                            paymentRequired:
                                                                videoPayment
                                                                    .item
                                                                    .monetization
                                                                    .type ===
                                                                "SubscriptionOnly",
                                                        },
                                                        createdAt:
                                                            videoPayment.createdAt,
                                                        profile:
                                                            videoPayment.profile,
                                                    }}
                                                    onClicked={videoId =>
                                                        navigate(
                                                            videoByIdRoute(
                                                                videoId,
                                                            ),
                                                        )
                                                    }
                                                    onUserClicked={profileId => {
                                                        if (O.isNone(userId))
                                                            return

                                                        navigate(
                                                            userId.value ===
                                                                profileId.toString()
                                                                ? "/app/my-profile"
                                                                : userProfileByIdRoute(
                                                                      profileId,
                                                                  ),
                                                        )
                                                    }}
                                                />
                                            ),
                                        ),
                                    ),
                                ),
                            )}

                        {myPaymentsQuery.isLoading &&
                            fill(3, idx => <PostLoadingTile key={idx} />)}
                    </div>
                </TabsContent>
            </Tabs>
        </>
    )
}

export const MyUserProfilePageLoading: FC = () => (
    <Page className={styles.page}>
        <NavigationBackButton />
        <MyUserProfileActions
            onNavigateToSettings={() => {}}
            shareMyProfile={() => {}}
        />
        <Content style={{ overflow: "hidden" }}>
            <Skeleton width="100vw" height="100vw" />
            <div className={styles.content}>
                <div className={styles.wrapper}>
                    <ProfileDataLoading />
                    <ProfileStatisticsLoading />
                    <div className={styles.divider} />
                    <TabsListLoading />
                    <div className={styles.loadingContentContainer}>
                        <PostLoadingTile />
                        <PostLoadingTile />
                    </div>
                </div>
            </div>
        </Content>
    </Page>
)
