import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonInput, IonToast } from "@ionic/react"
import * as O from "fp-ts/Option"
import { flow } from "fp-ts/function"
import { FC, useState } from "react"
import z from "zod"

import { MonetizationOption } from "../../api/api-models"
import { getEventDetailValue } from "../../utils/fp"

import { Link } from "react-router-dom"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./subscription-picker-modal.css"

export type SubscriptionModalText = {
    title: string
    description: string
    subscription: {
        free: SubscriptionDetailsText
        subscriber: SubscriptionDetailsText
        noneSubscriber: SubscriptionDetailsText
    }
    actions: {
        ok: string
    }
}

export type SubscriptionDetailsText = {
    title: string
    description: string
}

export type SubscriptionPickerModel = {
    text: SubscriptionModalText
    isOpen: boolean
    monetization: MonetizationOption

    onCanceled: () => void
    onConfirmed: (option: MonetizationOption) => void
}

const monetizePriceValueValid = z.number().refine(n => n > 0)

export const SubscriptionPickerModal: FC<SubscriptionPickerModel> = ({
    text,
    isOpen,
    monetization: defaultMonetization,
    onCanceled,
    onConfirmed,
}) => {
    const [isPriceValid, setPriceValid] = useState(true)
    const [monetizationValue, setMonetizationValue] = useState(1.99)
    const [monetization, setMonetization] =
        useState<MonetizationOption>(defaultMonetization)

    return (
        <>
            <Drawer open={isOpen} onClose={onCanceled}>
                <DrawerContent className={styles.modal}>
                    <DrawerHeader>
                        <FontAwesomeIcon
                            icon={faXmark}
                            className={styles.closeIcon}
                            onClick={onCanceled}
                        />
                    </DrawerHeader>
                    <DrawerTitle />
                    <DrawerDescription />
                    <div className={styles.content}>
                        <h1 style={{ fontSize: vars.font.size.xl, margin: 0 }}>
                            {text.title}
                        </h1>
                        <p
                            style={{
                                color: vars.color.tertiary.hex,
                                margin: "8px 0 12px 0",
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {text.description}
                        </p>

                        <div
                            className={styles.optionFree}
                            onClick={() => setMonetization({ type: "None" })}
                        >
                            <div className={styles.rows}>
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: vars.font.size.regular,
                                        fontWeight: 700,
                                    }}
                                    className={styles.optionTitle}
                                >
                                    {text.subscription.free.title}
                                </p>
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: vars.font.size.regular,
                                    }}
                                >
                                    {text.subscription.free.description}
                                </p>
                            </div>

                            {monetization.type === "None" ? (
                                <div className={styles.selectedCheckView}>
                                    <FontAwesomeIcon
                                        className={styles.checkIcon}
                                        icon={faCheck}
                                    />
                                </div>
                            ) : (
                                <div className={styles.deselectedCheckView} />
                            )}
                        </div>

                        <div
                            className={styles.optionSubscription}
                            onClick={() =>
                                setMonetization({
                                    type: "SubscriptionOnly",
                                    currency: "EUR",
                                    amount: 10,
                                })
                            }
                        >
                            <div className={styles.column}>
                                <div className={styles.rows}>
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 700,
                                        }}
                                        className={styles.optionTitle}
                                    >
                                        {text.subscription.subscriber.title}
                                    </p>
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: vars.font.size.regular,
                                        }}
                                    >
                                        {
                                            text.subscription.subscriber
                                                .description
                                        }
                                    </p>
                                </div>

                                {monetization.type === "SubscriptionOnly" ? (
                                    <div className={styles.selectedCheckView}>
                                        <FontAwesomeIcon
                                            className={styles.checkIcon}
                                            icon={faCheck}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={styles.deselectedCheckView}
                                    />
                                )}
                            </div>

                            <div className={styles.column}>
                                <div className={styles.rows}>
                                    <p
                                        className={styles.optionTitle}
                                        style={{
                                            margin: 0,
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 700,
                                        }}
                                    >
                                        {text.subscription.noneSubscriber.title}
                                    </p>
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: vars.font.size.regular,
                                        }}
                                    >
                                        {
                                            text.subscription.noneSubscriber
                                                .description
                                        }
                                    </p>
                                </div>

                                <div
                                    className={
                                        monetization.type === "None"
                                            ? styles.inputWrapperInactive
                                            : styles.inputWrapperActive
                                    }
                                >
                                    <IonInput
                                        className={styles.input}
                                        style={{ fontSize: vars.font.size.m }}
                                        value={`${monetizationValue}`}
                                        type="number"
                                        onIonInput={flow(
                                            getEventDetailValue,
                                            O.map(parseFloat),
                                            O.map(setMonetizationValue),
                                        )}
                                    />
                                    <p style={{ padding: "0 2px", margin: 0 }}>
                                        €
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DrawerFooter className={styles.footer}>
                        <p
                            style={{
                                margin: "8px 12px 0",
                                fontSize: vars.font.size.xs,
                                color: vars.color.tertiary.hex,
                            }}
                        >
                            By activating monetization for content, you agree to
                            our billing{" "}
                            <Link
                                to="/registration/terms-and-condition"
                                className={styles.link}
                            >
                                terms for content producers.
                            </Link>{" "}
                            Please note that your payment Details in Setting
                            must be complete.
                        </p>
                        <IonButton
                            className={styles.accepted}
                            onClick={() => {
                                if (monetization.type === "None") {
                                    onConfirmed(monetization)
                                    return
                                }
                                const parsedMonetizationValue =
                                    monetizePriceValueValid.safeParse(
                                        monetizationValue,
                                    )
                                if (parsedMonetizationValue.success) {
                                    onConfirmed({
                                        amount: parsedMonetizationValue.data,
                                        currency: "EUR",
                                        type: "SubscriptionOnly",
                                    })
                                } else {
                                    setPriceValid(false)
                                    console.error(parsedMonetizationValue.error)
                                }
                            }}
                        >
                            {text.actions.ok}
                        </IonButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <IonToast
                duration={3000}
                isOpen={!isPriceValid}
                message="Please enter a valid price"
                color="danger"
                className={styles.ionToast}
                onDidDismiss={() => setPriceValid(true)}
            />
        </>
    )
}
