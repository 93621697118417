/* eslint-disable functional/no-try-statements */

import { faAngleRight, faVideo } from "@fortawesome/pro-light-svg-icons"
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    IonButton,
    IonInput,
    IonLabel,
    IonTextarea,
    IonToast,
} from "@ionic/react"
import classNames from "classnames"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { VideoId } from "../../api/branded-types"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { VisibilityAdt } from "../../data-flow/common"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { fromEvent } from "../../utils/file-selector"
import { getEventDetailValue } from "../../utils/fp"

import { getIsAuthorizedAccount } from "api/api-utils"
import { Progress } from "common/progress"
import { SubscriptionPickerModal } from "features/upload/subscription-picker-modal"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { useNavigate, useParams } from "react-router-dom"
import { vars } from "theme/variables.css"
import { Content } from "../../common/content"
import { Footer } from "../../common/footer"
import { Page } from "../../common/page"
import { Toolbar } from "../../common/toolbar"
import * as styles from "./edit-video-post-page.css"
import { useEditVideo } from "./hooks/use-edit-video"
import { useEditVideoPostStore } from "./store/edit-video-post-store"

const MAX_TITLE_LENGTH = 120
const MAX_CONTENT_LENGTH = 500

export const EditVideoPostPage: FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation(["feed"])

    const videoId = VideoId.parse(id)

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const inputFile = useRef<HTMLInputElement>(null)
    const thumbnailInputFile = useRef<HTMLInputElement>(null)

    const [paymentPickerVisibility, setPaymentPickerVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const {
        title,
        message,
        monetization,
        uploadInProgress,
        uploadFinished,
        combinedUploadProgress,
        isError,
        isFileInvalid,
        monetizationDisabled,
        editDisabled,
        thumbnailExists,
        updateVideoUploadById,
        thumbnailImage,
    } = useEditVideo({ videoId, profileId })

    const reset = useEditVideoPostStore(store => store.reset)
    const setTitle = useEditVideoPostStore(store => store.setTitle)
    const setMessage = useEditVideoPostStore(store => store.setMessage)
    const setMonetization = useEditVideoPostStore(
        store => store.setMonetization,
    )
    const setFile = useEditVideoPostStore(store => store.setFile)
    const setThumbnailFile = useEditVideoPostStore(
        store => store.setThumbnailFile,
    )
    useEffect(() => () => reset(), [reset])

    return (
        <Page>
            {(!uploadInProgress || isError) && <NavigationBackButton />}

            <Toolbar>
                <p
                    style={{
                        fontSize: vars.font.size.xm,
                        fontWeight: 600,
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    Edit video post
                </p>
            </Toolbar>

            {uploadInProgress && !isError && (
                <Progress
                    className={styles.progress}
                    value={combinedUploadProgress}
                />
            )}
            <Content>
                <IonToast
                    isOpen={isError}
                    duration={3000}
                    className={styles.ionToast}
                    color="danger"
                    message="Oops! Something went wrong. Please try again later."
                />

                <IonToast
                    isOpen={isFileInvalid}
                    duration={3000}
                    className={styles.ionToast}
                    color="danger"
                    message="Oops! The selected video file is not valid. Ensure the file is a proper video format and not empty, then try again."
                />

                <IonToast
                    isOpen={uploadFinished}
                    duration={2000}
                    className={styles.ionToast}
                    color="success"
                    message={"Video has been edited!"}
                    onDidDismiss={() => {
                        reset()
                        navigate(-1)
                    }}
                />

                {monetization && (
                    <SubscriptionPickerModal
                        isOpen={VisibilityAdt.is.Visible(
                            paymentPickerVisibility,
                        )}
                        text={{
                            actions: {
                                ok: "Okay",
                            },
                            title: "Monetization Options",
                            description:
                                "Choose from the following options to monetize your contribution:",
                            subscription: {
                                free: {
                                    title: "Free for Everyone",
                                    description:
                                        "Your contribution is accessible to all users for free.",
                                },
                                subscriber: {
                                    title: "Free for Subscribers",
                                    description:
                                        "Your contribution is accessible for free only to your channel's subscribers. All other users must pay a price to access it.",
                                },
                                noneSubscriber: {
                                    title: "For Non-Subscribers",
                                    description:
                                        "You can set a price that all users except your subscribers must pay to access your contribution.",
                                },
                            },
                        }}
                        monetization={monetization}
                        onCanceled={() =>
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                        }
                        onConfirmed={monetization => {
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                            setMonetization(monetization)
                        }}
                    />
                )}

                <div className={styles.content}>
                    <div
                        className={classNames(
                            styles.uploadButton,
                            thumbnailExists ? "" : styles.border,
                        )}
                        style={{
                            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%),
                            url(${thumbnailImage}) no-repeat center/cover`,
                        }}
                        onClick={() => {
                            if (uploadInProgress) {
                                return
                            }

                            inputFile.current?.click()
                        }}
                    >
                        <div className={styles.iconWrapper}>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={faVideo}
                                color="black"
                            />
                        </div>

                        {!isFileInvalid && (
                            <div
                                className={styles.editCover}
                                onClick={e => {
                                    if (uploadInProgress) {
                                        return
                                    }
                                    e.stopPropagation()
                                    thumbnailInputFile.current?.click()
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: vars.font.size.regular,
                                        color: "var(--text-color, white)",
                                        textAlign: "left",
                                    }}
                                >
                                    edit cover
                                </p>
                            </div>
                        )}
                    </div>
                    <input
                        ref={inputFile}
                        style={{ display: "none" }}
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={async evt => {
                            evt.persist()
                            evt.preventDefault()
                            const files = await fromEvent(evt)
                            pipe(files, A.lookup(0), O.map(setFile))
                        }}
                    />
                    <input
                        ref={thumbnailInputFile}
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg, image/gif"
                        onChange={async evt => {
                            evt.persist()
                            evt.preventDefault()
                            const files = await fromEvent(evt)
                            pipe(files, A.lookup(0), O.map(setThumbnailFile))
                        }}
                    />
                    <div
                        className={classNames(
                            styles.inputWrapper,
                            styles.titleWrapper,
                        )}
                    >
                        <IonInput
                            className={styles.title}
                            placeholder={t("videoUpload.input.title")}
                            value={title}
                            disabled={uploadInProgress}
                            maxlength={MAX_TITLE_LENGTH}
                            clearInput
                            onIonInput={flow(
                                getEventDetailValue,
                                O.map(setTitle),
                            )}
                        />

                        <p
                            className={styles.characters}
                            style={{ fontSize: vars.font.size.xxs }}
                        >
                            {title
                                ? `${title.length} / ${MAX_TITLE_LENGTH}`
                                : ""}
                        </p>
                    </div>
                    <div className={styles.inputWrapper}>
                        <IonTextarea
                            className={styles.description}
                            placeholder={t("videoUpload.input.description")}
                            disabled={uploadInProgress}
                            value={message}
                            maxlength={MAX_CONTENT_LENGTH}
                            onIonInput={flow(
                                getEventDetailValue,
                                O.map(setMessage),
                            )}
                        />

                        <p
                            className={styles.characters}
                            style={{ fontSize: vars.font.size.xxs }}
                        >
                            {message
                                ? `${message.length} / ${MAX_CONTENT_LENGTH}`
                                : ""}
                        </p>
                    </div>

                    <p
                        style={{
                            fontSize: vars.font.size.s,
                            opacity: "50%",
                            margin: "8px 4px",
                        }}
                    >
                        <span
                            style={{
                                fontSize: vars.font.size.s,
                                fontWeight: 600,
                            }}
                        >
                            Note:
                        </span>{" "}
                        This feature is only active in the test environment and
                        will later only be available for monetized profiles.
                    </p>

                    <button
                        disabled={monetizationDisabled}
                        className={classNames(styles.monetizeWrapper)}
                        onClick={() =>
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Visible({}),
                            )
                        }
                    >
                        <div className={styles.monetizeTextWrapper}>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={faCircleStar}
                                color="white"
                            />
                            <IonLabel style={{ width: "100%" }}>
                                <p
                                    style={{
                                        fontSize: vars.font.size.m,
                                        color: "var(--text-color, white)",
                                        textAlign: "left",
                                    }}
                                >
                                    Edit Monetization Options
                                </p>
                            </IonLabel>
                            <FontAwesomeIcon
                                className={styles.navIcon}
                                icon={faAngleRight}
                                color="var(--nav-icon-color, white)"
                            />
                        </div>

                        <div
                            className={classNames(
                                styles.monetizeInfoTextWrapper,
                            )}
                        >
                            {monetization && monetization.type === "None" && (
                                <IonLabel>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.m,
                                            textAlign: "left",
                                        }}
                                    >
                                        Free for Everyone
                                    </p>
                                </IonLabel>
                            )}
                            {monetization &&
                                monetization.type === "SubscriptionOnly" && (
                                    <>
                                        <IonLabel>
                                            <p
                                                style={{
                                                    fontSize: vars.font.size.m,
                                                    textAlign: "left",
                                                }}
                                            >
                                                Free for Subscribers
                                            </p>
                                        </IonLabel>
                                        <IonLabel>
                                            <p
                                                style={{
                                                    fontSize: vars.font.size.m,
                                                    textAlign: "left",
                                                }}
                                            >
                                                {`For Non-Subscribers ${monetization.amount} ${monetization.currency}`}
                                            </p>
                                        </IonLabel>
                                    </>
                                )}
                        </div>
                    </button>
                </div>
            </Content>
            <Footer className={styles.footer}>
                <IonButton
                    expand="block"
                    className={styles.footerButton}
                    disabled={editDisabled}
                    onClick={() => {
                        if (!(title || message)) {
                            updateVideoUploadById(undefined)
                        } else if (title) {
                            updateVideoUploadById({
                                title,
                                description: message,
                            })
                        }
                    }}
                >
                    Upload
                </IonButton>
            </Footer>
        </Page>
    )
}

export default EditVideoPostPage
