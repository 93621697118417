import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { UserProfileId } from "../../../../api/branded-types"
import { useLiveNowEvents } from "../../../../hooks/use-live-now-events"
import { LiveNowAreaPlaceholder } from "./live-now-area-placeholder"
import { LiveNowAreaTile, LiveNowAreaTileModel } from "./live-now-area-tile"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./live-now-area-view.css"

export type LiveNowAreaViewModel = {
    text: {
        titleA: string
        titleB: string
        placeholder: string
        action: string
        userDetails: {
            liveState: string
        }
        account: {
            title: string
            message: string
            cancelTitle: string
            createAccountTitle: string
        }
    }
    onUserClicked: (id: UserProfileId) => void
    onGoLiveClicked: () => void
    onClicked: LiveNowAreaTileModel["onClicked"]
}

export const LiveNowAreaView: FC<LiveNowAreaViewModel> = ({
    text,
    onUserClicked,
    onGoLiveClicked,
    onClicked,
}) => {
    const liveNowEventsQuery = useLiveNowEvents()
    const liveNowEvents = pipe(
        liveNowEventsQuery?.data?.pages ?? [],
        A.flatMap(page => page.liveEventAreas),
    )
    const hasAvailableLiveEvents =
        (liveNowEventsQuery.data?.pages.at(0)?.liveEventAreas.length ?? 0) >
            0 &&
        !liveNowEventsQuery.isPending &&
        liveNowEventsQuery.isSuccess
    return (
        <>
            {liveNowEventsQuery.isPending &&
                !liveNowEventsQuery.isPlaceholderData && (
                    <LiveNowAreaViewLoading />
                )}
            {liveNowEventsQuery.isSuccess && (
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <h1
                            style={{
                                fontSize: vars.font.size.l,
                                color: "white",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 4,
                                    color: vars.color.liveColor.hex,
                                    fontSize: vars.font.size.l,
                                }}
                            >
                                {text.titleA}
                            </span>
                            {` ${text.titleB}`}
                        </h1>
                    </div>

                    <div className={styles.content}>
                        {hasAvailableLiveEvents &&
                            pipe(
                                liveNowEvents,
                                A.map(current => (
                                    <LiveNowAreaTile
                                        key={current.event.id}
                                        text={text.userDetails}
                                        liveArea={current}
                                        onUserClicked={onUserClicked}
                                        onClicked={onClicked}
                                    />
                                )),
                            )}
                        {!hasAvailableLiveEvents &&
                            !liveNowEventsQuery.isPending && (
                                <LiveNowAreaPlaceholder
                                    className={styles.placeholder}
                                    description={text.placeholder}
                                    action={text.action}
                                    onClicked={onGoLiveClicked}
                                />
                            )}
                    </div>
                </div>
            )}
        </>
    )
}

export const LiveNowAreaViewLoading = () => (
    <div className={styles.placeholder}>
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div
                    style={{
                        width: 150,
                        height: 26,
                        borderRadius: 24,
                        backgroundColor: vars.color.dark.hex,
                    }}
                />
            </div>

            <div className={styles.content}>
                <div className={styles.loadingPlaceholderContainer}>
                    <Skeleton
                        containerClassName="flex-fit"
                        height={45}
                        borderRadius={10}
                    />
                </div>
            </div>
        </div>
    </div>
)
