import { useQuery } from "@tanstack/react-query"

import { PostitId } from "../../../api/branded-types"
import { postitSelectorClient } from "../../../api/clients/post-api-client"
import { QueryKeys } from "../../../api/query-keys"

export const getPostitById = async (id?: PostitId) => {
    if (!id) throw new Error("PostitId is required")
    const res = await postitSelectorClient.getPostitById({ params: { id } })
    return res.data
}

export const usePostitById = (id?: PostitId) => {
    return useQuery({
        queryFn: () => getPostitById(id),
        queryKey: QueryKeys.payablePostit(id),
        enabled: !!id,
    })
}
