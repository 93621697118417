import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonItem, IonList } from "@ionic/react"
import classNames from "classnames"
import { FC } from "react"

import { Button } from "common/button"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { vars } from "theme/variables.css"
import * as styles from "./post-consumer-actions-modal.css"

export type OwnPostConsumerActionsModalTextModel = {
    edit: string
    delete: string
    cancel: string
}

type OwnPostConsumerActionsModalModel = {
    text: OwnPostConsumerActionsModalTextModel
    isOpen: boolean
    onEditClicked: () => void
    onDeleteClicked: () => void
    dismiss: () => void
}
export const OwnPostConsumerActionsModal: FC<
    OwnPostConsumerActionsModalModel
> = model => {
    return (
        <Drawer open={model.isOpen} onClose={model.dismiss}>
            <DrawerContent className={styles.actionModalContent}>
                <DrawerTitle />
                <DrawerDescription />
                <IonList className={styles.actionList}>
                    <IonItem
                        className={styles.action}
                        onClick={model.onEditClicked}
                    >
                        <FontAwesomeIcon icon={faPen} className={styles.icon} />
                        <span
                            style={{
                                fontSize: vars.font.size.m,
                            }}
                        >
                            {model.text.edit}
                        </span>
                    </IonItem>
                    <IonItem
                        className={classNames(styles.danger, styles.action)}
                        onClick={model.onDeleteClicked}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className={classNames(styles.icon, styles.danger)}
                        />
                        <span
                            style={{
                                fontSize: vars.font.size.m,
                            }}
                        >
                            {model.text.delete}
                        </span>
                    </IonItem>
                </IonList>
                <Button variant="secondary" onClick={model.dismiss}>
                    {model.text.cancel}
                </Button>
            </DrawerContent>
        </Drawer>
    )
}
