import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query"
import { ProfileChallenge, ProfileChallengeTask } from "api/api-models"
import { UserProfileId } from "api/branded-types"
import { userProfileSelectorClient } from "api/clients/user-profile-api-client"
import { QueryKeys } from "api/query-keys"
import { Except } from "type-fest"
import { WithLogoAsset } from "utils/asset"
import { getAssetsQueryFn } from "./use-asset"

export type ProfileChallengeTaskWithAsset = WithLogoAsset<ProfileChallengeTask>
export type ProfileChallengeWithLogoAsset = WithLogoAsset<
    Except<ProfileChallenge, "tasks"> & {
        tasks: ProfileChallengeTaskWithAsset[]
    }
>

type ProfileChallengesOverviewResponse = {
    firstSteps: ProfileChallengeTaskWithAsset[]
    followupChallenges: ProfileChallengeTaskWithAsset[]
}

export const getProfileChallengesOverviewQueryFn = async ({
    profileId,
    queryClient,
}: {
    profileId?: UserProfileId
    queryClient: QueryClient
}): Promise<ProfileChallengesOverviewResponse> => {
    if (!profileId) throw new Error("UserProfileId should be defined")

    const response =
        await userProfileSelectorClient.getProfileChallengesOverview({
            params: { id: profileId },
        })

    const firstStepResourceRefs = response.data.firstSteps.map(
        challenge => challenge.logoRef,
    )
    const challengeResourceRefs = response.data.followupChallenges.map(
        challenge => challenge.logoRef,
    )

    const resourceRefs = [...firstStepResourceRefs, ...challengeResourceRefs]

    const assetsRes = await getAssetsQueryFn({
        resourceRefs,
        queryClient,
    })

    const data: ProfileChallengesOverviewResponse = {
        firstSteps: response.data.firstSteps.map(challenge => {
            const challengeAsset = assetsRes.find(
                asset => asset.id.resourceId === challenge.logoRef.resourceId,
            )
            return {
                ...challenge,
                logoAsset: challengeAsset,
            }
        }),
        followupChallenges: response.data.followupChallenges.map(challenge => {
            const challengeAsset = assetsRes.find(
                asset => asset.id.resourceId === challenge.logoRef.resourceId,
            )
            return {
                ...challenge,
                logoAsset: challengeAsset,
            }
        }),
    }

    return data
}

export const useProfileChallengesOverview = (profileId?: UserProfileId) => {
    const queryClient = useQueryClient()
    return useQuery({
        queryFn: () =>
            getProfileChallengesOverviewQueryFn({ profileId, queryClient }),
        queryKey: QueryKeys.profileChallengesOverview(profileId),
        enabled: !!profileId,
    })
}
