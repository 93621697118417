import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, PaymentResourceRef, Price } from "../api-models"
import { Url, UserProfileId } from "../branded-types"

export const paymentCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/payment",
        alias: "startPayment",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    // profile which wants to buy the content
                    customerId: UserProfileId,
                    price: Price,
                    contentRef: PaymentResourceRef,
                    redirects: z.object({
                        succeeded: Url,
                        failed: Url,
                        canceled: Url,
                    }),
                }),
            },
        ],
        response: z.object({
            data: Url,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
