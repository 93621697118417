import { faLinkHorizontal } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, PropsWithChildren } from "react"

import { FoldableStatefulText } from "../../../components/texts/foldable-stateful-text"
import { VisibilityAdt } from "../../../data-flow/common"

import { UserProfile } from "api/api-models"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
    DrawerTrigger,
} from "../../../common/drawer"
import * as styles from "./profile-description.css"

export type ProfileDescriptionModel = {
    profile?: UserProfile
    text: {
        more: (value: number) => string
    }
}

export const ProfileDescription = (model: ProfileDescriptionModel) => {
    return (
        <Drawer>
            {model.profile && (
                <>
                    <div className={styles.userBio}>
                        {model.profile.description ? (
                            <FoldableStatefulText
                                maxLength={150}
                                fontSize={vars.font.size.regular}
                                visibility={VisibilityAdt.of.Invisible({})}
                                text={model.profile.description.trim()}
                            />
                        ) : (
                            <></>
                        )}
                        {pipe(
                            model.profile?.links ?? [],
                            A.filter(x => x !== ""),
                            O.fromPredicate(links => links.length > 0),
                            O.fold(
                                () => <></>,
                                links =>
                                    pipe(
                                        links,
                                        O.fromPredicate(
                                            links => links.length > 3,
                                        ),
                                        O.fold(
                                            () => (
                                                <div
                                                    className={
                                                        styles.linkContainer
                                                    }
                                                >
                                                    {pipe(
                                                        links,
                                                        A.mapWithIndex(
                                                            (key, link) => (
                                                                <ExternalLink
                                                                    key={key}
                                                                    link={link}
                                                                />
                                                            ),
                                                        ),
                                                    )}
                                                </div>
                                            ),
                                            () => (
                                                <div
                                                    className={
                                                        styles.linkContainer
                                                    }
                                                >
                                                    {pipe(
                                                        links,
                                                        A.splitAt(3),
                                                        ([firstLinks, _]) =>
                                                            pipe(
                                                                firstLinks,
                                                                A.mapWithIndex(
                                                                    (
                                                                        key,
                                                                        link,
                                                                    ) => (
                                                                        <ExternalLink
                                                                            key={
                                                                                key
                                                                            }
                                                                            link={
                                                                                link
                                                                            }
                                                                        >
                                                                            {key ===
                                                                            firstLinks.length -
                                                                                1 ? (
                                                                                <DrawerTrigger
                                                                                    className={
                                                                                        styles.modalTrigger
                                                                                    }
                                                                                    asChild
                                                                                >
                                                                                    <span
                                                                                        className={
                                                                                            styles.link
                                                                                        }
                                                                                        style={{
                                                                                            fontSize:
                                                                                                vars
                                                                                                    .font
                                                                                                    .size
                                                                                                    .regular,
                                                                                        }}
                                                                                    >
                                                                                        {model.text.more(
                                                                                            links.length -
                                                                                                3,
                                                                                        )}
                                                                                    </span>
                                                                                </DrawerTrigger>
                                                                            ) : (
                                                                                <>

                                                                                </>
                                                                            )}
                                                                        </ExternalLink>
                                                                    ),
                                                                ),
                                                            ),
                                                    )}
                                                </div>
                                            ),
                                        ),
                                    ),
                            ),
                        )}
                    </div>
                </>
            )}
            {!model.profile && <ProfileDescriptionLoading />}
            <DrawerContent className={styles.modalContent}>
                <DrawerTitle />
                <DrawerDescription />
                {model.profile &&
                    pipe(
                        model.profile?.links ?? [],
                        A.filter(x => x !== ""),
                        A.mapWithIndex((key, link) => (
                            <ExternalLink
                                key={key}
                                link={link}
                                iconColor="black"
                            />
                        )),
                        linkElements => (
                            <div className={styles.linkContainer}>
                                {linkElements}
                            </div>
                        ),
                    )}
            </DrawerContent>
        </Drawer>
    )
}

type ExternalLinkModel = {
    iconColor?: string
    link: string
}

const ExternalLink = (model: PropsWithChildren<ExternalLinkModel>) => (
    <div className={styles.externalLink}>
        <FontAwesomeIcon
            icon={faLinkHorizontal}
            color={model.iconColor || "white"}
        />
        <span>
            {/* TODO implement different protocols like ftp etc. */}
            <a
                className={styles.link}
                href={
                    model.link.startsWith("https://")
                        ? model.link
                        : `https://${model.link}`
                }
            >
                {formatLink(model.link)}
            </a>
            {model.children}
        </span>
    </div>
)

export const ProfileDescriptionLoading: FC = () => (
    <div className={styles.descriptionLoading} />
)

const formatLink = (link: string) =>
    link.length > 25 ? link.slice(0, 25) + "..." : link
