import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, Pagination, UserComment } from "../api-models"
import { UserCommentId } from "../branded-types"

export const commentSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/comments",
        alias: "listComments",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserComment),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/comment/:id",
        alias: "getComment",
        parameters: [
            {
                type: "Path",
                name: "id",
                schema: UserCommentId,
            },
        ],
        response: z.object({
            data: UserComment,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
