/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileWithPath, toFileWithPath } from "./file"

export async function fromEvent(evt: Event | any): Promise<FileWithPath[]> {
    if (isChangeEvt(evt)) return getInputFiles(evt)
    return []
}

function getInputFiles(evt: Event) {
    return fromList<FileWithPath>((evt.target as HTMLInputElement).files).map(
        file => toFileWithPath(file),
    )
}

// IE11 does not support Array.from()
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#Browser_compatibility
// https://developer.mozilla.org/en-US/docs/Web/API/FileList
// https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItemList
function fromList<T>(items: DataTransferItemList | FileList | null): T[] {
    if (items === null) {
        return []
    }

    const files = []

    // tslint:disable: prefer-for-of
    for (let i = 0; i < items.length; i++) {
        const file = items[i]
        files.push(file)
    }

    return files as any
}

function isChangeEvt(value: any): value is Event {
    return isObject<Event>(value) && isObject(value.target)
}

function isObject<T>(v: any): v is T {
    return typeof v === "object" && v !== null
}

interface FileArray extends Array<FileValue> {}
type FileValue = FileWithPath | FileArray[]
