import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./new-feature-coming-soon.css"

type TextModel = {
    title: string
    description: string
}

export type NewFeatureComingSoonModalModel = {
    text: TextModel
    isOpen: boolean
    onClosed: () => void
}

export const NewFeatureComingSoonModal = (
    props: NewFeatureComingSoonModalModel,
) => (
    <Drawer noBodyStyles open={props.isOpen} onClose={props.onClosed}>
        <DrawerContent onClose={props.onClosed} className={styles.modal}>
            <DrawerTitle />
            <DrawerDescription />
            <DrawerClose className={styles.closeIconContainer}>
                <FontAwesomeIcon
                    className={styles.closeIcon}
                    icon={faXmark}
                    onClick={props.onClosed}
                />
            </DrawerClose>
            <div className={styles.content}>
                <p
                    className={styles.text}
                    style={{
                        marginTop: 16,
                        fontSize: vars.font.size.xxxl,
                        fontWeight: 600,
                    }}
                >
                    {props.text.title}
                </p>
                <p
                    className={styles.text}
                    style={{
                        marginTop: 12,
                        fontSize: vars.font.size.regular,
                    }}
                >
                    {props.text.description}
                </p>
            </div>
        </DrawerContent>
    </Drawer>
)
