/* eslint-disable functional/no-try-statements */
// library is used and adapted from https://github.com/Rajesh-Royal/vThumb.js/blob/master/src/index.ts
export type Thumbnail = {
    blob: Blob
    base64Data: string
}

export const importFileAndPreview = (
    file: File,
    revoke?: boolean,
): Promise<string> => {
    return new Promise((resolve, reject) => {
        const localURL = window.URL || window.webkitURL
        try {
            const preview = localURL.createObjectURL(file)
            setTimeout(() => {
                resolve(preview)
                // Revoke the URL after confirming it's no longer needed
                if (revoke) {
                    localURL.revokeObjectURL(preview)
                }
            }, 100)
        } catch (error) {
            reject(error)
        }
    })
}

export const generateVideoThumbnails = async (
    videoFile: File,
    numberOfThumbnails: number,
    cb?: (thumbnail: Thumbnail, index: number) => void,
): Promise<Thumbnail[]> => {
    const thumbnail: Thumbnail[] = []
    const fractions: number[] = []

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        // if (type === "file") {
        //     if (!videoFile.type?.includes("video")) {
        //         reject("not a valid video file");
        //     }
        // }

        await getVideoDurationFromVideoFile(videoFile)
            .then(async duration => {
                // divide the video timing into particular timestamps in respective to number of thumbnails
                // ex if time is 10 and numOfthumbnails is 4 then result will be -> 0, 2.5, 5, 7.5 ,10
                // we will use this timestamp to take snapshots
                for (
                    let i = 0;
                    i <= duration;
                    i += duration / numberOfThumbnails
                ) {
                    fractions.push(Math.floor(i))
                }

                const promiseArray = fractions.map(async (time, index) => {
                    const res = await getVideoThumbnail(
                        videoFile,
                        index >= fractions.length - 1 ? time - 2 : time,
                    )

                    if (cb) cb(res, index)
                    return Promise.resolve(res)
                })

                await Promise.all(promiseArray)
                    .then(res => {
                        res.forEach(res => {
                            if (!res) return

                            thumbnail.push(res)
                        })
                        resolve(thumbnail)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => resolve(thumbnail))
            })
            .catch(err => {
                reject(err)
            })
        reject("something went wrong")
    })
}

export const getVideoThumbnail = (
    file: File | string,
    videoTimeInSeconds: number,
): Promise<Thumbnail> => {
    return new Promise((resolve, reject) => {
        if ((file as File)?.type?.match("video")) {
            importFileAndPreview(file as File).then(urlOfFIle => {
                getVideoCover(urlOfFIle, videoTimeInSeconds).then(res => {
                    resolve(res)
                })
            })
        } else if (file) {
            getVideoCover(file as string, videoTimeInSeconds)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        } else {
            reject("file not valid")
        }
    })
}

export const getVideoCover = (
    urlOfFIle: string,
    seekTo = 0.0,
): Promise<Thumbnail> => {
    return new Promise((resolve, reject) => {
        try {
            // load the file to a video player
            const videoPlayer = document.createElement("video")
            // videoPlayer.setAttribute("src", URL.createObjectURL(urlOfFIle));
            videoPlayer.setAttribute("src", urlOfFIle)
            videoPlayer.crossOrigin = "Anonymous"
            videoPlayer.load()
            videoPlayer.addEventListener("error", ex => {
                reject(`error when loading video file ${ex}`)
            })
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener("loadedmetadata", () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.")
                    return
                }
                // delay seeking or else "seeked" event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo
                }, 200)
                // extract video thumbnail once seeking is compconste
                videoPlayer.addEventListener("seeked", () => {
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas")
                    canvas.width = videoPlayer.videoWidth
                    canvas.height = videoPlayer.videoHeight
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d")
                    ctx!.drawImage(
                        videoPlayer,
                        0,
                        0,
                        canvas.width,
                        canvas.height,
                    )
                    // return the canvas image as a blob
                    // then convert it to base 64
                    ctx!.canvas.toBlob(
                        blob => {
                            if (!blob) {
                                return reject("selected blob is null")
                            }

                            const reader = new FileReader()
                            reader.readAsDataURL(blob as Blob)
                            reader.onloadend = function () {
                                const base64data = reader.result
                                if (!base64data) {
                                    return reject("base64 data are null")
                                }

                                resolve({
                                    blob,
                                    base64Data: base64data as string,
                                })
                            }
                        },
                        "image/png",
                        1 /* quality */,
                    )
                })
            })
        } catch (error) {
            reject(error)
        }
    })
}

export const getVideoDurationFromVideoFile = (
    videoFile: File | string,
): Promise<number> => {
    return new Promise((resolve, reject) => {
        try {
            if (videoFile) {
                if ((videoFile as File)?.type?.match("video")) {
                    importFileAndPreview(videoFile as File).then(url => {
                        generateVideoDurationFromUrl(url).then(res => {
                            resolve(res)
                        })
                    })
                } else {
                    generateVideoDurationFromUrl(videoFile as string).then(
                        res => {
                            resolve(res)
                        },
                    )
                }
            } else {
                reject("Cannot generate video duration for this video file.")
            }
        } catch (error) {
            reject(error)
        }
    })
}

const generateVideoDurationFromUrl = (url: string): Promise<number> =>
    new Promise((resolve, _reject) => {
        const video = document.createElement("video")
        video.addEventListener("loadeddata", function () {
            resolve(video.duration)
            window.URL.revokeObjectURL(url)
        })
        video.preload = "metadata"
        video.src = url
        // Load video in Safari / IE11
        video.muted = true
        video.crossOrigin = "Anonymous"
        video.playsInline = true
        video.play()
    })
