import { makeApi } from "@zodios/core"
import z from "zod"

import { AccountType, ApiError, Asset, Pagination } from "../api-models"

export const assetSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/assets",
        alias: "listAssets",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Asset),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])

export const GetAssetByIdResponse = z.object({
    data: Asset,
    accountType: AccountType,
})
export type GetAssetByIdResponse = z.infer<typeof GetAssetByIdResponse>
