import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { UserProfile } from "../../../../api/api-models"
import { UserProfileId } from "../../../../api/branded-types"
import { fill } from "../../../../utils/array"
import {
    UserResultListLoadingItem,
    UserSearchResultListItem,
} from "./user-search-result-list-item"

import * as styles from "./user-search-result-list.css"

export type UsersSearchResultListModel = {
    profiles: UserProfile[]

    isLoading?: boolean
    loadingItemsAmount?: number

    onClicked: (id: UserProfileId) => void
}

export const UserSearchResultList: FC<UsersSearchResultListModel> = model => (
    <div className={styles.list}>
        {pipe(
            model.profiles,
            A.map(profile => (
                <UserSearchResultListItem
                    key={profile.id}
                    avatarSize={40}
                    onClicked={() => model.onClicked(profile.id)}
                    src={profile.imageUrl}
                    isLive={profile.isLive}
                    location={profile.location}
                    locationNotAvailable="Location Unavailable"
                    name={
                        profile.displayName && profile.displayName.length > 0
                            ? profile.displayName
                            : profile.profileName
                    }
                />
            )),
        )}
        {model.isLoading &&
            fill(model.loadingItemsAmount ?? 4, idx => (
                <UserResultListLoadingItem key={idx} avatarSize={40} />
            ))}
    </div>
)

export const UserResultLoadingList: FC = () => (
    <div className={styles.list}>
        <UserResultListLoadingItem avatarSize={40} />
        <UserResultListLoadingItem avatarSize={40} />
        <UserResultListLoadingItem avatarSize={40} />
        <UserResultListLoadingItem avatarSize={40} />
    </div>
)

export const UserResultLoadingSection: FC = () => (
    <div className={styles.loadingSectionContainer}>
        <div className={styles.loadingSectionTitle} />
        <UserResultLoadingList />
    </div>
)
