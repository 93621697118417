import styled, { CSSObject } from "@emotion/styled"
import { vars } from "theme/variables.css"

const baseButtonStyles: CSSObject = {
    boxSizing: "border-box",
    width: "100%",

    fontSize: vars.font.size.m,
    fontWeight: 500,

    backgroundColor: vars.color.medium.hex,
    color: "white",

    border: "none",
    borderRadius: "8px",

    cursor: "pointer",
    padding: "14px 16px",

    "&:hover": {
        backgroundColor: vars.color.dark.tint,
    },
}

export const PrimaryButton = styled.button({
    ...baseButtonStyles,
})

export const SecondaryButton = styled.button({
    ...baseButtonStyles,

    backgroundColor: "transparent",
    color: vars.color.medium.hex,

    "&:hover": {
        color: "white",
        backgroundColor: vars.color.dark.tint,
    },
})
