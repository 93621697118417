import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC, PropsWithChildren, useEffect, useRef } from "react"
import { z } from "zod"
import * as styles from "./pull-to-refresh.css"

const PullDirection = z.union([z.literal("Up"), z.literal("Down")])
type PullDirection = z.infer<typeof PullDirection>

type PullToRefreshProps = {
    isPullable?: boolean
    canFetchMore?: boolean
    pullDownThreshold?: number
    maxPullDownDistance?: number
    resistance?: number
    backgroundColor?: string
    className?: string
    iconClassName?: string

    onRefresh: () => Promise<unknown>
}

//todo: refactor this to not use global styles
export const PullToRefresh: FC<PropsWithChildren<PullToRefreshProps>> = ({
    isPullable = true,
    canFetchMore = false,
    onRefresh,
    children,
    pullDownThreshold = 67,
    maxPullDownDistance = 95, // max distance to scroll to trigger refresh
    resistance = 1,
    backgroundColor,
    className = "",
    ...props
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const childrenRef = useRef<HTMLDivElement>(null)
    const pullDownRef = useRef<HTMLDivElement>(null)
    let pullToRefreshThresholdBreached: boolean = false
    let isDragging: boolean = false
    let startY: number = 0
    let currentY: number = 0

    useEffect(() => {
        if (!isPullable || !childrenRef || !childrenRef.current) return
        const childrenEl = childrenRef.current
        childrenEl.addEventListener("touchstart", onTouchStart, {
            passive: true,
        })
        childrenEl.addEventListener("mousedown", onTouchStart)
        childrenEl.addEventListener("touchmove", onTouchMove, {
            passive: false,
        })
        childrenEl.addEventListener("mousemove", onTouchMove)
        childrenEl.addEventListener("touchend", onEnd)
        childrenEl.addEventListener("mouseup", onEnd)
        document.body.addEventListener("mouseleave", onEnd)

        return () => {
            childrenEl.removeEventListener("touchstart", onTouchStart)
            childrenEl.removeEventListener("mousedown", onTouchStart)
            childrenEl.removeEventListener("touchmove", onTouchMove)
            childrenEl.removeEventListener("mousemove", onTouchMove)
            childrenEl.removeEventListener("touchend", onEnd)
            childrenEl.removeEventListener("mouseup", onEnd)
            document.body.removeEventListener("mouseleave", onEnd)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        children,
        isPullable,
        onRefresh,
        pullDownThreshold,
        maxPullDownDistance,
        canFetchMore,
    ])

    const initContainer = (): void => {
        requestAnimationFrame(() => {
            /**
             * Reset Styles
             */
            if (childrenRef.current) {
                childrenRef.current.style.transform = `unset`
            }
            if (pullDownRef.current) {
                pullDownRef.current.style.opacity = "0"
            }
            if (containerRef.current) {
                containerRef.current.classList.remove(
                    "ptr--pull-down-threshold-breached",
                )
                containerRef.current.classList.remove("ptr--dragging")
                containerRef.current.classList.remove(
                    "ptr--fetch-more-threshold-breached",
                )
            }

            if (pullToRefreshThresholdBreached)
                pullToRefreshThresholdBreached = false
        })
    }

    const onTouchStart = (e: MouseEvent | TouchEvent): void => {
        isDragging = false
        if (e instanceof MouseEvent) {
            startY = e.pageY
        }
        if (window.TouchEvent && e instanceof TouchEvent) {
            startY = e.touches[0].pageY
        }
        currentY = startY
        // Check if element can be scrolled
        if (
            e.type === "touchstart" &&
            isTreeScrollable(e.target as HTMLElement, "Up")
        ) {
            return
        }
        // Top non visible so cancel
        if (childrenRef.current!.getBoundingClientRect().top < 0) {
            return
        }
        isDragging = true
    }

    const onTouchMove = (e: MouseEvent | TouchEvent): void => {
        if (!isDragging) {
            return
        }

        if (window.TouchEvent && e instanceof TouchEvent) {
            currentY = e.touches[0].pageY
        } else {
            currentY = (e as MouseEvent).pageY
        }

        containerRef.current!.classList.add("ptr--dragging")

        if (currentY < startY) {
            isDragging = false
            return
        }

        if (e.cancelable) {
            e.preventDefault()
        }

        const yDistanceMoved = Math.min(
            (currentY - startY) / resistance,
            maxPullDownDistance,
        )

        // Limit to trigger refresh has been breached
        if (yDistanceMoved >= pullDownThreshold) {
            isDragging = true
            pullToRefreshThresholdBreached = true
            containerRef.current!.classList.remove("ptr--dragging")
            containerRef.current!.classList.add(
                "ptr--pull-down-threshold-breached",
            )
        }

        // maxPullDownDistance breached, stop the animation
        if (yDistanceMoved >= maxPullDownDistance) {
            return
        }
        pullDownRef.current!.style.opacity = (yDistanceMoved / 65).toString()
        childrenRef.current!.style.transform = `translate(0px, ${yDistanceMoved}px)`
        pullDownRef.current!.style.visibility = "visible"
    }

    const onEnd = (): void => {
        isDragging = false
        startY = 0
        currentY = 0

        // Container has not been dragged enough, put it back to it's initial state
        if (!pullToRefreshThresholdBreached) {
            if (pullDownRef.current)
                pullDownRef.current.style.visibility = "hidden"
            initContainer()
            return
        }

        if (childrenRef.current) {
            childrenRef.current.style.transform = `translate(0px, ${pullDownThreshold}px)`
        }
        onRefresh().then(initContainer).catch(initContainer)
    }

    return (
        <div
            className={classNames("ptr", className)}
            style={{ backgroundColor }}
            ref={containerRef}
        >
            <div className="ptr__pull-down" ref={pullDownRef}>
                <div className="ptr__loader ptr__pull-down--loading">
                    <FontAwesomeIcon
                        size="2xl"
                        icon={faSpinnerThird}
                        className={classNames(
                            styles.refresherIcon,
                            props.iconClassName,
                        )}
                    />
                </div>
                <div
                    className="ptr__pull-down--pull-more"
                    style={{ position: "absolute" }}
                >
                    <div
                        style={{ backgroundColor: "transparent", height: 45 }}
                    />
                </div>
            </div>
            <div className="ptr__children" ref={childrenRef}>
                {children}
            </div>
        </div>
    )
}

function isOverflowScrollable(element: HTMLElement): boolean {
    const overflowType: string = getComputedStyle(element).overflowY
    if (element === document.scrollingElement) {
        return true
    }

    if (overflowType !== "scroll" && overflowType !== "auto") {
        return false
    }

    return true
}

function isScrollable(element: HTMLElement, direction: PullDirection): boolean {
    if (!isOverflowScrollable(element)) {
        return false
    }

    if (direction === "Down") {
        const bottomScroll = element.scrollTop + element.clientHeight
        return bottomScroll < element.scrollHeight
    }

    if (direction === "Up") {
        return element.scrollTop > 0
    }

    console.error("unsupported direction")

    return false
}

/**
 * Returns whether a given element or any of its ancestors (up to rootElement) is scrollable in a given direction.
 */
export function isTreeScrollable(
    element: HTMLElement,
    direction: PullDirection,
): boolean {
    if (isScrollable(element, direction)) {
        return true
    }

    if (element.parentElement === null) {
        return false
    }

    return isTreeScrollable(element.parentElement, direction)
}
