import { IonButton } from "@ionic/react"
import { FC } from "react"

import { FollowState, FollowStateAdt } from "../../../data-flow/follower"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./user-profile-page-follow-button.css"

export type UserProfilePageFollowButtonModel = {
    text: {
        follow: string
        unfollow: string
    }
    followingState: FollowState
    onFollowClicked: () => void
    onNotPossibleFollowClicked: () => void
    onUnfollowClicked: () => void
}

export const UserProfilePageFollowButton: FC<
    UserProfilePageFollowButtonModel
> = ({
    text,
    followingState,
    onFollowClicked,
    onUnfollowClicked,
    onNotPossibleFollowClicked,
}) => (
    <div>
        {FollowStateAdt.match({
            Following: () => (
                <IonButton
                    expand="block"
                    className={styles.isFollowing}
                    style={{ color: vars.color.background.hex }}
                    onClick={onUnfollowClicked}
                >
                    <p
                        style={{ fontSize: vars.font.size.m }}
                        className={styles.isFollowingText}
                    >
                        {text.unfollow}
                    </p>
                </IonButton>
            ),
            IgnoringFollow: () => (
                <IonButton
                    expand="block"
                    className={styles.isNotFollowing}
                    style={{ color: vars.color.background.hex }}
                    onClick={onFollowClicked}
                >
                    <p
                        style={{ fontSize: vars.font.size.m }}
                        className={styles.isNotFollowingText}
                    >
                        {text.follow}
                    </p>
                </IonButton>
            ),
            LoadingFollow: () => (
                <Skeleton height={50} width="100%" borderRadius={16} />
            ),
            NotPossibleFollow: () => (
                <IonButton
                    expand="block"
                    onClick={onNotPossibleFollowClicked}
                    className={styles.isNotFollowing}
                    style={{ color: vars.color.background.hex }}
                >
                    <p
                        style={{ fontSize: vars.font.size.m }}
                        className={styles.isNotFollowingText}
                    >
                        {text.follow}
                    </p>
                </IonButton>
            ),
        })(followingState)}
    </div>
)
