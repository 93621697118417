import { flow } from "fp-ts/function"
import * as O from "fp-ts/Option"

import * as S from "fp-ts-std/String"

import {
    LiveEventId,
    PostitId,
    UserProfileId,
    VideoId,
} from "../../api/branded-types"

export const liveVideoByIdRoute = (profileId: UserProfileId, id: LiveEventId) =>
    `/app/live-event/${profileId}/viewer/${id}`

export const videoByIdRoute = (id: VideoId) => `/app/video/viewer/${id}`

export const postByIdRoute = (id: PostitId) => `/app/postit/viewer/${id}`

export const userProfileByIdRoute = (id: UserProfileId) =>
    `/app/user-profile/${id}`

export const getLiveEventIdStrFromRoute = flow(
    S.match(/\/app\/live-event\/viewer\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const getVideoIdStrFromRoute = flow(
    S.match(/\/app\/video\/viewer\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const getVideoIdFromRoute = flow(
    getVideoIdStrFromRoute,
    O.map(VideoId.safeParse),
    O.chain(x => (x.success ? O.some(x.data) : O.none)),
)

export const getPostitIdStrFromRoute = flow(
    S.match(/\/app\/postit\/viewer\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const getPostitIdFromRoute = flow(
    getPostitIdStrFromRoute,
    O.map(PostitId.safeParse),
    O.chain(x => (x.success ? O.some(x.data) : O.none)),
)

export const getLiveEventIdFromRoute = flow(
    getLiveEventIdStrFromRoute,
    O.map(LiveEventId.parse),
)

export const getUserIdFromUserProfileRoute = flow(
    S.match(/\/app\/user-profile\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const getUserProfileFromRoute = flow(
    S.match(/\/app\/user-profile\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
    O.map(UserProfileId.parse),
)

export const getLiveEventFromViewerRoute = flow(
    S.match(/\/app\/live-event\/viewer\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
    O.map(LiveEventId.parse),
)

export const getUserProfileIdStrFromFollowerRoute = flow(
    S.match(/\/app\/followers\/overview\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const getUserProfileIdFromFollowerRoute = flow(
    getUserProfileIdStrFromFollowerRoute,
    O.map(UserProfileId.safeParse),
    O.chain(x => (x.success ? O.some(x.data) : O.none)),
)

export const getUserProfileIdStrFromFollowingRoute = flow(
    S.match(/\/app\/followings\/overview\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const getUserProfileIdFromFollowingRoute = flow(
    getUserProfileIdStrFromFollowingRoute,
    O.map(UserProfileId.safeParse),
    O.chain(x => (x.success ? O.some(x.data) : O.none)),
)

export const getUserProfileIdStrFromLikingRoute = flow(
    S.match(/\/app\/liking\/overview\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const getUserProfileIdFromLikingRoute = flow(
    getUserProfileIdStrFromFollowingRoute,
    O.map(UserProfileId.safeParse),
    O.chain(x => (x.success ? O.some(x.data) : O.none)),
)

export const getLiveEventIdFromSummaryRoute = flow(
    S.match(/\/app\/live-event\/summary\/([0-9A-Za-z]+)/),
    O.map(([_, id]) => id),
    O.chain(O.fromNullable),
)

export const AUTHORIZED_PATHS = [
    "/app/live-event/creator",
    "/app/live-event/summary/:id",
    "/app/messages",
    "/app/my-profile",
    "/app/my-profile/edit",
    "/app/notifications",
    "/app/message/profile/:id",
    "/app/settings/notifications",
    "/app/settings",
    "/app/image-upload",
    "/app/text-upload",
    "/app/video-upload",
]

export const AUTHORIZED_ROUTES = AUTHORIZED_PATHS.map(path => ({ path }))
