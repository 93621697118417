import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, PropsWithChildren } from "react"
import { match } from "ts-pattern"

import { vars } from "theme/variables.css"
import { PayableVideo } from "../../../api/api-models"
import { useMergedInfiniteQueries } from "../../../api/api-utils"
import { PostitId, UserProfileId } from "../../../api/branded-types"
import { InfiniteScroll } from "../../../common/infinite-scroll"
import { FollowState, FollowStateAdt } from "../../../data-flow/follower"
import { fill } from "../../../utils/array"
import {
    PostAreaTile,
    isPostitArea,
    isVideoArea,
} from "../../feed/areas/post/post-area-tile"
import { VideoAreaTile } from "../../feed/areas/videos/video-area-tile"
import { PostLoadingTile } from "../../search/videos/post-loading-tile"
import { useProfilePostContent } from "../hooks/use-profile-post-content"
import { useProfileVideoContent } from "../hooks/use-profile-video-content"
import * as styles from "../user-profile-page.css"

type UserProfilePagePostContentModel = {
    text: {
        followCTA: {
            title: string
            subtitle: string
        }
        videoEmpty: string
    }
    followingState: FollowState
    onVideoSelected: (video: PayableVideo) => void
    onPostSelected: (id: PostitId) => void
    profileId?: UserProfileId
}

export const UserProfilePagePostContent: FC<
    UserProfilePagePostContentModel
> = model => {
    const profileVideoContentQuery = useProfileVideoContent(model.profileId)
    const profilePostContentQuery = useProfilePostContent(model.profileId)

    const { data, fetchNext } = useMergedInfiniteQueries({
        firstQuery: profileVideoContentQuery,
        secondQuery: profilePostContentQuery,
        incrementLoadAmount: 50,
        initialItemsAmount: 50,
    })

    const videosState = match(profileVideoContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const postsState = match(profilePostContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const contentState =
        videosState === "Loaded" && postsState === "Loaded"
            ? "Loaded"
            : videosState === "Failed" || postsState === "Failed"
              ? "Failed"
              : videosState === "Loading" || postsState === "Loading"
                ? "Loading"
                : "Unloaded"

    return pipe(
        model.followingState,
        O.fromPredicate(FollowStateAdt.is.Following),
        O.fold(
            () => <FollowCTA followCTA={model.text.followCTA} />,
            () =>
                pipe(
                    videosState,
                    O.fromPredicate(state => state === "Loaded"),
                    O.chain(O.fromPredicate(() => data.length === 0)),
                    O.fold(
                        () => (
                            <div className={styles.postContent}>
                                <InfiniteScroll
                                    state={contentState}
                                    disabled={
                                        !profilePostContentQuery.hasNextPage &&
                                        !profileVideoContentQuery.hasNextPage
                                    }
                                    threshold="100px"
                                    onLoadRequested={fetchNext}
                                >
                                    {pipe(
                                        data,
                                        A.map(post =>
                                            pipe(
                                                post,
                                                O.fromPredicate(isVideoArea),
                                                O.fold(
                                                    () =>
                                                        pipe(
                                                            post,
                                                            O.fromPredicate(
                                                                isPostitArea,
                                                            ),
                                                            O.fold(
                                                                () => <></>,
                                                                postit => (
                                                                    <PostAreaTile
                                                                        key={
                                                                            postit
                                                                                .post
                                                                                .id
                                                                        }
                                                                        area={
                                                                            postit
                                                                        }
                                                                        onClicked={() =>
                                                                            model.onPostSelected(
                                                                                postit
                                                                                    .post
                                                                                    .id,
                                                                            )
                                                                        }
                                                                        onUserClicked={() => {}}
                                                                    />
                                                                ),
                                                            ),
                                                        ),
                                                    videoArea => (
                                                        <VideoAreaTile
                                                            key={
                                                                videoArea.video
                                                                    .id
                                                            }
                                                            videoArea={
                                                                videoArea
                                                            }
                                                            onUserClicked={() => {}}
                                                            onClicked={() =>
                                                                model.onVideoSelected(
                                                                    videoArea.video,
                                                                )
                                                            }
                                                        />
                                                    ),
                                                ),
                                            ),
                                        ),
                                    )}
                                    {contentState === "Loading" &&
                                        fill(3, idx => (
                                            <PostLoadingTile key={idx} />
                                        ))}
                                </InfiniteScroll>
                            </div>
                        ),
                        () => (
                            <EmptyContent videoEmpty={model.text.videoEmpty} />
                        ),
                    ),
                ),
        ),
    )
}

const FollowCTA = (
    model: Pick<UserProfilePagePostContentModel["text"], "followCTA">,
) => (
    <InfoBox>
        <p
            className={styles.infoBoxTitle}
            style={{ fontSize: vars.font.size.m }}
        >
            {model.followCTA.title}
        </p>
        <p
            className={styles.infoBoxSubtitle}
            style={{ fontSize: vars.font.size.regular }}
        >
            {model.followCTA.subtitle}
        </p>
    </InfoBox>
)

const EmptyContent = (
    model: Pick<UserProfilePagePostContentModel["text"], "videoEmpty">,
) => (
    <InfoBox>
        <p
            className={styles.infoBoxTitle}
            style={{ fontSize: vars.font.size.m }}
        >
            {model.videoEmpty}
        </p>
    </InfoBox>
)

const InfoBox: FC<PropsWithChildren> = ({ children }) => (
    <div className={styles.infoBox}>
        <div className={styles.infoBoxText}>{children}</div>
    </div>
)
