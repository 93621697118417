import { getIsAuthorizedAccount } from "api/api-utils"
import { EnableNotificationsModal } from "components/modals/enable-notifications-modal"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { LocalStorage } from "local-storage"
import { FC, useMemo, useState } from "react"
import { useCreateNotificationSubscriptionMutation } from "./hooks/use-create-notification-subscription-mutation"

import {
    BrowserPushInfoAdt,
    NotificationPermissionStateAdt,
    getCurrentRegistration as getCurrentServiceWorkerRegistration,
    getNotificationPermission,
    requestNotificationPermission,
    subscribeUserToPush,
} from "features/notifications/notification"
import { useMe } from "hooks/use-me"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useAppStore } from "store"

export const EnableNotificationsProvider: FC = () => {
    const open = useAppStore(store => store.open)
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { data: myUserProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const [enableNotificationsModalOpen, setEnableNotificationsModalOpen] =
        useState(
            pipe(
                getNotificationPermission(),
                NotificationPermissionStateAdt.is.Default,
            ),
        )

    const { mutate: createNotificationSubscription } =
        useCreateNotificationSubscriptionMutation()

    return (
        <EnableNotificationsModal
            isOpen={enableNotificationsModalOpen && isAuthorizedAccount}
            onClosed={() => setEnableNotificationsModalOpen(false)}
            onSubmit={async () => {
                if (!isAuthorizedAccount) {
                    open("CreateAccount")
                    return
                }
                const swRegistration =
                    await getCurrentServiceWorkerRegistration()
                if (!swRegistration) return
                if (!myUserProfileId) return
                setEnableNotificationsModalOpen(false)
                const permission = await requestNotificationPermission()

                NotificationPermissionStateAdt.match(
                    {
                        PermissionGranted: async () => {
                            const subscriptionResult =
                                await subscribeUserToPush(swRegistration)
                            BrowserPushInfoAdt.match({
                                SubscriptionSuccess: async ({ data }) => {
                                    //? wait until created so refetch of subscription state would actually
                                    //? show the state on server
                                    //TODO: make it a task
                                    createNotificationSubscription({
                                        profileId: myUserProfileId,
                                        pushSubscription: data,
                                    })
                                },
                                SubscriptionError: () => {},
                            })(subscriptionResult)
                        },
                        PermissionDenied: () => {},
                    },
                    () => {},
                )(permission)
            }}
            //todo: Locize
            text={{
                cancel: "Maybe later",
                submit: "Allow notifications",
                title: "Enable notifications",
                description: `By enabling push notifications, you'll stay informed about new followers, recent content, and reactions to your posts. Receive instant notifications to stay up-to-date and never miss important interactions.`,
            }}
        />
    )
}
