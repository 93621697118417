import { FC } from "react"

import { Url } from "../../api/branded-types"
import { VisibilityAdt } from "../../data-flow/common"

import { pipe } from "fp-ts/function"
import { vars } from "theme/variables.css"
import { UserMessage } from "../../api/api-models"
import { Avatar } from "../../components/avatars/avatar"
import { FoldableStatefulText } from "../../components/texts/foldable-stateful-text"
import { messageTimeInfo } from "./time-format"
import * as styles from "./user-message-item.css"

type Props = {
    showTime: boolean
    imageUrl: Url
    message: UserMessage
    onAvatarClicked?: () => void
}

export const UserMessageItem: FC<Props> = ({
    showTime,
    imageUrl,
    message,
    onAvatarClicked,
}) => (
    <div className={styles.message}>
        <Avatar
            size={36}
            className={styles.avatar}
            src={imageUrl}
            onClicked={onAvatarClicked}
        />

        <div className={styles.messageBox}>
            {showTime && (
                <p
                    style={{
                        margin: "0 16px",
                        color: vars.color.tertiary.hex,
                        fontSize: vars.font.size.xs,
                    }}
                >
                    {pipe(message.createdAt, messageTimeInfo)}
                </p>
            )}

            <FoldableStatefulText
                className={styles.text}
                visibility={VisibilityAdt.of.Invisible({})}
                maxLength={750}
                text={message.text.trim()}
                fontSize={vars.font.size.regular}
            />
        </div>
    </div>
)
