import { Progress, ResourceRef } from "api/api-models"
import { ProfileChallengeId } from "api/branded-types"
import { create, StateCreator } from "zustand"

type FirstStepsCompleteModalState = {
    title: string
    description: string
    icon: ResourceRef
}

type ChallengeCompleteModalState = {
    title: string
    description: string
    icon: ResourceRef
    points: number
    badgeName: string
}

type CreateContentModalState = {
    title: string
    description: string
    icon: ResourceRef
    challengeId: ProfileChallengeId
    points: number
    progress: Progress
}

type State = {
    // Tiers
    levelUpModalOpen: boolean
    levelDownModalOpen: boolean

    //Challenge
    createContentCompleteModalOpen: boolean
    createContentCompleteModalState: CreateContentModalState | null

    //Challenge groups
    challengeCompleteModalOpen: boolean
    challengeCompleteModalState: ChallengeCompleteModalState | null
    firstStepsModalOpen: boolean
    firstStepsModalState: FirstStepsCompleteModalState | null

    // repeated task type, add impl. when discuss with backend
    dailyStreakModalOpen: boolean
}

type Actions = {
    setLevelUpModalOpen: (v: State["levelUpModalOpen"]) => void
    setLevelDownModalOpen: (v: State["levelDownModalOpen"]) => void

    setCreateContentCompleteModalOpen: (
        v: State["createContentCompleteModalOpen"],
    ) => void
    setCreateContentCompleteModalState: (
        v: State["createContentCompleteModalState"],
    ) => void

    setChallengeCompleteModalOpen: (
        v: State["challengeCompleteModalOpen"],
    ) => void
    setChallengeCompleteModalState: (
        v: State["challengeCompleteModalState"],
    ) => void
    setFirstStepsModalOpen: (v: State["firstStepsModalOpen"]) => void
    setFirstStepState: (v: State["firstStepsModalState"]) => void
    setDailyStreakModalOpen: (v: State["dailyStreakModalOpen"]) => void
}

const createPointSystemModalStore: StateCreator<State & Actions> = set => ({
    levelDownModalOpen: false,
    levelUpModalOpen: false,

    createContentCompleteModalOpen: false,
    createContentCompleteModalState: null,

    challengeCompleteModalOpen: false,
    challengeCompleteModalState: null,
    firstStepsModalOpen: false,
    firstStepsModalState: null,

    dailyStreakModalOpen: false,

    setLevelDownModalOpen: v => set({ levelDownModalOpen: v }),
    setLevelUpModalOpen: v => set({ levelUpModalOpen: v }),

    setCreateContentCompleteModalOpen: v =>
        set({ createContentCompleteModalOpen: v }),
    setCreateContentCompleteModalState: v =>
        set({ createContentCompleteModalState: v }),

    setChallengeCompleteModalOpen: v => set({ challengeCompleteModalOpen: v }),
    setChallengeCompleteModalState: v =>
        set({ challengeCompleteModalState: v }),
    setFirstStepsModalOpen: v => set({ firstStepsModalOpen: v }),
    setFirstStepState: v => set({ firstStepsModalState: v }),

    setDailyStreakModalOpen: v => set({ dailyStreakModalOpen: v }),
})

export const usePointSystemModalStore = create(createPointSystemModalStore)
