import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { useEffect } from "react"

import { useToast } from "../components/controls/use-toast"
import { getNavigateUrl } from "../features/notifications/notification"
import { usePushNotification } from "./use-push-notifications"

import { LazyLoadImage } from "react-lazy-load-image-component"
import { useNavigate } from "react-router-dom"
import { vars } from "theme/variables.css"
import * as styles from "./in-app-toast-notification.css"

export const useInAppToastNotifications = () => {
    const navigate = useNavigate()
    const { notification } = usePushNotification()
    const { toast, dismiss, toasts } = useToast()

    useEffect(
        () => {
            pipe(
                notification,
                O.fromNullable,
                O.map(notification => {
                    pipe(
                        toasts,
                        A.map(toast => dismiss(toast.id)),
                    )

                    // Create a new toast
                    const { id } = toast({
                        toastContent: (
                            <NotificationToastContent
                                onClicked={() => {
                                    dismiss(id)
                                    pipe(
                                        notification.clickAction,
                                        getNavigateUrl,
                                        O.fromNullable,
                                        O.filter(
                                            path => typeof path === "string",
                                        ),
                                        O.map(path => navigate(path)),
                                    )
                                }}
                                image={notification.icon}
                                title={notification.title}
                                message={notification.message}
                            />
                        ),
                    })
                }),
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notification],
    )
}

type NotificationToastContentModel = {
    onClicked: () => void
    image: string
    title: string
    message: string
}

const NotificationToastContent = ({
    image,
    message,
    title,
    onClicked,
}: NotificationToastContentModel) => (
    <div onClick={onClicked} className={styles.notificationContainer}>
        <LazyLoadImage
            width={64}
            height={64}
            src={`${image}?height=128`}
            className={styles.notificationImage}
        />

        <div className={styles.textContent}>
            <p
                className={styles.title}
                style={{
                    fontSize: vars.font.size.regular,
                }}
            >
                {title}
            </p>
            <p
                className={styles.description}
                style={{
                    fontSize: vars.font.size.regular,
                }}
            >
                {message}
            </p>
        </div>
    </div>
)
