import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CommentedResourceRef } from "../../../api/api-models"
import { UserProfileId, VideoId } from "../../../api/branded-types"
import { userCommentCreatorClient } from "../../../api/clients/comment-api-client"
import { QueryKeys } from "../../../api/query-keys"

type UsePostCommentMutationParams = {
    resourceOwnerId: UserProfileId
    commentedResource: CommentedResourceRef
    text: string
}

export const usePostCommentMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({
            resourceOwnerId,
            commentedResource,
            text,
        }: UsePostCommentMutationParams) =>
            userCommentCreatorClient.postComment({
                resourceOwnerId,
                resource: commentedResource,
                text,
            }),
        onSettled: (_data, _err, { commentedResource }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.resourceComments(
                    VideoId.parse(commentedResource.contentId),
                ),
            })
        },
    })
}
