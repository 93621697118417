import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import { FC } from "react"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "common/dialog"
import { Image } from "common/image"
import { useAsset } from "hooks/use-asset"
import { useNavigate } from "react-router-dom"
import { usePointSystemModalStore } from "./store/point-system-modal-store"

import { ParticleAnimation } from "./particles-animation/particles-animation"

import { vars } from "theme/variables.css"
import * as styles from "./challenge-complete-modal.css"

type ChallengeCompleteModalModel = {
    isOpen: boolean
    onOpenChange: (v: boolean) => void
}

export const ChallgengeCompleteModal: FC<
    ChallengeCompleteModalModel
> = model => {
    const challengeCompleteModalState = usePointSystemModalStore(
        store => store.challengeCompleteModalState,
    )

    const navigate = useNavigate()

    const assetQuery = useAsset({
        resource: challengeCompleteModalState?.icon,
    })

    return (
        <Dialog open={model.isOpen} onOpenChange={model.onOpenChange}>
            {model.isOpen && <ParticleAnimation />}
            <DialogContent className={styles.content}>
                <div>
                    <div className={styles.iconWrapper}>
                        <div className={styles.iconContainer}>
                            <div>
                                <Image
                                    color={vars.color.medium.hex}
                                    imageType={
                                        assetQuery.isSuccess &&
                                        assetQuery.data.type === "Image"
                                            ? assetQuery.data.imageType
                                            : "Png"
                                    }
                                    width={
                                        assetQuery.isSuccess &&
                                        assetQuery.data.type === "Image"
                                            ? assetQuery.data.resolution.width
                                            : 28
                                    }
                                    height={
                                        assetQuery.isSuccess &&
                                        assetQuery.data.type === "Image"
                                            ? assetQuery.data.resolution.height
                                            : 28
                                    }
                                    className={styles.icon}
                                    src={
                                        assetQuery.isSuccess
                                            ? assetQuery.data.source
                                            : undefined
                                    }
                                />
                            </div>
                            <FontAwesomeIcon
                                className={styles.hornIcon}
                                icon={faPartyHorn}
                            />
                        </div>
                    </div>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xl,
                                margin: 0,
                                fontWeight: 500,
                            }}
                        >
                            {challengeCompleteModalState?.title}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {challengeCompleteModalState?.description}
                        </DialogDescription>
                    </div>
                    <div className={styles.rewardContainer}>
                        <p className={styles.rewardText}>
                            +{challengeCompleteModalState?.points} Points
                        </p>
                    </div>
                    <div className={styles.additionalInfoContainer}>
                        <p
                            style={{
                                fontWeight: 700,
                                fontSize: vars.font.size.regular,
                                margin: 0,
                            }}
                        >
                            Badge Unlocked
                        </p>
                        <p
                            style={{
                                fontWeight: 400,
                                fontSize: vars.font.size.regular,
                                margin: 0,
                            }}
                        >
                            You’ve unlocked the
                            {challengeCompleteModalState?.badgeName}! Keep
                            posting and move on to the next challenge to earn
                            more rewards.
                        </p>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <IonButton
                            className={styles.button}
                            color="medium"
                            onClick={() => {
                                model.onOpenChange(false)
                                navigate(
                                    "/app/settings/rewards?currentTab=get-started",
                                )
                                //todo: define what path this button should navigate to
                            }}
                        >
                            Next challenges
                        </IonButton>
                        <IonButton
                            onClick={() => {
                                model.onOpenChange(false)
                            }}
                            className={styles.ghostButton}
                        >
                            Close
                        </IonButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
