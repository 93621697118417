import { FC } from "react"

import { useAppStore } from "store"
import { FindOutMoreModal } from "./find-out-more-modal"

export const OnboardingProvider: FC = () => {
    const open = useAppStore(store => store.open)
    const close = useAppStore(store => store.close)
    const isModalVisible = useAppStore(store => store.isModalVisible)

    return (
        <FindOutMoreModal
            isOpen={isModalVisible.FindOutMore}
            onOpenChange={isOpen =>
                !isOpen ? close("FindOutMore") : open("FindOutMore")
            }
        />
    )
}
