import { faPaperPlaneTop, faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonModal,
    IonSkeletonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from "@ionic/react"
import { InfiniteData } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { FC, useMemo, useRef, useState } from "react"
import { match } from "ts-pattern"

import { CommentedResourceRef, Pagination } from "../../api/api-models"
import { UserProfileId } from "../../api/branded-types"
import { CommentWithUserData } from "../../data-flow/comment"
import { usePostCommentMutation } from "../../features/video/hooks/use-post-comment-mutation"
import { useResourceComments } from "../../features/video/hooks/use-resource-comments"
import { fill } from "../../utils/array"
import { getEventDetailValue } from "../../utils/fp"
import { UserCommentItem } from "./user-comment-item"

import classNames from "classnames"
import { vars } from "theme/variables.css"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { useIOSKeyboardAdaptations } from "../../hooks/use-ios-keyboard-adaptations"
import * as styles from "./comment-overview-modal.css"

type TextModel = {
    title: (value: number) => string
    inputPlaceholder: string
}

export type InfiniteCommentsAreasData = InfiniteData<{
    commentsWithUserData: CommentWithUserData[]
    paging: Pagination
    total: number
}>

export type CommentOverviewPageModel = {
    text: TextModel
    isOpen: boolean
    commentResourceRef: O.Option<CommentedResourceRef>
    resourceOwnerId?: UserProfileId
    onClosed: () => void
    onUserClicked: (id: UserProfileId) => void
}
//TODO: implement using `Drawer`
export const CommentOverviewModal: FC<CommentOverviewPageModel> = ({
    text,
    isOpen,
    commentResourceRef,
    resourceOwnerId,
    onClosed,
    onUserClicked,
}) => {
    const modal = useRef<HTMLIonModalElement>(null)

    const videoCommentsQuery = useResourceComments(
        O.isSome(commentResourceRef) ? commentResourceRef.value : undefined,
    )

    const commentsState = match(videoCommentsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const commentsWithUserData = useMemo(
        () =>
            pipe(
                videoCommentsQuery.data?.pages ?? [],
                A.flatMap(page => page.commentsWithUserData),
            ),
        [videoCommentsQuery.data?.pages],
    )

    const total = videoCommentsQuery.data?.pages.at(0)?.total ?? 0

    return (
        <IonModal
            ref={modal}
            className={styles.modal}
            canDismiss
            isOpen={modal.current !== undefined && isOpen}
            onDidDismiss={onClosed}
            initialBreakpoint={1}
            breakpoints={[0, 1]}
            animated
            backdropDismiss
            showBackdrop
        >
            <IonHeader className="ion-no-border">
                <IonToolbar className={styles.toolbar}>
                    <IonButtons>
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={onClosed}
                            className={styles.closeIcon}
                        />
                    </IonButtons>
                    <IonTitle className={styles.scrolledToolbarTitle}>
                        {commentsState === "Loading" && (
                            <IonSkeletonText
                                animated
                                className={styles.loadingTitle}
                            />
                        )}
                        {commentsState === "Loaded" &&
                        commentsWithUserData.length > 0
                            ? text.title(total)
                            : "No comments yet"}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar className={styles.titleToolbar}>
                        <div className={styles.titleContainer}>
                            {commentsState === "Loading" && (
                                <IonSkeletonText
                                    animated
                                    className={styles.loadingTitle}
                                />
                            )}
                            {commentsState === "Loaded" &&
                                commentsWithUserData.length > 0 && (
                                    <h1 className={styles.title}>
                                        {text.title(total)}
                                    </h1>
                                )}
                            {commentsState === "Loaded" &&
                                commentsWithUserData.length === 0 && (
                                    <h1 className={styles.title}>
                                        No comments yet
                                    </h1>
                                )}
                        </div>
                    </IonToolbar>
                </IonHeader>
                <InfiniteScroll
                    disabled={!videoCommentsQuery.hasNextPage}
                    state={commentsState}
                    onLoadRequested={videoCommentsQuery.fetchNextPage}
                    threshold="100px"
                >
                    <div className={styles.content}>
                        {videoCommentsQuery.isSuccess &&
                            pipe(
                                commentsWithUserData,
                                A.map(({ userComment, userProfile }) => (
                                    <UserCommentItem
                                        key={userComment.id}
                                        comment={userComment}
                                        profile={userProfile}
                                        onUserClicked={id => {
                                            onClosed()
                                            onUserClicked(id)
                                        }}
                                    />
                                )),
                            )}

                        {commentsState === "Loading" &&
                            fill(5, i => <UserCommentLoadingItem key={i} />)}

                        {commentsState === "Loaded" &&
                            commentsWithUserData.length === 0 && (
                                <EmptyCommentsCard />
                            )}
                    </div>
                </InfiniteScroll>
            </IonContent>
            <ModalToolbar
                resourceOwnerId={resourceOwnerId}
                commentResourceRef={commentResourceRef}
                commentsState={commentsState}
                text={text}
            />
        </IonModal>
    )
}

type ModalToolbarModel = {
    resourceOwnerId?: UserProfileId
    text: CommentOverviewPageModel["text"]
    commentResourceRef: CommentOverviewPageModel["commentResourceRef"]
    commentsState: "Loading" | "Loaded" | "Failed" | "Unloaded"
}

const ModalToolbar: FC<ModalToolbarModel> = ({
    commentResourceRef,
    commentsState,
    text,
    resourceOwnerId,
}) => {
    const fakeInputRef = useRef<HTMLDivElement>(null)
    const realInputRef = useRef<HTMLIonTextareaElement>(null)
    const resizeElementRef = useRef<HTMLIonToolbarElement>(null)

    useIOSKeyboardAdaptations({
        realInputRef,
        fakeInputRef,
        resizeElementRef,
    })
    const [comment, setComment] = useState("")
    const [isEditingText, setIsEditingText] = useState(false)

    const { mutate: postComment } = usePostCommentMutation()
    return (
        <IonToolbar
            ref={resizeElementRef}
            className={classNames(
                styles.bottomToolbar,
                isEditingText ? styles.bottomToolbarActive : "",
            )}
        >
            <div className={styles.inputWrapper}>
                <IonItem className={styles.inputBox}>
                    <div ref={fakeInputRef} className={styles.fakeInput}>
                        {comment ? (
                            <p
                                style={{
                                    fontSize: vars.font.size.m,
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {comment}
                            </p>
                        ) : (
                            <p
                                style={{
                                    fontSize: vars.font.size.m,
                                }}
                                className={styles.fakeInputPlaceholder}
                            >
                                {text.inputPlaceholder}
                            </p>
                        )}
                    </div>
                    <IonTextarea
                        ref={realInputRef}
                        className={styles.input}
                        autoGrow
                        placeholder={text.inputPlaceholder}
                        value={comment}
                        onIonFocus={() => setIsEditingText(true)}
                        onIonBlur={() => setIsEditingText(false)}
                        maxlength={100000}
                        rows={1}
                        onIonInput={flow(
                            getEventDetailValue,
                            O.map(setComment),
                        )}
                    />
                </IonItem>
                <IonButtons className={styles.inputButtons}>
                    {(isEditingText || comment.length > 0) &&
                        pipe(
                            commentsState,
                            O.fromPredicate(state => state === "Loaded"),
                            O.chain(O.fromPredicate(() => comment.length > 0)),
                            O.fold(
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        color={vars.color.tertiary.hex}
                                    />
                                ),
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        color={vars.color.dark.hex}
                                        onClick={() => {
                                            if (!resourceOwnerId) return

                                            pipe(
                                                commentResourceRef,
                                                O.fold(
                                                    () => {},
                                                    commentedResource =>
                                                        postComment({
                                                            resourceOwnerId,
                                                            commentedResource,
                                                            text: comment.trim(),
                                                        }),
                                                ),
                                            )
                                            setComment("")
                                        }}
                                    />
                                ),
                            ),
                        )}
                </IonButtons>
            </div>
        </IonToolbar>
    )
}

const UserCommentLoadingItem: FC = () => (
    <div className={styles.loadingCommentContainer}>
        <IonSkeletonText animated className={styles.loadingCommentAvatar} />
        <IonSkeletonText animated className={styles.loadingCommentContent} />
    </div>
)
//TODO: localize
const EmptyCommentsCard = () => (
    <div className={styles.emptyCommentsCardContainer}>
        <p className={styles.emptyText}>Be the first to share your thoughts!</p>
        <p
            className={styles.emptyText}
            style={{
                fontSize: vars.font.size.regular,
            }}
        >
            Drop a comment and let us know what you think about.
        </p>
    </div>
)
