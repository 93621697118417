import { useMutation, useQueryClient } from "@tanstack/react-query"

import { UserProfileId } from "../../../api/branded-types"
import { userProfileCreatorClient } from "../../../api/clients/user-profile-api-client"
import { QueryKeys } from "../../../api/query-keys"

type MutationFunctionParams = {
    myProfileId: UserProfileId
    profileId: UserProfileId
}

export const useFollowUserProfileMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        //TODO: invalidate followers/following pages contents
        mutationFn: (params: MutationFunctionParams) =>
            userProfileCreatorClient.followUserProfile(
                { profileId: params.myProfileId },
                {
                    params: {
                        id: params.profileId,
                    },
                },
            ),
        onSettled: (_data, _err, { myProfileId, profileId }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileStatistics(myProfileId),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileStatistics(profileId),
            })
        },
    })
}
