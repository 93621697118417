import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import { flow, pipe } from "fp-ts/function"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ShareContentId, UserProfileId } from "../../api/branded-types"
import { createShareTokenTask } from "../../api/clients/auth-api-client"
import { listFollowersOfUserProfileIdTask } from "../../api/clients/user-profile-api-client"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { ShareOverviewAlertModal } from "../../components/modals/share-overview-alert-modal"
import { VisibilityAdt } from "../../data-flow/common"
import { FollowStateAdt } from "../../data-flow/follower"
import { createShareUrl } from "../../data-flow/sharing"
import { useMe } from "../../hooks/use-me"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import {
    useIsAlreadySubscribing,
    useSubscribeUserProfile,
} from "../../hooks/use-subscribers"
import { LocalStorage } from "../../local-storage"
import { isDefined, isNotDefined } from "../../utils/object"
import { ShareWelcomeModal } from "../registration/share-welcome-modal"
import { postByIdRoute, videoByIdRoute } from "../routing/routing"
import {
    ProfileDescription,
    ProfileDescriptionLoading,
} from "./components/profile-description"
import {
    ProfileStatistics,
    ProfileStatisticsLoading,
} from "./components/profile-statistics"
import { UserProfilePageActions } from "./components/user-profile-page-actions"
import { UserProfilePagePostContent } from "./components/user-profile-page-post-content"
import { UserProfilePageStatus } from "./components/user-profile-page-status"
import { UserProfileToolbar } from "./components/user-profile-toolbar"
import { UserProfilePageFollowButton } from "./following/user-profile-page-follow-button"
import { useFollowUserProfileMutation } from "./hooks/use-follow-user-profile-mutation"
import { useUnfollowUserProfileMutation } from "./hooks/use-unfollow-user-profile-mutation"
import {
    SubscribeButton,
    SubscriptionStateAdt,
} from "./subscription/subscribe-button"

import { shareLinkClient } from "api/clients/share-link-api-client"
import { Loading } from "common/loading"
import { NewFeatureComingSoonModal } from "components/modals/new-feature-coming-soon-modal"
import { useReportStore } from "features/report/report-store"
import { useInView } from "framer-motion"
import { useUserProfile } from "hooks/use-user-profile"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Skeleton from "react-loading-skeleton"
import { useNavigate, useParams } from "react-router-dom"
import { useAppStore } from "store"
import {
    getIsAuthorizedAccount,
    withAuthorizedAccess,
} from "../../api/api-utils"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { TabsListLoading } from "../../components/tabs/tabs"
import { getSafeArea } from "../../utils/constant"
import { PostLoadingTile } from "../search/videos/post-loading-tile"
import { ProfileDataLoading } from "./components/my-user-profile-data"
import { ProfileActionsModal } from "./components/profile-actions-modal"
import * as styles from "./user-profile-page.css"

export const UserProfilePage: FC = () => {
    const open = useAppStore(store => store.open)
    const [isNewFeatureComingSoonOpen, setNewFeatureComingSoonOpen] =
        useState(false)
    const setReportUserOpen = useReportStore(store => store.setReportUserOpen)
    const navigate = useNavigate()
    const { t } = useTranslation(["user"])
    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)

    const { t: tUser } = useTranslation(["user"])

    const { mutate: onFollowed } = useFollowUserProfileMutation()
    const { mutate: onUnfollowed } = useUnfollowUserProfileMutation()

    const {
        onSubscribe: {
            // mutate: subscribe,
            isSuccess: isSubscribed,
            error: subscribeFailed,
        },
        onUnsubscribe: {
            mutate: unsubscribe,
            isSuccess: isUnsubscribed,
            error: unsubscribeFailed,
        },
    } = useSubscribeUserProfile()

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const imageRef = useRef(null)
    const isInView = useInView(imageRef, {
        margin: `${window.innerWidth * -0.2 - getSafeArea("top")}px`,
    })

    const [followingState, setFollowingState] = useState(
        FollowStateAdt.of.LoadingFollow({}),
    )
    const [profileActionsModalOpen, setProfileActionsModalOpen] =
        useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const [shareUrl, setShareUrl] = useState<string>()
    const [welcomeModalVisibility, setWelcomeModalVisibility] = useState(
        accountType === "Guest"
            ? VisibilityAdt.of.Visible({})
            : VisibilityAdt.of.Invisible({}),
    )
    const { id } = useParams()
    const profileId = UserProfileId.parse(id)
    const [subscriptionSelectorVisibility, setSubscriptionSelectorVisibility] =
        useState(VisibilityAdt.of.Invisible({}))

    const isSubscribingPageQuery = useIsAlreadySubscribing({
        myId: myProfileId,
        idToSubscribe: profileId,
    })

    const [subscriptionState, setSubscriptionState] = useState(
        SubscriptionStateAdt.of.Loading({}),
    )

    useEffect(() => {
        if (!myProfileId) {
            setSubscriptionState(
                SubscriptionStateAdt.as.NotPossibleSubscription({}),
            )
            return
        }
        if (isSubscribingPageQuery.isLoading) return
        if (isNotDefined(isSubscribingPageQuery.data)) return

        setSubscriptionState(
            isSubscribingPageQuery.data
                ? SubscriptionStateAdt.as.ActiveSubscription({})
                : SubscriptionStateAdt.as.NoSubscription,
        )
    }, [
        myProfileId,
        isSubscribingPageQuery.data,
        isSubscribingPageQuery.isLoading,
    ])

    const userProfileQuery = useUserProfile(profileId)

    useEffect(() => {
        if (!profileId) {
            setFollowingState(FollowStateAdt.as.NotPossibleFollow({}))
            return
        }
        if (isNotDefined(myProfileId)) {
            setFollowingState(FollowStateAdt.as.NotPossibleFollow({}))
            return
        }

        setFollowingState(FollowStateAdt.as.LoadingFollow({}))

        pipe(
            listFollowersOfUserProfileIdTask(
                { filter: `{$match:{id: in ["${myProfileId}"]}}` },
                { params: { id: profileId } },
            ),
            TE.map(({ data }) =>
                pipe(
                    data,
                    A.findFirst(x => x.id === myProfileId),
                    O.fold(
                        () =>
                            setFollowingState(
                                FollowStateAdt.as.IgnoringFollow({}),
                            ),
                        () =>
                            setFollowingState(FollowStateAdt.as.Following({})),
                    ),
                ),
            ),
        )()
    }, [profileId, myProfileId])

    const shareMyProfile = useCallback(() => {
        if (!isAuthorizedAccount) {
            open("CreateAccount")
            return
        }

        if (!profileId) return
        setIsShareLinkInCreation(true)

        pipe(
            createShareTokenTask(
                {
                    contents: [
                        {
                            contentType: "UserProfile",
                            contentFilter: ShareContentId.parse(profileId),
                        },
                    ],
                },
                {},
            ),
            TE.bimap(
                error => {
                    setIsShareLinkInCreation(false)
                    console.error("Error share", error)
                },
                ({ data }) => {
                    shareLinkClient
                        .createShortenedLink({
                            link: createShareUrl(data),
                        })
                        .then(({ shortURL }) => {
                            setShareUrl(shortURL)
                            setShowShareModal(true)
                            setIsShareLinkInCreation(false)
                        })
                },
            ),
        )()
    }, [profileId, isAuthorizedAccount])

    useEffect(() => {
        // TODO force state refresh in useSubscribeUserProfile
        if (isDefined(subscribeFailed)) console.error("subscription failed")

        if (isSubscribed)
            setSubscriptionState(SubscriptionStateAdt.as.ActiveSubscription({}))
    }, [isSubscribed, subscribeFailed])

    useEffect(() => {
        // TODO force state refresh in useSubscribeUserProfile
        if (isDefined(unsubscribeFailed)) console.error("subscription failed")

        if (isUnsubscribed)
            setSubscriptionState(SubscriptionStateAdt.as.NoSubscription({}))
    }, [isUnsubscribed, unsubscribeFailed])

    return (
        <Page className={styles.page}>
            <NavigationBackButton />
            <UserProfileToolbar profileId={profileId} isVisible={isInView} />
            <Loading open={isShareLinkInCreation} message="Loading..." />

            {isInView && <div className={styles.overlay} />}

            <UserProfilePageActions
                onActionsClicked={() => setProfileActionsModalOpen(true)}
                onShareClicked={shareMyProfile}
                onMessageClicked={() => {
                    if (!isAuthorizedAccount) {
                        open("CreateAccount")
                        return
                    }
                    if (!profileId) return

                    navigate(`/app/message/profile/${profileId}`)
                }}
            />

            <Content fullscreen>
                <ShareWelcomeModal
                    isOpen={VisibilityAdt.is.Visible(welcomeModalVisibility)}
                    profileId={profileId}
                    text={{
                        title: name => `join ${name} on Seemee`,
                        message:
                            "Become part of the community that directly connects fans and creators.",
                        onSignIn: "Log in to Seemee",
                        onSignUp: "Sign up",
                    }}
                    onDismissed={() =>
                        setWelcomeModalVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                />
                <NewFeatureComingSoonModal
                    isOpen={isNewFeatureComingSoonOpen}
                    onClosed={() => setNewFeatureComingSoonOpen(false)}
                    text={{
                        title: tUser(
                            "myUserProfile.newFeatureComingSoon.title",
                        ),
                        description: tUser(
                            "myUserProfile.newFeatureComingSoon.description",
                        ),
                    }}
                />

                <ProfileActionsModal
                    isOpen={profileActionsModalOpen}
                    dismiss={() => setProfileActionsModalOpen(false)}
                    onReportClicked={() => {
                        setProfileActionsModalOpen(false)
                        setReportUserOpen({ open: true, profileId })
                    }}
                    text={{
                        report: "User report",
                    }}
                />

                <ShareOverviewAlertModal
                    isOpen={showShareModal}
                    title="Share profile"
                    onClosed={() => setShowShareModal(false)}
                    shareUrl={O.fromNullable(shareUrl)}
                />

                {/* show subscription after review, discussion and payment integration */}
                <NewFeatureComingSoonModal
                    isOpen={VisibilityAdt.is.Visible(
                        subscriptionSelectorVisibility,
                    )}
                    onClosed={() =>
                        setSubscriptionSelectorVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                    text={{
                        title: tUser(
                            "myUserProfile.newFeatureComingSoon.title",
                        ),
                        description: tUser(
                            "myUserProfile.newFeatureComingSoon.description",
                        ),
                    }}
                />

                {/* {userProfileQuery.isSuccess && (
                    <SubscriptionModal
                        isOpen={VisibilityAdt.is.Visible(
                            subscriptionSelectorVisibility,
                        )}
                        text={{
                            user: {
                                info: "Subscription for",
                                description: "For €14.99/month, cancel anytime",
                            },
                            subscription: {
                                info: "Exclusive access to premium content",
                                description:
                                    "Subscribers receive exclusive access to high-quality content that is only available to subscribers.",
                                ok: "Subscribe now",
                                disclaimer:
                                    "By tapping Subscribe now, you agree to Seemee's Terms of Service and Privacy Policy. This Seemee subscription automatically renews monthly, and you will be notified in advance if the monthly amount increases. Cancel anytime in your subscription settings.",
                            },
                        }}
                        user={{
                            imageUrl: userProfileQuery.data.imageUrl,
                            isLive: userProfileQuery.data.isLive,
                            name:
                                userProfileQuery.data.displayName ??
                                userProfileQuery.data.profileName,
                        }}
                        onCanceled={() =>
                            setSubscriptionSelectorVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                        }
                        onConfirmed={() => {
                            if (!myProfileId) return
                            if (!profileId) return

                            subscribe({
                                idToSubscribe: profileId,
                                myId: myProfileId,
                            })

                            setSubscriptionSelectorVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                        }}
                    />
                )} */}

                <div ref={imageRef}>
                    <LazyLoadImage
                        width={window.innerWidth}
                        height={window.innerWidth}
                        className={styles.profileImage}
                        src={
                            userProfileQuery.isSuccess
                                ? `${userProfileQuery.data.imageUrl}?width=${window.innerWidth * 2}`
                                : ""
                        }
                    />
                </div>

                <div className={styles.content}>
                    <div className={styles.wrapper}>
                        <UserProfilePageStatus
                            profileId={profileId}
                            text={{
                                online: t("userProfile.online.text"),
                                offline: t("userProfile.offline.text"),
                                locationPlaceholder: t(
                                    "myUserProfile.location.text",
                                ),
                                live: t("myUserProfile.live.text"),
                            }}
                        />

                        <ProfileStatistics
                            id={profileId}
                            text={{
                                posts: t("userProfile.posts.text"),
                                followers: t("userProfile.followers.text"),
                                following: t("userProfile.following.text"),
                            }}
                            onFollowedViewClicked={id => {
                                if (!userProfileQuery.isSuccess) return

                                navigate(`/app/followers/overview/${id}`)
                            }}
                            onFollowingViewClicked={id => {
                                if (!userProfileQuery.isSuccess) return

                                navigate(`/app/followings/overview/${id}`)
                            }}
                        />

                        <ProfileDescription
                            profile={userProfileQuery.data}
                            text={{
                                more: count => ` and ${count} more`,
                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 16,
                            }}
                        >
                            <UserProfilePageFollowButton
                                followingState={followingState}
                                text={{
                                    follow: t(
                                        "userProfile.followButton.follow",
                                    ),
                                    unfollow: t(
                                        "userProfile.followButton.unfollow",
                                    ),
                                }}
                                onNotPossibleFollowClicked={() =>
                                    open("CreateAccount")
                                }
                                onUnfollowClicked={() => {
                                    if (!isAuthorizedAccount) {
                                        open("CreateAccount")
                                        return
                                    }

                                    if (!myProfileId) return
                                    if (!userProfileQuery.isSuccess) return

                                    onUnfollowed({
                                        profileId: userProfileQuery.data.id,
                                        myProfileId,
                                    })
                                    setFollowingState(
                                        FollowStateAdt.as.IgnoringFollow({}),
                                    )
                                }}
                                onFollowClicked={() => {
                                    if (!isAuthorizedAccount) {
                                        open("CreateAccount")
                                        return
                                    }
                                    if (!myProfileId) return
                                    if (!userProfileQuery.isSuccess) return

                                    onFollowed({
                                        profileId: userProfileQuery.data.id,
                                        myProfileId,
                                    })
                                    setFollowingState(
                                        FollowStateAdt.as.Following({}),
                                    )
                                }}
                            />

                            <SubscribeButton
                                text={{
                                    subscribe: "Subscribe 14,99 €/Month",
                                    unSubscribe: "Subscribed",
                                }}
                                subscriptionState={subscriptionState}
                                onNotPossibleSubscribeClicked={() =>
                                    open("CreateAccount")
                                }
                                onSubscribeClicked={withAuthorizedAccess({
                                    onAuthorized: () =>
                                        setSubscriptionSelectorVisibility(
                                            VisibilityAdt.as.Visible({}),
                                        ),
                                    onUnauthorized: () => open("CreateAccount"),
                                    accountType,
                                })}
                                onUnSubscribeClicked={() => {
                                    if (!myProfileId) return
                                    if (!profileId) return

                                    unsubscribe({
                                        idToSubscribe: profileId,
                                        myId: myProfileId,
                                    })
                                }}
                            />
                        </div>

                        <div className={styles.divider} />

                        <UserProfilePagePostContent
                            profileId={profileId}
                            followingState={followingState}
                            onPostSelected={flow(postByIdRoute, route =>
                                navigate(route),
                            )}
                            onVideoSelected={({ id }) =>
                                pipe(id, videoByIdRoute, route =>
                                    navigate(route),
                                )
                            }
                            text={{
                                followCTA: {
                                    title: t(
                                        "userProfile.video.followCTA.title",
                                    ),
                                    subtitle: t(
                                        "userProfile.video.followCTA.subtitle",
                                    ),
                                },
                                videoEmpty: t("userProfile.video.empty"),
                            }}
                        />
                    </div>
                </div>
            </Content>
        </Page>
    )
}

export const UserProfilePageLoading: FC = () => (
    <Page className={styles.page}>
        <NavigationBackButton />
        <UserProfilePageActions
            onMessageClicked={() => {}}
            onShareClicked={() => {}}
            onActionsClicked={() => {}}
        />
        <Content fullscreen>
            <Skeleton width="100vw" height="100vw" />
            <div className={styles.content}>
                <div className={styles.wrapper}>
                    <ProfileDataLoading />
                    <ProfileStatisticsLoading />
                    <ProfileDescriptionLoading />
                    <div
                        style={{
                            marginTop: 24,
                        }}
                        className={styles.divider}
                    />
                    <div
                        style={{
                            marginTop: 24,
                        }}
                    >
                        <TabsListLoading />
                    </div>
                    <div className={styles.loadingContentContainer}>
                        <PostLoadingTile />
                        <PostLoadingTile />
                    </div>
                </div>
            </div>
        </Content>
    </Page>
)

export default UserProfilePage
