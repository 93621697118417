import { QueryClient } from "@tanstack/react-query"
import {
    Asset,
    DatabaseNotification,
    ImageAsset,
    LiveEvent,
    NotificationClickActionDocument,
    Postit,
    UserComment,
    UserProfile,
    Video,
    Vote,
} from "api/api-models"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mergeWithQueryCache,
} from "api/api-utils"
import {
    LiveEventId,
    PostitId,
    UserCommentId,
    UserProfileId,
    VideoId,
    VoteId,
} from "api/branded-types"
import { userCommentSelectorClient } from "api/clients/comment-api-client"
import { liveEventSelectorClient } from "api/clients/live-event-api-client"
import { notificationSelectorClient } from "api/clients/notification-api-client"
import { postitSelectorClient } from "api/clients/post-api-client"
import { userProfileSelectorClient } from "api/clients/user-profile-api-client"
import { videoSelectorClient } from "api/clients/video-api-client"
import { votingSelectorClient } from "api/clients/voting-api-client"
import { QueryKeys } from "api/query-keys"
import { generateDataByClickActionDocument } from "features/notifications/notification"
import * as A from "fp-ts/Array"
import { absurd, pipe } from "fp-ts/function"
import { getAssetsQueryFn } from "hooks/use-asset"
import { Except } from "type-fest"
import { isResourceRef } from "utils/asset"
import { isDefined } from "utils/object"
import { z } from "zod"

export const NOTIFICATION_CENTER_PAGINATION_AMOUNT = 50

export const getNotificationCenterItemsQueryFn = async ({
    pageParam,
    profileId,
    filter,
    queryClient,
}: {
    pageParam: number
    profileId?: UserProfileId
    filter: string
    queryClient: QueryClient
}) => {
    if (!profileId) throw new Error("profileId should be defined")

    const notifications = await notificationSelectorClient.listNotifications({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: NOTIFICATION_CENTER_PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    const notificationsWithDataIds = pipe(
        notifications.data,
        A.map(notification =>
            pipe(
                notification.pushNotification.clickAction,
                getNotificationDetailsDataIds,
                ({ dataIds, type }) => ({
                    ...notification,
                    dataIds,
                    type,
                }),
            ),
        ),
    )

    const profileIds = notificationsWithDataIds.map(n => n.senderId)
    const videoIds = notificationsWithDataIds
        .filter(n => isDefined(n.dataIds?.["videoId"]))
        .map(n => n.dataIds.videoId) as VideoId[]
    const postIds = notificationsWithDataIds
        .filter(n => isDefined(n.dataIds?.["postitId"]))
        .map(n => n.dataIds.postitId) as PostitId[]
    const liveEventIds = notificationsWithDataIds
        .filter(n => isDefined(n.dataIds?.["liveEventId"]))
        .map(n => n.dataIds.liveEventId) as LiveEventId[]
    const commentIds = notificationsWithDataIds
        .filter(n => isDefined(n.dataIds?.["commentId"]))
        .map(n => n.dataIds.commentId) as UserCommentId[]
    const voteIds = notificationsWithDataIds
        .filter(n => isDefined(n.dataIds?.["likeId"]))
        .map(n => n.dataIds.likeId) as VoteId[]

    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        profileIds,
        QueryKeys.profile,
    )
    const missingVideoIds = getQueryClientMissingIds(
        queryClient,
        videoIds,
        QueryKeys.video,
    )
    const missingPostIds = getQueryClientMissingIds(
        queryClient,
        postIds,
        QueryKeys.postit,
    )
    const missingLiveEventIds = getQueryClientMissingIds(
        queryClient,
        liveEventIds,
        QueryKeys.liveEvent,
    )
    const missingCommentIds = getQueryClientMissingIds(
        queryClient,
        commentIds,
        QueryKeys.comment,
    )
    const missingVoteIds = getQueryClientMissingIds(
        queryClient,
        voteIds,
        QueryKeys.vote,
    )

    const profilesPromise =
        missingProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("id", missingProfileIds)}}`,
              })
            : Promise.resolve({ data: [] as UserProfile[] })

    const videosPromise =
        missingVideoIds.length > 0
            ? videoSelectorClient.listVideos({
                  filter: `{${mapToMatchFilterDc("id", missingVideoIds)}}`,
              })
            : Promise.resolve({ data: [] as Video[] })

    const postsPromise =
        missingPostIds.length > 0
            ? postitSelectorClient.listPostits({
                  filter: `{${mapToMatchFilterDc("id", missingPostIds)}}`,
              })
            : Promise.resolve({ data: [] as Postit[] })

    const liveEventsPromise =
        missingLiveEventIds.length > 0
            ? liveEventSelectorClient.listLiveEvents({
                  filter: `{${mapToMatchFilterDc("id", missingLiveEventIds)}}`,
              })
            : Promise.resolve({ data: [] as LiveEvent[] })

    const commentsPromise =
        missingCommentIds.length > 0
            ? userCommentSelectorClient.listComments({
                  filter: `{${mapToMatchFilterDc("id", missingCommentIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const votesPromise =
        missingVoteIds.length > 0
            ? votingSelectorClient.listVotes({
                  filter: `{${mapToMatchFilterDc("id", missingVoteIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const [
        profilesRes,
        videosRes,
        postsRes,
        liveEventsRes,
        commentsRes,
        votesRes,
    ] = await Promise.all([
        profilesPromise,
        videosPromise,
        postsPromise,
        liveEventsPromise,
        commentsPromise,
        votesPromise,
    ])

    profilesRes.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )
    videosRes.data.forEach(video =>
        queryClient.setQueryData<Video>(QueryKeys.video(video.id), video),
    )
    postsRes.data.forEach(post =>
        queryClient.setQueryData<Postit>(QueryKeys.postit(post.id), post),
    )
    liveEventsRes.data.forEach(event =>
        queryClient.setQueryData<LiveEvent>(
            QueryKeys.liveEvent(event.id),
            event,
        ),
    )
    commentsRes.data.forEach(comment =>
        queryClient.setQueryData<UserComment>(
            QueryKeys.comment(comment.id),
            comment,
        ),
    )
    votesRes.data.forEach(vote =>
        queryClient.setQueryData<Vote>(QueryKeys.vote(vote.id), vote),
    )

    const postResourceAssetRefs = pipe(
        postsRes.data,
        A.filter(post => post.type === "Image"),
        A.map(post => post.imageUrl),
        A.filter(isResourceRef),
    )

    const assetsRes = await getAssetsQueryFn({
        queryClient,
        resourceRefs: postResourceAssetRefs,
    })

    const imageAssets = pipe(
        assetsRes,
        A.filter(asset => asset.type === "Image"),
    )

    const profiles = mergeWithQueryCache(
        queryClient,
        profileIds,
        QueryKeys.profile,
        profilesRes.data,
    )
    const videos = mergeWithQueryCache(
        queryClient,
        videoIds,
        QueryKeys.video,
        videosRes.data,
    )
    const posts = mergeWithQueryCache(
        queryClient,
        postIds,
        QueryKeys.postit,
        postsRes.data,
    )
    const liveEvents = mergeWithQueryCache(
        queryClient,
        liveEventIds,
        QueryKeys.liveEvent,
        liveEventsRes.data,
    )
    const comments = mergeWithQueryCache(
        queryClient,
        commentIds,
        QueryKeys.comment,
        commentsRes.data,
    )
    const votes = mergeWithQueryCache(
        queryClient,
        voteIds,
        QueryKeys.vote,
        votesRes.data,
    )

    const notificationCenterItems = getNotificationCenterItems({
        comments,
        liveEvents,
        assets: imageAssets,
        notificationsWithDataIds:
            notificationsWithDataIds as NotificationWithDataIds[],
        posts,
        profiles,
        videos,
        votes,
    })

    return {
        items: notificationCenterItems,
        totalCount: notifications.totalCount,
        paging: notifications.paging,
    }
}

type VideoNotificationDetails = {
    type: "VideoNotificationDetails"
    data?: VideoNotificationData
    dataIds: VideoNotificationDataIds
}

type VideoCommentedNotificationDetails = {
    type: "VideoCommentedNotificationDetails"
    data?: VideoCommentedNotificationData
    dataIds: VideoCommentedNotificationDataIds
}

type VideoLikedNotificationDetails = {
    type: "VideoLikedNotificationDetails"
    data?: VideoLikedNotificationData
    dataIds: VideoLikedNotificationDataIds
}
type PostNotificationDetails = {
    type: "PostNotificationDetails"
    data?: PostNotificationData
    dataIds: PostNotificationDataIds
}

type PostCommentedNotificationDetails = {
    type: "PostCommentedNotificationDetails"
    data?: PostCommentedNotificationData
    dataIds: PostCommentedNotificationDataIds
}

type PostLikedNotificationDetails = {
    type: "PostLikedNotificationDetails"
    data?: PostLikedNotificationData
    dataIds: PostLikedNotificationDataIds
}
type UserProfileNotificationDetails = {
    type: "UserProfileNotificationDetails"
    data?: UserProfileNotificationData
    dataIds: UserProfileNotificationDataIds
}

type LiveEventNotificationDetails = {
    type: "LiveEventNotificationDetails"
    data?: LiveEventNotificationData
    dataIds: LiveEventNotificationDataIds
}

type NoDetail = {
    type: "NoDetail"
    data?: undefined
    dataIds: undefined
}

type NotificationDetail =
    | VideoNotificationDetails
    | VideoCommentedNotificationDetails
    | VideoLikedNotificationDetails
    | PostNotificationDetails
    | PostCommentedNotificationDetails
    | PostLikedNotificationDetails
    | UserProfileNotificationDetails
    | LiveEventNotificationDetails
    | NoDetail

export type NotificationDetailType = NotificationDetail["type"]

export type DatabaseNotificationWithDetails<
    T extends NotificationDetail["type"],
> = DatabaseNotification & {
    type: T
    dataIds: Extract<NotificationDetail, { type: T }>["dataIds"]
    details: NonNullable<Extract<NotificationDetail, { type: T }>["data"]>
}

const makeIs =
    <T extends NotificationDetail["type"]>(x: T) =>
    (
        s: DatabaseNotificationWithDetails<NotificationDetailType>,
    ): s is DatabaseNotificationWithDetails<T> =>
        s.type === x

export const isVideoNotification = makeIs("VideoNotificationDetails")
export const isVideoLikeNotification = makeIs("VideoLikedNotificationDetails")
export const isVideoCommentNotification = makeIs(
    "VideoCommentedNotificationDetails",
)
export const isPostNotification = makeIs("PostNotificationDetails")
export const isPostLikeNotification = makeIs("PostLikedNotificationDetails")
export const isPostCommentNotification = makeIs(
    "PostCommentedNotificationDetails",
)
export const isUserProfileNotification = makeIs(
    "UserProfileNotificationDetails",
)
export const isLiveEventNotification = makeIs("LiveEventNotificationDetails")
export const isNoDetailNotification = makeIs("NoDetail")

export type NotificationWithDataIds = Except<
    DatabaseNotificationWithDetails<NotificationDetailType>,
    "details"
>

type GetNotificationCenterItemsModel = {
    assets: Asset[]
    notificationsWithDataIds: NotificationWithDataIds[]
    profiles: UserProfile[]
    comments: UserComment[]
    votes: Vote[]
    liveEvents: LiveEvent[]
    videos: Video[]
    posts: Postit[]
}

export const getNotificationCenterItems = (
    model: GetNotificationCenterItemsModel,
) => {
    const notifications = model.notificationsWithDataIds.map(n => {
        switch (n.type) {
            case "VideoNotificationDetails": {
                const ids = n.dataIds as DatabaseNotificationWithDetails<
                    typeof n.type
                >["dataIds"]

                const profileId = n.senderId
                const videoId = ids.videoId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )
                const video = model.videos.find(video => video.id === videoId)

                if (!userProfile || !video) return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                        video,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }
            case "VideoLikedNotificationDetails": {
                const ids = n.dataIds as DatabaseNotificationWithDetails<
                    typeof n.type
                >["dataIds"]

                const profileId = n.senderId
                const videoId = ids.videoId
                const voteId = ids.likeId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )
                const vote = model.votes.find(vote => vote.id === voteId)
                const video = model.videos.find(video => video.id === videoId)

                if (!userProfile || !video || !vote)
                    return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                        vote,
                        video,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }
            case "VideoCommentedNotificationDetails": {
                const ids = n.dataIds as DatabaseNotificationWithDetails<
                    typeof n.type
                >["dataIds"]

                const profileId = n.senderId
                const videoId = ids.videoId
                const commentId = ids.commentId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )
                const userComment = model.comments.find(
                    comment => comment.id === commentId,
                )
                const video = model.videos.find(video => video.id === videoId)

                if (!userProfile || !video || !userComment)
                    return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                        video,
                        userComment,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }
            case "PostNotificationDetails": {
                const ids = n.dataIds as DatabaseNotificationWithDetails<
                    typeof n.type
                >["dataIds"]

                const profileId = n.senderId
                const postId = ids.postitId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )
                const post = model.posts.find(post => post.id === postId)

                if (!userProfile || !post) return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                        post,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }
            case "PostLikedNotificationDetails": {
                const ids = n.dataIds as DatabaseNotificationWithDetails<
                    typeof n.type
                >["dataIds"]

                const profileId = n.senderId
                const postId = ids.postitId
                const voteId = ids.likeId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )
                const vote = model.votes.find(vote => vote.id === voteId)
                const post = model.posts.find(post => post.id === postId)

                const asset = model.assets.find(
                    asset =>
                        !!post &&
                        post.type === "Image" &&
                        isResourceRef(post.imageUrl) &&
                        post.imageUrl.resourceId === asset.id.resourceId,
                )

                if (!userProfile || !post || !vote)
                    return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                        asset,
                        vote,
                        post,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }
            case "PostCommentedNotificationDetails": {
                const ids = n.dataIds as DatabaseNotificationWithDetails<
                    typeof n.type
                >["dataIds"]

                const profileId = n.senderId
                const postId = ids.postitId
                const commentId = ids.commentId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )
                const userComment = model.comments.find(
                    comment => comment.id === commentId,
                )
                const post = model.posts.find(post => post.id === postId)

                if (!userProfile || !post || !userComment)
                    return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                        post,
                        userComment,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }
            case "UserProfileNotificationDetails": {
                const profileId = n.senderId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )

                if (!userProfile) return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }
            case "LiveEventNotificationDetails": {
                const ids = n.dataIds as DatabaseNotificationWithDetails<
                    typeof n.type
                >["dataIds"]

                const profileId = n.senderId
                const liveEventId = ids.liveEventId

                const userProfile = model.profiles.find(
                    profile => profile.id === profileId,
                )
                const liveEvent = model.liveEvents.find(
                    liveEvent => liveEvent.id === liveEventId,
                )

                if (!userProfile || !liveEvent)
                    return getNoDetailNotification(n)

                return {
                    ...n,
                    details: {
                        userProfile,
                        liveEvent,
                    },
                } as DatabaseNotificationWithDetails<typeof n.type>
            }

            case "NoDetail": {
                return getNoDetailNotification(n)
            }
            default:
                absurd(n.type)
                return getNoDetailNotification(n)
        }
    })
    return notifications
}

const getNoDetailNotification = (notification: DatabaseNotification) =>
    ({
        ...notification,
        type: "NoDetail",
        details: undefined,
    }) as DatabaseNotificationWithDetails<"NoDetail">

export const getNotificationDetailsDataIds = (
    clickAction?: NotificationClickActionDocument,
) => {
    const { key, ids } = generateDataByClickActionDocument(clickAction)
    const keys = key.split("/")
    const dataIds = keys.reduce<Record<string, string>>(
        (result, key, index) => {
            const dataIdKey = `${key}Id`
            result[dataIdKey] = ids[index]
            return result
        },
        {},
    )
    const type = keyToNotificationDetailsTypeMapping[key]
    return {
        dataIds,
        type,
    }
}

const keyToNotificationDetailsTypeMapping: Record<
    string,
    NotificationDetailType
> = {
    "video/comment": "VideoCommentedNotificationDetails",
    "video/like": "VideoLikedNotificationDetails",
    "video/profile": "VideoNotificationDetails",
    video: "VideoNotificationDetails",
    "postit/comment": "PostCommentedNotificationDetails",
    "postit/like": "PostLikedNotificationDetails",
    "postit/profile": "PostNotificationDetails",
    postit: "PostNotificationDetails",
    profile: "UserProfileNotificationDetails",
    liveevent: "LiveEventNotificationDetails",
}
//#region ------ NOTIFICATIONS DATA ---------

//? Video
export const VideoNotificationDataIds = z.object({
    videoId: VideoId,
    profileId: UserProfileId,
})
export type VideoNotificationDataIds = z.infer<typeof VideoNotificationDataIds>
export const VideoNotificationData = z.object({
    video: Video,
    userProfile: UserProfile,
})
export type VideoNotificationData = z.infer<typeof VideoNotificationData>
//? Post
export const PostNotificationDataIds = z.object({
    postitId: PostitId,
    profileId: UserProfileId,
})
export type PostNotificationDataIds = z.infer<typeof PostNotificationDataIds>
export const PostNotificationData = z.object({
    post: Postit,
    asset: ImageAsset.optional(),
    userProfile: UserProfile,
})
export type PostNotificationData = z.infer<typeof PostNotificationData>
//? Live Event
export const LiveEventNotificationDataIds = z.object({
    liveEventId: LiveEventId,
    profileId: UserProfileId,
})
export type LiveEventNotificationDataIds = z.infer<
    typeof LiveEventNotificationDataIds
>
export const LiveEventNotificationData = z.object({
    liveEvent: LiveEvent,
    userProfile: UserProfile,
})
export type LiveEventNotificationData = z.infer<
    typeof LiveEventNotificationData
>
//? Comment
export const VideoCommentedNotificationDataIds = z.object({
    videoId: VideoId,
    commentId: UserCommentId,
    profileId: UserProfileId,
})
export type VideoCommentedNotificationDataIds = z.infer<
    typeof VideoCommentedNotificationDataIds
>
export const VideoCommentedNotificationData = z.object({
    video: Video,
    userComment: UserComment,
    userProfile: UserProfile,
})
export type VideoCommentedNotificationData = z.infer<
    typeof VideoCommentedNotificationData
>

export const PostCommentedNotificationDataIds = z.object({
    postitId: PostitId,
    commentId: UserCommentId,
    profileId: UserProfileId,
})
export type PostCommentedNotificationDataIds = z.infer<
    typeof PostCommentedNotificationDataIds
>
export const PostCommentedNotificationData = z.object({
    post: Postit,
    asset: ImageAsset.optional(),
    userComment: UserComment,
    userProfile: UserProfile,
})
export type PostCommentedNotificationData = z.infer<
    typeof PostCommentedNotificationData
>
//? Vote
export const VideoLikedNotificationDataIds = z.object({
    videoId: VideoId,
    likeId: VoteId,
    profileId: UserProfileId,
})
export type VideoLikedNotificationDataIds = z.infer<
    typeof VideoLikedNotificationDataIds
>
export const VideoLikedNotificationData = z.object({
    video: Video,
    vote: Vote,
    userProfile: UserProfile,
})
export type VideoLikedNotificationData = z.infer<
    typeof VideoLikedNotificationData
>

export const PostLikedNotificationDataIds = z.object({
    postitId: PostitId,
    likeId: VoteId,
    profileId: UserProfileId,
})
export type PostLikedNotificationDataIds = z.infer<
    typeof PostLikedNotificationDataIds
>
export const PostLikedNotificationData = z.object({
    post: Postit,
    asset: ImageAsset.optional(),
    vote: Vote,
    userProfile: UserProfile,
})
export type PostLikedNotificationData = z.infer<
    typeof PostLikedNotificationData
>
//? User Profile
export const UserProfileNotificationDataIds = z.object({
    profileId: UserProfileId,
})
export type UserProfileNotificationDataIds = z.infer<
    typeof UserProfileNotificationDataIds
>
export const UserProfileNotificationData = z.object({
    userProfile: UserProfile,
})
export type UserProfileNotificationData = z.infer<
    typeof UserProfileNotificationData
>

//#endregion
