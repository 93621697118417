import * as ToastPrimitives from "@radix-ui/react-toast"
import classNames from "classnames"
import * as React from "react"

import * as styles from "./toast.css"

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Viewport>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Viewport
        ref={ref}
        className={classNames(styles.toastViewport, className)}
        {...props}
    />
))

ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const Toast = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Root>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Root
        ref={ref}
        className={classNames(styles.toast, className)}
        {...props}
    />
))

Toast.displayName = ToastPrimitives.Root.displayName

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>
export { Toast, ToastProvider, ToastViewport, type ToastProps }
