import { FC, useState } from "react"

import { Except } from "type-fest"

import { VisibilityAdt } from "../../data-flow/common"
import { FoldableText, FoldableTextModel } from "./foldable-text"

export type FoldableStatefulTextModel = Except<
    FoldableTextModel,
    "onVisibilityChanged"
>

export const FoldableStatefulText: FC<FoldableStatefulTextModel> = model => {
    const [visibility, setVisibility] = useState(model.visibility)

    return (
        <FoldableText
            {...model}
            visibility={visibility}
            onVisibilityChanged={() =>
                setVisibility(
                    VisibilityAdt.is.Visible(visibility)
                        ? VisibilityAdt.as.Invisible({})
                        : VisibilityAdt.as.Visible({}),
                )
            }
        />
    )
}
