import { IonAlert } from "@ionic/react"
import * as O from "fp-ts/Option"
import { FC, useState } from "react"

import { isDefined } from "../../utils/object"
import { ShareOverviewModal } from "./share-content-overview-modal"

export type ShareOverviewAlertModalModal = {
    title: string
    isOpen: boolean
    shareUrl: O.Option<string>
    onClosed: () => void
}

export const ShareOverviewAlertModal: FC<ShareOverviewAlertModalModal> = ({
    title,
    isOpen,
    shareUrl,
    onClosed,
}) => {
    const [showShareUrl, setShowShareUrl] = useState(false)

    return (
        <>
            <ShareOverviewModal
                isOpen={isOpen}
                title={title}
                canShare={isDefined(navigator.canShare)}
                onShareToClicked={() => {
                    if (O.isNone(shareUrl)) return

                    navigator.share({
                        title: "Share Profile",
                        url: shareUrl.value,
                    })
                }}
                onCopyLinkClicked={() => setShowShareUrl(true)}
                onClosed={() => onClosed()}
            />

            <IonAlert
                isOpen={showShareUrl}
                onDidDismiss={() => {
                    if (O.isSome(shareUrl))
                        navigator.clipboard.writeText(shareUrl.value)

                    setShowShareUrl(false)
                }}
                inputs={[
                    {
                        type: "textarea",
                        value: O.isSome(shareUrl) ? shareUrl.value : "",
                        attributes: {
                            style: {
                                height: "100px",
                                "-webkit-user-select": "all",
                                "-moz-user-select": "all",
                                "-ms-user-select": "all",
                                "user-select": "all",
                            },
                        },
                    },
                ]}
                header="Share"
                message="copy your share url"
                buttons={["ok"]}
            />
        </>
    )
}
