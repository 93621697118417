import axios, { AxiosRequestConfig } from "axios"
import * as TE from "fp-ts/TaskEither"
import { pipe } from "fp-ts/function"
import { LocalStorage } from "../../local-storage"

const createUploadConfig = (
    config?: AxiosRequestConfig,
): AxiosRequestConfig => ({
    withCredentials: true,
    responseType: "json",
    ...config,

    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
        "x-ms-blob-type": "BlockBlob",
        Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
        ...config?.headers,
    },
})

export const uploadClientTask = (url: string, config?: AxiosRequestConfig) =>
    pipe(
        TE.tryCatch(
            () => axios(url, createUploadConfig(config)),
            error => error as Error,
        ),
        TE.map(response => response.data),
    )

export const uploadClient = async (
    url: string,
    config?: AxiosRequestConfig,
) => {
    const response = await axios(url, createUploadConfig(config))
    return response.data
}
