import { useQuery } from "@tanstack/react-query"

import { UserProfileStatistic } from "../../../api/api-models"
import { UserProfileId } from "../../../api/branded-types"
import { userProfileSelectorClient } from "../../../api/clients/user-profile-api-client"
import { QueryKeys } from "../../../api/query-keys"

export type ProfileStatisticsQueryData = {
    data: UserProfileStatistic
}

export const useProfileStatistics = (profileId?: UserProfileId) => {
    return useQuery({
        queryKey: QueryKeys.profileStatistics(profileId),
        queryFn: () => getUserProfileStatisticById(profileId),
        enabled: !!profileId,
    })
}

export const getUserProfileStatisticById = async (id?: UserProfileId) => {
    if (!id) throw new Error("User profile id is not defined")
    const res = await userProfileSelectorClient.getUserProfileStatisticById({
        params: {
            id,
        },
    })
    return res.data
}
