import { Zodios } from "@zodios/core"
import {
    corsFetchHandler,
    tokenHandlerForCreate,
    validationErrorHandler,
} from "api/api-utils"
import { reportCreatorApi } from "api/creators/report-creator-api"
import { API_URL } from "envs"

//creator client
export const reportCreatorClient = new Zodios(
    `${API_URL}/report-creator`,
    reportCreatorApi,
)

reportCreatorClient.use(tokenHandlerForCreate)
reportCreatorClient.use(corsFetchHandler)
reportCreatorClient.use(validationErrorHandler)
