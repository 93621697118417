import classNames from "classnames"
import { FC, MouseEventHandler, PropsWithChildren } from "react"

import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"
import * as styles from "./avatar.css"

export type AvatarStyleState = "isLive" | "None"

export const createStateBorderStyle = (
    state: AvatarStyleState,
): AvatarModel["style"] => ({
    WebkitBoxSizing: "border-box",
    MozBoxSizing: "border-box",
    boxSizing: "border-box",
    overflow: "hidden",

    borderRadius: "100%",
    border:
        state === "isLive"
            ? `2px solid ${vars.color.liveColor.hex}`
            : "1px solid #B4B2C0",
})

export type AvatarModel = {
    src?: string | undefined
    size?: number
    className?: string
    classNameImage?: string
    style?: React.CSSProperties
    styleState?: AvatarStyleState
    onClicked?: MouseEventHandler<HTMLDivElement>
}

export const Avatar: FC<PropsWithChildren<AvatarModel>> = ({
    className = "",
    classNameImage = "",
    size = 64,
    src,
    style,
    styleState = "None",
    onClicked,
    children,
}) => (
    <div
        className={classNames(className, styles.avatar)}
        style={
            {
                ["--size"]: `${size}px`,
                ...style,
            } as React.CSSProperties
        }
        onClick={e => (onClicked ? onClicked(e) : {})}
    >
        <>
            <LazyLoadImage
                className={classNames(styles.image, classNameImage)}
                style={{
                    ...createStateBorderStyle(styleState),
                    ...style,
                }}
                height={size}
                width={size}
                src={`${src}?width=${size * 2}&height=${size * 2}`}
            />

            {children}
        </>
    </div>
)
