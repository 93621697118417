import { faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    IonButton,
    IonInput,
    IonLabel,
    IonTextarea,
    IonToast,
} from "@ionic/react"
import classNames from "classnames"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { FC, useEffect, useMemo, useState } from "react"

import { NavigationBackButton } from "components/buttons/navigation-back-button"
import { VisibilityAdt } from "data-flow/common"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { getEventDetailValue } from "utils/fp"

import { getIsAuthorizedAccount } from "api/api-utils"
import { PostitId } from "api/branded-types"
import { Content } from "common/content"
import { Footer } from "common/footer"
import { Page } from "common/page"
import { Toolbar } from "common/toolbar"
import { SubscriptionPickerModal } from "features/upload/subscription-picker-modal"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { useNavigate, useParams } from "react-router-dom"
import { vars } from "theme/variables.css"
import * as styles from "./edit-text-post-page.css"
import { useEditText } from "./hooks/use-edit-text"
import { useEditTextPostStore } from "./store/edit-text-post-store"

const MAX_TITLE_LENGTH = 80
const MAX_CONTENT_LENGTH = 65000

export const EditTextPostPage: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const postitId = PostitId.parse(id)

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const [paymentPickerVisibility, setPaymentPickerVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const {
        editPost,
        isUploadButtonDisabled,
        message,
        monetization,
        title,
        uploadFailed,
        uploadFinished,
        monetizationDisabled,
        isPostitLoaded,
    } = useEditText({ postitId, profileId })

    const setTitle = useEditTextPostStore(store => store.setTitle)
    const setMessage = useEditTextPostStore(store => store.setMessage)
    const setMonetization = useEditTextPostStore(store => store.setMonetization)
    const reset = useEditTextPostStore(store => store.reset)

    useEffect(() => () => reset(), [reset])

    return (
        <Page>
            <NavigationBackButton />

            <Toolbar>
                <p
                    style={{
                        fontSize: vars.font.size.xm,
                        fontWeight: 600,
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    Edit text post
                </p>
            </Toolbar>

            <Content>
                <IonToast
                    isOpen={uploadFailed}
                    duration={3000}
                    className={styles.ionToast}
                    color="danger"
                    message="Oops! Something went wrong. Please try again later."
                />

                <IonToast
                    isOpen={uploadFinished}
                    duration={2000}
                    className={styles.ionToast}
                    color="success"
                    message="Post was updated successfully."
                    onDidDismiss={() => {
                        reset()
                        navigate(-1)
                    }}
                />

                {monetization && (
                    <SubscriptionPickerModal
                        isOpen={VisibilityAdt.is.Visible(
                            paymentPickerVisibility,
                        )}
                        text={{
                            actions: {
                                ok: "Okay",
                            },
                            title: "Monetization Options",
                            description:
                                "Choose from the following options to monetize your contribution:",
                            subscription: {
                                free: {
                                    title: "Free for Everyone",
                                    description:
                                        "Your contribution is accessible to all users for free.",
                                },
                                subscriber: {
                                    title: "Free for Subscribers",
                                    description:
                                        "Your contribution is accessible for free only to your channel's subscribers. All other users must pay a price to access it.",
                                },
                                noneSubscriber: {
                                    title: "For Non-Subscribers",
                                    description:
                                        "You can set a price that all users except your subscribers must pay to access your contribution.",
                                },
                            },
                        }}
                        monetization={monetization}
                        onCanceled={() =>
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                        }
                        onConfirmed={monetization => {
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                            setMonetization(monetization)
                        }}
                    />
                )}

                <div className={styles.content}>
                    <div className={styles.inputWrapper}>
                        <IonInput
                            className={styles.title}
                            disabled={!isPostitLoaded}
                            style={{
                                fontSize: vars.font.size.m,
                                fontWeight: 500,
                            }}
                            data-test="input-title"
                            placeholder="What is your post about"
                            value={title}
                            maxlength={MAX_TITLE_LENGTH}
                            clearInput
                            onIonInput={flow(
                                getEventDetailValue,
                                O.map(setTitle),
                            )}
                        />

                        <p
                            className={styles.characters}
                            style={{ fontSize: vars.font.size.xxs }}
                        >
                            {title
                                ? `${title.length} / ${MAX_TITLE_LENGTH}`
                                : ""}
                        </p>
                    </div>

                    <div className={styles.inputWrapper}>
                        <IonTextarea
                            className={styles.description}
                            disabled={!isPostitLoaded}
                            style={{
                                fontSize: vars.font.size.regular,
                                fontWeight: 400,
                            }}
                            data-test="input-message"
                            maxlength={MAX_CONTENT_LENGTH}
                            autoGrow
                            placeholder="Add a description to your text post."
                            value={message}
                            onIonInput={flow(
                                getEventDetailValue,
                                O.map(setMessage),
                            )}
                        />

                        <p
                            className={styles.characters}
                            style={{ fontSize: vars.font.size.xxs }}
                        >
                            {message
                                ? `${message.length} / ${MAX_CONTENT_LENGTH}`
                                : ""}
                        </p>
                    </div>

                    <p
                        style={{
                            fontSize: vars.font.size.s,
                            opacity: "50%",
                            margin: "8px 4px",
                        }}
                    >
                        <span
                            style={{
                                fontSize: vars.font.size.s,
                                fontWeight: 600,
                            }}
                        >
                            Note:
                        </span>{" "}
                        This feature is only active in the test environment and
                        will later only be available for monetized profiles.
                    </p>

                    <button
                        disabled={monetizationDisabled}
                        className={styles.monetizeWrapper}
                        onClick={() =>
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Visible({}),
                            )
                        }
                    >
                        <div className={styles.monetizeTextWrapper}>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={faCircleStar}
                                color="white"
                            />
                            <IonLabel style={{ width: "100%" }}>
                                <p
                                    style={{
                                        fontSize: vars.font.size.m,
                                        color: "var(--text-color, white)",
                                        textAlign: "left",
                                    }}
                                >
                                    Edit Monetization Options
                                </p>
                            </IonLabel>
                            <FontAwesomeIcon
                                className={styles.navIcon}
                                icon={faAngleRight}
                                color="var(--nav-icon-color, white)"
                            />
                        </div>
                        <div className={styles.monetizeInfoTextWrapper}>
                            {monetization && monetization.type === "None" && (
                                <>
                                    <IonLabel>
                                        <p
                                            style={{
                                                fontSize: vars.font.size.m,
                                                textAlign: "left",
                                            }}
                                        >
                                            Free for Everyone
                                        </p>
                                    </IonLabel>
                                </>
                            )}
                            {monetization &&
                                monetization.type === "SubscriptionOnly" && (
                                    <>
                                        <IonLabel>
                                            <p
                                                style={{
                                                    fontSize: vars.font.size.m,
                                                    textAlign: "left",
                                                }}
                                            >
                                                Free for Subscribers
                                            </p>
                                        </IonLabel>
                                        <IonLabel>
                                            <p
                                                style={{
                                                    fontSize: vars.font.size.m,
                                                    textAlign: "left",
                                                }}
                                            >
                                                {`For Non-Subscribers ${monetization.amount} ${monetization.currency}`}
                                            </p>
                                        </IonLabel>
                                    </>
                                )}
                        </div>
                    </button>
                </div>
            </Content>
            <Footer className={classNames("ion-no-border", styles.footer)}>
                <IonButton
                    disabled={isUploadButtonDisabled}
                    className={styles.footerButton}
                    data-test="action-post-text"
                    expand="block"
                    onClick={() => {
                        if (!title || !message) return
                        if (!profileId) return

                        editPost({
                            body: {
                                title: title,
                                message,
                            },
                            id: postitId,
                        })
                    }}
                >
                    Upload
                </IonButton>
            </Footer>
        </Page>
    )
}

export default EditTextPostPage
