/* eslint-disable no-useless-escape */

import {
    GetNextPageParamFunction,
    InfiniteData,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { mapToMatchFilterDc, mapToSortFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { videoSelectorClient } from "../api/clients/video-api-client"
import { QueryKeys } from "../api/query-keys"
import { VideoAreaFeedModel } from "../features/feed/areas/videos/video-area-tile"
import { InfiniteProfilesData } from "../features/search/search-profiles-page"

const PAGINATION_AMOUNT = 50

export type UseVideosSearchModel = {
    searchKey: string
    profileId?: UserProfileId
    enabled?: boolean
}

const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
const mimeType = 'mimeType: !"" and !"unknown/unknown"'

export const searchVideosDefaultFilter = `{$match:{${mimeType}}, ${sortFilter}}`

export const getSearchVideos = async ({
    pageParam,
    filter,
}: {
    pageParam: unknown
    filter: string
}) => {
    const videos = await videoSelectorClient.listVideos({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam as number,
        },
    })

    const profileIds = pipe(
        videos.data,
        A.map(({ creatorId }) => creatorId),
    )

    const profiles = () =>
        userProfileSelectorClient.listProfiles({
            filter: `{${mapToMatchFilterDc("id", profileIds)}}`,
        })

    const [profilesResult] = await Promise.all([
        profiles().then(({ data }) => data),
    ])

    return pipe(
        videos.data,
        A.map(video => {
            const profile = profilesResult.find(p => p.id === video.creatorId)
            return {
                id: video.id,
                video,
                profile,
                createdAt: video.createdAt,
            } as VideoAreaFeedModel
        }),
        A.filter(
            ({ profile, video }) =>
                video !== undefined && profile !== undefined,
        ),
        items => ({
            items,
            paging: videos.paging,
        }),
    )
}

type Data = Awaited<ReturnType<typeof getSearchVideos>>

export type SearchVideosData = Data
export type InfiniteSearchVideosData = InfiniteData<Data>

export const useVideosSearch = (model: UseVideosSearchModel) => {
    const queryKey = QueryKeys.searchVideos(model)
    const searchProfilesQueryKey = QueryKeys.searchUserProfiles(model)

    const queryClient = useQueryClient()

    const userProfilesData = queryClient.getQueryData<InfiniteProfilesData>(
        searchProfilesQueryKey,
    )

    const creatorIds =
        userProfilesData?.pages
            .flatMap(page => page.data)
            .map(item => item.id) ?? []

    const titleFilter = `title: *"${model.searchKey}"*:ci`
    const descriptionFilter = `description: *"${model.searchKey}"*:ci`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
    const mimeType = 'mimeType: !"" and !"unknown/unknown"'
    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, creatorId: !"${model.profileId}"`
    //TODO: this is not 100% precise, but would work out most of the time
    //TODO: otherwise we need specific API endpoint for this
    const profilesFilter =
        model.searchKey.length === 0
            ? ""
            : `, {creatorId: in ${JSON.stringify(creatorIds)}}`

    const filter = `{$match:{$or: [{${titleFilter}}, {${descriptionFilter}}${profilesFilter}]${removeMyProfileFilter}, ${mimeType}}, ${sortFilter}}`

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) => getSearchVideos({ pageParam, filter }),
        getNextPageParam: getSearchVideosNextPageParam,
        enabled: model.enabled,
    })
}

export const getSearchVideosNextPageParam: GetNextPageParamFunction<
    number,
    Data
> = lastPage => {
    if (lastPage.items.length !== PAGINATION_AMOUNT) {
        return undefined
    }

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}
