import { useQuery } from "@tanstack/react-query"

import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { StaticQueryKeys } from "../api/query-keys"

const getMyProfiles = async () => {
    const profilesRes = await userProfileSelectorClient.getMyProfiles(undefined)
    return {
        profiles: profilesRes.data,
    }
}

type ProfilesResponse = Awaited<ReturnType<typeof getMyProfiles>>

export const useMyUserProfiles = <TReturn = ProfilesResponse>(
    select?: (data: ProfilesResponse) => TReturn,
    enabled?: boolean,
) => {
    return useQuery({
        queryFn: getMyProfiles,
        queryKey: [StaticQueryKeys.MY_PROFILES],
        select,
        enabled,
    })
}

export const useMyUserProfile = (enabled?: boolean) =>
    useMyUserProfiles(data => data.profiles.at(0), enabled)

export const useMyUserProfileId = (enabled?: boolean) =>
    useMyUserProfiles(data => data.profiles.at(0)?.id, enabled)
