import { RefObject, useLayoutEffect } from "react"
import { isIOS } from "react-device-detect"

type UseIOSKeyboardAdaptationsOptions = {
    bottomSpace?: number
}
//TODO: make more configurable to be able to pass more components
type UseIOSKeyboardAdaptationsProps = {
    realInputRef: RefObject<HTMLIonTextareaElement | HTMLIonInputElement>
    fakeInputRef: RefObject<HTMLDivElement>
    resizeElementRef: RefObject<HTMLIonToolbarElement>
    options?: UseIOSKeyboardAdaptationsOptions
}

export const useIOSKeyboardAdaptations = ({
    realInputRef,
    fakeInputRef,
    resizeElementRef,
    options,
}: UseIOSKeyboardAdaptationsProps) => {
    useLayoutEffect(() => {
        const realInputElement = realInputRef.current
        const fakeInputElement = fakeInputRef.current
        const resizeElement = resizeElementRef.current

        const handleFocus = () => {
            if (realInputElement) {
                realInputElement.style.position = "relative"
                realInputElement.style.top = "0px"
            }
        }

        const handleBlur = () => {
            if (realInputElement) {
                realInputElement.style.position = ""
                realInputElement.style.top = ""
            }
            if (fakeInputElement) {
                fakeInputElement.style.position = ""
                fakeInputElement.style.top = ""
            }
            document.body.style.paddingBottom = "0"
        }

        const handleFakeInputClick = () => {
            if (realInputElement) {
                realInputElement.setFocus()
            }
            setTimeout(() => {
                if (realInputElement) {
                    realInputElement.style.position = "relative"
                    realInputElement.style.top = "0px"
                }
                if (fakeInputElement) {
                    fakeInputElement.style.position = "absolute"
                    fakeInputElement.style.top = "-9999px"
                }
            }, 150)
        }

        const handleResize = () => {
            if (!isIOS) return
            if (!resizeElement) return
            const inner = window.innerHeight
            const visible = window.visualViewport?.height ?? window.innerHeight
            if (inner > visible) {
                resizeElement.style.paddingBottom = `${inner - visible + (options?.bottomSpace ?? 16)}px`
            } else {
                resizeElement.style.paddingBottom = ""
            }
        }

        if (realInputElement) {
            realInputElement.addEventListener("focus", handleFocus)
            realInputElement.addEventListener("focusout", handleBlur)
        }

        if (fakeInputElement) {
            fakeInputElement.addEventListener("click", handleFakeInputClick)
        }

        window.visualViewport?.addEventListener("resize", handleResize)

        return () => {
            if (realInputElement) {
                realInputElement.removeEventListener("focus", handleFocus)
                realInputElement.removeEventListener("focusout", handleBlur)
            }
            if (fakeInputElement) {
                fakeInputElement.removeEventListener(
                    "click",
                    handleFakeInputClick,
                )
            }
            window.visualViewport?.removeEventListener("resize", handleResize)
        }
    }, [realInputRef, fakeInputRef, resizeElementRef, options])
}
