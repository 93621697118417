import { MonetizationOption } from "api/api-models"
import { create } from "zustand"

type State = {
    title?: string
    message?: string
    monetization?: MonetizationOption
    uploadFinished: boolean
    uploadFailed: boolean
}

type Actions = {
    setTitle: (v: State["title"]) => void
    setMessage: (v: State["message"]) => void
    setMonetization: (v: State["monetization"]) => void
    setUploadFinished: (v: State["uploadFinished"]) => void
    setUploadFailed: (v: State["uploadFailed"]) => void

    reset: () => void
}

const initialState: State = {
    title: undefined,
    message: undefined,
    monetization: undefined,
    uploadFailed: false,
    uploadFinished: false,
}

export const useEditTextPostStore = create<State & Actions>(set => ({
    ...initialState,

    setTitle: v => set({ title: v }),
    setMessage: v => set({ message: v }),
    setMonetization: v => set({ monetization: v }),
    setUploadFailed: v => set({ uploadFailed: v }),
    setUploadFinished: v => set({ uploadFinished: v }),

    reset: () => set(initialState),
}))
