/* eslint-disable no-useless-escape */

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { mapToMatchFilterDc, mapToSortFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { postitSelectorClient } from "../api/clients/post-api-client"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"
import { PostitArea } from "../features/feed/areas/post/post-area-tile"
import { InfiniteProfilesData } from "../features/search/search-profiles-page"

const PAGINATION_AMOUNT = 25

export type UseTextPostSearchModel = {
    searchKey: string
    profileId?: UserProfileId
    enabled?: boolean
}
export const useTextPostsSearch = (model: UseTextPostSearchModel) => {
    const queryKey = QueryKeys.searchTextPosts(model)

    const searchProfilesQueryKey = QueryKeys.searchUserProfiles(model)

    const queryClient = useQueryClient()

    const userProfilesData = queryClient.getQueryData<InfiniteProfilesData>(
        searchProfilesQueryKey,
    )

    const creatorIds =
        userProfilesData?.pages
            .flatMap(page => page.data)
            .map(item => item.id) ?? []

    const titleFilter = `title: *"${model.searchKey}"*:ci`
    const messageFilter = `message: *"${model.searchKey}"*:ci`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
    const typeFilter = `type: "Text"`
    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, creatorId: !"${model.profileId}"`
    //TODO: this is not 100% precise, but would work out most of the time
    //TODO: otherwise we need specific API endpoint for this
    const profilesFilter =
        model.searchKey.length === 0
            ? ""
            : `, {creatorId: in ${JSON.stringify(creatorIds)}}`

    const filter = `{$match:{$or: [{${titleFilter}}, {${messageFilter}}${profilesFilter}]${removeMyProfileFilter}, ${typeFilter}}, ${sortFilter}}`

    const queryFn = async ({ pageParam }: { pageParam: number }) => {
        const posts = await postitSelectorClient.listPostits({
            filter,
            paging: {
                type: "Index",
                direction: "After",
                limit: PAGINATION_AMOUNT,
                index: pageParam,
            },
        })

        const profileIds = pipe(
            posts.data,
            A.map(({ creatorId }) => creatorId),
        )

        const profiles = () =>
            userProfileSelectorClient.listProfiles({
                filter: `{${mapToMatchFilterDc("id", profileIds)}}`,
            })

        const [profilesResult] = await Promise.all([
            profiles().then(({ data }) => data),
        ])

        return pipe(
            posts.data,
            A.map(post => {
                const profile = profilesResult.find(
                    p => p.id === post.creatorId,
                )
                return {
                    id: post.id,
                    post,
                    profile,
                    createdAt: post.createdAt,
                } as PostitArea
            }),
            A.filter(
                ({ profile, post }) =>
                    post !== undefined && profile !== undefined,
            ),
            items => ({
                items,
                paging: posts.paging,
            }),
        )
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn,
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.items.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: model.enabled,
    })
}
