import { makeApi } from "@zodios/core"
import z from "zod"
import { ApiError, Asset } from "../api-models"
import { ResourceId } from "../branded-types"

export const assetCreatorApi = makeApi([
    {
        method: "put",
        path: "/v1/asset/:id",
        alias: "updateImage",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: ResourceId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.instanceof(Uint8Array),
            },
        ],
        response: z.object({
            data: Asset,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
