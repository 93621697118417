import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons"
import { faStarCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import { QueryClient, useIsMutating } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import { identity, pipe } from "fp-ts/function"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { PostitId, ShareContentId, Url } from "../../../api/branded-types"
import { createShareTokenTask } from "../../../api/clients/auth-api-client"
import { paymentCreatorClient } from "../../../api/clients/payment-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../api/query-keys"
import { NavigationBackButton } from "../../../components/buttons/navigation-back-button"
import { CommentOverviewModal } from "../../../components/comments/comment-overview-modal"
import { NewFeatureComingSoonModal } from "../../../components/modals/new-feature-coming-soon-modal"
import { ShareOverviewAlertModal } from "../../../components/modals/share-overview-alert-modal"
import { VoterOverviewModal } from "../../../components/modals/voter-overview-modal"
import { VisibilityAdt } from "../../../data-flow/common"
import { createShareUrl } from "../../../data-flow/sharing"
import { useDeletePostMutation } from "../../../features/feed/areas/post/hooks/use-delete-post-mutation"
import {
    UserDetails,
    UserDetailsLoading,
} from "../../../features/feed/areas/user/user-details"
import { SubscriptionStateAdt } from "../../../features/user-profile/subscription/subscribe-button"
import { DeletePostDialog } from "../../../features/video/components/delete-post-dialog"
import { PostConsumerActionsModal } from "../../../features/video/components/post-consumer-actions-modal"
import { ContentPayModal } from "../../../features/video/content-pay-modal"
import { useMyResourceVote } from "../../../features/video/hooks/use-my-resource-vote"
import { useResourceComments } from "../../../features/video/hooks/use-resource-comments"
import { useResourceVotes } from "../../../features/video/hooks/use-resource-votes"
import { useRevokeVoteMutation } from "../../../features/video/hooks/use-revoke-vote-mutation"
import { useVoteMutation } from "../../../features/video/hooks/use-vote-mutation"
import { useMe } from "../../../hooks/use-me"
import { useMyUserProfiles } from "../../../hooks/use-my-user-profiles"
import {
    useIsAlreadySubscribing,
    useSubscribeUserProfile,
} from "../../../hooks/use-subscribers"
import { isDefined, isNotDefined } from "../../../utils/object"
import { ShareWelcomeModal } from "../../registration/share-welcome-modal"

import { ImagePost, Postit, ResourceRef } from "api/api-models"
import { Loading } from "common/loading"
import { LinkItUrl } from "features/linkify"
import { paymentModalStore } from "features/payment/payment-modal-store"
import { ContentSubscribeModal } from "features/video/content-subscribe-modal"
import { useAsset, useAssetData } from "hooks/use-asset"
import { useToggle } from "hooks/use-toggle"
import { useUserProfile } from "hooks/use-user-profile"
import Skeleton from "react-loading-skeleton"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppStore } from "store"
import { isImageAsset } from "utils/asset"
import { getIsAuthorizedAccount } from "../../../api/api-utils"
import { Content } from "../../../common/content"
import { Footer } from "../../../common/footer"
import { Page } from "../../../common/page"
import { userProfileByIdRoute } from "../../../features/routing/routing"
import { LocalStorage } from "../../../local-storage"
import { creatorReferenceStrategy } from "../../user-profile/strategies/user-content-reference-strategy"
import { usePostitById } from "../hooks/use-postit-by-id"
import * as styles from "./postit-consumer-page.css"

import { shareLinkClient } from "api/clients/share-link-api-client"
import { ImageView } from "common/image-view"
import { useReportStore } from "features/report/report-store"
import { OwnPostConsumerActionsModal } from "features/video/components/own-post-consumer-actions-modal"
import { motion } from "framer-motion"
import { vars } from "theme/variables.css"

const buildRedirectUrl = (status: string, viewerId: string) =>
    pipe(
        location.origin,
        url => `${url}/app/postit/viewer/${viewerId}?payment_status=${status}`,
    )
//TODO: refactor, show UI only when asset document is there
// (not yet possible because asset document is not loaded since backend is not updated)
export const PostitConsumerPage: FC = () => {
    const open = useAppStore(store => store.open)
    const navigate = useNavigate()
    const location = useLocation()
    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)

    const { t } = useTranslation(["user"])
    const { id } = useParams()
    const postitId = PostitId.parse(id)

    const queryClient = new QueryClient()

    const setReportContentOpen = useReportStore(
        store => store.setReportContentOpen,
    )

    const setFailedPaymentModalOpen = paymentModalStore(
        store => store.setFailedPaymentModalOpen,
    )
    const setSuccessPaymentModalOpen = paymentModalStore(
        store => store.setSuccessPaymentModalOpen,
    )

    const [isMonetizeProcessInProgress, setMonetizeProcessInProgress] =
        useState(false)

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const postitQuery = usePostitById(postitId)

    const imagePostOption = pipe(
        postitQuery.data,
        O.fromNullable,
        O.chain(O.fromPredicate(data => imagePostPredicate(data))),
    )

    const isImageResourceOption = pipe(
        imagePostOption,
        O.map(post => ResourceRef.safeParse(post.imageUrl).success),
    )

    const assetQuery = useAsset({
        enabled: pipe(
            isImageResourceOption,
            O.getOrElse(() => false),
        ),
        resource:
            postitQuery.data?.type === "Image"
                ? (postitQuery.data.imageUrl as ResourceRef)
                : undefined,
    })

    const imageAsset = pipe(
        assetQuery.data,
        O.fromNullable,
        O.chain(O.fromPredicate(isImageAsset)),
    )

    const aspectRatio = O.isSome(imageAsset)
        ? imageAsset.value.resolution.width /
          (imageAsset.value.resolution.height ?? 1)
        : undefined

    const desiredImageWidth = window.innerWidth - 40
    const desiredImageHeight = aspectRatio
        ? desiredImageWidth / aspectRatio
        : undefined

    const assetDataUrlOption = pipe(
        imagePostOption,
        O.chain(post =>
            pipe(
                isImageResourceOption,
                O.chain(isImageResource =>
                    isImageResource
                        ? pipe(assetQuery.data?.source, O.fromNullable)
                        : O.some(post.imageUrl as Url),
                ),
                O.map(url => `${url}?width=${desiredImageWidth}` as Url),
            ),
        ),
    )

    const imageAssetDataQuery = useAssetData(
        O.isSome(assetDataUrlOption) ? assetDataUrlOption.value : undefined,
    )

    const canShowPaymentModal =
        postitQuery.isSuccess &&
        (imageAssetDataQuery.isSuccess || postitQuery.data.type === "Text")

    const contentCreatorId = postitQuery.isSuccess
        ? postitQuery.data.creatorId
        : undefined

    const contentCreatorProfileQuery = useUserProfile(contentCreatorId)

    const {
        onSubscribe: {
            mutate: subscribe,
            isSuccess: isSubscribed,
            error: subscribeFailed,
        },
    } = useSubscribeUserProfile()

    const [likingOverviewVisibility, setLikingOverviewVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [deletePostDialogVisibile, setDeletePostDialogVisibile] =
        useState(false)

    const [commentOverviewVisibility, setCommentOverviewVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [actionsVisibility, setActionsVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [newFeatureModalVisibility, setNewFeatureModalVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [shareUrl, setShareUrl] = useState<undefined | string>()
    const [showShareModal, setShowShareModal] = useState(false)
    const [welcomeModalVisibility, setWelcomeModalVisibility] = useState(
        accountType === "Guest"
            ? VisibilityAdt.of.Visible({})
            : VisibilityAdt.of.Invisible({}),
    )
    //todo: rewrite with visibility
    const [isPostitPayModalOpen, showPostitPayModal] = useState(false)

    const userId = meQuery.data?.user.id
    const {
        isOpen: isSubscribeOpen,
        open: openSubscribe,
        close: closeSubscribe,
    } = useToggle()

    const myProfilesQuery = useMyUserProfiles(identity, isAuthorizedAccount)

    const myProfiles = myProfilesQuery.isSuccess
        ? myProfilesQuery.data.profiles
        : []

    const myProfileId = myProfiles.at(0)?.id

    const voter = useMemo(
        () =>
            creatorReferenceStrategy({
                accountType,
                userId,
                userProfileId: myProfileId,
            }),
        [accountType, myProfileId, userId],
    )

    const postitCommentsQuery = useResourceComments(
        postitId
            ? {
                  contentId: postitId,
                  contentType: "Postit",
              }
            : undefined,
    )

    const postitVotesQuery = useResourceVotes(
        postitId
            ? {
                  contentId: postitId,
                  contentType: "Postit",
              }
            : undefined,
    )

    const myPostitVote = useMyResourceVote({
        voteResourceRef: postitId
            ? {
                  contentId: postitId,
                  contentType: "Postit",
              }
            : undefined,
        voter,
    })

    const totalComments = postitCommentsQuery.data?.pages.at(0)?.total ?? 0
    const totalVotes = postitVotesQuery.data?.pages.at(0)?.total ?? 0

    const isLiked = myPostitVote?.data?.isLiked ?? false
    const voteId = myPostitVote.data?.voteId ?? O.none

    const { mutate: vote } = useVoteMutation()
    const { mutate: revokeVote } = useRevokeVoteMutation()

    const votingUpMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_UP],
    })
    const votingDownMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_DOWN],
    })

    const isVoting = votingDownMutates !== 0 || votingUpMutates !== 0

    const isSubscribingPageQuery = useIsAlreadySubscribing({
        myId: myProfileId,
        idToSubscribe: contentCreatorId,
    })

    const [, setSubscriptionState] = useState(
        SubscriptionStateAdt.of.Loading({}),
    )

    useEffect(() => {
        if (isSubscribingPageQuery.isLoading) return
        if (isNotDefined(isSubscribingPageQuery.data)) return

        setSubscriptionState(
            isSubscribingPageQuery.data
                ? SubscriptionStateAdt.as.ActiveSubscription({})
                : SubscriptionStateAdt.as.NoSubscription,
        )
    }, [isSubscribingPageQuery.data, isSubscribingPageQuery.isLoading])

    useEffect(() => {
        // TODO force state refresh in useSubscribeUserProfile
        if (isDefined(subscribeFailed)) console.error("subscription failed")

        if (isSubscribed)
            setSubscriptionState(SubscriptionStateAdt.as.ActiveSubscription({}))
    }, [isSubscribed, subscribeFailed])

    const { mutate: deletePostMutation } = useDeletePostMutation()
    //TODO: rework this to show modal immediately after click
    const sharePost = useCallback(() => {
        if (!postitId) return
        setIsShareLinkInCreation(true)

        pipe(
            createShareTokenTask(
                {
                    contents: [
                        {
                            contentType: "Postit",
                            contentFilter: ShareContentId.parse(postitId),
                        },
                    ],
                },
                {},
            ),
            TE.bimap(
                error => {
                    setIsShareLinkInCreation(false)
                    console.error("Error share", error)
                },
                ({ data }) => {
                    shareLinkClient
                        .createShortenedLink({
                            link: createShareUrl(data),
                        })
                        .then(({ shortURL }) => {
                            setShareUrl(shortURL)
                            setShowShareModal(true)
                            setIsShareLinkInCreation(false)
                        })
                },
            ),
        )()
    }, [postitId])

    const isMyContent = useCallback(() => {
        if (!contentCreatorId) return
        if (isNotDefined(myProfileId)) return

        return contentCreatorId === myProfileId
    }, [contentCreatorId, myProfileId])

    const urlParams = new URLSearchParams(location.search)
    const status = urlParams.get("payment_status")

    // FIXME: improve payment and navigation back flow
    const [paymentAttempts, setPaymentAttempts] = useState(0)

    useEffect(() => {
        if (status) {
            setSuccessPaymentModalOpen(status === "succeeded")
            setFailedPaymentModalOpen(status === "failed")
        }
    }, [status])

    return (
        <Page>
            <NavigationBackButton
                onClicked={paymentAttempts > 0 ? () => navigate(-1) : undefined}
            />
            <Loading open={isShareLinkInCreation} message="Loading..." />
            <Loading open={isMonetizeProcessInProgress} message="Loading..." />

            <div
                onClick={() => {
                    if (!isAuthorizedAccount) {
                        open("CreateAccount")
                        return
                    }
                    setActionsVisibility(VisibilityAdt.as.Visible({}))
                }}
                className={styles.actionIconContainer}
            >
                <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    color="white"
                    className={styles.actionIcon}
                />
            </div>

            <Content className={styles.content}>
                <div className={styles.contentWrapper}>
                    {postitQuery.isSuccess &&
                        postitQuery.data.type === "Image" && (
                            <div className={styles.imageWrapper}>
                                <ImageView
                                    id={postitId}
                                    className={styles.image}
                                    src={
                                        imageAssetDataQuery.isSuccess
                                            ? URL.createObjectURL(
                                                  imageAssetDataQuery.data,
                                              )
                                            : (postitQuery.data.imageUrl as Url)
                                    }
                                    style={{
                                        borderRadius:
                                            vars.measurement.radius.md,
                                    }}
                                    width={desiredImageWidth}
                                    height={desiredImageHeight}
                                />
                                {postitQuery.data.paymentRequired && (
                                    <div className={styles.secureOverlay} />
                                )}
                            </div>
                        )}

                    {postitQuery.isLoading && (
                        <div className={styles.imageWrapper}>
                            <PostitLoadingImage />
                        </div>
                    )}

                    {postitQuery.isSuccess && (
                        <motion.div
                            exit={{ filter: "blur(8px)" }}
                            animate={{ filter: "blur(0px)" }}
                            initial={{ filter: "blur(8px)" }}
                            transition={{ duration: 0.125 }}
                            className={styles.textContainer}
                        >
                            <p
                                style={{
                                    fontSize: vars.font.size.l,
                                }}
                            >
                                {postitQuery.data.title}
                            </p>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    marginTop: 8,
                                    whiteSpace: "pre-wrap",
                                    lineHeight: "18px",
                                }}
                            >
                                <LinkItUrl className={styles.link}>
                                    {postitQuery.data.message}
                                </LinkItUrl>
                            </p>
                            {postitQuery.data.paymentRequired && (
                                <div className={styles.textSecureOverlay} />
                            )}
                        </motion.div>
                    )}

                    {postitQuery.isLoading && (
                        <>
                            <Skeleton
                                style={{ marginBottom: 8 }}
                                width={230}
                                height={30}
                                borderRadius={24}
                            />
                            <Skeleton
                                width={144}
                                height={18}
                                borderRadius={24}
                            />
                        </>
                    )}

                    {postitQuery.data?.paymentRequired && (
                        <div className={styles.exclusiveContentBanner}>
                            <div className={styles.exclusiveContentBannerInfo}>
                                <FontAwesomeIcon
                                    className={styles.exclusiveIcon}
                                    icon={faStarCircle}
                                />
                                <p
                                    style={{
                                        fontSize: vars.font.size.m,
                                    }}
                                >
                                    This post is exclusive
                                </p>
                            </div>
                            <IonButton
                                className={styles.exclusiveButton}
                                onClick={() => showPostitPayModal(true)}
                                color="medium"
                            >
                                {postitQuery.data.monetization.type ===
                                "SubscriptionOnly"
                                    ? `Buy for ${postitQuery.data.monetization.amount.toLocaleString()}€`
                                    : `unlock now`}
                            </IonButton>
                        </div>
                    )}
                </div>

                {canShowPaymentModal &&
                    contentCreatorProfileQuery.isSuccess && (
                        <ContentPayModal
                            isOpen={
                                isPostitPayModalOpen &&
                                !isMonetizeProcessInProgress
                            }
                            purchaseActionDisabled={isMonetizeProcessInProgress}
                            text={{
                                purchase: {
                                    action: `Buy for ${
                                        postitQuery.data.monetization.type ===
                                        "SubscriptionOnly"
                                            ? postitQuery.data.monetization
                                                  .amount
                                            : 0
                                    } €`,
                                    amount:
                                        postitQuery.data.monetization.type ===
                                        "SubscriptionOnly"
                                            ? postitQuery.data.monetization
                                                  .amount
                                            : 0,
                                    currency: "EUR",
                                    title: `${
                                        postitQuery.data.monetization.type ===
                                        "SubscriptionOnly"
                                            ? postitQuery.data.monetization
                                                  .amount
                                            : 0
                                    }€`,
                                    info: `Kaufe diesen Betrag für nur ${
                                        postitQuery.data.monetization.type ===
                                        "SubscriptionOnly"
                                            ? postitQuery.data.monetization
                                                  .amount
                                            : 0
                                    }${" €"} und erhalte sofortigen Zugriff`,
                                },
                                subscribe: {
                                    action: `Subscribe ${
                                        contentCreatorProfileQuery.data
                                            .displayName ??
                                        contentCreatorProfileQuery.data
                                            .profileName
                                    } now`,
                                    amount: 14.99,
                                    currency: "EUR",
                                    title: "Subscribe",
                                    info: "Oder entdecke alles für nur 14,99 €/Monat und erhalte uneingeschränkten Zugriff auf all seine Inhalte.",
                                },
                                terms: `Durch Tippen auf „Abonnieren" stimmst du zu, dass Informationen zu deinem Abo verfügbar gemacht werden. Du stimmst damit auch den Nutzungsbedingungen für Abos zu und bestätigst, dass du die Datenschutzrichtlinie und die Informationen zum Widerrufsrecht (EU/EWR/UK) zur Kenntnis genommen hast. Mehr über die Informationen, die verfügbar gemacht werden, erfährst du im Hilfebereich.`,
                            }}
                            user={{
                                imageUrl:
                                    contentCreatorProfileQuery.data.imageUrl,
                                name:
                                    contentCreatorProfileQuery.data
                                        .displayName ??
                                    contentCreatorProfileQuery.data.profileName,
                                isLive: contentCreatorProfileQuery.data.isLive,
                            }}
                            content={{
                                imageUrl:
                                    postitQuery.data.type === "Image"
                                        ? imageAssetDataQuery.isSuccess
                                            ? (URL.createObjectURL(
                                                  imageAssetDataQuery.data,
                                              ) as Url)
                                            : (postitQuery.data.imageUrl as Url)
                                        : Url.parse("areas/img_bg_0"),
                                title: postitQuery.data.title,
                                type: postitQuery.data.type,
                            }}
                            onCanceled={() => showPostitPayModal(false)}
                            onPurchased={() => {
                                if (
                                    postitQuery.data.monetization.type !==
                                    "SubscriptionOnly"
                                )
                                    return

                                if (isNotDefined(myProfileId)) return

                                setMonetizeProcessInProgress(true)
                                setPaymentAttempts(paymentAttempts + 1)

                                paymentCreatorClient
                                    .startPayment({
                                        customerId: myProfileId,
                                        price: {
                                            amount: postitQuery.data
                                                .monetization.amount,
                                            currency:
                                                postitQuery.data.monetization
                                                    .currency,
                                        },
                                        contentRef: {
                                            contentType: "Postit",
                                            contentId: postitQuery.data.id,
                                        },
                                        redirects: {
                                            succeeded: buildRedirectUrl(
                                                "succeeded",
                                                postitQuery.data.id,
                                            ),
                                            failed: buildRedirectUrl(
                                                "failed",
                                                postitQuery.data.id,
                                            ),
                                            canceled: buildRedirectUrl(
                                                "canceled",
                                                postitQuery.data.id,
                                            ),
                                        },
                                    })
                                    .then(({ data: locationUrl }) => {
                                        window.location.href = locationUrl
                                    })
                                    .finally(() => {
                                        queryClient.invalidateQueries({
                                            queryKey: QueryKeys.myPayments(),
                                        })
                                        setMonetizeProcessInProgress(false)
                                    })
                            }}
                            onSubscribed={() => {
                                showPostitPayModal(false)
                                openSubscribe()
                            }}
                        />
                    )}

                {canShowPaymentModal &&
                    contentCreatorProfileQuery.isSuccess && (
                        <ContentSubscribeModal
                            isOpen={isSubscribeOpen}
                            text={{
                                subscribe: {
                                    action: `Subscribe ${
                                        contentCreatorProfileQuery.data
                                            .displayName ??
                                        contentCreatorProfileQuery.data
                                            .profileName
                                    } now`,
                                    amount: 14.99,
                                    currency: "EUR",
                                    title: "Subscribe",
                                    info: "Oder entdecke alles für nur 14,99 €/Monat und erhalte uneingeschränkten Zugriff auf all seine Inhalte.",
                                },
                                terms: `Durch Tippen auf „Abonnieren" stimmst du zu, dass Informationen zu deinem Abo verfügbar gemacht werden. Du stimmst damit auch den Nutzungsbedingungen für Abos zu und bestätigst, dass du die Datenschutzrichtlinie und die Informationen zum Widerrufsrecht (EU/EWR/UK) zur Kenntnis genommen hast. Mehr über die Informationen, die verfügbar gemacht werden, erfährst du im Hilfebereich.`,
                            }}
                            user={{
                                imageUrl:
                                    postitQuery.data.type === "Image"
                                        ? imageAssetDataQuery.isSuccess
                                            ? (URL.createObjectURL(
                                                  imageAssetDataQuery.data,
                                              ) as Url)
                                            : (postitQuery.data.imageUrl as Url)
                                        : Url.parse("areas/img_bg_0"),
                                name:
                                    contentCreatorProfileQuery.data
                                        .displayName ??
                                    contentCreatorProfileQuery.data.profileName,
                                isLive: contentCreatorProfileQuery.data.isLive,
                            }}
                            content={{
                                imageUrl:
                                    postitQuery.data.type === "Image"
                                        ? imageAssetDataQuery.isSuccess
                                            ? (URL.createObjectURL(
                                                  imageAssetDataQuery.data,
                                              ) as Url)
                                            : (postitQuery.data.imageUrl as Url)
                                        : Url.parse("areas/img_bg_0"),
                                title: postitQuery.data.title,
                                type: postitQuery.data.type,
                            }}
                            onCanceled={closeSubscribe}
                            onSubscribed={() => {
                                if (!myProfileId) return

                                subscribe({
                                    idToSubscribe:
                                        contentCreatorProfileQuery.data.id,
                                    myId: myProfileId,
                                })
                                closeSubscribe()
                            }}
                        />
                    )}

                <NewFeatureComingSoonModal
                    isOpen={VisibilityAdt.is.Visible(newFeatureModalVisibility)}
                    onClosed={() =>
                        setNewFeatureModalVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                    text={{
                        title: t("myUserProfile.newFeatureComingSoon.title"),
                        description: t(
                            "myUserProfile.newFeatureComingSoon.description",
                        ),
                    }}
                />
                {pipe(
                    myProfiles,
                    A.map(profile => profile.id),
                    profileIds =>
                        pipe(
                            postitQuery.data,
                            O.fromNullable,
                            O.map(postit => postit.creatorId),
                            O.chain(creatorId =>
                                pipe(
                                    profileIds,
                                    A.findFirst(id => id === creatorId),
                                ),
                            ),
                            O.fold(
                                () => (
                                    <PostConsumerActionsModal
                                        isOpen={VisibilityAdt.is.Visible(
                                            actionsVisibility,
                                        )}
                                        dismiss={() =>
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                        }
                                        onReportClicked={() => {
                                            if (!isAuthorizedAccount) {
                                                open("CreateAccount")
                                                return
                                            }
                                            if (!postitQuery.isSuccess) return
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )

                                            setReportContentOpen({
                                                open: true,
                                                content: {
                                                    contentId:
                                                        postitQuery.data.id,
                                                    contentType: "Postit",
                                                },
                                                contentOwner:
                                                    postitQuery.data.creatorId,
                                            })
                                        }}
                                        //TODO: Locize
                                        text={{
                                            report: "Content report",
                                        }}
                                    />
                                ),
                                () => (
                                    <OwnPostConsumerActionsModal
                                        isOpen={VisibilityAdt.is.Visible(
                                            actionsVisibility,
                                        )}
                                        dismiss={() =>
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                        }
                                        onEditClicked={() => {
                                            if (!isAuthorizedAccount) {
                                                open("CreateAccount")
                                                return
                                            }
                                            if (!postitQuery.isSuccess) return
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                            navigate(
                                                postitQuery.data.type === "Text"
                                                    ? `/app/text-edit/${postitId}`
                                                    : `/app/image-edit/${postitId}`,
                                            )
                                        }}
                                        onDeleteClicked={() => {
                                            if (!isAuthorizedAccount) {
                                                open("CreateAccount")
                                                return
                                            }
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                            setDeletePostDialogVisibile(true)
                                        }}
                                        //TODO: Locize
                                        text={{
                                            edit: "Edit",
                                            delete: "Delete",
                                            cancel: "Cancel",
                                        }}
                                    />
                                ),
                            ),
                        ),
                )}

                <VoterOverviewModal
                    //TODO: Locize
                    title="Likes"
                    voteContentRef={pipe(
                        postitId,
                        O.fromNullable,
                        O.map(contentId => ({
                            contentType: "Postit",
                            contentId,
                        })),
                    )}
                    isOpen={VisibilityAdt.is.Visible(likingOverviewVisibility)}
                    onClosed={() =>
                        setLikingOverviewVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                    onUserClicked={id => {
                        if (!isAuthorizedAccount) {
                            navigate(userProfileByIdRoute(id))
                            return
                        }
                        if (!userId) return
                        setLikingOverviewVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )

                        navigate(
                            userId === id.toString()
                                ? "/app/my-profile"
                                : userProfileByIdRoute(id),
                        )
                    }}
                />

                <DeletePostDialog
                    isOpen={deletePostDialogVisibile}
                    onSubmit={() => {
                        if (!postitId) return
                        if (isNotDefined(myProfileId)) return

                        deletePostMutation({
                            id: postitId,
                            profileId: myProfileId,
                        })

                        setDeletePostDialogVisibile(false)
                        navigate(-1)
                    }}
                    onOpenChange={setDeletePostDialogVisibile}
                    //TODO: locize
                    text={{
                        cancel: "Cancel",
                        submit: "Yes, delete",
                        title: "Are you sure you want to delete this post?",
                        description:
                            "Please note that deleting a post will actually remove it and will no longer be available.",
                    }}
                />

                {postitQuery.isSuccess && (
                    <CommentOverviewModal
                        text={{
                            title: count =>
                                count === 1 ? `1 Comment` : `${count} Comments`,
                            inputPlaceholder: "Add comment",
                        }}
                        isOpen={VisibilityAdt.is.Visible(
                            commentOverviewVisibility,
                        )}
                        onClosed={() =>
                            setCommentOverviewVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                        }
                        resourceOwnerId={contentCreatorId}
                        commentResourceRef={pipe(
                            postitId,
                            O.fromNullable,
                            O.map(contentId => ({
                                contentType: "Postit",
                                contentId,
                            })),
                        )}
                        onUserClicked={id => {
                            if (!isAuthorizedAccount) {
                                navigate(userProfileByIdRoute(id))
                                return
                            }
                            if (!userId) return

                            setCommentOverviewVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )

                            navigate(
                                userId === id.toString()
                                    ? "/app/my-profile"
                                    : userProfileByIdRoute(id),
                            )
                        }}
                    />
                )}

                <ShareWelcomeModal
                    isOpen={VisibilityAdt.is.Visible(welcomeModalVisibility)}
                    profileId={contentCreatorId}
                    // TODO add locize
                    text={{
                        title: name => `join ${name} on Seemee`,
                        message:
                            "Become part of the community that directly connects fans and creators.",
                        onSignIn: "Log in to Seemee",
                        onSignUp: "Sign up",
                    }}
                    onDismissed={() =>
                        setWelcomeModalVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                />

                <ShareOverviewAlertModal
                    isOpen={showShareModal}
                    title="Share post"
                    onClosed={() => setShowShareModal(false)}
                    shareUrl={O.fromNullable(shareUrl)}
                />
            </Content>
            <Footer className={styles.footer}>
                {postitQuery.isSuccess &&
                    contentCreatorProfileQuery.isSuccess && (
                        <UserDetails
                            contentLastChangedAt={postitQuery.data.createdAt}
                            liked={isLiked}
                            paymentRequired={postitQuery.data.paymentRequired}
                            monetization={postitQuery.data.monetization}
                            avatarSize={36}
                            profile={contentCreatorProfileQuery.data}
                            comments={totalComments}
                            votes={totalVotes}
                            onLikeChangedClicked={() => {
                                if (!isAuthorizedAccount) {
                                    open("CreateAccount")
                                    return
                                }

                                if (myPostitVote.isFetching) return
                                if (!myPostitVote.isSuccess) return
                                if (isVoting) return
                                if (!voter) return
                                if (!postitId) return

                                if (!myPostitVote.data.isLiked) {
                                    vote({
                                        voter,
                                        resourceOwnerId:
                                            postitQuery.data.creatorId,
                                        voteResourceRef: {
                                            contentType: "Postit",
                                            contentId: postitId,
                                        },
                                    })
                                } else if (O.isSome(voteId)) {
                                    revokeVote({
                                        voter,
                                        voteResourceRef: {
                                            contentType: "Postit",
                                            contentId: postitId,
                                        },
                                        voteId: voteId.value,
                                    })
                                }
                            }}
                            onClicked={id => {
                                if (!isAuthorizedAccount) {
                                    navigate(userProfileByIdRoute(id))
                                    return
                                }
                                if (!userId) return

                                navigate(
                                    userId === id.toString()
                                        ? "/app/my-profile"
                                        : userProfileByIdRoute(id),
                                )
                            }}
                            onCommentDetailsClicked={() => {
                                if (!isAuthorizedAccount) {
                                    open("CreateAccount")
                                    return
                                }
                                if (!postitQuery.isSuccess) return

                                setCommentOverviewVisibility(
                                    VisibilityAdt.as.Visible({}),
                                )
                            }}
                            onShareClicked={() => {
                                if (!isAuthorizedAccount) {
                                    open("CreateAccount")
                                    return
                                }
                                sharePost()
                            }}
                            onLikeDetailsClicked={() => {
                                if (!postitQuery.isSuccess) return
                                if (totalVotes === 0) return

                                setLikingOverviewVisibility(
                                    VisibilityAdt.as.Visible({}),
                                )
                            }}
                            onPurchaseClicked={
                                !isMyContent()
                                    ? () => {
                                          if (!isAuthorizedAccount) {
                                              open("CreateAccount")
                                              return
                                          }
                                          if (
                                              postitQuery.isSuccess &&
                                              userId !==
                                                  contentCreatorProfileQuery
                                                      .data.userId
                                          ) {
                                              showPostitPayModal(true)
                                          }
                                      }
                                    : undefined
                            }
                        />
                    )}
                {postitQuery.isLoading && <UserDetailsLoading />}
            </Footer>
        </Page>
    )
}

export const PostitConsumerPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content
            style={{ overflow: "scroll" }}
            className={styles.content}
            fullscreen
        >
            <div className={styles.imageWrapper}>
                <PostitLoadingImage />
            </div>
            <Skeleton
                style={{ marginBottom: 8 }}
                width={230}
                height={30}
                borderRadius={24}
            />
            <Skeleton width={144} height={18} borderRadius={24} />
        </Content>
        <Footer className={styles.footer}>
            <UserDetailsLoading />
        </Footer>
    </Page>
)

export const PostitLoadingImage: FC = () => (
    <Skeleton width="100%" height="50vh" borderRadius={12} />
)

export default PostitConsumerPage

const imagePostPredicate = (post: Postit): post is ImagePost =>
    post.type === "Image"
