import { faShieldHeart } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { PrimaryButton, SecondaryButton } from "common/buttons"
import { vars } from "theme/variables.css"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "../../common/dialog"
import * as styles from "./cookie-consent-initial-modal.css"

type CookieConsentInitialModalModel = {
    isOpen: boolean
    onOpenChange: (v: boolean) => void
    onNavigateToPrivacyPolicy: () => void
    onAcceptAllClicked: () => void
    onOptionsClicked: () => void
}

export const CookieConsentInitialModal: FC<
    CookieConsentInitialModalModel
> = model => (
    <Dialog open={model.isOpen} onOpenChange={model.onOpenChange}>
        <DialogContent className={styles.content}>
            <FontAwesomeIcon className={styles.icon} icon={faShieldHeart} />

            <div className={styles.textContainer}>
                <DialogTitle
                    style={{
                        fontSize: vars.font.size.l,
                        margin: 0,
                        fontWeight: 600,
                    }}
                >
                    We care about your privacy!
                </DialogTitle>
                <DialogDescription
                    style={{
                        margin: 0,
                        fontSize: vars.font.size.regular,
                    }}
                >
                    We will not use your data for any purpose you do not consent
                    to. Specific purposes may be analytics, conversion tracking
                    or user feedback. You can learn more in our{" "}
                    <span
                        className={styles.link}
                        style={{
                            fontSize: vars.font.size.regular,
                        }}
                        onClick={model.onNavigateToPrivacyPolicy}
                    >
                        privacy settings.
                    </span>
                </DialogDescription>
            </div>

            <div className={styles.buttonsContainer}>
                <PrimaryButton
                    data-test="accept-all"
                    onClick={model.onAcceptAllClicked}
                >
                    Accept all
                </PrimaryButton>

                <SecondaryButton
                    onClick={model.onOptionsClicked}
                    style={{ backgroundColor: "#ECEBEF" }}
                >
                    Options
                </SecondaryButton>

                <SecondaryButton
                    onClick={() => model.onOpenChange(false)}
                    style={{
                        backgroundColor: "white",
                        color: vars.color.tertiary.hex,
                    }}
                >
                    Continue without accepting
                </SecondaryButton>
            </div>
        </DialogContent>
    </Dialog>
)
