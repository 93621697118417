import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { Toast, ToastProvider, ToastViewport } from "./toast"
import { useToast } from "./use-toast"

export const Toaster: FC = () => {
    const { toasts } = useToast()

    return (
        <ToastProvider swipeDirection="up">
            {pipe(
                toasts,
                A.map(({ id, toastContent, ...props }) => (
                    <Toast key={id} {...props}>
                        {toastContent}
                    </Toast>
                )),
            )}

            <ToastViewport />
        </ToastProvider>
    )
}
