import classNames from "classnames"
import { FC } from "react"

import { SeeMoreTextViewModal } from "../../../components/texts/see-more-text-view-modal"

import Skeleton from "react-loading-skeleton"
import { VideoId } from "../../../api/branded-types"
import { useVideoById } from "../hooks/use-video-by-id"
import * as styles from "./video-details.css"

export type VideoDetailsModel = {
    className?: string
    textClassName?: string
    text: {
        seeMore: string
    }
    videoId?: VideoId
}

export const VideoDetails: FC<VideoDetailsModel> = ({
    className = "",
    textClassName = "",
    text,
    videoId,
}) => {
    const videoQuery = useVideoById(videoId)
    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.textWrapper}>
                {videoQuery.isSuccess && (
                    <div className={styles.videoDescription}>
                        <SeeMoreTextViewModal
                            className={textClassName}
                            text={{
                                title: videoQuery.data.title,
                                description: videoQuery.data.description ?? "",
                                seeMore: text.seeMore,
                            }}
                        />
                    </div>
                )}
                {videoQuery.isLoading && <VideoDetailsLoading />}
            </div>
        </div>
    )
}

type VideoDetailsLoadingModel = {
    className?: string
}

export const VideoDetailsLoading: FC<VideoDetailsLoadingModel> = model => (
    <div className={classNames(styles.videoDescription, model.className)}>
        <Skeleton
            width={(window.innerWidth - 40) * 0.65}
            height={30}
            borderRadius={24}
        />
        <Skeleton
            width={(window.innerWidth - 40) * 0.35}
            height={18}
            borderRadius={24}
        />
    </div>
)
