import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { QueryClient, useIsMutating } from "@tanstack/react-query"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import { identity, pipe } from "fp-ts/function"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { ShareContentId, Url, VideoId } from "../../api/branded-types"
import { createShareTokenTask } from "../../api/clients/auth-api-client"
import { paymentCreatorClient } from "../../api/clients/payment-api-client"
import { QueryKeys, StaticQueryKeys } from "../../api/query-keys"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { CommentOverviewModal } from "../../components/comments/comment-overview-modal"
import { PlayStateAdt } from "../../components/controls/play-state-control"
import { NewFeatureComingSoonModal } from "../../components/modals/new-feature-coming-soon-modal"
import { ShareOverviewAlertModal } from "../../components/modals/share-overview-alert-modal"
import { VoterOverviewModal } from "../../components/modals/voter-overview-modal"
import { VisibilityAdt } from "../../data-flow/common"
import { createShareUrl } from "../../data-flow/sharing"
import { useMe } from "../../hooks/use-me"
import { useMyUserProfiles } from "../../hooks/use-my-user-profiles"
import {
    useIsAlreadySubscribing,
    useSubscribeUserProfile,
} from "../../hooks/use-subscribers"
import { LocalStorage } from "../../local-storage"
import { isDefined, isNotDefined } from "../../utils/object"
import { ShareWelcomeModal } from "../registration/share-welcome-modal"
import { SubscriptionStateAdt } from "../user-profile/subscription/subscribe-button"
import { DeletePostDialog } from "./components/delete-post-dialog"
import { PostConsumerActionsModal } from "./components/post-consumer-actions-modal"
import { ContentPayModal } from "./content-pay-modal"
import { VideoConsumerControlWithPlayer } from "./controls/video-consumer-with-video-details-control"
import { useDeleteVideoMutation } from "./hooks/use-delete-video-mutation"
import { useMyResourceVote } from "./hooks/use-my-resource-vote"
import { useResourceComments } from "./hooks/use-resource-comments"
import { useResourceVotes } from "./hooks/use-resource-votes"
import { useRevokeVoteMutation } from "./hooks/use-revoke-vote-mutation"
import { useVoteMutation } from "./hooks/use-vote-mutation"

import classNames from "classnames"
import { Loading } from "common/loading"
import { paymentModalStore } from "features/payment/payment-modal-store"
import * as A from "fp-ts/Array"
import { useUserProfile } from "hooks/use-user-profile"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppStore } from "store"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { Content } from "../../common/content"
import { Footer } from "../../common/footer"
import { Page } from "../../common/page"
import {
    UserDetails,
    UserDetailsLoading,
} from "../feed/areas/user/user-details"
import { userProfileByIdRoute, videoByIdRoute } from "../routing/routing"
import { creatorReferenceStrategy } from "../user-profile/strategies/user-content-reference-strategy"
import { VideoDetailsLoading } from "./details/video-details"
import { useVideoById } from "./hooks/use-video-by-id"

import { shareLinkClient } from "api/clients/share-link-api-client"
import { useReportStore } from "features/report/report-store"
import { OwnPostConsumerActionsModal } from "./components/own-post-consumer-actions-modal"
import * as styles from "./video-consumer-page.css"

type BuildRedirectUrlModel = {
    status: "canceled" | "failed" | "succeeded"
    videoId: VideoId
}

const buildRedirectUrl = (model: BuildRedirectUrlModel) =>
    pipe(
        location.origin,
        url =>
            `${url}${videoByIdRoute(model.videoId)}?payment_status=${model.status}`,
    )

export const VideoConsumerPage: FC = () => {
    const open = useAppStore(store => store.open)
    const navigate = useNavigate()
    const location = useLocation()
    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)

    const { t: tFeed } = useTranslation(["feed"])
    const { t: tUser } = useTranslation(["user"])
    const { id } = useParams()
    const videoId = VideoId.parse(id)

    const setReportContentOpen = useReportStore(
        store => store.setReportContentOpen,
    )

    const setFailedPaymentModalOpen = paymentModalStore(
        store => store.setFailedPaymentModalOpen,
    )
    const setSuccessPaymentModalOpen = paymentModalStore(
        store => store.setSuccessPaymentModalOpen,
    )

    const meQuery = useMe()
    const videoQuery = useVideoById(videoId)
    const contentCreatorProfileQuery = useUserProfile(
        videoQuery.data?.creatorId,
    )

    const [isMonetizeProcessInProgress, setMonetizeProcessInProgress] =
        useState(false)

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const {
        onSubscribe: {
            mutate: subscribe,
            isSuccess: isSubscribed,
            error: subscribeFailed,
        },
    } = useSubscribeUserProfile()

    const [likingOverviewVisibility, setLikingOverviewVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [deleteVideoDialogVisible, setDeleteVideoDialogVisible] =
        useState(false)

    const [commentOverviewVisibility, setCommentOverviewVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [actionsVisibility, setActionsVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [newFeatureModalVisibility, setNewFeatureModalVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [playState, setPlayState] = useState(PlayStateAdt.of.None({}))
    const [shareUrl, setShareUrl] = useState<undefined | string>()
    const [showShareModal, setShowShareModal] = useState(false)
    const [welcomeModalVisibility, setWelcomeModalVisibility] = useState(
        accountType === "Guest"
            ? VisibilityAdt.of.Visible({})
            : VisibilityAdt.of.Invisible({}),
    )
    const [isVideoPayModalOpen, showVideoPayModal] = useState(false)

    const userId = meQuery.data?.user.id

    const myProfilesQuery = useMyUserProfiles(identity, isAuthorizedAccount)

    const myProfiles = myProfilesQuery.isSuccess
        ? myProfilesQuery.data.profiles
        : []

    const myProfileId = myProfiles.at(0)?.id

    const videoCreatorId = videoQuery.isSuccess
        ? videoQuery.data.creatorId
        : undefined

    const videoCommentsQuery = useResourceComments(
        videoId
            ? {
                  contentId: videoId,
                  contentType: "Video",
              }
            : undefined,
    )

    const videoVotesQuery = useResourceVotes(
        videoId
            ? {
                  contentId: videoId,
                  contentType: "Video",
              }
            : undefined,
    )

    const voter = useMemo(
        () =>
            creatorReferenceStrategy({
                accountType,
                userId,
                userProfileId: myProfileId,
            }),
        [accountType, myProfileId, userId],
    )

    const myVideoVote = useMyResourceVote({
        voteResourceRef: videoId
            ? {
                  contentId: videoId,
                  contentType: "Video",
              }
            : undefined,
        voter,
    })

    const totalComments = videoCommentsQuery.data?.pages.at(0)?.total ?? 0
    const totalVotes = videoVotesQuery.data?.pages.at(0)?.total ?? 0

    const isLiked = myVideoVote?.data?.isLiked ?? false
    const voteId = myVideoVote.data?.voteId ?? O.none

    const { mutate: deleteVideoMutation } = useDeleteVideoMutation()

    const { mutate: vote } = useVoteMutation()
    const { mutate: revokeVote } = useRevokeVoteMutation()

    const votingUpMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_UP],
    })
    const votingDownMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_DOWN],
    })

    const isVoting = votingDownMutates !== 0 || votingUpMutates !== 0

    const isSubscribingPageQuery = useIsAlreadySubscribing({
        myId: myProfileId,
        idToSubscribe: videoQuery.isSuccess
            ? videoQuery.data.creatorId
            : undefined,
    })

    const queryClient = new QueryClient()

    const [, setSubscriptionState] = useState(
        SubscriptionStateAdt.of.Loading({}),
    )

    useEffect(() => {
        if (isSubscribingPageQuery.isLoading) return
        if (isNotDefined(isSubscribingPageQuery.data)) return

        setSubscriptionState(
            isSubscribingPageQuery.data
                ? SubscriptionStateAdt.as.ActiveSubscription({})
                : SubscriptionStateAdt.as.NoSubscription,
        )
    }, [isSubscribingPageQuery.data, isSubscribingPageQuery.isLoading])

    useEffect(() => {
        // TODO force state refresh in useSubscribeUserProfile
        if (isDefined(subscribeFailed)) console.error("subscription failed")

        if (isSubscribed)
            setSubscriptionState(SubscriptionStateAdt.as.ActiveSubscription({}))
    }, [isSubscribed, subscribeFailed])

    //TODO: rework this to show modal immediately after click
    const shareVideo = useCallback(() => {
        if (!videoId) return
        setIsShareLinkInCreation(true)

        pipe(
            createShareTokenTask(
                {
                    contents: [
                        {
                            contentType: "Video",
                            contentFilter: ShareContentId.parse(videoId),
                        },
                    ],
                },
                {},
            ),
            TE.bimap(
                error => {
                    setIsShareLinkInCreation(false)
                    console.error("Error share", error)
                },
                ({ data }) => {
                    shareLinkClient
                        .createShortenedLink({
                            link: createShareUrl(data),
                        })
                        .then(({ shortURL }) => {
                            setShareUrl(shortURL)
                            setShowShareModal(true)
                            setIsShareLinkInCreation(false)
                        })
                },
            ),
        )()
    }, [videoId])

    const isMyContent = useCallback(() => {
        if (!videoCreatorId) return
        if (isNotDefined(myProfileId)) return

        return videoCreatorId === myProfileId
    }, [videoCreatorId, myProfileId])

    const backgroundImageUrl = videoQuery.isSuccess
        ? videoQuery.data.previewImageUrl
            ? `${videoQuery.data.previewImageUrl}?height=${window.innerHeight * 2}`
            : ""
        : ""

    const urlParams = new URLSearchParams(location.search)
    const status = urlParams.get("payment_status")

    useEffect(() => {
        if (status) {
            setSuccessPaymentModalOpen(status === "succeeded")
            setFailedPaymentModalOpen(status === "failed")
        }
    }, [status])

    return (
        <Page>
            <NavigationBackButton />
            <Loading open={isShareLinkInCreation} message="Loading..." />
            <Loading open={isMonetizeProcessInProgress} message="Loading..." />

            <div
                onClick={() => {
                    if (!isAuthorizedAccount) {
                        open("CreateAccount")
                        return
                    }
                    setActionsVisibility(VisibilityAdt.as.Visible({}))
                }}
                className={styles.actionIconContainer}
            >
                <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    color="white"
                    className={styles.actionIcon}
                />
            </div>

            <Content className={styles.content} fullscreen>
                <LazyLoadImage
                    src={backgroundImageUrl}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    className={styles.backgroundImage}
                />
                <div className={styles.overlay} />
                {videoQuery.isSuccess &&
                    contentCreatorProfileQuery.isSuccess && (
                        <ContentPayModal
                            isOpen={isVideoPayModalOpen}
                            text={{
                                // TODO add locize
                                purchase: {
                                    action: `Buy for ${
                                        videoQuery.data.monetization.type ===
                                        "SubscriptionOnly"
                                            ? videoQuery.data.monetization
                                                  .amount
                                            : 0
                                    } €`,
                                    amount:
                                        videoQuery.data.monetization.type ===
                                        "SubscriptionOnly"
                                            ? videoQuery.data.monetization
                                                  .amount
                                            : 0,
                                    currency: "EUR",
                                    title: `${
                                        videoQuery.data.monetization.type ===
                                        "SubscriptionOnly"
                                            ? videoQuery.data.monetization
                                                  .amount
                                            : 0
                                    }€`,
                                    info: `Purchase this content for only ${videoQuery.data.monetization.type === "SubscriptionOnly" ? videoQuery.data.monetization.amount : 0}€ and get instant access`,
                                },
                                subscribe: {
                                    action: `Subscribe ${
                                        contentCreatorProfileQuery.data
                                            .displayName ??
                                        contentCreatorProfileQuery.data
                                            .profileName
                                    } now`,
                                    amount: 14.99,
                                    currency: "EUR",
                                    title: "Subscribe",
                                    info: "Or discover everything for just €14.99/month and get unlimited access to all its content.",
                                },
                                terms: "By tapping 'Subscribe', you agree that information about your subscription will be made available. You also agree to the subscription terms of service and confirm that you have acknowledged the privacy policy and the right of withdrawal information (EU/EAA/UK). Learn more about the information that will be made available in the help section.",
                            }}
                            user={{
                                imageUrl:
                                    contentCreatorProfileQuery.data.imageUrl,
                                name:
                                    contentCreatorProfileQuery.data
                                        .displayName ??
                                    contentCreatorProfileQuery.data.profileName,
                                isLive: contentCreatorProfileQuery.data.isLive,
                            }}
                            content={{
                                imageUrl:
                                    videoQuery.data.previewImageUrl ??
                                    Url.parse("areas/img_bg_0"),
                                title: videoQuery.data.title,
                                type: "Video",
                            }}
                            onCanceled={() => showVideoPayModal(false)}
                            purchaseActionDisabled={isMonetizeProcessInProgress}
                            onPurchased={() => {
                                if (
                                    videoQuery.data.monetization.type !==
                                    "SubscriptionOnly"
                                )
                                    return

                                if (isNotDefined(myProfileId)) return

                                setMonetizeProcessInProgress(true)

                                //todo: make this a mutation
                                paymentCreatorClient
                                    .startPayment({
                                        customerId: myProfileId,
                                        price: {
                                            amount: videoQuery.data.monetization
                                                .amount,
                                            currency:
                                                videoQuery.data.monetization
                                                    .currency,
                                        },
                                        contentRef: {
                                            contentType: "Video",
                                            contentId: videoQuery.data.id,
                                        },
                                        redirects: {
                                            succeeded: buildRedirectUrl({
                                                status: "succeeded",
                                                videoId: videoQuery.data.id,
                                            }),
                                            failed: buildRedirectUrl({
                                                status: "failed",
                                                videoId: videoQuery.data.id,
                                            }),
                                            canceled: buildRedirectUrl({
                                                status: "canceled",
                                                videoId: videoQuery.data.id,
                                            }),
                                        },
                                    })
                                    .then(({ data: locationUrl }) => {
                                        window.location.href = locationUrl
                                    })
                                    .finally(() => {
                                        queryClient.invalidateQueries({
                                            queryKey: QueryKeys.myPayments(),
                                        })
                                        setMonetizeProcessInProgress(false)
                                    })
                            }}
                            onSubscribed={() => {
                                if (!myProfileId) return

                                subscribe({
                                    idToSubscribe: videoQuery.data.creatorId,
                                    myId: myProfileId,
                                })
                                showVideoPayModal(false)
                            }}
                        />
                    )}

                <NewFeatureComingSoonModal
                    isOpen={VisibilityAdt.is.Visible(newFeatureModalVisibility)}
                    onClosed={() =>
                        setNewFeatureModalVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                    text={{
                        title: tUser(
                            "myUserProfile.newFeatureComingSoon.title",
                        ),
                        description: tUser(
                            "myUserProfile.newFeatureComingSoon.description",
                        ),
                    }}
                />
                {pipe(
                    myProfiles,
                    A.map(profile => profile.id),
                    profileIds =>
                        pipe(
                            videoCreatorId,
                            O.fromNullable,
                            O.chain(creatorId =>
                                pipe(
                                    profileIds,
                                    A.findFirst(id => id === creatorId),
                                    a => a,
                                ),
                            ),
                            O.fold(
                                () => (
                                    <PostConsumerActionsModal
                                        isOpen={VisibilityAdt.is.Visible(
                                            actionsVisibility,
                                        )}
                                        dismiss={() =>
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                        }
                                        onReportClicked={() => {
                                            if (!isAuthorizedAccount) {
                                                open("CreateAccount")
                                                return
                                            }
                                            if (!videoQuery.isSuccess) return
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                            setReportContentOpen({
                                                open: true,
                                                content: {
                                                    contentId:
                                                        videoQuery.data.id,
                                                    contentType: "Video",
                                                },
                                                contentOwner:
                                                    videoQuery.data.creatorId,
                                            })
                                        }}
                                        //TODO: Locize
                                        text={{
                                            report: "Content report",
                                        }}
                                    />
                                ),
                                () => (
                                    <OwnPostConsumerActionsModal
                                        isOpen={VisibilityAdt.is.Visible(
                                            actionsVisibility,
                                        )}
                                        dismiss={() =>
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                        }
                                        onEditClicked={() => {
                                            if (!isAuthorizedAccount) {
                                                open("CreateAccount")
                                                return
                                            }
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                            navigate(
                                                `/app/video-edit/${videoId}`,
                                            )
                                        }}
                                        onDeleteClicked={() => {
                                            if (!isAuthorizedAccount) {
                                                open("CreateAccount")
                                                return
                                            }
                                            setActionsVisibility(
                                                VisibilityAdt.as.Invisible({}),
                                            )
                                            setDeleteVideoDialogVisible(true)
                                        }}
                                        //TODO: Locize
                                        text={{
                                            edit: "Edit",
                                            delete: "Delete",
                                            cancel: "Cancel",
                                        }}
                                    />
                                ),
                            ),
                        ),
                )}

                <VoterOverviewModal
                    //TODO: Locize
                    title="Likes"
                    voteContentRef={pipe(
                        videoId,
                        O.fromNullable,
                        O.map(contentId => ({
                            contentType: "Video",
                            contentId,
                        })),
                    )}
                    isOpen={VisibilityAdt.is.Visible(likingOverviewVisibility)}
                    onClosed={() =>
                        setLikingOverviewVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                    onUserClicked={id => {
                        if (!isAuthorizedAccount) {
                            navigate(userProfileByIdRoute(id))
                            return
                        }
                        if (!userId) return
                        setLikingOverviewVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )

                        navigate(
                            userId === id.toString()
                                ? "/app/my-profile"
                                : userProfileByIdRoute(id),
                        )
                    }}
                />
                <DeletePostDialog
                    isOpen={deleteVideoDialogVisible}
                    onSubmit={() => {
                        if (!videoId) return
                        if (isNotDefined(myProfileId)) return

                        deleteVideoMutation({
                            id: videoId,
                            profileId: myProfileId,
                        })
                        setDeleteVideoDialogVisible(false)
                        navigate(-1)
                    }}
                    onOpenChange={setDeleteVideoDialogVisible}
                    //TODO: locize
                    text={{
                        cancel: "Cancel",
                        submit: "Yes, delete",
                        title: "Are you sure you want to delete the video post?",
                        description:
                            "Please note that deleting a post will actually remove it and will no longer be available.",
                    }}
                />

                <CommentOverviewModal
                    text={{
                        inputPlaceholder: "Add comment",
                        title: count =>
                            count === 1 ? `1 Comment` : `${count} Comments`,
                    }}
                    isOpen={VisibilityAdt.is.Visible(commentOverviewVisibility)}
                    onClosed={() =>
                        setCommentOverviewVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                    resourceOwnerId={videoCreatorId}
                    commentResourceRef={pipe(
                        videoId,
                        O.fromNullable,
                        O.map(contentId => ({
                            contentType: "Video",
                            contentId,
                        })),
                    )}
                    onUserClicked={id => {
                        if (!isAuthorizedAccount) {
                            navigate(userProfileByIdRoute(id))
                            return
                        }
                        if (!userId) return
                        setCommentOverviewVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )

                        navigate(
                            userId === id.toString()
                                ? "/app/my-profile"
                                : userProfileByIdRoute(id),
                        )
                    }}
                />

                <ShareWelcomeModal
                    isOpen={VisibilityAdt.is.Visible(welcomeModalVisibility)}
                    profileId={videoCreatorId}
                    // TODO add locize
                    text={{
                        title: name => `join ${name} on Seemee`,
                        message:
                            "Become part of the community that directly connects fans and creators.",
                        onSignIn: "Log in to Seemee",
                        onSignUp: "Sign up",
                    }}
                    onDismissed={() =>
                        setWelcomeModalVisibility(
                            VisibilityAdt.as.Invisible({}),
                        )
                    }
                />

                <ShareOverviewAlertModal
                    isOpen={showShareModal}
                    title="Share video"
                    onClosed={() => setShowShareModal(false)}
                    shareUrl={O.fromNullable(shareUrl)}
                />

                <VideoConsumerControlWithPlayer
                    text={{ seeMore: tFeed("videoConsumer.action") }}
                    videoId={videoId}
                    playState={playState}
                    onChanged={PlayStateAdt.matchStrict({
                        None: () => setPlayState(PlayStateAdt.as.Playing({})),
                        Pausing: () =>
                            setPlayState(PlayStateAdt.as.Playing({})),
                        Playing: () =>
                            setPlayState(PlayStateAdt.as.Pausing({})),
                        Ended: () => {},
                    })}
                    onError={console.error}
                    onPurchaseClicked={
                        !isMyContent()
                            ? () => {
                                  if (!isAuthorizedAccount) {
                                      open("CreateAccount")
                                      return
                                  }
                                  if (
                                      contentCreatorProfileQuery.isSuccess &&
                                      userId !==
                                          contentCreatorProfileQuery.data.userId
                                  ) {
                                      showVideoPayModal(true)
                                  }
                              }
                            : undefined
                    }
                />
            </Content>
            <Footer className={styles.footer}>
                {videoQuery.isSuccess &&
                    contentCreatorProfileQuery.isSuccess && (
                        <UserDetails
                            contentLastChangedAt={videoQuery.data.lastChangedAt}
                            avatarSize={36}
                            profile={contentCreatorProfileQuery.data}
                            paymentRequired={videoQuery.data.paymentRequired}
                            votes={totalVotes}
                            comments={totalComments}
                            onClicked={id => {
                                if (!isAuthorizedAccount) {
                                    navigate(userProfileByIdRoute(id))
                                    return
                                }
                                if (!userId) return

                                navigate(
                                    userId === id.toString()
                                        ? "/app/my-profile"
                                        : userProfileByIdRoute(id),
                                )
                            }}
                            onLikeChangedClicked={() => {
                                if (!isAuthorizedAccount) {
                                    open("CreateAccount")
                                    return
                                }
                                if (isVoting) return
                                if (myVideoVote.isFetching) return
                                if (!myVideoVote.isSuccess) return
                                if (!voter) return
                                if (!videoId) return

                                if (!myVideoVote.data.isLiked) {
                                    vote({
                                        voter,
                                        resourceOwnerId:
                                            videoQuery.data.creatorId,
                                        voteResourceRef: {
                                            contentType: "Video",
                                            contentId: videoId,
                                        },
                                    })
                                } else if (O.isSome(voteId)) {
                                    revokeVote({
                                        voter,
                                        voteResourceRef: {
                                            contentType: "Video",
                                            contentId: videoId,
                                        },
                                        voteId: voteId.value,
                                    })
                                }
                            }}
                            monetization={videoQuery.data.monetization}
                            liked={isLiked}
                            onCommentDetailsClicked={() => {
                                if (!isAuthorizedAccount) {
                                    open("CreateAccount")
                                    return
                                }
                                if (!videoQuery.isSuccess) return

                                setCommentOverviewVisibility(
                                    VisibilityAdt.as.Visible({}),
                                )
                            }}
                            onShareClicked={() => {
                                if (!isAuthorizedAccount) {
                                    open("CreateAccount")
                                    return
                                }
                                shareVideo()
                            }}
                            onLikeDetailsClicked={() => {
                                if (!videoQuery.isSuccess) return
                                if (totalVotes === 0) return

                                setLikingOverviewVisibility(
                                    VisibilityAdt.as.Visible({}),
                                )
                            }}
                            onPurchaseClicked={
                                !isMyContent()
                                    ? () => {
                                          if (!isAuthorizedAccount) {
                                              open("CreateAccount")
                                              return
                                          }
                                          if (
                                              videoQuery.isSuccess &&
                                              userId !==
                                                  contentCreatorProfileQuery
                                                      .data.userId
                                          ) {
                                              showVideoPayModal(true)
                                          }
                                      }
                                    : undefined
                            }
                        />
                    )}
                {videoQuery.isLoading && <UserDetailsLoading />}
            </Footer>
        </Page>
    )
}

export const VideoConsumerPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={classNames(styles.content, styles.contentLoading)}>
            <VideoDetailsLoading className={styles.userDetailsLoading} />
        </Content>
        <Footer>
            <UserDetailsLoading />
        </Footer>
    </Page>
)

export default VideoConsumerPage
