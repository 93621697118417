import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogTitle,
} from "common/dialog"
import { FC } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useNavigate } from "react-router-dom"

import { vars } from "theme/variables.css"
import * as styles from "./find-out-more-modal.css"

type FindOutMoreModalModel = {
    isOpen: boolean
    onOpenChange: (v: boolean) => void
}

export const FindOutMoreModal: FC<FindOutMoreModalModel> = model => {
    const navigate = useNavigate()

    return (
        <Dialog open={model.isOpen} onOpenChange={model.onOpenChange}>
            <DialogContent className={styles.content}>
                <FontAwesomeIcon
                    icon={faXmark}
                    className={styles.closeIcon}
                    onClick={() => model.onOpenChange(false)}
                />
                <div className={styles.mainContentWrapper}>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.l,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            Welcome
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.xxxxl,
                                fontWeight: 500,
                            }}
                        >
                            to Seemee
                        </DialogDescription>
                    </div>
                    <LazyLoadImage
                        width={window.innerWidth - 52 * 2}
                        height={(window.innerWidth - 52 * 2) * 0.855}
                        className={styles.image}
                        alt="Person wearing black headphones and a white t-shirt, holding a smartphone while looking at the screen with sunlight casting soft shadows in the background."
                        loading="eager"
                        src="/images/find-out-more-about-seemee.png"
                    />
                    <p
                        style={{
                            fontSize: vars.font.size.m,
                            fontWeight: 400,
                            margin: "28px 4px 10px",
                        }}
                    >
                        Are you ready to share your passions, build reach
                        quickly and earn money while doing it? We'll show you
                        how easy it is to bring your passions to the world and
                        be successful at it.
                    </p>
                </div>
                <DialogFooter className={styles.footer}>
                    <IonButton
                        className={styles.button}
                        color="medium"
                        disabled
                        onClick={() => {
                            model.onOpenChange(false)
                            navigate(
                                "/app/settings/rewards?currentTab=get-started",
                            )
                        }}
                    >
                        Read more
                    </IonButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
