import { makeApi } from "@zodios/core"
import z from "zod"

import {
    AccountType,
    ApiError,
    Pagination,
    PayableVideo,
    Video,
} from "../api-models"
import { VideoId } from "../branded-types"

export const videoSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/videos",
        alias: "listVideos",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    // TODO create typed meta filter instead of string
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Video),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/video/:id",
        alias: "getVideoById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: VideoId,
            },
        ],
        response: z.object({
            data: PayableVideo,
            accountType: z.optional(AccountType).default("Anonymous"),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
