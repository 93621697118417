import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { Postit, UserProfile } from "api/api-models"
import { getAssetsQueryFn } from "hooks/use-asset"
import { isImageAsset, isResourceRef } from "utils/asset"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mergeWithQueryCache,
} from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { postitSelectorClient } from "../../../api/clients/post-api-client"
import { userProfileSelectorClient } from "../../../api/clients/user-profile-api-client"
import { QueryKeys } from "../../../api/query-keys"
import { PostitArea } from "../../feed/areas/post/post-area-tile"

const PAGINATION_AMOUNT = 25

export const useProfilePostContent = (profileId?: UserProfileId) => {
    const filter = `{$match: {creatorId: in ["${profileId}"]}, $sort:{createdAt: desc}}`
    const queryClient = useQueryClient()
    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            getProfilePostContent({ pageParam, filter, queryClient }),
        queryKey: QueryKeys.profilePostContent(profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.items.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!profileId,
    })
}

const getProfilePostContent = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: number
    filter: string
    queryClient: QueryClient
}) => {
    const postsRes = await postitSelectorClient.listPostits({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    postsRes.data.forEach(post =>
        queryClient.setQueryData<Postit>(QueryKeys.postit(post.id), post),
    )

    const postResourceAssetRefs = pipe(
        postsRes.data,
        A.filter(post => post.type === "Image"),
        A.map(post => post.imageUrl),
        A.filter(isResourceRef),
    )

    const profileIds = pipe(
        postsRes.data,
        A.map(post => post.creatorId),
    )

    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        profileIds,
        QueryKeys.profile,
    )

    const getProfiles =
        missingProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("id", missingProfileIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const [profilesRes, assetsRes] = await Promise.all([
        getProfiles,
        getAssetsQueryFn({ resourceRefs: postResourceAssetRefs, queryClient }),
    ])

    profilesRes.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )

    const profiles = mergeWithQueryCache(
        queryClient,
        profileIds,
        QueryKeys.profile,
        profilesRes.data,
    )

    return pipe(
        postsRes.data,
        A.map(post => {
            const profile = profiles.find(p => p.id === post.creatorId)
            const assetInfo = post.type === "Image" ? post.imageUrl : undefined
            const assetResource =
                assetInfo && isResourceRef(assetInfo) ? assetInfo : undefined
            const asset = assetResource
                ? assetsRes.find(
                      asset => asset.id.resourceId === assetResource.resourceId,
                  )
                : undefined

            const imageAsset =
                !!asset && isImageAsset(asset) ? asset : undefined
            return {
                id: post.id,
                profile,
                post,
                createdAt: post.createdAt,
                asset: imageAsset,
            } as PostitArea
        }),
        A.filter(
            ({ profile, post }) => post !== undefined && profile !== undefined,
        ),
        items => ({
            items,
            paging: postsRes.paging,
        }),
    )
}
