import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, MessageGroup, Pagination, UserMessage } from "../api-models"
import { UserMessageId } from "../branded-types"

export const messageSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/messages",
        alias: "listMessages",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserMessage),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        description:
            "List all profiles that have or had a conversation with me",
        path: "/v1/messages/groups",
        alias: "listMessageGroups",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(MessageGroup).default([]),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/message/:id",
        alias: "getMessage",
        parameters: [
            {
                type: "Path",
                name: "id",
                schema: UserMessageId,
            },
        ],
        response: z.object({
            data: UserMessage,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
