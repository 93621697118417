import { ADTType, makeADT, ofType } from "@morphic-ts/adt"

// states
export type NoOp = { type: "NoOp" }
export type None = { type: "None" }

export type Unloaded = { type: "Unloaded" }
export type Loading = { type: "Loading" }

// visibility
type Visible = { type: "Visible" }
type Invisible = { type: "Invisible" }

export const VisibilityAdt = makeADT("type")({
    Visible: ofType<Visible>(),
    Invisible: ofType<Invisible>(),
})

export type Visibility = ADTType<typeof VisibilityAdt>
