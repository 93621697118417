import { PostitId } from "api/branded-types"
import { EDIT_POSTIT_MONETIZE_ENABLED } from "envs"
import { monetizeEqual } from "features/payment/utils"
import { usePostitById } from "features/postit/hooks/use-postit-by-id"
import { isStringInvalid } from "utils/object"
import { useEditImagePostStore } from "../store/edit-image-post-store"

type UseDerivedEditImagePostStateModel = {
    postitId?: PostitId
    isEditPostPending: boolean
    isUploadImagePending: boolean
}

export const useDerivedEditImagePostState = (
    model: UseDerivedEditImagePostStateModel,
) => {
    const editImagePostStore = useEditImagePostStore()
    const postitQuery = usePostitById(model.postitId)

    const titleValue = postitQuery.isSuccess
        ? (editImagePostStore.title ?? postitQuery.data.title)
        : undefined
    const messageValue = postitQuery.isSuccess
        ? (editImagePostStore.message ?? postitQuery.data.message)
        : undefined
    const monetizationValue = postitQuery.isSuccess
        ? (editImagePostStore.monetization ?? postitQuery.data.monetization)
        : undefined
    const backgroundImageUrlValue =
        postitQuery.isSuccess && postitQuery.data.type === "Image"
            ? (editImagePostStore.backgroundImageUrl ??
              postitQuery.data.imageUrl)
            : editImagePostStore.backgroundImageUrl

    const isTitleInvalid = isStringInvalid(titleValue)

    const imageIsTheSame =
        postitQuery.data?.type === "Image"
            ? postitQuery.data.imageUrl === backgroundImageUrlValue
            : true

    const messageIsTheSame =
        messageValue === postitQuery.data?.message ||
        (messageValue === "" && postitQuery.data?.message === undefined)

    const valuesAreSame =
        imageIsTheSame &&
        titleValue === postitQuery.data?.title &&
        messageIsTheSame &&
        monetizeEqual(monetizationValue, postitQuery.data?.monetization)

    const isFileInvalid =
        editImagePostStore.file === undefined
            ? false
            : editImagePostStore.file.size <= 0

    const isUploadButtonDisabled =
        isFileInvalid ||
        isTitleInvalid ||
        valuesAreSame ||
        model.isEditPostPending ||
        model.isUploadImagePending

    const monetizationDisabled = !EDIT_POSTIT_MONETIZE_ENABLED

    return {
        isPostitLoaded: postitQuery.isSuccess,
        title: titleValue,
        message: messageValue,
        monetization: monetizationValue,
        isFileInvalid,
        isUploadButtonDisabled,
        monetizationDisabled,
        backgroundImageUrlValue,
    }
}
