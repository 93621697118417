import { AppState } from "./state"

import { StateCreator } from "zustand"

export type ModalTypes =
    | "CreateAccount"
    | "CreateContentMenu"
    | "FirstStepsWelcome"
    | "FindOutMore"

export type AppActions = {
    open: (type: ModalTypes) => void
    close: (type: ModalTypes) => void

    setFeedScrollPosition: (position: number) => void
    setFirstWelcomeModalOpen: (v: boolean) => void

    setScrollFeedToTop: (fn: () => void) => void
    scrollFeedToTop: () => void

    setSelectedProfileImage: (file: File) => void
    removeSelectedProfileImage: () => void
}

export const createAppStore: StateCreator<AppState & AppActions> = set => ({
    feedScrollPosition: 0,

    firstWelcomeModalOpen: false,

    isModalVisible: {
        CreateAccount: false,
        CreateContentMenu: false,
        FirstStepsWelcome: false,
        FindOutMore: false,
    },

    open: dialogName =>
        set(state => ({
            isModalVisible: {
                ...state.isModalVisible,
                [dialogName]: true,
            },
        })),
    close: dialogName =>
        set(state => ({
            isModalVisible: {
                ...state.isModalVisible,
                [dialogName]: false,
            },
        })),

    setFeedScrollPosition: position =>
        set(state => ({
            ...state,
            feedScrollPosition: position,
        })),
    setFirstWelcomeModalOpen: v => set({ firstWelcomeModalOpen: v }),

    scrollFeedToTop: () => {},
    setScrollFeedToTop: fun => set({ scrollFeedToTop: fun }),

    setSelectedProfileImage: file => set({ selectedProfileImage: file }),
    removeSelectedProfileImage: () => set({ selectedProfileImage: undefined }),
})
