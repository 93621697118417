import { FC, useEffect, useState } from "react"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import { IonButton } from "@ionic/react"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { vars } from "theme/variables.css"
import { z } from "zod"
import { Checkbox } from "../../common/checkbox"
import { Content } from "../../common/content"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "../../common/drawer"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../common/form"
import { Input } from "../../common/input"
import { Page } from "../../common/page"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { usePaymentsInfo } from "../../hooks/use-payments-info"
import { useUpdatePaymentInfoMutation } from "../../hooks/use-update-payment-info-mutation"
import * as styles from "./payment-page.css"

const paymentModalValidationSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    street: z.string(),
    zip: z.string(),
    city: z.string(),
    country: z.string(),
    taxId: z.string(),
    isSalesTaxLiable: z.boolean().optional(),
})

export const PaymentPage: FC = () => {
    const [modalOpen, setModalOpen] = useState(false)

    const paymentInfo = usePaymentsInfo()
    const { mutate: updatePaymentInfo } = useUpdatePaymentInfoMutation()

    const form = useForm<z.infer<typeof paymentModalValidationSchema>>({
        resolver: zodResolver(paymentModalValidationSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            street: "",
            zip: "",
            city: "",
            country: "",
            taxId: "",
            isSalesTaxLiable: false,
        },
    })

    function onSubmit(values: z.infer<typeof paymentModalValidationSchema>) {
        const {
            city,
            country,
            firstName,
            lastName,
            street,
            taxId,
            zip,
            isSalesTaxLiable,
        } = values
        const address = JSON.stringify({
            city,
            country,
            street,
            zip,
        })
        const name = JSON.stringify({
            firstName,
            lastName,
        })
        updatePaymentInfo({
            address,
            name,
            taxId,
            isSalesTaxLiable: isSalesTaxLiable ?? false,
        })
        setModalOpen(false)
    }

    useEffect(() => {
        if (paymentInfo.isSuccess) {
            form.reset({
                firstName: paymentInfo.data.firstName,
                lastName: paymentInfo.data.lastName,
                street: paymentInfo.data.street,
                zip: paymentInfo.data.zip,
                city: paymentInfo.data.city,
                country: paymentInfo.data.country,
                taxId: paymentInfo.data.taxId,
                isSalesTaxLiable: paymentInfo.data.isSalesTaxLiable,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.reset, paymentInfo.isSuccess])

    const isNotFilled =
        paymentInfo.isSuccess &&
        paymentInfo.data.firstName.length === 0 &&
        paymentInfo.data.lastName.length === 0 &&
        paymentInfo.data.city.length === 0 &&
        paymentInfo.data.country.length === 0 &&
        paymentInfo.data.street.length === 0 &&
        paymentInfo.data.taxId.length === 0 &&
        paymentInfo.data.zip.length === 0
    return (
        <Page>
            <NavigationBackButton />
            <Content>
                <div className={styles.content}>
                    <h1 className={styles.title}>Payments</h1>
                    <p
                        style={{
                            marginTop: 8,
                            fontSize: vars.font.size.regular,
                        }}
                        className={styles.subtitle}
                    >
                        Manage your payment information.
                    </p>
                    <div className={styles.wrapper}>
                        <div className={styles.wrapperTitle}>
                            Payout information
                            {paymentInfo.isSuccess && !isNotFilled && (
                                <div className={styles.paymentInfoSection}>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {paymentInfo.data.firstName}
                                        {paymentInfo.data.lastName.length !==
                                            0 && " "}
                                        {paymentInfo.data.lastName}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                            color: vars.color.tertiary.hex,
                                        }}
                                    >
                                        {paymentInfo.data.street}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                            color: vars.color.tertiary.hex,
                                        }}
                                    >
                                        {paymentInfo.data.country}
                                        {paymentInfo.data.country.length !==
                                            0 &&
                                            paymentInfo.data.zip.length !== 0 &&
                                            ", "}
                                        {paymentInfo.data.zip}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            fontWeight: 400,
                                            color: vars.color.tertiary.hex,
                                        }}
                                    >
                                        {paymentInfo.data.taxId}
                                    </p>
                                </div>
                            )}
                        </div>
                        <Drawer open={modalOpen} onOpenChange={setModalOpen}>
                            <DrawerTrigger asChild>
                                <div className={styles.wrapperAction}>
                                    {isNotFilled
                                        ? "Add details"
                                        : "Change data"}
                                </div>
                            </DrawerTrigger>
                            <DrawerContent>
                                <Form {...form}>
                                    <form
                                        onSubmit={form.handleSubmit(onSubmit)}
                                    >
                                        <DrawerHeader>
                                            <DrawerClose asChild>
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    className={styles.closeIcon}
                                                />
                                            </DrawerClose>
                                        </DrawerHeader>
                                        <DrawerTitle />
                                        <DrawerDescription />

                                        <div className={styles.modalContent}>
                                            <h1
                                                style={{
                                                    fontSize: vars.font.size.xl,
                                                    color: vars.color.medium
                                                        .hex,
                                                    margin: 0,
                                                }}
                                            >
                                                Payout information
                                            </h1>
                                            <p
                                                style={{
                                                    margin: "8px 0 0 0",
                                                    fontSize:
                                                        vars.font.size.regular,
                                                    color: vars.color.tertiary
                                                        .hex,
                                                }}
                                            >
                                                To ensure that your payouts are
                                                deposited into your account
                                                correctly and on time, please
                                                provide all requested
                                                information. This data is
                                                necessary to manage your
                                                earnings from monetized content.
                                            </p>
                                            <div
                                                className={
                                                    styles.requiredInfoWrapper
                                                }
                                            >
                                                <p
                                                    style={{
                                                        fontSize:
                                                            vars.font.size
                                                                .regular,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Required information
                                                </p>
                                                <div
                                                    className={
                                                        styles.inputsWrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.usernameWrapper
                                                        }
                                                    >
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="firstName"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="First name"
                                                                            {...field}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />

                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="lastName"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="Last name"
                                                                            {...field}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                    <div>
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="street"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="Street, house number"
                                                                            {...field}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.cityWrapper
                                                        }
                                                    >
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="zip"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="Zip"
                                                                            {...field}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="city"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="City"
                                                                            {...field}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                    <div>
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="country"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="Country"
                                                                            {...field}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                    <div>
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="taxId"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="Tax or VAT ID"
                                                                            {...field}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <FormField
                                                    control={form.control}
                                                    name="isSalesTaxLiable"
                                                    render={({ field }) => (
                                                        <FormItem
                                                            className={
                                                                styles.checkboxSection
                                                            }
                                                        >
                                                            <FormControl>
                                                                <Checkbox
                                                                    checked={
                                                                        field.value
                                                                    }
                                                                    onCheckedChange={
                                                                        field.onChange
                                                                    }
                                                                />
                                                            </FormControl>
                                                            <FormLabel
                                                                className={classNames(
                                                                    styles.checkboxLabel,
                                                                    field.value ===
                                                                        true &&
                                                                        styles.checkboxActive,
                                                                )}
                                                            >
                                                                I am a small
                                                                business owner
                                                                according to §19
                                                                UStG and
                                                                therefore exempt
                                                                from sales tax.
                                                            </FormLabel>
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            <p
                                                style={{
                                                    margin: "12px 0 0 0",
                                                    fontSize: vars.font.size.xs,
                                                    color: vars.color.tertiary
                                                        .hex,
                                                }}
                                            >
                                                Note: The tax number is used for
                                                national tax matters in Germany.
                                                The VAT ID number is required
                                                for cross-border transactions
                                                within the EU. If you are a
                                                small business according to
                                                Section 19 UStG and do not
                                                charge sales tax, select the
                                                corresponding option.
                                            </p>
                                        </div>
                                        <DrawerFooter>
                                            <IonButton
                                                type="submit"
                                                className={styles.submitButton}
                                            >
                                                Save
                                            </IonButton>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    fontSize: vars.font.size.xs,
                                                    color: vars.color.tertiary
                                                        .hex,
                                                }}
                                            >
                                                Your billing information is
                                                saved for future payouts. You
                                                can manage it here at any time.
                                            </p>
                                        </DrawerFooter>
                                    </form>
                                </Form>
                            </DrawerContent>
                        </Drawer>
                    </div>
                </div>
            </Content>
        </Page>
    )
}

export default PaymentPage
