import { CreateAccountModal } from "features/create-account/components/create-account-modal"
import { EnableNotificationsProvider } from "features/notifications/enable-notifications-provider"
import { OnboardingProvider } from "features/onboarding/onboarding-provider"
import { PaymentModalProvider } from "features/payment/modals/payment-modal-provider"
import { PointSystemModalProvider } from "features/point-system/point-system-modal-provider"
import { ReportProvider } from "features/report/report-provider"
import { FC } from "react"
import { useAuth } from "react-oidc-context"

export const ProtectedProviders: FC = () => {
    const { isAuthenticated } = useAuth()
    if (!isAuthenticated) return <></>
    return (
        <>
            <CreateAccountModal />
            <PointSystemModalProvider />
            <ReportProvider />
            <EnableNotificationsProvider />
            <PaymentModalProvider />
            <OnboardingProvider />
        </>
    )
}
