import { useMutation, useQueryClient } from "@tanstack/react-query"

import { UserProfileId, VideoId } from "../../../api/branded-types"
import { videoCreatorClient } from "../../../api/clients/video-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../api/query-keys"

type MutationFunctionParams = {
    id: VideoId
    profileId: UserProfileId
}

const searchPageVideosQueryKey = [
    StaticQueryKeys.SEARCH,
    StaticQueryKeys.SEARCH_VIDEOS,
]

export const useDeleteVideoMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        //probably is copypasted wrongly
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_DOWN],
        mutationFn: ({ id }: MutationFunctionParams) =>
            videoCreatorClient.deleteVideoById(undefined, {
                params: {
                    id,
                },
            }),
        onSettled: (_data, _err, { profileId }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileVideoContent(profileId),
            })
            queryClient.invalidateQueries({
                queryKey: searchPageVideosQueryKey,
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileStatistics(profileId),
            })
        },
    })
}
