import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    tokenHandlerForGet,
    validationErrorHandler,
} from "../api-utils"
import { assetCreatorApi } from "../creators/asset-creator-api"
import { assetSelectorApi } from "../selectors/asset-selector-api"

// creator client
export const assetCreatorClient = new Zodios(
    `${API_URL}/asset-creator`,
    assetCreatorApi,
)

assetCreatorClient.use(tokenHandlerForCreate)
assetCreatorClient.use(corsFetchHandler)
assetCreatorClient.use(validationErrorHandler)

export const updateImageTask = pipe(
    assetCreatorClient.updateImage,
    endpointWithTaskEither,
)

// selector client
export const assetSelectorClient = new Zodios(
    `${API_URL}/asset-selector`,
    assetSelectorApi,
)

assetSelectorClient.use(tokenHandlerForGet)
assetSelectorClient.use(corsFetchHandler)
assetCreatorClient.use(validationErrorHandler)

export const listAssetsTask = pipe(
    assetSelectorClient.listAssets,
    endpointWithTaskEither,
)
