import { QueryClient } from "@tanstack/react-query"
import { ContentChangedMessage } from "api/api-models"
import { UserId, UserProfileId } from "api/branded-types"
import { QueryKeys, StaticQueryKeys } from "api/query-keys"
import { absurd } from "fp-ts/function"

type HandleContentChangedMessageModel = {
    queryClient: QueryClient
    myProfileId: UserProfileId | undefined
    myUserId: UserId | undefined
    data: ContentChangedMessage
}

export const handleContentChangedMessage = ({
    queryClient,
    myProfileId,
    myUserId,
    data,
}: HandleContentChangedMessageModel) => {
    switch (data.content) {
        case "Challenge": {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileChallengesOverview(myProfileId),
            })
            queryClient.invalidateQueries({
                queryKey: [StaticQueryKeys.PROFILE_CHALLENGE],
            })
            break
        }
        case "Profile": {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profile(myProfileId),
            })
            break
        }
        case "ProfileStatistic": {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileStatistics(myProfileId),
            })
            break
        }
        case "PushNotification": {
            queryClient.invalidateQueries({
                queryKey: [
                    StaticQueryKeys.NOTIFICATIONS,
                    StaticQueryKeys.NOTIFICATION_CENTER,
                ],
            })
            break
        }
        case "User": {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.user(myUserId),
            })
            break
        }
        case "Chat": {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.myResourceMessages(myProfileId),
            })
            break
        }
        default: {
            absurd(data.content)
            break
        }
    }
}
