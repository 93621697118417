import { useInfiniteQuery } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { mapToMatchFilterDc } from "../api/api-utils"
import { liveEventSelectorClient } from "../api/clients/live-event-api-client"
import { userSelectorClient } from "../api/clients/user-api-client"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"
import { LiveAreaFeedModel } from "../data-flow/feed/live-area-feed"

const PAGINATION_AMOUNT = 20

export const useLiveNowEvents = () => {
    const queryFn = async ({ pageParam }: { pageParam: number }) => {
        const { data: events, paging } =
            await liveEventSelectorClient.listLiveEvents({
                paging: {
                    type: "Index",
                    direction: "After",
                    limit: PAGINATION_AMOUNT,
                    index: pageParam,
                },
                filter: `{$sort: {title: asc}, $match: {state: "Running"}}`,
            })

        const profileIds = pipe(
            events,
            A.map(event => event.creatorId),
        )

        const profilesPromise = userProfileSelectorClient.listProfiles({
            filter: `{${mapToMatchFilterDc("id", profileIds)}}`,
        })
        const usersPromise = userSelectorClient.listUsers({
            filter: `{${mapToMatchFilterDc("id", profileIds)}}`,
        })
        const statisticsPromise =
            liveEventSelectorClient.listLiveEventsStatistics({
                filter: `{${mapToMatchFilterDc("creatorId", profileIds)}}`,
            })

        const [profiles, users, statistics] = await Promise.all([
            profilesPromise,
            usersPromise,
            statisticsPromise,
        ])

        return pipe(
            events,
            A.map(event => {
                const profile = profiles.data.find(
                    x => x.id === event.creatorId,
                )
                const user = users.data.find(
                    x => x.id.toString() === event.creatorId.toString(),
                )
                const statistic = statistics.data.find(
                    x => x.id.toString() === event.id.toString(),
                )

                return {
                    id: event.id,
                    profile,
                    user,
                    statistic,
                    event,
                }
            }),
            A.filter(
                x =>
                    x.profile !== undefined &&
                    x.user !== undefined &&
                    x.statistic !== undefined,
            ),
            x => ({
                liveEventAreas: x as LiveAreaFeedModel[],
                paging,
            }),
        )
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn,
        queryKey: QueryKeys.feedLiveNowEvents(),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.liveEventAreas.length !== PAGINATION_AMOUNT)
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
    })
}
