import { FC } from "react"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { PostLoadingSection } from "./videos/post-loading-tile"

export const SearchPostSubPagesLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content style={{ paddingInline: 20 }}>
            <PostLoadingSection />
        </Content>
    </Page>
)
