import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useState } from "react"
import { useCookies } from "react-cookie"
import z from "zod"

import { useNavigate } from "react-router-dom"
import { VisibilityAdt } from "../../data-flow/common"
import { CookieConsentInitialModal } from "./cookie-consent-initial-modal"
import { CookieConsentOptionsModal } from "./cookie-consent-options-modal"

export const CookiePreferences = z.object({
    essential: z.literal(true), //essential cookies are always enabled
    functional: z.boolean(),
    marketing: z.boolean(),
})

export type CookiePreferences = z.infer<typeof CookiePreferences>

export type CookiePreference = keyof CookiePreferences
export type UpdateCookiePreference<TPreference extends CookiePreference> = {
    key: TPreference
    value: CookiePreferences[TPreference]
}

export const CookieConsent: FC = () => {
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies(["cookiePreferences"])

    const cookiePreferences = pipe(
        cookies.cookiePreferences,
        O.fromNullable,
        O.map(CookiePreferences.safeParse),
    )

    const cookiePreferencesAreValid =
        O.isSome(cookiePreferences) && cookiePreferences.value.success

    const [initialModalVisibility, setInitialModalVisibility] = useState(
        cookiePreferencesAreValid
            ? VisibilityAdt.of.Invisible({})
            : VisibilityAdt.of.Visible({}),
    )
    const [optionsModalVisibility, setOptionsModalVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const [preferences, setPreferences] = useState<CookiePreferences>(
        cookiePreferencesAreValid
            ? {
                  essential: cookiePreferences.value.data.essential,
                  functional: cookiePreferences.value.data.functional,
                  marketing: cookiePreferences.value.data.marketing,
              }
            : {
                  essential: true,
                  functional: false,
                  marketing: false,
              },
    )

    const onAcceptAllClicked = () => {
        const newPreferences = {
            essential: true,
            functional: true,
            marketing: true,
        } satisfies CookiePreferences

        const expirationDate = getCookieExpirationDate()

        setPreferences(newPreferences)

        setCookie("cookiePreferences", JSON.stringify(newPreferences), {
            path: "/",
            expires: expirationDate,
            secure: true,
        })

        setInitialModalVisibility(VisibilityAdt.as.Invisible({}))
        setOptionsModalVisibility(VisibilityAdt.as.Invisible({}))
    }

    const onSaveClicked = () => {
        const expirationDate = getCookieExpirationDate()

        setCookie("cookiePreferences", JSON.stringify(preferences), {
            path: "/",
            expires: expirationDate,
            secure: true,
        })

        setOptionsModalVisibility(VisibilityAdt.as.Invisible({}))
    }

    return (
        <>
            <CookieConsentInitialModal
                isOpen={VisibilityAdt.is.Visible(initialModalVisibility)}
                onAcceptAllClicked={onAcceptAllClicked}
                onOpenChange={open =>
                    setInitialModalVisibility(
                        open
                            ? VisibilityAdt.as.Visible({})
                            : VisibilityAdt.as.Invisible({}),
                    )
                }
                onNavigateToPrivacyPolicy={() => {
                    setInitialModalVisibility(VisibilityAdt.as.Invisible({}))

                    navigate("/registration/privacy-policy")
                }}
                onOptionsClicked={() => {
                    setInitialModalVisibility(VisibilityAdt.as.Invisible({}))
                    setOptionsModalVisibility(VisibilityAdt.as.Visible({}))
                }}
            />
            <CookieConsentOptionsModal
                isOpen={VisibilityAdt.is.Visible(optionsModalVisibility)}
                preferences={preferences}
                updatePreferences={updateValues =>
                    setPreferences(prev => ({
                        ...prev,
                        [updateValues.key]: updateValues.value,
                    }))
                }
                onAcceptAllClicked={onAcceptAllClicked}
                onDidDismiss={() =>
                    setOptionsModalVisibility(VisibilityAdt.as.Invisible({}))
                }
                onNavigateToPrivacyPolicy={() => {
                    setOptionsModalVisibility(VisibilityAdt.as.Invisible({}))
                    setInitialModalVisibility(VisibilityAdt.as.Invisible({}))

                    navigate("/registration/privacy-policy")
                }}
                onSaveClicked={onSaveClicked}
            />
        </>
    )
}

export const getCookieExpirationDate = () => {
    const currentDate = new Date()
    const expirationDate = new Date(
        currentDate.setMonth(currentDate.getMonth() + 13),
    )
    return expirationDate
}
