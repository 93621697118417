import { faMessage } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import * as R from "fp-ts/Record"
import { pipe } from "fp-ts/function"
import _ from "lodash"
import { FC, useMemo, useRef } from "react"
import Skeleton from "react-loading-skeleton"
import { useNavigate } from "react-router-dom"
import { match } from "ts-pattern"
import { Content } from "../../common/content"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { fill } from "../../utils/array"
import { useMyMessages } from "./hooks/use-my-messages"
import * as styles from "./my-messages-page.css"
import {
    UserMessageListItem,
    UserMessageListLoadingItem,
} from "./user-message-list-item"

import { getIsAuthorizedAccount } from "api/api-utils"
import { motion, useInView } from "framer-motion"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { vars } from "theme/variables.css"
import { CondensedHeader } from "../../common/condensed-header"
import { Toolbar } from "../../common/toolbar"
import { getSafeArea } from "../../utils/constant"

const HEADER_HEIGHT = 62

export const MyMessagesPage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const navigate = useNavigate()
    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const headerRef = useRef(null)

    const myMessagesQuery = useMyMessages({
        me: myProfileId,
    })

    const myMessagesQueryState = match(myMessagesQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const refreshMyMessagesPage = async () => {
        await myMessagesQuery.refetch()
    }

    const profileWithMessages = useMemo(
        () =>
            pipe(
                myMessagesQuery.data?.pages ?? [],
                A.chain(page => page.messagesWithUserData),
                userMessages => _.groupBy(userMessages, e => e.id),
                R.toArray,
                A.chain(([__, profiles]) =>
                    profiles.map(profile => ({
                        ...profile,
                        messages: _.sortBy(profile.messages, ["createdAt"]),
                    })),
                ),
                sortedProfiles =>
                    _.sortBy(
                        sortedProfiles,
                        profile =>
                            -new Date(
                                _.last(profile.messages)?.createdAt ?? 0,
                            ).getTime(),
                    ),
            ),
        [myMessagesQuery.data?.pages],
    )

    const isEmpty =
        myMessagesQueryState === "Loaded" &&
        (profileWithMessages.length === 0 ||
            profileWithMessages.reduce(
                (prev, current) => prev + current.messages.length,
                0,
            ) === 0)

    const isInView = useInView(headerRef, {
        margin: `${-1 * (getSafeArea("top") + HEADER_HEIGHT)}px`,
    })

    return (
        <Page className={styles.page}>
            <Toolbar>
                <CondensedHeader className={styles.header} isInView={isInView}>
                    <p className={styles.headerTitle}>Messenger</p>
                </CondensedHeader>
            </Toolbar>
            <Content>
                <PullToRefresh onRefresh={refreshMyMessagesPage}>
                    <motion.div ref={headerRef}>
                        <p
                            style={{
                                margin: "25px 20px 10px 20px",
                                fontSize: vars.font.size.xxxl,
                                fontWeight: 700,
                            }}
                        >
                            Messenger
                        </p>
                    </motion.div>

                    <div>
                        <InfiniteScroll
                            disabled={!myMessagesQuery.hasNextPage}
                            state={myMessagesQueryState}
                            onLoadRequested={myMessagesQuery.fetchNextPage}
                            threshold="100px"
                        >
                            <div className={styles.content}>
                                <div className={styles.contentBox}>
                                    {myMessagesQuery.isSuccess &&
                                        pipe(
                                            profileWithMessages,
                                            A.mapWithIndex(
                                                (key, profileWithMessages) => {
                                                    const lastMessage = pipe(
                                                        profileWithMessages.messages,
                                                        A.last,
                                                    )

                                                    if (O.isNone(lastMessage))
                                                        return <></>

                                                    return (
                                                        <UserMessageListItem
                                                            key={`user-message-list-item-${key}`}
                                                            className={
                                                                styles.userListItem
                                                            }
                                                            onClicked={id =>
                                                                navigate(
                                                                    `/app/message/profile/${id}`,
                                                                )
                                                            }
                                                            profile={
                                                                profileWithMessages
                                                            }
                                                            time={
                                                                lastMessage
                                                                    .value
                                                                    .createdAt
                                                            }
                                                            message={
                                                                lastMessage
                                                                    .value.text
                                                            }
                                                            newMessageIds={
                                                                profileWithMessages.newMessageIds
                                                            }
                                                            avatarSize={48}
                                                        />
                                                    )
                                                },
                                            ),
                                        )}

                                    {myMessagesQueryState === "Loading" &&
                                        fill(5, key => (
                                            <UserMessageListLoadingItem
                                                key={key}
                                            />
                                        ))}
                                </div>
                                {isEmpty && (
                                    <div className={styles.placeholderWrapper}>
                                        {myMessagesQueryState === "Loaded" && (
                                            <div
                                                className={
                                                    styles.noMessagesWrapper
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faMessage}
                                                    style={{
                                                        width: "32px",
                                                        height: "32px",
                                                        marginBottom: "20px",
                                                    }}
                                                    color={
                                                        vars.color.tertiary.hex
                                                    }
                                                />
                                                <p
                                                    style={{
                                                        fontSize:
                                                            vars.font.size
                                                                .regular,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    You have no messages
                                                </p>
                                                <p
                                                    style={{
                                                        fontSize:
                                                            vars.font.size
                                                                .regular,
                                                        color: vars.color
                                                            .tertiary.hex,
                                                    }}
                                                >
                                                    If you receive a message, it
                                                    will be displayed here.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </InfiniteScroll>
                    </div>
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const MyMessagesLoadingPage: FC = () => (
    <Page className={styles.page}>
        <NavigationBackButton />
        <Content className={styles.content}>
            <Skeleton
                style={{ marginBottom: 10 }}
                width={196}
                height={30}
                borderRadius={16}
            />
            <div className={styles.contentBox}>
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
            </div>
        </Content>
    </Page>
)

export default MyMessagesPage
