import { CSSProperties, FC } from "react"
// import { faCircleStar as lightStar } from "@fortawesome/pro-light-svg-icons";
import { faCircleStar as solidStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonLabel } from "@ionic/react"
import classNames from "classnames"
import { flow, pipe } from "fp-ts/function"
import {
    ImageAsset,
    ImagePost,
    TextPost,
    UserProfile,
} from "../../../../api/api-models"
import { PostitId, Url, UserProfileId } from "../../../../api/branded-types"
import { Avatar } from "../../../../components/avatars/avatar"
import { stopPropagation as stopClickPropagation } from "../../../../utils/fp"
import { truncateText } from "../../../../utils/text"
import { VideoAreaFeedModel } from "../videos/video-area-tile"

import { Image } from "common/image"
import { LinkItUrl } from "features/linkify"
import { messageDateTimeInfo } from "features/messaging/time-format"
import { vars } from "theme/variables.css"
import * as styles from "./post-area-tile.css"

type BasePostitArea = {
    id: PostitId
    profile: UserProfile
    createdAt: string
}

type TextPostitArea = BasePostitArea & {
    post: TextPost
}

type ImagePostitArea = BasePostitArea & {
    post: ImagePost
    //todo: remove the optional type when imageUrl is only ResourceRef
    asset?: ImageAsset
}

export type PostitArea = TextPostitArea | ImagePostitArea

export type PostAreaTileModel = {
    className?: string
    style?: CSSProperties | undefined
    area: PostitArea
    onUserClicked: (id: UserProfileId) => void
    onClicked: (id: PostitId) => void
}

export const PostAreaTile: FC<PostAreaTileModel> = ({
    className = "",
    area,
    onUserClicked,
    onClicked,
}) => {
    const shouldBeSecured = area.post.monetization.type === "SubscriptionOnly"
    const aspectRatio =
        isImagePostArea(area) && area.asset
            ? area.asset.resolution.width / area.asset.resolution.height
            : undefined
    const desiredImageWidth = window.innerWidth - 40
    const desiredImageHeight = aspectRatio
        ? desiredImageWidth / aspectRatio
        : undefined
    return (
        <div
            className={classNames(styles.wrapper, className)}
            onClick={() => onClicked(area.post.id)}
        >
            {isImagePostArea(area) && shouldBeSecured ? (
                <div
                    className={classNames(
                        styles.payableMark,
                        styles.payableMarkAbsolute,
                    )}
                >
                    <FontAwesomeIcon
                        style={{
                            width: 16,
                            height: 16,
                        }}
                        icon={solidStar}
                        color="white"
                    />
                    <p
                        className={styles.message}
                        style={{
                            fontSize: vars.font.size.regular,
                            fontWeight: 600,
                        }}
                    >
                        Exclusive Post
                    </p>
                </div>
            ) : (
                <></>
            )}
            {isImagePostArea(area) && (
                <div className={styles.imageWrapper}>
                    {shouldBeSecured && (
                        <div className={styles.secureOverlay} />
                    )}
                    <Image
                        imageType={area.asset?.imageType}
                        alt="post image"
                        className={
                            shouldBeSecured
                                ? styles.progressiveImageSecured
                                : ""
                        }
                        src={
                            area.asset?.source ??
                            (`${area.post.imageUrl}?width=${desiredImageWidth}` as Url)
                        }
                        width={desiredImageWidth}
                        height={desiredImageHeight}
                    />
                </div>
            )}

            <div className={styles.content}>
                <div className={classNames(styles.textContent)}>
                    <p
                        className={styles.title}
                        style={{ fontSize: vars.font.size.m, fontWeight: 600 }}
                    >
                        {area.post.title}
                    </p>

                    {area.post.message ? (
                        <p
                            className={styles.message}
                            style={{ fontSize: vars.font.size.regular }}
                        >
                            <LinkItUrl
                                onLinkClicked={e => e.stopPropagation()}
                                className={styles.link}
                            >
                                {pipe(area.post.message, truncateText(200))}
                            </LinkItUrl>
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <div className={styles.divider} />

            <div
                className={styles.footer}
                onClick={flow(stopClickPropagation, () =>
                    onUserClicked(area.profile.id),
                )}
            >
                <Avatar
                    className={styles.avatar}
                    src={area.profile.imageUrl}
                    size={36}
                    styleState={area.profile.isLive ? "isLive" : "None"}
                />

                <div>
                    <IonLabel>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                color: "white",
                            }}
                        >
                            {area.profile.displayName ??
                                area.profile.profileName}
                        </p>
                    </IonLabel>
                    <IonLabel>
                        <p
                            style={{
                                marginTop: "2px",
                                fontSize: vars.font.size.xs,
                                color: "white",
                                opacity: 0.5,
                            }}
                        >
                            {messageDateTimeInfo(area.createdAt)}
                        </p>
                    </IonLabel>
                </div>
            </div>
        </div>
    )
}

export const isPostitArea = (
    postArea: VideoAreaFeedModel | PostitArea,
): postArea is PostitArea => Object.hasOwn(postArea, "post")

export const isVideoArea = (
    postArea: VideoAreaFeedModel | PostitArea,
): postArea is VideoAreaFeedModel => Object.hasOwn(postArea, "video")

export const isImagePostArea = (area: PostitArea): area is ImagePostitArea =>
    area.post.type === "Image"
