import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import {
    FC,
    UIEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react"
import { useTranslation } from "react-i18next"

import { useLiveNowEvents } from "../../hooks/use-live-now-events"
import { useMe } from "../../hooks/use-me"
import { useMyUserProfile } from "../../hooks/use-my-user-profiles"
import { useRecommendedPostits } from "../../hooks/use-recommended-postits"
import { useRecommendedVideos } from "../../hooks/use-recommended-videos"
import { useTopUsers } from "../../hooks/use-top-users"
import { LocalStorage } from "../../local-storage"

import { MyProfileAvatar } from "../user-profile/my-profile-avatar"
import {
    LiveNowAreaView,
    LiveNowAreaViewLoading,
} from "./areas/live-now/live-now-area-view"
import {
    TopUserAreaView,
    TopUserAreaViewLoading,
} from "./areas/top-users/top-user-area-view"
import { RecommendedPostAreaView } from "./areas/videos/recommended-post-area-view"

import { faBell } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import {
    getIsAuthorizedAccount,
    withAuthorizedAccess,
} from "../../api/api-utils"
import { UserProfileId } from "../../api/branded-types"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { Toolbar } from "../../common/toolbar"
import { useUnreadNotifications } from "../notifications/hooks/use-unread-notifications"
import {
    liveVideoByIdRoute,
    postByIdRoute,
    userProfileByIdRoute,
    videoByIdRoute,
} from "../routing/routing"

import Skeleton from "react-loading-skeleton"
import { PostLoadingSection } from "../search/videos/post-loading-tile"

import logo from "/app-logo.svg"

import { RewardPointSystemTeaser } from "features/point-system/reward-point-system-teaser"
import { useProfileStatistics } from "features/user-profile/hooks/use-profile-statistic"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import { useDebouncedCallback } from "use-debounce"
import * as styles from "./feed-overview-page.css"

export const FeedOverviewPage: FC = () => {
    const open = useAppStore(store => store.open)
    const feedScrollPosition = useAppStore(store => store.feedScrollPosition)
    const setFeedScrollPosition = useAppStore(
        store => store.setFeedScrollPosition,
    )
    const setScrollFeedToTop = useAppStore(state => state.setScrollFeedToTop)

    const navigate = useNavigate()
    const contentRef = useRef<HTMLDivElement | null>(null)

    const debouncedHandleScroll = useDebouncedCallback(
        (currentScroll: number) => {
            setFeedScrollPosition(currentScroll)
        },
        250,
    )

    const { t: tFeed } = useTranslation(["feed"])
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    useEffect(() => {
        if (!contentRef.current) return
        contentRef.current.scrollTop = feedScrollPosition
    }, [])

    useEffect(() => {
        setScrollFeedToTop(async () => {
            if (!contentRef.current) return
            contentRef.current.scrollTop = 0

            await refreshFeedPage()
        })
    }, [setScrollFeedToTop])

    const myProfilesQuery = useMyUserProfile(isAuthorizedAccount)
    const liveNowEventsQuery = useLiveNowEvents()

    const userId = myProfilesQuery.isSuccess
        ? myProfilesQuery.data?.userId
        : undefined

    const profileId = myProfilesQuery.isSuccess
        ? myProfilesQuery.data?.id
        : undefined

    const feedRecommendedVideosQuery = useRecommendedVideos(profileId)
    const feedRecommendedPostsQuery = useRecommendedPostits(profileId)

    const myProfileStatistics = useProfileStatistics(profileId)

    const topUsersQuery = useTopUsers(userId)

    const newNotificationsQuery = useUnreadNotifications(profileId)
    const isHaveNewNotifications =
        (newNotificationsQuery.data?.totalCount ?? 0) > 0

    const handleUserClick = useCallback(
        (id: UserProfileId) => {
            if (!isAuthorizedAccount) {
                navigate(userProfileByIdRoute(id))
                return
            }
            if (!userId) return

            navigate(
                userId === id.toString()
                    ? "/app/my-profile"
                    : userProfileByIdRoute(id),
            )
        },
        [isAuthorizedAccount, navigate, userId],
    )

    useEffect(() => {
        //TODO: this is bad, but without it styling breaks as render is not finished fully
        setTimeout(() => {
            if (!isAuthorizedAccount) open("CreateAccount")
        }, 200)
    }, [open, isAuthorizedAccount])

    const refreshFeedPage = async () => {
        await Promise.allSettled([
            myProfilesQuery.refetch(),
            meQuery.refetch(),
            topUsersQuery.refetch(),
            liveNowEventsQuery.refetch(),
            feedRecommendedVideosQuery.refetch(),
            feedRecommendedPostsQuery.refetch(),
            newNotificationsQuery.refetch(),
        ])
    }

    const handleScroll: UIEventHandler<HTMLDivElement> = event => {
        const target = event.currentTarget as HTMLDivElement
        const scrollTop = target.scrollTop

        debouncedHandleScroll(scrollTop)
    }

    return (
        <Page className={styles.page}>
            <Content ref={contentRef} onScroll={handleScroll}>
                <PullToRefresh onRefresh={refreshFeedPage}>
                    <button
                        onClick={() => open("FindOutMore")}
                        className={styles.learnMoreWrapper}
                    >
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                fontWeight: 400,
                            }}
                        >
                            Find out more about Seemee
                        </p>
                    </button>
                    <svg className={styles.logo}>
                        <use href={`${logo}#app_logo`} />
                    </svg>
                    <div className={styles.myAvatarContainer}>
                        {myProfileStatistics.isSuccess && (
                            <div
                                className={styles.pointsAmountContainer}
                                onClick={() =>
                                    navigate(
                                        "/app/settings/rewards?currentTab=get-started",
                                    )
                                }
                            >
                                <p
                                    style={{
                                        fontSize: vars.font.size.xs,
                                        fontWeight: 700,
                                    }}
                                >
                                    {myProfileStatistics.data.points}
                                </p>
                            </div>
                        )}
                        <div className={styles.notificationIconWrapper}>
                            {isHaveNewNotifications && (
                                <div className={styles.newNotificationBadge} />
                            )}
                            <FontAwesomeIcon
                                icon={faBell}
                                color="white"
                                className={styles.notificationIcon}
                                onClick={withAuthorizedAccess({
                                    onAuthorized: () =>
                                        navigate("/app/notifications"),
                                    onUnauthorized: () => open("CreateAccount"),
                                    accountType,
                                })}
                            />
                        </div>
                        <MyProfileAvatar
                            isAuthorizedAccount={isAuthorizedAccount}
                            avatarSize={40}
                            onUserClicked={() => {
                                if (!isAuthorizedAccount) {
                                    open("CreateAccount")
                                    return
                                }
                                if (!userId) return

                                navigate("/app/my-profile")
                            }}
                        />
                    </div>

                    <LiveNowAreaView
                        text={{
                            titleA: tFeed("feedOverView.liveAreaView.titleA"),
                            titleB: tFeed("feedOverView.liveAreaView.titleB"),
                            userDetails: {
                                liveState: tFeed(
                                    "feedOverView.liveAreaView.userDetails.liveState.text",
                                ),
                            },
                            placeholder: tFeed(
                                "feedOverView.liveAreaView.placeHolder",
                            ),
                            action: tFeed("feedOverView.liveAreaView.action"),
                            account: {
                                title: tFeed(
                                    "feedOverView.liveAreaView.verifyAccount.title",
                                ),
                                message: tFeed(
                                    "feedOverView.liveAreaView.verifyAccount.message",
                                ),
                                cancelTitle: tFeed(
                                    "feedOverView.liveAreaView.verifyAccount.cancel.text",
                                ),
                                createAccountTitle: tFeed(
                                    "feedOverView.liveAreaView.verifyAccount.action",
                                ),
                            },
                        }}
                        onUserClicked={id => {
                            if (!isAuthorizedAccount) {
                                navigate(userProfileByIdRoute(id))
                                return
                            }
                            if (!userId) return

                            navigate(
                                userId === id.toString()
                                    ? "/app/my-profile"
                                    : userProfileByIdRoute(id),
                            )
                        }}
                        onGoLiveClicked={withAuthorizedAccess({
                            onAuthorized: () =>
                                navigate("/app/live-event/creator"),
                            onUnauthorized: () => open("CreateAccount"),
                            accountType,
                        })}
                        onClicked={flow(liveVideoByIdRoute, route =>
                            navigate(route),
                        )}
                    />

                    <TopUserAreaView
                        title={tFeed("feedOverView.areas.topUser.title")}
                        userId={userId}
                        onUserClicked={handleUserClick}
                    />

                    {/* <div style={{ margin: "16px 20px" }}>
                        <FeedPointSystemTeaser />
                    </div> */}

                    <div
                        style={{
                            margin: "0px 20px",
                            marginTop: "20px",
                        }}
                    >
                        <RewardPointSystemTeaser />
                    </div>

                    <RecommendedPostAreaView
                        onPostSelected={flow(postByIdRoute, route =>
                            navigate(route),
                        )}
                        title={tFeed("feedOverView.areas.videos.title")}
                        placeholder="It's pretty quiet here. Create a post or live stream now."
                        profileId={profileId}
                        onUserClicked={id => {
                            if (!isAuthorizedAccount) {
                                navigate(userProfileByIdRoute(id))
                                return
                            }
                            if (!userId) return

                            navigate(
                                userId === id.toString()
                                    ? "/app/my-profile"
                                    : userProfileByIdRoute(id),
                            )
                        }}
                        onVideoSelected={flow(videoByIdRoute, route =>
                            navigate(route),
                        )}
                        className={styles.videoAreaWrapper}
                    />
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const FeedOverviewPageLoading = () => (
    <Page className={styles.page}>
        <Toolbar className={styles.toolbar}>
            <Skeleton
                baseColor={vars.color.dark.hex}
                width={40}
                height={40}
                circle
            />
        </Toolbar>
        <Content>
            <svg className={styles.logo}>
                <use href={`${logo}#app_logo`} />
            </svg>
            <LiveNowAreaViewLoading />
            <TopUserAreaViewLoading />
            <div style={{ marginInline: 20, marginTop: 24 }}>
                <PostLoadingSection />
                <div style={{ marginTop: 24 }} />
                <PostLoadingSection />
            </div>
        </Content>
    </Page>
)

export default FeedOverviewPage
