import { ReportContentModal } from "features/report/report-content-modal"
import { FC } from "react"
import { useReportStore } from "./report-store"
import { ReportUserModal } from "./report-user-modal"

export const ReportProvider: FC = () => {
    const reportContentOpen = useReportStore(store => store.reportContentOpen)
    const setReportContentOpen = useReportStore(
        store => store.setReportContentOpen,
    )
    const resetReportContent = useReportStore(store => store.resetReportContent)

    const reportUserOpen = useReportStore(store => store.reportUserOpen)
    const setReportUserOpen = useReportStore(store => store.setReportUserOpen)
    const resetReportUser = useReportStore(store => store.resetReportUser)

    return (
        <>
            <ReportContentModal
                open={reportContentOpen}
                onClose={() => {
                    setReportContentOpen({ open: false })
                }}
                onReset={() => resetReportContent()}
            />
            <ReportUserModal
                open={reportUserOpen}
                onClose={() => {
                    setReportUserOpen({ open: false })
                }}
                onReset={() => resetReportUser()}
            />
        </>
    )
}
