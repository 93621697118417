import { FC } from "react"
import { useInAppToastNotifications } from "../hooks/use-in-app-toast-notifications"

type NotificationProviderModel = {
    children: React.ReactNode
}

export const NotificationProvider: FC<NotificationProviderModel> = model => {
    useInAppToastNotifications()
    return model.children
}
