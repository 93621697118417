import { faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import * as styles from "./list.css"

export type ListProps = JSX.IntrinsicElements["div"]

export const List = React.forwardRef<HTMLDivElement, ListProps>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={classNames(styles.list, className)}
                {...props}
            />
        )
    },
)

export type ListItemProps = JSX.IntrinsicElements["div"] & {
    detail?: boolean
}

export const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(
    ({ className, children, detail, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={classNames(styles.listItem, className)}
                {...props}
            >
                {children}
                {detail && (
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        className={styles.detail}
                    />
                )}
            </div>
        )
    },
)
