import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
    faArrowUpFromSquare,
    faLinkHorizontal,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonLabel, IonModal } from "@ionic/react"
import classNames from "classnames"
import { FC, useRef } from "react"

import { vars } from "theme/variables.css"
import * as styles from "./share-overview-modal.css"

export type ShareOverviewModalModel = {
    title: string
    isOpen: boolean
    canShare: boolean
    onCopyLinkClicked: () => void
    onShareToClicked: () => void
    onClosed: () => void
}

export const ShareOverviewModal: FC<ShareOverviewModalModel> = ({
    title,
    isOpen,
    canShare,
    onCopyLinkClicked,
    onShareToClicked,
    onClosed,
}) => {
    const modal = useRef<HTMLIonModalElement>(null)

    return (
        <IonModal
            ref={modal}
            className={styles.modal}
            canDismiss
            isOpen={modal.current !== null && isOpen}
            onDidDismiss={onClosed}
            initialBreakpoint={1}
            breakpoints={[1]}
            animated
            backdropDismiss
            showBackdrop
        >
            <div className={styles.header}>
                <IonLabel>
                    <p
                        style={{
                            fontSize: vars.font.size.m,
                            fontWeight: 700,
                            color: "black",
                        }}
                    >
                        {title}
                    </p>
                </IonLabel>
            </div>

            <div className={styles.wrapper}>
                <ActionTile
                    title="share to"
                    className={styles.shareIcon}
                    isDisabled={!canShare}
                    icon={faArrowUpFromSquare}
                    onClicked={() => {
                        onClosed()
                        onShareToClicked()
                    }}
                />
                <ActionTile
                    title="copy link"
                    icon={faLinkHorizontal}
                    onClicked={() => {
                        onClosed()
                        onCopyLinkClicked()
                    }}
                />
            </div>
        </IonModal>
    )
}

type ActionTileModel = {
    className?: string
    title: string
    isDisabled?: boolean
    icon: IconProp
    onClicked?: () => void
}

const ActionTile: FC<ActionTileModel> = ({
    className = "",
    title,
    icon,
    isDisabled = false,
    onClicked,
}) => (
    <div
        onClick={onClicked}
        style={
            isDisabled
                ? {
                      pointerEvents: "none",
                      opacity: "0.4",
                  }
                : {}
        }
    >
        <div className={styles.actionTileImage}>
            <FontAwesomeIcon
                className={classNames(styles.icon, className)}
                icon={icon}
                onClick={onClicked}
            />
        </div>
        <div className={styles.actionTileTitle}>
            <p style={{ fontSize: vars.font.size.m }}>{title}</p>
        </div>
    </div>
)
