import { Slot } from "@radix-ui/react-slot"
import classNames from "classnames"
import * as React from "react"

import * as styles from "./button.css"

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: keyof typeof styles.variantStyles
    size?: keyof typeof styles.sizeStyles
    asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        { variant = "default", size = "default", asChild = false, ...props },
        ref,
    ) => {
        const Comp = asChild ? Slot : "button"
        return (
            <Comp
                className={classNames(
                    styles.baseButton,
                    styles.variantStyles[variant],
                    styles.sizeStyles[size],
                    props.className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
Button.displayName = "Button"

export { Button }
