import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, Pagination, Payment } from "../api-models"
import { PaymentId } from "../branded-types"

export const paymentSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/payments",
        alias: "listPayments",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(Payment),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/payment/:id",
        alias: "getPaymentById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: PaymentId,
            },
        ],
        response: z.object({
            data: Payment,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
