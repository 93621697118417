import { IonSkeletonText } from "@ionic/react"
import { Url } from "api/branded-types"
import classNames from "classnames"
import { Image } from "common/image"
import {
    DatabaseNotificationWithDetails,
    isPostCommentNotification,
    isPostLikeNotification,
    isPostNotification,
    isUserProfileNotification,
    isVideoCommentNotification,
    isVideoLikeNotification,
    isVideoNotification,
    NotificationDetailType,
} from "data-flow/notification"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { forwardRef, useCallback } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useNavigate } from "react-router-dom"
import { vars } from "theme/variables.css"
import { Avatar } from "../../components/avatars/avatar"
import { AvatarLoading } from "../../components/avatars/avatar-loading"
import { stopPropagation } from "../../utils/fp"
import { formatRelativeTimeShortToParts } from "../locales/formats"
import { getNavigateUrlByActionDocument } from "./notification"
import * as styles from "./notification-center-item.css"

export type NotificationCenterItemModel<T extends NotificationDetailType> = {
    className?: string
    notification: DatabaseNotificationWithDetails<T>
}

const NotificationCenterItemInner = <T extends NotificationDetailType>(
    model: NotificationCenterItemModel<T>,
    ref: React.Ref<HTMLDivElement>,
) => {
    const navigate = useNavigate()
    const navigateUrl = getNavigateUrlByActionDocument(
        model.notification.pushNotification.clickAction,
    )

    const navigateToContent = useCallback(() => {
        // FIXME
        if (navigateUrl instanceof Error) return

        navigate(navigateUrl)
    }, [navigateUrl, navigate])
    const navigateToUserProfile = useCallback(
        () =>
            pipe(
                model.notification.details?.userProfile.id,
                O.fromNullable,
                O.map(profileId => navigate(`/app/user-profile/${profileId}`)),
            ),
        [model.notification.details?.userProfile.id, navigate],
    )
    if (model.notification.type === "NoDetail") return <></>

    const read = model.notification.notificationType === "UserNotification"
    let contents: React.ReactNode = <div style={{ width: 46, height: 53 }} />

    if (isVideoNotification(model.notification)) {
        contents = model.notification.details.video.previewImageUrl ? (
            <LazyLoadImage
                //todo: add empty placeholder image
                src={`${model.notification.details.video.previewImageUrl}?height=106`}
                className={styles.image}
                width={46}
                height={53}
                alt="video preview image"
            />
        ) : (
            <></>
        )
    }

    if (isVideoLikeNotification(model.notification)) {
        contents = model.notification.details.video.previewImageUrl ? (
            <LazyLoadImage
                //todo: add empty placeholder image
                src={`${model.notification.details.video.previewImageUrl}?height=106`}
                className={styles.image}
                width={46}
                height={53}
                alt="video preview image"
            />
        ) : (
            <></>
        )
    }

    if (isVideoCommentNotification(model.notification)) {
        contents = model.notification.details.video.previewImageUrl ? (
            <LazyLoadImage
                //todo: add empty placeholder image
                src={`${model.notification.details.video.previewImageUrl}?height=106`}
                className={styles.image}
                width={46}
                height={53}
                alt="video preview image"
            />
        ) : (
            <></>
        )
    }

    if (
        isPostNotification(model.notification) &&
        model.notification.details.post.type === "Image"
    ) {
        contents = (
            <Image
                imageType={model.notification.details.asset?.imageType}
                src={
                    model.notification.details.asset?.source ??
                    (`${model.notification.details.post.imageUrl}?height=106` as Url)
                }
                className={styles.image}
                width={46}
                height={53}
                alt="post preview image"
            />
        )
    }

    if (
        isPostLikeNotification(model.notification) &&
        model.notification.details.post.type === "Image"
    ) {
        contents = (
            <Image
                imageType={model.notification.details.asset?.imageType}
                src={
                    model.notification.details.asset?.source ??
                    (`${model.notification.details.post.imageUrl}?height=106` as Url)
                }
                className={styles.image}
                width={46}
                height={53}
                alt="post preview image"
            />
        )
    }

    if (
        isPostCommentNotification(model.notification) &&
        model.notification.details.post.type === "Image"
    ) {
        contents = (
            <Image
                imageType={model.notification.details.asset?.imageType}
                src={
                    model.notification.details.asset?.source ??
                    (`${model.notification.details.post.imageUrl}?height=106` as Url)
                }
                className={styles.image}
                width={46}
                height={53}
                alt="post preview image"
            />
        )
    }

    if (isUserProfileNotification(model.notification)) {
        contents = <button className={styles.showButton}>Show</button>
    }
    return (
        <div
            ref={ref}
            className={classNames(styles.item, model.className)}
            onClick={navigateToContent}
            data-id={model.notification.id}
        >
            {!read && <div className={styles.unreadBadge} />}
            <Avatar
                onClicked={flow(stopPropagation, navigateToUserProfile)}
                size={40}
                src={model.notification.details?.userProfile.imageUrl}
            />
            <div className={styles.textContent}>
                <p
                    style={{ fontSize: vars.font.size.regular }}
                    className={classNames(
                        styles.text,
                        !read ? styles.textBold : "",
                    )}
                >
                    {model.notification.details?.userProfile.displayName ||
                        model.notification.details?.userProfile.profileName}
                </p>
                <div className={styles.descriptionBlock}>
                    <p
                        style={{ fontSize: vars.font.size.regular }}
                        className={classNames(styles.text, styles.title)}
                    >
                        {model.notification.pushNotification.title}
                    </p>
                    <p
                        style={{ fontSize: vars.font.size.regular }}
                        className={classNames(styles.title, styles.time)}
                    >
                        {" . "}
                        {formatNotificationSentDate(
                            model.notification.createdAt,
                        )}
                    </p>
                </div>
                <p
                    style={{ fontSize: vars.font.size.regular }}
                    className={classNames(
                        styles.text,
                        !read ? styles.textBold : "",
                    )}
                >
                    {model.notification.pushNotification.message}
                </p>
            </div>
            {contents}
        </div>
    )
}

export const NotificationCenterItem = forwardRef(NotificationCenterItemInner)
//TODO: implement proper way to localize relative time to fit our needs
const formatNotificationSentDate = (date: string) =>
    pipe(
        formatRelativeTimeShortToParts(date),
        O.fold(
            () => "",
            ({ count, timeFrame, tense }) =>
                tense === "now" ? "just now" : `${count} ${timeFrame}`,
        ),
    )

type NotificationCenterLoadingItemModel = {
    className?: string
    hasImage?: boolean
}

export const NotificationCenterLoadingItem = (
    model: NotificationCenterLoadingItemModel,
) => (
    <div className={classNames(styles.item, model.className)}>
        <AvatarLoading baseColor="#3C375C" size={42} />
        <div className={styles.textContent}>
            <IonSkeletonText
                animated
                style={{
                    width: "100%",
                    height: 15,
                    borderRadius: vars.measurement.radius.xs,
                }}
            />
            <IonSkeletonText
                animated
                style={{
                    width: "60%",
                    height: 15,
                    borderRadius: vars.measurement.radius.xs,
                }}
            />
        </div>
        {model.hasImage ? (
            <IonSkeletonText
                animated
                style={{
                    width: 46,
                    height: 53,
                    margin: 0,
                    borderRadius: vars.measurement.radius.md,
                }}
            />
        ) : (
            <div style={{ width: 46, height: 53 }} />
        )}
    </div>
)
