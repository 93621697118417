import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { UserProfile } from "../api/api-models"
import { mapToMatchFilterDc } from "../api/api-utils"
import { PostitId, UserProfileId, VideoId } from "../api/branded-types"
import {
    userProfileCreatorClient,
    userProfileSelectorClient,
} from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 25

export type UseListSubscribers = {
    id?: UserProfileId
    pageParam: number
}

export const useListSubscribers = (param: UseListSubscribers) => {
    const queryKey = QueryKeys.profileSubscriptions(param.id)

    const queryFn = async (param: UseListSubscribers) => {
        if (!param.id) throw new Error("UserProfileId is required")

        return await userProfileSelectorClient.listSubscriberOfUserProfileId(
            {
                paging: {
                    type: "Index",
                    direction: "After",
                    limit: PAGINATION_AMOUNT,
                    index: param.pageParam,
                },
            },
            { params: { id: param.id } },
        )
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn,
        getNextPageParam: lastPage => {
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!param.id,
    })
}

export type UseIsAlreadySubscribingModel = {
    myId?: UserProfileId
    idToSubscribe?: UserProfileId
}

export const useIsAlreadySubscribing = (
    param: UseIsAlreadySubscribingModel,
) => {
    const queryFn = async (myId?: UserProfileId, id?: UserProfileId) => {
        if (!id) throw new Error("UserProfileId is required")
        if (!myId) throw new Error("My UserProfileId is required")

        const rs =
            await userProfileSelectorClient.listSubscriberOfUserProfileId(
                { filter: `{${mapToMatchFilterDc("id", [myId])}}` },
                { params: { id } },
            )

        return rs.data.length > 0
    }

    return useQuery({
        queryFn: () => queryFn(param.myId, param.idToSubscribe),
        queryKey: QueryKeys.profileSubscriptions(param.idToSubscribe),
        enabled: !!param.myId && !!param.idToSubscribe,
    })
}

export type useSubscribeUserProfileMutationModel = {
    myId: UserProfileId
    idToSubscribe: UserProfileId
    videoId?: VideoId
    postitId?: PostitId
}

export const useSubscribeUserProfile = () => {
    const client = useQueryClient()

    return {
        onSubscribe: useMutation({
            mutationFn: (param: useSubscribeUserProfileMutationModel) =>
                userProfileCreatorClient.subscribeUserProfile(
                    { profileId: param.myId },
                    { params: { id: param.idToSubscribe } },
                ),

            onMutate: ({ myId }) => {
                const prevSubKey = QueryKeys.profileSubscriptions(myId)

                client.cancelQueries({ queryKey: prevSubKey })
                const prevSubs = client.getQueryData<UserProfile[]>(prevSubKey)
                // TODO add subscribed user profile

                return { prevSubs }
            },

            onError: err => {
                console.error(err)
            },

            onSettled: (_data, _err, { myId, videoId, postitId }) => {
                client.invalidateQueries({
                    queryKey: QueryKeys.profileSubscriptions(myId),
                })
                client.invalidateQueries({
                    queryKey: QueryKeys.video(videoId),
                })
                client.invalidateQueries({
                    queryKey: QueryKeys.payableVideo(videoId),
                })
                client.invalidateQueries({
                    queryKey: QueryKeys.postit(postitId),
                })
                client.invalidateQueries({
                    queryKey: QueryKeys.payablePostit(postitId),
                })
            },
        }),

        onUnsubscribe: useMutation({
            mutationFn: (param: useSubscribeUserProfileMutationModel) =>
                userProfileCreatorClient.unsubscribeUserProfile(
                    { profileId: param.myId },
                    { params: { id: param.idToSubscribe } },
                ),

            onMutate: ({ myId }) => {
                const prevSubKey = QueryKeys.profileSubscriptions(myId)

                client.cancelQueries({ queryKey: prevSubKey })
                const prevSubs = client.getQueryData<UserProfile[]>(prevSubKey)

                if (prevSubs) {
                    return { prevSubs: prevSubs.filter(x => x.id === myId) }
                }

                return { prevSubs }
            },

            onError: err => {
                console.error(err)
            },

            onSettled: (_data, _err, { myId }) => {
                client.invalidateQueries({
                    queryKey: QueryKeys.profileSubscriptions(myId),
                })
            },
        }),
    }
}
