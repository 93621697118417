import { faCirclePlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"
import { InfiniteData } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC } from "react"
import { match } from "ts-pattern"

import { AccountType, Pagination } from "../../../api/api-models"
import { mergeSortedInfiniteData } from "../../../api/api-utils"
import { PostitId, UserProfileId, VideoId } from "../../../api/branded-types"
import { fill } from "../../../utils/array"
import {
    PostAreaTile,
    PostitArea,
    isPostitArea,
    isVideoArea,
} from "../../feed/areas/post/post-area-tile"
import {
    VideoAreaFeedModel,
    VideoAreaTile,
} from "../../feed/areas/videos/video-area-tile"
import { PostLoadingTile } from "../../search/videos/post-loading-tile"
import { useProfilePostContent } from "../hooks/use-profile-post-content"
import { useProfileVideoContent } from "../hooks/use-profile-video-content"

import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import { InfiniteScroll } from "../../../common/infinite-scroll"
import * as styles from "../my-user-profile-page.css"

export type MyUserProfilePageTextModel = {
    title: string
    subtitle: string
    action: string
}

export type InfiniteVideoAreasData = InfiniteData<{
    items: VideoAreaFeedModel[]
    paging: Pagination
}>

export type InfinitePostAreasData = InfiniteData<{
    items: PostitArea[]
    paging: Pagination
}>

type Props = {
    profileId?: UserProfileId
    accountType: AccountType
    text: MyUserProfilePageTextModel
    onVideoSelected: (id: VideoId) => void
    onPostSelected: (id: PostitId) => void
}

export const MyUserProfilePostContent: FC<Props> = model => {
    const open = useAppStore(store => store.open)

    const myProfileVideoContentQuery = useProfileVideoContent(model.profileId)
    const myProfilePostContentQuery = useProfilePostContent(model.profileId)

    const myProfileVideoItems =
        myProfileVideoContentQuery.data?.pages.flatMap(
            page => page.items ?? [],
        ) ?? []
    const myProfilePostItems =
        myProfilePostContentQuery.data?.pages.flatMap(
            page => page.items ?? [],
        ) ?? []

    const data = mergeSortedInfiniteData({
        firstItems: myProfileVideoItems,
        secondItems: myProfilePostItems,
        firstHasNextPage: myProfileVideoContentQuery.hasNextPage,
        secondHasNextPage: myProfilePostContentQuery.hasNextPage,
        desiredAmount: 100,
    })

    const videosState = match(myProfileVideoContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const postsState = match(myProfilePostContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const contentState =
        videosState === "Loaded" && postsState === "Loaded"
            ? "Loaded"
            : videosState === "Failed" || postsState === "Failed"
              ? "Failed"
              : videosState === "Loading" || postsState === "Loading"
                ? "Loading"
                : "Unloaded"

    return pipe(
        contentState,
        O.fromPredicate(state => state !== "Unloaded"),
        O.fold(
            () => <></>,
            () =>
                pipe(
                    contentState,
                    O.fromPredicate(state => state === "Loaded"),
                    O.chain(O.fromPredicate(() => data.length === 0)),
                    O.fold(
                        () => (
                            <div className={styles.postContent}>
                                <InfiniteScroll
                                    disabled={
                                        !myProfileVideoContentQuery.hasNextPage &&
                                        !myProfilePostContentQuery.hasNextPage
                                    }
                                    state={contentState}
                                    threshold="100px"
                                    //TODO: fix issue with query merging and add back infinite scroll
                                    onLoadRequested={() => {}}
                                >
                                    {pipe(
                                        data,
                                        A.map(post =>
                                            pipe(
                                                post,
                                                O.fromPredicate(isVideoArea),
                                                O.fold(
                                                    () =>
                                                        pipe(
                                                            post,
                                                            O.fromPredicate(
                                                                isPostitArea,
                                                            ),
                                                            O.fold(
                                                                () => <></>,
                                                                postit => (
                                                                    <PostAreaTile
                                                                        key={
                                                                            postit
                                                                                .post
                                                                                .id
                                                                        }
                                                                        area={
                                                                            postit
                                                                        }
                                                                        onClicked={
                                                                            model.onPostSelected
                                                                        }
                                                                        onUserClicked={() => {}}
                                                                    />
                                                                ),
                                                            ),
                                                        ),
                                                    videoArea => (
                                                        <VideoAreaTile
                                                            key={
                                                                videoArea.video
                                                                    .id
                                                            }
                                                            videoArea={{
                                                                id: videoArea
                                                                    .video.id,
                                                                video: {
                                                                    ...videoArea.video,
                                                                    paymentRequired:
                                                                        videoArea
                                                                            .video
                                                                            .monetization
                                                                            .type ===
                                                                        "SubscriptionOnly",
                                                                },
                                                                createdAt:
                                                                    videoArea.createdAt,
                                                                profile:
                                                                    videoArea.profile,
                                                            }}
                                                            onUserClicked={() => {}}
                                                            onClicked={
                                                                model.onVideoSelected
                                                            }
                                                        />
                                                    ),
                                                ),
                                            ),
                                        ),
                                    )}
                                    {contentState === "Loading" &&
                                        fill(3, idx => (
                                            <PostLoadingTile key={idx} />
                                        ))}
                                </InfiniteScroll>
                            </div>
                        ),
                        () => (
                            <div className={styles.infoBox}>
                                <div className={styles.infoBoxText}>
                                    <p
                                        className={styles.infoBoxTitle}
                                        style={{ fontSize: vars.font.size.m }}
                                    >
                                        {model.text.title}
                                    </p>

                                    <p
                                        className={styles.infoBoxSubtitle}
                                        style={{
                                            fontSize: vars.font.size.regular,
                                        }}
                                    >
                                        {model.text.subtitle}
                                    </p>
                                </div>

                                <IonButton
                                    className={styles.createPostButton}
                                    onClick={() => open("CreateContentMenu")}
                                >
                                    <FontAwesomeIcon
                                        className={styles.createPostIcon}
                                        icon={faCirclePlus}
                                        color="black"
                                    />
                                    {model.text.action}
                                </IonButton>
                            </div>
                        ),
                    ),
                ),
        ),
    )
}
