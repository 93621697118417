import * as EQ from "fp-ts/Eq"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as T from "fp-ts/ReadonlyTuple"
import * as S from "fp-ts/string"

import { parseDate } from "fp-ts-std/Date"
import { memoize } from "fp-ts-std/Function"

import { dateFromUtcIsoString, stdShortNumber } from "../../utils/fp"

export const formatNumber = memoize(S.Eq)((lng: string) =>
    pipe(new Intl.NumberFormat(lng, { useGrouping: true }), nf =>
        nf.format.bind(nf),
    ),
)

export const formatPercent = memoize(S.Eq)((lng: string) =>
    pipe(
        new Intl.NumberFormat(lng, {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
        }),
        nf => nf.format.bind(nf),
    ),
)

const fixedDateFormats = {
    short: {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    },
    medium: {
        day: "numeric",
        month: "short",
        year: "numeric",
    },
    long: {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    },
} as const

type DateFormatName = keyof typeof fixedDateFormats

const EqWithFormatName = EQ.struct({
    lng: S.Eq,
    format: S.Eq as EQ.Eq<DateFormatName>,
})

const getDateFormatter = memoize(EqWithFormatName)(({ lng, format }) =>
    pipe(new Intl.DateTimeFormat(lng, fixedDateFormats[format]), df =>
        df.format.bind(df),
    ),
)

export const formatNumberShort = (lng: string) => (value: number) =>
    pipe(stdShortNumber(value), T.mapFst(formatNumber(lng)))

export const formatDate =
    (lng: string) =>
    (value: string, format: DateFormatName = "short") =>
        pipe(value, parseDate, O.map(getDateFormatter({ lng, format })))

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
const WEEK = 7 * DAY
const MONTH = 30 * DAY
const YEAR = 365 * DAY

const { floor, abs } = Math

// Not Deterministic, should be IO, not supported in view function
export const formatRelativeTimeToParts = (
    value: string,
    fromDate: Date = new Date(),
) =>
    pipe(
        value,
        dateFromUtcIsoString,
        O.map(d => {
            const difference = fromDate.getTime() - d.getTime(),
                diff = abs(difference)

            if (diff < SECOND) {
                return { tense: "now" } as const
            }

            const [count, timeFrame] =
                diff < MINUTE
                    ? ([floor(diff / SECOND), "second"] as const)
                    : diff < HOUR
                      ? ([floor(diff / MINUTE), "minute"] as const)
                      : diff < DAY
                        ? ([floor(diff / HOUR), "hour"] as const)
                        : diff < WEEK
                          ? ([floor(diff / DAY), "day"] as const)
                          : diff < MONTH
                            ? ([floor(diff / WEEK), "week"] as const)
                            : diff < YEAR
                              ? ([floor(diff / MONTH), "month"] as const)
                              : ([floor(diff / YEAR), "year"] as const)

            const tense = difference < 0 ? "future" : "past"

            // "count" as key name is required for plurals to work in i18next
            // https://www.i18next.com/translation-function/plurals
            return { count, tense, timeFrame } as const
        }),
    )

export const formatRelativeTimeShortToParts = (
    value: string,
    fromDate: Date = new Date(),
) =>
    pipe(
        value,
        dateFromUtcIsoString,
        O.map(d => {
            const difference = fromDate.getTime() - d.getTime(),
                diff = abs(difference)

            if (diff < SECOND) {
                return { tense: "now" } as const
            }

            const [count, timeFrame] =
                diff < MINUTE
                    ? ([floor(diff / SECOND), "s"] as const)
                    : diff < HOUR
                      ? ([floor(diff / MINUTE), "min"] as const)
                      : diff < DAY
                        ? ([floor(diff / HOUR), "h"] as const)
                        : diff < WEEK
                          ? ([floor(diff / DAY), "d"] as const)
                          : diff < MONTH
                            ? ([floor(diff / WEEK), "w"] as const)
                            : diff < YEAR
                              ? ([floor(diff / MONTH), "m"] as const)
                              : ([floor(diff / YEAR), "y"] as const)

            const tense = difference < 0 ? "future" : "past"

            // "count" as key name is required for plurals to work in i18next
            // https://www.i18next.com/translation-function/plurals
            return { count, tense, timeFrame } as const
        }),
    )
