import { IonSearchbar } from "@ionic/react"
import classNames from "classnames"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { isEmpty } from "fp-ts/string"
import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"

import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/tabs/tabs"
import { useImagePostsSearch } from "../../hooks/use-image-post-search"
import { useLiveEventsSearch } from "../../hooks/use-live-event-search"
import { useTextPostsSearch } from "../../hooks/use-text-post-search"
import { useUserProfilesSearch } from "../../hooks/use-user-profiles-search"
import { useVideosSearch } from "../../hooks/use-videos-search"
import { LocalStorage } from "../../local-storage"
import { fill } from "../../utils/array"
import { getEventDetailValue } from "../../utils/fp"
import { PostAreaTile } from "../feed/areas/post/post-area-tile"
import { VideoAreaTile } from "../feed/areas/videos/video-area-tile"
import { LiveEventSearchResultList } from "./live-events/live-event-search-result-list"
import { ResultNotFoundPlaceholder } from "./placeholder/result-not-found-placeholder"
import { SectionWithTitle } from "./section-with-title"
import { UserSearchResultList } from "./users/list/user-search-result-list"
import {
    UserSearchResultSectionLoading,
    UserSearchResultTileList,
} from "./users/tile/user-search-result-tile-list"
import { PostLoadingSection, PostLoadingTile } from "./videos/post-loading-tile"

import Skeleton from "react-loading-skeleton"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { UserProfileId } from "../../api/branded-types"
import { Content } from "../../common/content"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import {
    liveVideoByIdRoute,
    postByIdRoute,
    userProfileByIdRoute,
    videoByIdRoute,
} from "../routing/routing"
import * as styles from "./search-page.css"

import { useMe } from "hooks/use-me"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { Toolbar } from "../../common/toolbar"

const ResultNotFoundPlaceholderView: FC<{ searchKey: string }> = ({
    searchKey,
}) => (
    <ResultNotFoundPlaceholder
        title={`No results for "${searchKey}"`}
        description="Check the spelling or start a new search"
        className={styles.emptyContentPlaceholder}
    />
)

type Tab = "All" | "Profile" | "LiveEvent" | "Image" | "Video" | "Text"

export const SearchPage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const navigate = useNavigate()
    const { t } = useTranslation(["search"])
    const userId = LocalStorage.getOidcUserId()

    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const [searchKey, setSearchKey] = useState("")
    const [debouncedSearchKey] = useDebounce(searchKey, 250)
    const [searchTab, setSearchTab] = useState<Tab>("All")
    const [searchFocused, setSearchFocused] = useState(false)

    const userProfilesSearchQuery = useUserProfilesSearch({
        searchKey: debouncedSearchKey,
        profileId,
    })

    const userDependandQueriesEnabled =
        (!!debouncedSearchKey && !userProfilesSearchQuery.isLoading) ||
        !debouncedSearchKey

    const videosQuerySearchQuery = useVideosSearch({
        searchKey: debouncedSearchKey,
        profileId,
        enabled: userDependandQueriesEnabled,
    })
    const liveEventQuerySearchQuery = useLiveEventsSearch(debouncedSearchKey)
    const imagePostsQuerySearchQuery = useImagePostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        enabled: userDependandQueriesEnabled,
    })
    const textPostsQuerySearchQuery = useTextPostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        enabled: userDependandQueriesEnabled,
    })

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)
    const isSearchKeyEmptyRealTime = isEmpty(searchKey)

    const videosQuerySearchData = useMemo(
        () =>
            videosQuerySearchQuery.data?.pages.flatMap(page => page.items) ??
            [],
        [videosQuerySearchQuery.data?.pages],
    )
    const imageQuerySearchData = useMemo(
        () =>
            imagePostsQuerySearchQuery.data?.pages.flatMap(
                page => page.items,
            ) ?? [],
        [imagePostsQuerySearchQuery.data?.pages],
    )
    const textQuerySearchData = useMemo(
        () =>
            textPostsQuerySearchQuery.data?.pages.flatMap(page => page.items) ??
            [],
        [textPostsQuerySearchQuery.data?.pages],
    )

    // const headerRef = useRef(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLIonSearchbarElement>(null)

    // const isInView = useInView(headerRef, {
    //     margin: `${-62 - getSafeArea("top")}px`,
    // })

    const scrollTop = useCallback(() => {
        window.scrollTo({ behavior: "smooth", top: 0 })
    }, [])

    // const scrollHeader = useCallback(() => {
    //     window.scrollTo({ behavior: "smooth", top: 105 })
    // }, [])

    const resetSearch = useCallback(() => {
        setSearchKey("")
        setSearchTab("All")
    }, [])

    const hasAvailableUserProfiles =
        (userProfilesSearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !userProfilesSearchQuery.isPending &&
        userProfilesSearchQuery.isSuccess

    const hasAvailableVideos =
        (videosQuerySearchQuery.data?.pages.at(0)?.items.length ?? 0) > 0 &&
        !videosQuerySearchQuery.isPending &&
        videosQuerySearchQuery.isSuccess

    const hasAvailableLiveEvents =
        (liveEventQuerySearchQuery.data?.pages.at(0)?.liveEventAreas.length ??
            0) > 0 &&
        !liveEventQuerySearchQuery.isPending &&
        liveEventQuerySearchQuery.isSuccess

    const hasAvailableImagePosts =
        (imagePostsQuerySearchQuery.data?.pages.at(0)?.items.length ?? 0) > 0 &&
        !imagePostsQuerySearchQuery.isPending &&
        imagePostsQuerySearchQuery.isSuccess

    const hasAvailableTextPosts =
        (textPostsQuerySearchQuery.data?.pages.at(0)?.items.length ?? 0) > 0 &&
        !textPostsQuerySearchQuery.isPending &&
        textPostsQuerySearchQuery.isSuccess

    const isPending =
        liveEventQuerySearchQuery.isPending ||
        videosQuerySearchQuery.isPending ||
        userProfilesSearchQuery.isPending ||
        imagePostsQuerySearchQuery.isPending ||
        textPostsQuerySearchQuery.isPending

    const isNoResult =
        !isSearchKeyEmpty &&
        !hasAvailableImagePosts &&
        !hasAvailableTextPosts &&
        !hasAvailableLiveEvents &&
        !hasAvailableUserProfiles &&
        !hasAvailableVideos &&
        !isPending

    const isNoResultUser =
        !isSearchKeyEmpty &&
        !hasAvailableUserProfiles &&
        !userProfilesSearchQuery.isPending

    const isNoResultVideo =
        !isSearchKeyEmpty &&
        !hasAvailableVideos &&
        !videosQuerySearchQuery.isPending

    const isNoResultLiveStream =
        !isSearchKeyEmpty &&
        !hasAvailableLiveEvents &&
        !liveEventQuerySearchQuery.isPending

    const isNoResultTextPost = !isSearchKeyEmpty && !hasAvailableTextPosts

    const isNoResultImagePost = !isSearchKeyEmpty && !hasAvailableImagePosts

    const showUserProfilesTitle =
        hasAvailableUserProfiles || userProfilesSearchQuery.isPending
    const showLiveStreamTitle =
        hasAvailableLiveEvents || liveEventQuerySearchQuery.isPending

    const showVideosTitle =
        hasAvailableVideos || videosQuerySearchQuery.isPending

    const showImagePostsTitle =
        hasAvailableImagePosts || imagePostsQuerySearchQuery.isPending

    const showTextPostsTitle =
        hasAvailableTextPosts || textPostsQuerySearchQuery.isPending

    const userProfileState = match(userProfilesSearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const liveEventState = match(liveEventQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const videosState = match(videosQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const imagePostsState = match(imagePostsQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const textPostsState = match(textPostsQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const refreshSearchPage = async () => {
        await Promise.allSettled([
            userProfilesSearchQuery.refetch(),
            videosQuerySearchQuery.refetch(),
            liveEventQuerySearchQuery.refetch(),
            imagePostsQuerySearchQuery.refetch(),
            textPostsQuerySearchQuery.refetch(),
        ])
    }

    const onUserClicked = (id: UserProfileId) => {
        if (O.isNone(userId)) return

        navigate(
            userId.value === id.toString()
                ? "/app/my-profile"
                : userProfileByIdRoute(id),
        )
    }

    return (
        <Page className={styles.page}>
            <Toolbar>
                {/* <CondensedHeader isInView={isInView && isSearchKeyEmpty}> */}
                <IonSearchbar
                    className={styles.borderedSearch}
                    inputMode="search"
                    autoCapitalize="off"
                    inputmode="search"
                    ref={searchRef}
                    placeholder={
                        searchFocused ? "" : "Profiles, posts and more"
                    }
                    value={searchKey}
                    onIonInput={flow(
                        getEventDetailValue,
                        O.map(key => {
                            //set scroll position so user's UI is not jumping and he sees all the content
                            key.length !== 0 && scrollTop()
                            // when input is empty user might be stuck in different tab
                            key.length === 0 && setSearchTab("All")
                            setSearchKey(key)
                        }),
                    )}
                    onIonFocus={() => setSearchFocused(true)}
                    onIonBlur={() => setSearchFocused(false)}
                    cancelButtonText={t("search.cancel")}
                    onIonClear={resetSearch}
                    onIonCancel={() => {
                        resetSearch()
                        scrollTop()
                    }}
                    showCancelButton={
                        !isSearchKeyEmptyRealTime ? "always" : "never"
                    }
                />
                {/* </CondensedHeader> */}
            </Toolbar>

            <Tabs value={searchTab} onValueChange={setSearchTab}>
                <TabsList
                    className={classNames(
                        styles.tabsListContainer,
                        styles.tabsList,
                        !isSearchKeyEmptyRealTime ? styles.tabsListVisible : "",
                    )}
                >
                    <TabsTrigger<Tab> value="All">
                        {t("search.tabs.all.name")}
                    </TabsTrigger>
                    <TabsTrigger<Tab> value="Profile">
                        {t("search.tabs.profile.name")}
                    </TabsTrigger>
                    <TabsTrigger<Tab> value="LiveEvent">
                        {t("search.tabs.liveStream.name")}
                    </TabsTrigger>
                    <TabsTrigger<Tab> value="Image">Images</TabsTrigger>
                    <TabsTrigger<Tab> value="Video">
                        {t("search.tabs.video.name")}
                    </TabsTrigger>
                    <TabsTrigger<Tab> value="Text">Textposts</TabsTrigger>
                </TabsList>
                <Content className={styles.content} ref={contentRef}>
                    <PullToRefresh onRefresh={refreshSearchPage}>
                        {/* <div ref={headerRef}>
                            <p
                                style={{
                                    display: isSearchKeyEmptyRealTime
                                        ? "block"
                                        : "none",
                                    margin: "0 12px 11px 0px",
                                    fontSize: vars.font.size.xxl,
                                    fontWeight: 700,
                                }}
                            >
                                {t("search.title")}
                            </p>

                            <IonSearchbar
                                style={{
                                    display: isSearchKeyEmptyRealTime
                                        ? "block"
                                        : "none",
                                }}
                                placeholder="Profiles, posts and more"
                                autocapitalize="off"
                                className={classNames(
                                    styles.headerSearch,
                                    styles.borderedSearch,
                                )}
                                value={searchKey}
                                onIonFocus={() => {
                                    scrollHeader()
                                    searchRef.current?.setFocus()
                                }}
                                onIonClear={e => {
                                    e.stopPropagation()
                                    resetSearch()
                                }}
                            />
                        </div> */}

                        <div className={styles.divider} />

                        <TabsContent<Tab>
                            value="All"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            {
                                //? PROFILE LIST
                            }
                            <SectionWithTitle
                                className={classNames(
                                    showUserProfilesTitle ? styles.section : "",
                                )}
                                title={
                                    isSearchKeyEmpty
                                        ? t(
                                              "search.tabs.all.default.profile.title",
                                          )
                                        : t(
                                              "search.tabs.all.active.profile.title",
                                          )
                                }
                                showTitle={showUserProfilesTitle}
                                action={{
                                    text: t(
                                        "search.tabs.all.active.profile.action",
                                    ),
                                    onClick: () =>
                                        isSearchKeyEmpty
                                            ? navigate("/app/search/profiles")
                                            : setSearchTab("Profile"),
                                }}
                            >
                                <UserSearchResultTileList
                                    isLoading={
                                        userProfilesSearchQuery.isLoading
                                    }
                                    onUserClicked={onUserClicked}
                                    onActionClicked={onUserClicked}
                                    profiles={pipe(
                                        userProfilesSearchQuery?.data?.pages ??
                                            [],
                                        A.flatMap(page => page.data),
                                        A.takeLeft(4),
                                    )}
                                    text={{
                                        action: t("search.tabs.profile.action"),
                                    }}
                                />
                            </SectionWithTitle>
                            {
                                //? VIDEOS
                            }
                            <SectionWithTitle
                                className={classNames(
                                    showVideosTitle ? styles.section : "",
                                )}
                                title={
                                    isSearchKeyEmpty
                                        ? "Video trends."
                                        : "Videos"
                                }
                                showTitle={showVideosTitle}
                                action={{
                                    text: t(
                                        "search.tabs.all.active.video.action",
                                    ),
                                    onClick: isSearchKeyEmpty
                                        ? () => navigate("/app/search/videos")
                                        : () => setSearchTab("Video"),
                                }}
                            >
                                <div className={styles.postContentSection}>
                                    {pipe(
                                        videosQuerySearchData,
                                        A.takeLeft(4),
                                        A.map(videoArea => (
                                            <VideoAreaTile
                                                key={videoArea.video.id}
                                                videoArea={{
                                                    id: videoArea.video.id,
                                                    video: {
                                                        ...videoArea.video,
                                                        paymentRequired:
                                                            videoArea.video
                                                                .monetization
                                                                .type ===
                                                            "SubscriptionOnly",
                                                    },
                                                    createdAt:
                                                        videoArea.createdAt,
                                                    profile: videoArea.profile,
                                                }}
                                                onUserClicked={onUserClicked}
                                                onClicked={flow(
                                                    videoByIdRoute,
                                                    route => navigate(route),
                                                )}
                                            />
                                        )),
                                    )}

                                    {videosState === "Loading" &&
                                        fill(2, idx => (
                                            <PostLoadingTile key={idx} />
                                        ))}
                                </div>
                            </SectionWithTitle>
                            {
                                //? IMAGE POSTS
                            }
                            <SectionWithTitle
                                className={classNames(
                                    showImagePostsTitle ? styles.section : "",
                                )}
                                title={
                                    isSearchKeyEmpty ? "Image trends" : "Images"
                                }
                                showTitle={showImagePostsTitle}
                                action={{
                                    text: t(
                                        "search.tabs.all.active.video.action",
                                    ),
                                    onClick: isSearchKeyEmpty
                                        ? () =>
                                              navigate(
                                                  "/app/search/image-posts",
                                              )
                                        : () => setSearchTab("Image"),
                                }}
                            >
                                <div className={styles.postContentSection}>
                                    {pipe(
                                        imageQuerySearchData,
                                        A.takeLeft(4),
                                        A.map(postit => (
                                            <PostAreaTile
                                                key={postit.post.id}
                                                area={postit}
                                                onClicked={flow(
                                                    postByIdRoute,
                                                    route => navigate(route),
                                                )}
                                                onUserClicked={onUserClicked}
                                            />
                                        )),
                                    )}

                                    {imagePostsState === "Loading" &&
                                        fill(2, idx => (
                                            <PostLoadingTile key={idx} />
                                        ))}
                                </div>
                            </SectionWithTitle>
                            {
                                //? TEXT POSTS
                            }
                            <SectionWithTitle
                                className={classNames(
                                    showTextPostsTitle ? styles.section : "",
                                )}
                                title={
                                    isSearchKeyEmpty
                                        ? "Text post trends"
                                        : "Text"
                                }
                                showTitle={showTextPostsTitle}
                                action={{
                                    text: t(
                                        "search.tabs.all.active.video.action",
                                    ),
                                    onClick: isSearchKeyEmpty
                                        ? () =>
                                              navigate("/app/search/text-posts")
                                        : () => setSearchTab("Text"),
                                }}
                            >
                                <div className={styles.postContentSection}>
                                    {pipe(
                                        textQuerySearchData,
                                        A.takeLeft(4),
                                        A.map(postit => (
                                            <PostAreaTile
                                                key={postit.post.id}
                                                area={postit}
                                                onClicked={flow(
                                                    postByIdRoute,
                                                    route => navigate(route),
                                                )}
                                                onUserClicked={onUserClicked}
                                            />
                                        )),
                                    )}

                                    {textPostsState === "Loading" &&
                                        fill(2, idx => (
                                            <PostLoadingTile key={idx} />
                                        ))}
                                </div>
                            </SectionWithTitle>
                            {
                                //? LIVE EVENTS
                            }
                            <SectionWithTitle
                                className={classNames(
                                    showLiveStreamTitle ? styles.section : "",
                                )}
                                title={
                                    isSearchKeyEmpty
                                        ? t(
                                              "search.tabs.all.default.liveStream.title",
                                          )
                                        : t(
                                              "search.tabs.all.active.liveStream.title",
                                          )
                                }
                                showTitle={showLiveStreamTitle}
                                action={{
                                    text: t(
                                        "search.tabs.all.active.liveStream.action",
                                    ),
                                    onClick: () =>
                                        isSearchKeyEmpty
                                            ? navigate(
                                                  "/app/search/live-events",
                                              )
                                            : setSearchTab("LiveEvent"),
                                }}
                            >
                                <LiveEventSearchResultList
                                    isLoading={
                                        liveEventQuerySearchQuery.isLoading
                                    }
                                    text={{
                                        liveText: "Live",
                                    }}
                                    events={pipe(
                                        liveEventQuerySearchQuery?.data
                                            ?.pages ?? [],
                                        A.flatMap(page => page.liveEventAreas),
                                        A.takeLeft(4),
                                        A.map(data => ({
                                            viewCount:
                                                data.statistic.viewCount
                                                    .current,
                                            eventId: data.event.id,
                                            title: data.event.title,
                                            avatar: {
                                                image: data.profile.imageUrl,
                                                isLive: data.profile.isLive,
                                                name:
                                                    data.profile.displayName ??
                                                    data.profile.profileName,
                                            },
                                            onClicked: () =>
                                                pipe(
                                                    liveVideoByIdRoute(
                                                        data.event.creatorId,
                                                        data.event.id,
                                                    ),
                                                    route => navigate(route),
                                                ),
                                            onUserClicked: () =>
                                                onUserClicked(data.profile.id),
                                        })),
                                    )}
                                />
                            </SectionWithTitle>

                            {/* No Results Found */}
                            {isNoResult && (
                                <div className={styles.section}>
                                    <ResultNotFoundPlaceholderView
                                        searchKey={searchKey}
                                    />
                                </div>
                            )}
                        </TabsContent>

                        <TabsContent<Tab>
                            value="Profile"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <InfiniteScroll
                                disabled={!userProfilesSearchQuery.hasNextPage}
                                state={userProfileState}
                                threshold="100px"
                                onLoadRequested={
                                    userProfilesSearchQuery.fetchNextPage
                                }
                            >
                                <div className={styles.section}>
                                    <UserSearchResultList
                                        isLoading={
                                            userProfilesSearchQuery.isFetchingNextPage ||
                                            userProfilesSearchQuery.isLoading
                                        }
                                        onClicked={onUserClicked}
                                        profiles={pipe(
                                            userProfilesSearchQuery?.data
                                                ?.pages ?? [],
                                            A.flatMap(page => page.data),
                                        )}
                                    />
                                </div>

                                {isNoResultUser && (
                                    <ResultNotFoundPlaceholderView
                                        searchKey={searchKey}
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>

                        <TabsContent<Tab>
                            value="Video"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <InfiniteScroll
                                disabled={!videosQuerySearchQuery.hasNextPage}
                                state={videosState}
                                threshold="100px"
                                onLoadRequested={
                                    videosQuerySearchQuery.fetchNextPage
                                }
                            >
                                <div
                                    className={classNames(
                                        styles.section,
                                        styles.postContentSection,
                                    )}
                                >
                                    {pipe(
                                        videosQuerySearchData,
                                        A.map(videoArea => (
                                            <VideoAreaTile
                                                key={videoArea.video.id}
                                                videoArea={{
                                                    id: videoArea.video.id,
                                                    video: {
                                                        ...videoArea.video,
                                                        paymentRequired:
                                                            videoArea.video
                                                                .monetization
                                                                .type ===
                                                            "SubscriptionOnly",
                                                    },
                                                    createdAt:
                                                        videoArea.createdAt,
                                                    profile: videoArea.profile,
                                                }}
                                                onUserClicked={onUserClicked}
                                                onClicked={flow(
                                                    videoByIdRoute,
                                                    route => navigate(route),
                                                )}
                                            />
                                        )),
                                    )}
                                    {videosQuerySearchQuery.isFetchingNextPage ||
                                        (videosQuerySearchQuery.isLoading &&
                                            fill(2, idx => (
                                                <PostLoadingTile key={idx} />
                                            )))}
                                </div>
                                {isNoResultVideo && (
                                    <ResultNotFoundPlaceholderView
                                        searchKey={searchKey}
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>

                        <TabsContent<Tab>
                            value="LiveEvent"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <InfiniteScroll
                                disabled={
                                    !liveEventQuerySearchQuery.hasNextPage
                                }
                                state={liveEventState}
                                threshold="100px"
                                onLoadRequested={
                                    liveEventQuerySearchQuery.fetchNextPage
                                }
                            >
                                <div className={styles.section}>
                                    <LiveEventSearchResultList
                                        isLoading={
                                            liveEventQuerySearchQuery.isFetchingNextPage ||
                                            liveEventQuerySearchQuery.isLoading
                                        }
                                        text={{ liveText: "Live" }}
                                        events={pipe(
                                            liveEventQuerySearchQuery?.data
                                                ?.pages ?? [],
                                            A.flatMap(
                                                page => page.liveEventAreas,
                                            ),
                                            A.map(data => ({
                                                viewCount:
                                                    data.statistic.viewCount
                                                        .current,
                                                eventId: data.event.id,
                                                title: data.event.title,
                                                avatar: {
                                                    image: data.profile
                                                        .imageUrl,
                                                    isLive: data.profile.isLive,
                                                    name:
                                                        data.profile
                                                            .displayName ??
                                                        data.profile
                                                            .profileName,
                                                },
                                                onClicked: () =>
                                                    pipe(
                                                        liveVideoByIdRoute(
                                                            data.event
                                                                .creatorId,
                                                            data.event.id,
                                                        ),
                                                        route =>
                                                            navigate(route),
                                                    ),
                                                onUserClicked: () =>
                                                    onUserClicked(
                                                        data.profile.id,
                                                    ),
                                            })),
                                        )}
                                    />
                                </div>
                                {isNoResultLiveStream && (
                                    <ResultNotFoundPlaceholderView
                                        searchKey={searchKey}
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                        <TabsContent<Tab>
                            value="Image"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <InfiniteScroll
                                disabled={
                                    !imagePostsQuerySearchQuery.hasNextPage
                                }
                                state={imagePostsState}
                                threshold="100px"
                                onLoadRequested={
                                    imagePostsQuerySearchQuery.fetchNextPage
                                }
                            >
                                <div
                                    className={classNames(
                                        styles.section,
                                        styles.postContentSection,
                                    )}
                                >
                                    {pipe(
                                        imagePostsQuerySearchQuery?.data
                                            ?.pages ?? [],
                                        A.flatMap(page => page.items),
                                        A.map(postit => (
                                            <PostAreaTile
                                                key={postit.post.id}
                                                area={postit}
                                                onClicked={flow(
                                                    postByIdRoute,
                                                    route => navigate(route),
                                                )}
                                                onUserClicked={onUserClicked}
                                            />
                                        )),
                                    )}
                                    {imagePostsQuerySearchQuery.isFetchingNextPage ||
                                        (imagePostsQuerySearchQuery.isLoading &&
                                            fill(2, idx => (
                                                <PostLoadingTile key={idx} />
                                            )))}
                                </div>
                                {isNoResultImagePost && (
                                    <ResultNotFoundPlaceholderView
                                        searchKey={searchKey}
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                        <TabsContent<Tab>
                            value="Text"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <InfiniteScroll
                                disabled={
                                    !textPostsQuerySearchQuery.hasNextPage
                                }
                                state={textPostsState}
                                threshold="100px"
                                onLoadRequested={
                                    textPostsQuerySearchQuery.fetchNextPage
                                }
                            >
                                <div
                                    className={classNames(
                                        styles.section,
                                        styles.postContentSection,
                                    )}
                                >
                                    {pipe(
                                        textPostsQuerySearchQuery?.data
                                            ?.pages ?? [],
                                        A.flatMap(page => page.items),
                                        A.map(postit => (
                                            <PostAreaTile
                                                key={postit.post.id}
                                                area={postit}
                                                onClicked={flow(
                                                    postByIdRoute,
                                                    route => navigate(route),
                                                )}
                                                onUserClicked={onUserClicked}
                                            />
                                        )),
                                    )}
                                    {textPostsQuerySearchQuery.isFetchingNextPage ||
                                        (textPostsQuerySearchQuery.isLoading &&
                                            fill(2, idx => (
                                                <PostLoadingTile key={idx} />
                                            )))}
                                </div>
                                {isNoResultTextPost && (
                                    <ResultNotFoundPlaceholderView
                                        searchKey={searchKey}
                                    />
                                )}
                            </InfiniteScroll>
                        </TabsContent>
                    </PullToRefresh>
                </Content>
            </Tabs>
        </Page>
    )
}

export const SearchPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={styles.loadingContent}>
            <Skeleton width={196} height={30} borderRadius={16} />
            <Skeleton
                style={{ marginTop: 18, marginBottom: 20 }}
                height={36}
                width={"100%"}
                borderRadius={10}
            />
            <div style={{ marginBottom: 24 }} className={styles.divider} />
            <UserSearchResultSectionLoading />
            <div style={{ marginTop: 24 }} />
            <PostLoadingSection />
        </Content>
    </Page>
)
//TODO: unused, but contains useful logic for future impl.
// refactor when we have time to make this function properly

// type SearchPageData = {
//     //TODO: extend to have full data which is needed
//     profiles?: InfiniteSearchProfilesData
//     videos?: InfiniteSearchVideosData
//     images?: InfiniteSearchImagePostsData
// }

// export const searchPagePreloadDataBuilder =
//     new PagePreloadDataBuilder<SearchPageData>()

// const items = [
//     {
//         key: "profiles",
//         queryKey: QueryKeys.searchUserProfiles({ searchKey: "" }),
//         fetchFunction: getSearchProfiles,
//         defaultFilter: searchProfilesDefaultFilter,
//         isInfinite: true,
//         getNextPageParam: getSearchProfilesNextPageParam,
//     },
//     {
//         key: "videos",
//         queryKey: QueryKeys.searchVideos({ searchKey: "" }),
//         fetchFunction: getSearchVideos,
//         defaultFilter: searchVideosDefaultFilter,
//         isInfinite: true,
//         getNextPageParam: getSearchVideosNextPageParam,
//     },
//     {
//         key: "images",
//         queryKey: QueryKeys.searchImagePosts({ searchKey: "" }),
//         fetchFunction: getSearchImagePosts,
//         defaultFilter: searchImagePostsDefaultFilter,
//         isInfinite: true,
//         getNextPageParam: getSearchImagePostsNextPageParam,
//     },
// ]

// export const searchPageLoader = createPageLoader({
//     items,
//     builder: searchPagePreloadDataBuilder,
//     emptyData: {
//         pageParams: [],
//         pages: [],
//     },
// })

export default SearchPage
