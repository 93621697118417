import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { Video } from "api/api-models"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mergeWithQueryCache,
} from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { userProfileSelectorClient } from "../../../api/clients/user-profile-api-client"
import { videoSelectorClient } from "../../../api/clients/video-api-client"
import { QueryKeys } from "../../../api/query-keys"
import { VideoAreaFeedModel } from "../../feed/areas/videos/video-area-tile"

const PAGINATION_AMOUNT = 25

export const useProfileVideoContent = (profileId?: UserProfileId) => {
    const filter = `{$match: {mimeType: !"" and !"unknown/unknown", creatorId: in ["${profileId}"]}, $sort:{createdAt: desc}}`
    const queryClient = useQueryClient()
    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            getProfileVideoContent({ pageParam, queryClient, filter }),
        queryKey: QueryKeys.profileVideoContent(profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.items.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!profileId,
    })
}

export const getProfileVideoContent = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: number
    filter: string
    queryClient: QueryClient
}) => {
    const videos = await videoSelectorClient.listVideos({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    videos.data.forEach(video =>
        queryClient.setQueryData<Video>(QueryKeys.video(video.id), video),
    )

    const profileIds = pipe(
        videos.data,
        A.map(video => video.creatorId),
    )

    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        profileIds,
        QueryKeys.profile,
    )

    const getProfiles =
        missingProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("id", profileIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const profilesRes = await getProfiles

    const profiles = mergeWithQueryCache(
        queryClient,
        profileIds,
        QueryKeys.profile,
        profilesRes.data,
    )

    return pipe(
        videos.data,
        A.map(video => {
            const profile = profiles.find(p => p.id === video.creatorId)

            return {
                id: video.id,
                profile,
                video,
                createdAt: video.createdAt,
            } as VideoAreaFeedModel
        }),
        A.filter(
            ({ profile, video }) =>
                video !== undefined && profile !== undefined,
        ),
        items => ({
            items,
            paging: videos.paging,
        }),
    )
}
