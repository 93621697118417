import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"

import { UserMessage, UserProfile } from "../../../api/api-models"
import {
    getQueryClientMissingIds,
    mapToMatchFilterDc,
    mergeWithQueryCache,
} from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { userMessageSelectorClient } from "../../../api/clients/message-api-client"
import { userProfileSelectorClient } from "../../../api/clients/user-profile-api-client"
import { QueryKeys } from "../../../api/query-keys"

type Params = {
    me?: UserProfileId
    pageLimit?: number
}

export type UserMyMessageWithProfile = {
    id: string
    message: UserMessage
    profile: UserProfile
}

export const useMyMessages = (params: Params) => {
    const queryKey = QueryKeys.myResourceMessages(params.me)
    const queryClient = useQueryClient()

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) =>
            listMyMessages({
                ...params,
                pageParam,
                queryClient,
            }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (
                Object.keys(lastPage.messagesWithUserData).length !==
                (params.pageLimit ?? 50)
            )
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + (params.pageLimit ?? 50)
                : -1
        },
        enabled: !!params.me,
        notifyOnChangeProps: ["data", "error"],
    })
}

const listMyMessages = async ({
    pageParam,
    me,
    pageLimit,
    queryClient,
}: {
    pageParam: number
    queryClient: QueryClient
} & Params) => {
    if (!me) throw new Error("My profile Id is required")

    const messageGroups = await userMessageSelectorClient.listMessageGroups({
        filter: `{ $match: {profileId: "${me}"} }`,
        paging: {
            type: "Index",
            direction: "After",
            limit: pageLimit ?? 50,
            index: pageParam,
        },
    })

    const profileIdsWithNewMessages = pipe(
        messageGroups.data,
        A.map(({ partner, newMessageIds }) => ({
            profileId: partner.contentId,
            newMessageIds,
        })),
    )
    const profileIds = pipe(
        profileIdsWithNewMessages,
        A.map(({ profileId }) => profileId),
    )
    const missingProfileIds = getQueryClientMissingIds(
        queryClient,
        profileIds,
        QueryKeys.profile,
    )

    const messageRequests = pipe(
        profileIds,
        A.map(
            profileId => `{
                $match: {$or: [
                    {recipient.contentType: "UserProfile", recipient.contentId: "${me}", senderId: "${profileId}"},
                    {recipient.contentType: "UserProfile", recipient.contentId: "${profileId}", senderId: "${me}"},
                ]},
                $sort: {createdAt: desc}
            }}`,
        ),
        A.map(filter =>
            userMessageSelectorClient.listMessages({
                filter,
                paging: {
                    type: "Index",
                    direction: "After",
                    limit: 1,
                    index: -1,
                },
            }),
        ),
    )

    const messages = await Promise.all(messageRequests)

    const profilesPromise =
        missingProfileIds.length > 0
            ? userProfileSelectorClient.listProfiles({
                  filter: `{${mapToMatchFilterDc("id", missingProfileIds)}}`,
              })
            : Promise.resolve({ data: [] })

    const profilesRes = await profilesPromise

    profilesRes.data.forEach(profile =>
        queryClient.setQueryData<UserProfile>(
            QueryKeys.profile(profile.id),
            profile,
        ),
    )

    const profiles = mergeWithQueryCache(
        queryClient,
        profileIds,
        QueryKeys.profile,
        profilesRes.data,
    )

    const profilesWithMessages = pipe(
        profiles,
        A.map(profile => ({
            ...profile,
            messages: pipe(
                messages,
                A.chain(m => m.data),
                A.filter(
                    m =>
                        m.senderId === profile.id ||
                        m.recipient.contentId === profile.id,
                ),
            ),
            newMessageIds: pipe(
                profileIdsWithNewMessages,
                A.filter(e => e.profileId === profile.id),
                A.chain(e => e.newMessageIds),
            ),
        })),
    )

    return pipe(profilesWithMessages, messagesWithUserData => ({
        total: messageGroups.totalCount,
        paging: messageGroups.paging,
        messagesWithUserData,
    }))
}
