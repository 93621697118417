/* eslint-disable functional/no-throw-statements */

import { useQuery } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"

import { CreatorRef, VotedResourceRef } from "../../../api/api-models"
import { VoteId } from "../../../api/branded-types"
import { votingSelectorClient } from "../../../api/clients/voting-api-client"
import { QueryKeys } from "../../../api/query-keys"

type UseMyResourceVoteParams = {
    voteResourceRef?: VotedResourceRef
    voter?: CreatorRef
}

export type MyResourceVoteData = {
    data: {
        isLiked: boolean
        voteId: O.Option<VoteId>
    }
}

export const useMyResourceVote = ({
    voteResourceRef,
    voter,
}: UseMyResourceVoteParams) => {
    const queryKey = QueryKeys.myResourceVote(voteResourceRef, voter)

    const queryFn = async () => {
        if (!voteResourceRef?.contentId)
            throw new Error("VoteResourceId is required")

        if (!voter) throw new Error("CreatorRef is required")

        const filter =
            voter.type === "User"
                ? `{$match: {voter.type: "User", voter.id: "${voter.id}"}}`
                : `{$match: {voter.type: "UserProfile", voter.id: "${voter.id}"}}`

        const votesRes =
            await votingSelectorClient.listVotesOfTypeByVotedResource(
                {
                    filter,
                },
                {
                    params: {
                        id: voteResourceRef.contentId,
                        ref: voteResourceRef.contentType,
                        type: "Like",
                    },
                },
            )

        const voteId = pipe(
            votesRes.data,
            A.lookup(0),
            O.map(data => data.id),
        )

        return {
            isLiked: O.isSome(voteId),
            voteId,
        }
    }

    return useQuery({
        queryKey,
        queryFn,
        enabled: !!voteResourceRef && !!voter,
    })
}
