import { makeApi } from "@zodios/core"
import z from "zod"

import {
    AgoraVideo,
    ApiError,
    LiveEvent,
    LiveEventStatistic,
    Pagination,
} from "../api-models"
import { AudienceUrl, LiveEventId, UserProfileId } from "../branded-types"

export const liveEventSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/events",
        alias: "listLiveEvents",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    // TODO create typed meta filter instead of string
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(LiveEvent),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/event/:id",
        alias: "getLiveEventById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
        ],
        response: z.object({
            data: LiveEvent,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/event/:id/viewers",
        alias: "listViewersByLiveEventId",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserProfileId),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/event/:id/viewers/current",
        alias: "listCurrentViewersByLiveEventId",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserProfileId),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/events/statistics",
        alias: "listLiveEventsStatistics",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    // TODO create typed meta filter instead of string
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(LiveEventStatistic),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/event/:id/statistic",
        alias: "getLiveEventStatisticById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
        ],
        response: z.object({
            data: LiveEventStatistic,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/event/:id/session/audience-url",
        description:
            "The audience URL is utilized to enable the HTML player to receive and consume the streaming data from Agora.",
        alias: "getAudienceUrlById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
        ],
        response: z.object({
            data: AudienceUrl,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/event/:id/session/agora",
        description:
            "Retrieve the existing and active live event using the associated live event ID.",
        alias: "getStartedLiveEventById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
        ],
        response: z.object({
            data: AgoraVideo,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
