import { faBellRing } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton } from "@ionic/react"

import classNames from "classnames"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./enable-notifications-modal.css"

type TextModel = {
    title: string
    description: string
    cancel: string
    submit: string
}

export type EnableNotificationsModalModel = {
    text: TextModel
    isOpen: boolean
    onSubmit: () => void
    onClosed: () => void
}

export const EnableNotificationsModal = (
    props: EnableNotificationsModalModel,
) => {
    return (
        <Drawer open={props.isOpen} onClose={props.onClosed}>
            <DrawerContent className={styles.content} onClose={props.onClosed}>
                <DrawerTitle />
                <DrawerDescription />
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon
                        //todo: ask design and lead staff to decide whether
                        //we would need @fortawesome/pro-duotone-svg-icons
                        //for styles required by design
                        className={styles.icon}
                        icon={faBellRing}
                    />
                </div>
                <p
                    className={styles.text}
                    style={{
                        margin: "16px 0 0 0",
                        fontSize: vars.font.size.xxxl,
                        fontWeight: 600,
                    }}
                >
                    {props.text.title}
                </p>
                <p
                    className={classNames(styles.text, styles.description)}
                    style={{
                        margin: "16px 0 0 0",
                        marginInline: "auto",
                        fontSize: vars.font.size.regular,
                    }}
                >
                    {props.text.description}
                </p>

                <div className={styles.buttonContainer}>
                    <IonButton
                        className={styles.submitButton}
                        onClick={props.onSubmit}
                    >
                        {props.text.submit}
                    </IonButton>
                    <IonButton
                        onClick={props.onClosed}
                        className={styles.cancelButton}
                    >
                        {props.text.cancel}
                    </IonButton>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
