import { FC } from "react"

import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { useNavigate } from "react-router-dom"
import { useAppStore } from "store"
import { withAuthorizedAccess } from "../../api/api-utils"
import { Visibility, VisibilityAdt } from "../../data-flow/common"
import { useMe } from "../../hooks/use-me"
import { LocalStorage } from "../../local-storage"
import { CreateContentOverviewModal } from "../modals/create-content-overview-modal"

export type ContextMenuButtonModel = {
    icon: string
    color?: string
    backgroundColor?:
        | "primary"
        | "secondary"
        | "tertiary"
        | "success"
        | "warning"
        | "danger"
        | "dark"
        | "light"
        | "medium"
    disabled?: boolean
    onClicked: () => void
}

export type CreateContentMenuModel = {
    isOpen: boolean
    showFeatureNotAvailable: () => void
    onVisibilityChange: (visibility: Visibility) => void
}

export const CreateContentMenu: FC<CreateContentMenuModel> = ({
    isOpen,
    showFeatureNotAvailable,
    onVisibilityChange,
}) => {
    const open = useAppStore(store => store.open)
    const navigate = useNavigate()

    const meQuery = useMe(isOpen)

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    return (
        <CreateContentOverviewModal
            title="Create content"
            isOpen={isOpen}
            onClosed={() => onVisibilityChange(VisibilityAdt.as.Invisible({}))}
            showGoLive={withAuthorizedAccess({
                onAuthorized: () => navigate("/app/live-event/creator"),
                onUnauthorized: () => open("CreateAccount"),
                accountType,
            })}
            showVideoUpload={withAuthorizedAccess({
                onAuthorized: () => navigate("/app/video-upload"),
                onUnauthorized: () => open("CreateAccount"),
                accountType,
            })}
            showImageUpload={withAuthorizedAccess({
                onAuthorized: () => navigate("/app/image-upload"),
                onUnauthorized: () => open("CreateAccount"),
                accountType,
            })}
            showTextUpload={withAuthorizedAccess({
                onAuthorized: () => navigate("/app/text-upload"),
                onUnauthorized: () => open("CreateAccount"),
                accountType,
            })}
            showFeatureNotAvailable={showFeatureNotAvailable}
        />
    )
}
