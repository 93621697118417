import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, User, UserPaymentInfo } from "../api-models"
import { UserName } from "../branded-types"

export const userCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/user",
        alias: "createUser",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    username: UserName,
                }),
            },
        ],
        response: z.object({
            data: User,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "put",
        path: "/v1/user/me/payment",
        alias: "updateUserPayment",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    address: z.string(),
                    name: z.string(),
                    taxId: z.string(),
                    isSalesTaxLiable: z.boolean(),
                }),
            },
        ],
        response: z.object({
            data: UserPaymentInfo,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
