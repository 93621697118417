import classNames from "classnames"
import { HTMLMotionProps, motion } from "framer-motion"
import { forwardRef } from "react"
import { isIOS } from "react-device-detect"
import { useLocation, useNavigationType } from "react-router-dom"
import * as styles from "./page.css"

type Direction = "forward" | "back"
//! TODO: x -> transitionX -> any transition creates its own viewport which breaks `position: fixed`
// https://stackoverflow.com/questions/28157125/why-does-transform-break-position-fixed
const variants = {
    enter: (_direction: Direction) => ({
        zIndex: 1,
        // x: direction === "forward" ? "20%" : "-20%",
        opacity: 1,
    }),
    center: {
        zIndex: 1,
        // x: 0,
        opacity: 1,
        transitionEnd: {
            zIndex: 0,
        },
    },
    exit: (_direction: Direction) => ({
        zIndex: 0,
        // x: direction === "forward" ? "-20%" : "20%",
        opacity: 1,
    }),
}

export const Page = forwardRef<React.ElementRef<"div">, HTMLMotionProps<"div">>(
    ({ className, ...props }, ref) => {
        const navigationType = useNavigationType()
        const location = useLocation()
        const direction = getNavigationDirection(navigationType)

        const shouldNotAnimate =
            (direction === "back" && isIOS) ||
            (direction === "forward" && location.state?.doNotAnimate)

        return (
            <motion.div
                {...props}
                key={location.pathname}
                custom={direction}
                initial="enter"
                animate="center"
                exit="exit"
                variants={variants}
                transition={{
                    type: "spring",
                    duration: shouldNotAnimate ? 0 : 0.25,
                    mass: 0.2,
                }}
                ref={ref}
                className={classNames(styles.page, className)}
            />
        )
    },
)

export const getNavigationDirection = (action: string): Direction =>
    action === "POP" ? "back" : "forward"
