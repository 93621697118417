import { UserProfile } from "../api/api-models"
import { Url, UserId, UserProfileId } from "../api/branded-types"

export const getDefaultUserProfile = (id: UserProfileId): UserProfile => ({
    createdAt: "",
    engagementScore: 0,
    id,
    imageUrl: Url.parse("/image/default_avatar.jpg"),
    isDefaultImage: true,
    isLive: false,
    lastChangedAt: "",
    links: [],
    profileName: "",
    userId: UserId.parse(""),
    description: "",
    displayName: "",
    location: "",
})
