import { Asset, ImageAsset, ResourceRef, ResourceUrl } from "api/api-models"
import { Url } from "api/branded-types"
import { GetAssetByIdResponse } from "api/selectors/asset-selector-api"
import axios, { AxiosRequestConfig } from "axios"
import { LocalStorage } from "local-storage"

const createRequestConfig = (
    config?: AxiosRequestConfig,
): AxiosRequestConfig => ({
    withCredentials: true,
    responseType: "json",
    ...config,

    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
        Authorization: LocalStorage.getAccessToken()
            ? `Bearer ${LocalStorage.getAccessToken()}`
            : undefined,
        ...config?.headers,
    },
})

export const requestClient = async (
    url: string,
    config?: AxiosRequestConfig,
) => {
    const response = await axios(url, createRequestConfig(config))
    return response.data as Blob
}

export const requestBlobClient = async (
    url: string,
    config?: AxiosRequestConfig,
) => {
    const response = await axios(
        url,
        createRequestConfig({ ...config, responseType: "blob" }),
    )
    return response.data as Blob
}

export const getAssetData = async (src?: string) => {
    if (!src) throw new Error("Source is required to get data")
    const response = await requestBlobClient(src)
    return response
}

export const getAssetById = async (url?: ResourceUrl) => {
    if (!url) throw new Error("Url is required to get asset")
    const response = await requestClient(url)
    const assetResponseResult = GetAssetByIdResponse.safeParse(response)
    if (!assetResponseResult.success) {
        console.error(assetResponseResult.error.issues)
        throw new Error(
            `Result provided does not match the expected format: ${assetResponseResult.error.issues}`,
        )
    }

    return assetResponseResult.data
}

export const isResourceRef = (
    assetResource: Url | ResourceRef,
): assetResource is ResourceRef => ResourceRef.safeParse(assetResource).success

export const isImageAsset = (asset: Asset): asset is ImageAsset =>
    asset.type === "Image"

export type WithLogoAsset<T extends Record<string, unknown>> = T & {
    logoAsset?: Asset
}
