import { useCallback, useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"
import { PushNotification } from "../api/api-models"

export type PushEventMessage = {
    type: "PUSH_NOTIFICATION"
    data: PushNotification
}
export type ClickNotificationMessage = {
    type: "CLICK_NOTIFICATION"
    data: string | undefined
}
export type ServiceWorkerPostMessage =
    | PushEventMessage
    | ClickNotificationMessage

//todo: refactor - use global service worker registration - swRegistration.active
export const usePushNotification = () => {
    const navigate = useNavigate()

    const [notification, setNotification] = useState<
        PushNotification | undefined
    >()

    useEffect(() => {
        if (
            !(
                "serviceWorker" in navigator &&
                navigator.serviceWorker &&
                "PushManager" in window
            )
        )
            return

        const handleMessage = (
            event: MessageEvent<ServiceWorkerPostMessage>,
        ) => {
            if (!event.data) return
            if (event.data.type === "PUSH_NOTIFICATION") {
                setNotification(event.data.data)
            }
            if (event.data.type === "CLICK_NOTIFICATION") {
                const navigateUrl = event.data.data
                navigate(`${navigateUrl}`)
            }
        }

        navigator.serviceWorker.addEventListener("message", handleMessage)

        return () => {
            navigator.serviceWorker.removeEventListener(
                "message",
                handleMessage,
            )
        }
    }, [])

    const resetNotification = useCallback(() => setNotification(undefined), [])

    return {
        notification,
        resetNotification,
    }
}
