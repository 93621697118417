import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from "common/dialog"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog"
import { calc } from "@vanilla-extract/css-utils"
import { PrimaryButton, SecondaryButton } from "common/buttons"
import { FC } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useNavigate } from "react-router-dom"
import { vars } from "theme/variables.css"

type Props = {
    isOpen: boolean
    onOpenChange: (v: boolean) => void
}

export const CongratulationSilverModal: FC<Props> = model => {
    const navigate = useNavigate()

    return (
        <Dialog open={model.isOpen} onOpenChange={model.onOpenChange}>
            <DialogContent
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    textAlign: "center",
                    padding: "0 40px 50px 40px",
                    backgroundColor: "#C0C0C0",
                    color: vars.color.medium.hex,
                    whiteSpace: "pre-wrap",

                    minWidth: "100vw",
                    height: "100vh",
                    borderRadius: 0,
                }}
            >
                <DialogHeader
                    style={{
                        position: "fixed",
                        paddingTop: calc.add(
                            "env(safe-area-inset-top, 0)",
                            "60px",
                        ),
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 60,
                        backgroundColor: "#C0C0C0",
                        zIndex: 50,
                    }}
                >
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{
                            position: "absolute",
                            top: calc.add(
                                "env(safe-area-inset-top, 0)",
                                "20px",
                            ),
                            left: 20,
                            width: 30,
                            height: 30,
                            background: "transparent",
                        }}
                        onClick={() => model.onOpenChange(false)}
                    />
                </DialogHeader>
                <div
                    style={{
                        position: "relative",
                        maxHeight: "calc(100% - 120px)",
                        bottom: 120,
                        overflow: "scroll",
                        paddingTop: calc.add(
                            "env(safe-area-inset-top, 0)",
                            "60px",
                        ),
                    }}
                >
                    <div
                        style={{
                            marginTop: 20,
                            marginBottom: 26,

                            display: "flex",
                            flexDirection: "column",
                            gap: 4,
                        }}
                    >
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xxxl,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            Yeah!
                        </DialogTitle>

                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xxxl,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            Congratulations
                        </DialogTitle>

                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            You're starting at{" "}
                            <span style={{ fontWeight: 700, margin: 0 }}>
                                Silver level.
                            </span>
                        </DialogDescription>
                    </div>

                    <LazyLoadImage
                        width={window.innerWidth - 36 * 2}
                        height={(window.innerWidth - 36 * 2) * 0.44}
                        alt="rewards"
                        loading="eager"
                        src="/images/gfx_new_level.svg"
                        style={{ marginBottom: 40 }}
                    />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 45,
                            borderRadius: 10,
                            fontSize: vars.font.size.l,
                            fontWeight: 600,
                            background:
                                "var(--Opacity-Light-Dark, rgba(48, 42, 80, 0.15))",
                            marginBottom: 26,
                        }}
                    >
                        +100 Points
                    </div>

                    <p style={{ textAlign: "left", marginBottom: 26 }}>
                        Amazing! You’ve reached the Silver level. Enjoy enhanced
                        features and new benefits. Keep up the great work!
                    </p>

                    <div style={{ textAlign: "left", marginBottom: 50 }}>
                        <p style={{ fontWeight: 600 }}>
                            Your benefits as a Silver member:
                        </p>

                        <ul>
                            <li>Personal support and priority responses</li>
                            <li>Discounts on premium content</li>
                            <li>Access to the Seemee community</li>
                            <li>And more...</li>
                        </ul>
                    </div>
                </div>

                <DialogFooter
                    style={{
                        display: "flex",
                        flexDirection: "column",

                        width: "100%",

                        position: "fixed",
                        padding: "24px 36px 52px",
                        bottom: 0,
                    }}
                >
                    <PrimaryButton
                        onClick={() => {
                            model.onOpenChange(false)
                        }}
                    >
                        Close
                    </PrimaryButton>

                    <SecondaryButton
                        onClick={() => {
                            model.onOpenChange(false)
                            navigate(
                                "/app/settings/rewards?currentTab=get-started",
                            )
                        }}
                    >
                        To my badges
                    </SecondaryButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
