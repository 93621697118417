import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useMemo, useState } from "react"
import * as styles from "./tab-bar.css"

import {
    faHouseBlank as houseLight,
    faMessages as messageLight,
    faSearch as searchLight,
} from "@fortawesome/pro-light-svg-icons"

import {
    faPlus,
    faHouseBlank as house,
    faMessages as message,
    faSearch as search,
} from "@fortawesome/pro-regular-svg-icons"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useTranslation } from "react-i18next"
import { NavLink, Outlet } from "react-router-dom"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { VisibilityAdt } from "../../data-flow/common"
import { useMyMessages } from "../../features/messaging/hooks/use-my-messages"
import { useUnreadNotifications } from "../../features/notifications/hooks/use-unread-notifications"
import { MyProfileAvatar } from "../../features/user-profile/my-profile-avatar"
import { useMe } from "../../hooks/use-me"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { LocalStorage } from "../../local-storage"
import { CreateContentMenu } from "../buttons/create-content-menu"
import { NewFeatureComingSoonModal } from "../modals/new-feature-coming-soon-modal"

export const TabBar: FC = () => {
    const open = useAppStore(store => store.open)
    const close = useAppStore(store => store.close)
    const isModalVisible = useAppStore(store => store.isModalVisible)
    const scrollFeedToTop = useAppStore(state => state.scrollFeedToTop)

    const { t: tUser } = useTranslation(["user"])

    const [newFeatureModalVisibility, setNewFeatureModalVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const newNotificationsQuery = useUnreadNotifications(profileId)

    const myMessagesQuery = useMyMessages({
        me: profileId,
    })

    const newMessageIds = pipe(
        myMessagesQuery.data?.pages ?? [],
        A.chain(page => page.messagesWithUserData),
        A.chain(x => x.newMessageIds),
    )

    const isHaveNewNotifications =
        (newNotificationsQuery.data?.totalCount ?? 0) > 0

    const handleAuthorizedAccess = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (getIsAuthorizedAccount(accountType)) return
            e.preventDefault()
            open("CreateAccount")
        },
        [accountType, open],
    )

    return (
        <>
            <CreateContentMenu
                isOpen={isModalVisible.CreateContentMenu}
                showFeatureNotAvailable={() =>
                    setNewFeatureModalVisibility(VisibilityAdt.as.Visible({}))
                }
                onVisibilityChange={() => close("CreateContentMenu")}
            />
            <NewFeatureComingSoonModal
                isOpen={VisibilityAdt.is.Visible(newFeatureModalVisibility)}
                onClosed={() =>
                    setNewFeatureModalVisibility(VisibilityAdt.as.Invisible({}))
                }
                text={{
                    title: tUser("myUserProfile.newFeatureComingSoon.title"),
                    description: tUser(
                        "myUserProfile.newFeatureComingSoon.description",
                    ),
                }}
            />
            <Outlet />

            <div className={styles.container}>
                <NavLink
                    className={styles.tabButton}
                    to="/app/feed"
                    state={{
                        doNotAnimate: true,
                    }}
                >
                    {({ isActive }) => (
                        <div
                            className={styles.iconWrapper}
                            onClick={() => {
                                if (isActive) scrollFeedToTop()
                            }}
                        >
                            <FontAwesomeIcon
                                color="white"
                                style={{
                                    height: "26px",
                                    color: !isActive
                                        ? vars.color.tertiary.hex
                                        : "inherit",
                                }}
                                icon={isActive ? house : houseLight}
                            />
                            {isHaveNewNotifications && (
                                <div className={styles.notificationsBadge} />
                            )}
                        </div>
                    )}
                </NavLink>

                <NavLink
                    className={styles.tabButton}
                    to="/app/search"
                    state={{
                        doNotAnimate: true,
                    }}
                >
                    {({ isActive }) => (
                        <FontAwesomeIcon
                            color="white"
                            style={{
                                height: "26px",
                                color: !isActive
                                    ? vars.color.tertiary.hex
                                    : "inherit",
                            }}
                            icon={isActive ? search : searchLight}
                        />
                    )}
                </NavLink>

                <div
                    onClick={() => open("CreateContentMenu")}
                    data-test="action-create-content"
                    style={{
                        background: "#FF944B",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 40,
                        height: 40,
                    }}
                >
                    <FontAwesomeIcon
                        color={vars.color.background.hex}
                        style={{
                            width: 24,
                            height: 24,
                        }}
                        icon={faPlus}
                    />
                </div>

                <NavLink
                    className={styles.tabButton}
                    to="/app/messages"
                    onClick={handleAuthorizedAccess}
                    state={{
                        doNotAnimate: true,
                    }}
                >
                    {({ isActive }) =>
                        isActive ? (
                            <FontAwesomeIcon
                                color="white"
                                style={{
                                    height: "26px",
                                }}
                                icon={message}
                            />
                        ) : (
                            <div className={styles.iconWrapper}>
                                <FontAwesomeIcon
                                    color="white"
                                    style={{
                                        height: "26px",
                                        color: vars.color.tertiary.hex,
                                    }}
                                    icon={messageLight}
                                />
                                {newMessageIds.length > 0 && (
                                    <span
                                        className={styles.messageCountInfo}
                                        style={{ fontSize: vars.font.size.xs }}
                                    >
                                        {newMessageIds.length}
                                    </span>
                                )}
                            </div>
                        )
                    }
                </NavLink>

                <NavLink
                    className={styles.tabButton}
                    to="/app/my-profile"
                    onClick={handleAuthorizedAccess}
                    state={{
                        doNotAnimate: true,
                    }}
                >
                    {({ isActive }) => (
                        <MyProfileAvatar
                            isAuthorizedAccount={isAuthorizedAccount}
                            avatarSize={30}
                            style={{
                                border: isActive
                                    ? "1px solid white"
                                    : "1px solid #B4B2C0",
                            }}
                        />
                    )}
                </NavLink>
            </div>
        </>
    )
}
