import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    validationErrorHandler,
} from "../api-utils"
import { messageCreatorApi } from "../creators/message-creator-api"
import { messageSelectorApi } from "../selectors/message-selector-api"

// creator client
export const userMessageCreatorClient = new Zodios(
    `${API_URL}/message-creator`,
    messageCreatorApi,
)

userMessageCreatorClient.use(tokenHandlerForCreate)
userMessageCreatorClient.use(corsFetchHandler)
userMessageCreatorClient.use(validationErrorHandler)

export const postMessageTask = pipe(
    userMessageCreatorClient.postMessage,
    endpointWithTaskEither,
)

// selector client
export const userMessageSelectorClient = new Zodios(
    `${API_URL}/message-selector`,
    messageSelectorApi,
)

userMessageSelectorClient.use(tokenHandlerForCreate)
userMessageSelectorClient.use(corsFetchHandler)
userMessageSelectorClient.use(validationErrorHandler)

export const getMessageTask = pipe(
    userMessageSelectorClient.getMessage,
    endpointWithTaskEither,
)

export const listMessagesTask = pipe(
    userMessageSelectorClient.listMessages,
    endpointWithTaskEither,
)
