import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query"

import { User } from "api/api-models"
import { userSelectorClient } from "../api/clients/user-api-client"
import { QueryKeys, StaticQueryKeys } from "../api/query-keys"

export const useMe = (enabled: boolean = true) => {
    const queryClient = useQueryClient()
    return useQuery({
        queryFn: () => getMe(queryClient),
        queryKey: [StaticQueryKeys.ME],
        enabled,
    })
}

const getMe = async (queryClient: QueryClient) => {
    const meRes = await userSelectorClient.getMe()
    queryClient.setQueryData<User>(QueryKeys.user(meRes.data.id), meRes.data)

    return {
        user: meRes.data,
        accountType: meRes.accountType,
    }
}
