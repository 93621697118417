import { FC } from "react"

import { motion } from "framer-motion"
import { useMeasure } from "hooks/use-measure"
import { Drawer, DrawerContent } from "./drawer"

export const multiStepModalVariants = {
    initial: { opacity: 0, scale: 0.96 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.96 },
}

type MultiStepModalWrapperModel = {
    open: boolean
    onClose: () => void
    onReset: () => void
    children?: React.ReactNode
}

export const MultiStepModalWrapper: FC<MultiStepModalWrapperModel> = ({
    open,
    onClose,
    onReset,
    children,
}) => {
    const [containerRef, { height }] = useMeasure()

    return (
        <Drawer
            open={open}
            onAnimationEnd={open => !open && onReset()}
            onClose={onClose}
        >
            <DrawerContent onClose={close}>
                <motion.div
                    animate={{
                        height,
                        transition: {
                            duration: 0.27,
                            ease: [0.25, 1, 0.5, 1],
                        },
                    }}
                >
                    <div ref={containerRef}>{children}</div>
                </motion.div>
            </DrawerContent>
        </Drawer>
    )
}
