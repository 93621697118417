import { Zodios } from "@zodios/core"
import { pipe } from "fp-ts/function"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    endpointWithTaskEither,
    tokenHandlerForCreate,
    validationErrorHandler,
} from "../api-utils"
import { authCreatorApi } from "../creators/auth-creator-api"

// creator client
export const authCreatorClient = new Zodios(
    `${API_URL}/security-auth`,
    authCreatorApi,
)

authCreatorClient.use(tokenHandlerForCreate)
authCreatorClient.use(corsFetchHandler)
authCreatorClient.use(validationErrorHandler)

export const createShareTokenTask = pipe(
    authCreatorClient.createShareToken,
    endpointWithTaskEither,
)
