import { MotionValue } from "framer-motion"

import {
    faEllipsisVertical,
    faMessage,
    faShare,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as styles from "../user-profile-page.css"

type UserProfilePageActionsModel = {
    opacity?: MotionValue<number> | MotionValue<string>
    onShareClicked: () => void
    onMessageClicked: () => void
    onActionsClicked: () => void
}

export const UserProfilePageActions = (model: UserProfilePageActionsModel) => (
    <div className={styles.actionGroup}>
        <FontAwesomeIcon
            icon={faShare}
            className={styles.actionIcon}
            onClick={model.onShareClicked}
            size="xl"
            color="white"
        />
        <FontAwesomeIcon
            icon={faMessage}
            className={styles.actionIcon}
            onClick={model.onMessageClicked}
            size="xl"
            color="white"
        />
        <FontAwesomeIcon
            icon={faEllipsisVertical}
            className={styles.actionIcon}
            onClick={model.onActionsClicked}
            size="xl"
            color="white"
        />
    </div>
)
